.sk-block,
.sk-content,
.sk-content-block,
.sk-page-modal,
.sk-blog-content .article-body > .article-content,
.page-block {

  $headings: (
    'h1-tablet-size': 64px,
    'h1-tablet-line-height': 75px,
  );

  @include Typography($headings);

  .text-white,
  &.text-white {
    @include WhiteText;
  }
  .text-blue,
  &.text-blue {
    @include BlueText;
  }

  p {
    margin-bottom: $contentPadding;
  }
  p:last-child {
    margin-bottom: 0;
  }

  h5,
  .h5 {
    font-weight: 600;
  }

  .char {
    opacity: 1 !important;
  }
}
