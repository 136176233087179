.background-video-wrap {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  @media screen and ($mobile_all) {
    &.parallax-img {
      height: calc(100% + 100px);
    }
  }

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
  }
}

.background-video {
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  &.display-video {
    display: block;
  }
}
