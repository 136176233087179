.inner-table-heading {
  background-color: transparent !important;
  margin-top: 25px !important;
  display: block !important;

  td {
    color: $blue-1 !important;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
  }
}

// Modifications for reviews block
.reviews-table-block {
  @media screen and ($mobile_all) {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .table-cols-4 {
      min-width: 600px;
    }

    .table-cols-8 {
      min-width: 800px;
    }
  }

  .table-cols-4 {
    th {
      width: 25%;
    }

    td {
      width: 25%;
    }
  }

  .table-cols-8 {
    th {
      border-right: 1px solid $gray-20;
      width: 10%;

      &:first-of-type {
        width: 30%;
      }
    }

    td {
      border-right: 1px solid $gray-20;
      width: 10%;

      &:first-of-type {
        width: 30%;
      }
    }
  }

  .heading-table {
    margin-bottom: 0;

    thead {
      color: $black;

      @media screen and ($mobile_sm) {
        font-size: 6vw;
      }

      @media screen and ($mobile_lg) {
        font-size: 30px;
      }

      @media screen and ($min1024) {
        font-size: 1.563vw;
      }

      @media screen and ($min1920) {
        font-size: 30px;
      }

      th {
        vertical-align: middle;
      }

      th:first-of-type {
        text-align: left;
        letter-spacing: 0.35px;

        @media screen and ($min1024) {
          letter-spacing: 0.018vw;
        }

        @media screen and ($min1920) {
          letter-spacing: 0.35px;
        }
      }
    }
  }

  table {
    border-spacing: 0;
    border-collapse: separate;
    table-layout: fixed;
    text-align: center;
    width: 100%;
    margin-bottom: 25px;
    font-weight: 700;

    @media screen and ($min1024) {
      margin-bottom: 1.302vw;
    }

    @media screen and ($min1920) {
      margin-bottom: 25px;
    }

    thead {
      color: $blue-1;

      @media screen and ($mobile_sm) {
        font-size: 4.8vw;
      }

      @media screen and ($mobile_lg) {
        font-size: 24px;
      }

      @media screen and ($min1024) {
        font-size: 1.25vw;
      }

      @media screen and ($min1920) {
        font-size: 24px;
      }

      th:first-of-type {
        text-align: left;
        letter-spacing: 0.35px;

        @media screen and ($min1024) {
          letter-spacing: 0.018vw;
        }

        @media screen and ($min1920) {
          letter-spacing: 0.35px;
        }
      }
    }

    tbody tr {
      td:first-of-type {
        text-align: left;
        color: $gray-18;
      }

      td:not(:first-of-type) {
        color: $gray-19;
      }

      &:nth-of-type(odd) {
        background-color: $blue-20;
      }
    }

    strong,
    b {
      color: $blue-1;
    }

    tr {
      vertical-align: initial;

      &.four-columns-heading-row {
        vertical-align: middle;

        th {
          text-align: center;

          &:first-child {
            text-align: left;
          }

          img {
            display: block;
            margin: 0 auto;
          }
        }
      }
    }

    img {
      vertical-align: middle;
    }

    th {
      padding: 3px;

      &:first-of-type {
        padding-left: 0;
        padding-right: 0;
      }
    }

    td {
      padding: 4px;

      &:first-of-type {
        padding-left: 0;
        padding-right: 0;
      }
    }

    tbody {
      font-size: 11px;

      @media screen and ($min400) {
        font-size: 12px;
      }

      @media screen and ($min480) {
        font-size: 14px;
      }

      @media screen and ($min640) {
        font-size: 16px;
      }

      @media screen and ($min1024) {
        font-size: 12px;
      }

      @media screen and ($min1280) {
        font-size: 14px;
      }

      @media screen and ($min1440) {
        font-size: 16px;
      }
    }
  }
}

.table-for-reviews {
  @media screen and ($mobile) {
    margin-bottom: 30px;
  }
}

.txt {
  &-post-rtf {
    &.textpage-rtf {
      .table-wrapper {
        @media screen and ($mobile_all) {
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;

          table {
            min-width: 550px;
          }
        }
      }
      table {
        font-weight: 700;
        border-spacing: 0;
        border-collapse: separate;
        table-layout: fixed;
        text-align: center;
        width: 100%;
        border: 1px solid $blue-16;
        border-radius: 25px;

        @media screen and ($mobile_sm) {
          margin: 11vw 0vw;
        }

        @media screen and ($mobile_lg) {
          margin: 55px 0;
        }

        @media screen and ($min1024) {
          margin: 2.865vw 0vw;
          margin-right: (-$contentPadding);
          width: calc(100% + 27px);
        }

        @media screen and ($min1920) {
          margin: 55px 0;
        }

        thead {
          color: $blue-1;
        }

        th {
          border-bottom: 1px solid $blue-16;
          padding: 23px 15px;

          @media screen and ($min1024) {
            padding: 1.198vw 0.781vw;
          }

          @media screen and ($min1920) {
            padding: 23px 15px;
          }

          &:not(:last-of-type) {
            border-right: 1px solid $blue-16;
          }
        }

        tr:last-of-type {
          td {
            border-bottom: none;
          }
        }

        td {
          padding: 15px;

          @media screen and ($min1024) {
            padding: 0.781vw;
          }

          @media screen and ($min1920) {
            padding: 15px;
          }

          border-bottom: 1px solid $blue-16;

          &:not(:last-of-type) {
            border-right: 1px solid $blue-16;
          }
        }

        tbody {
          color: $blue-15;
          line-height: 1.4285;
          font-size: 11px;

          @media screen and ($min400) {
            font-size: 12px;
          }

          @media screen and ($min480) {
            font-size: 13px;
          }

          @media screen and ($min640) {
            font-size: 14px;
          }

          @media screen and ($min1024) {
            font-size: 12px;
          }

          @media screen and ($min1280) {
            font-size: 13px;
          }

          @media screen and ($min1440) {
            font-size: 14px;
          }
        }
      }
    }

    &.txt-blocks-layout-rtf {
      .table-wrapper {
        @media screen and ($mobile_all) {
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;

          table {
            min-width: 550px;
          }
        }
      }

      &.table-block {
        @media screen and ($mobile_all) {
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;

          .table-cols-3 {
            min-width: 400px;
          }

          .table-cols-4 {
            min-width: 600px;
          }

          .table-cols-8 {
            min-width: 800px;
          }
        }

        .table-cols-3 {
          th {
            width: 33.33%;

            @media screen and ($min1024) {
              padding-top: 40px;

              .first-accent-column {
                padding-top: 35px;
              }
            }
          }

          td {
            width: 33.33%;
          }
        }

        .table-cols-4 {
          th {
            width: 25%;
          }

          td {
            width: 25%;
          }
        }

        .table-cols-8 {
          th {
            border-right: 1px solid $gray-20;
            width: 10%;

            &:first-of-type {
              width: 30%;
            }
          }

          td {
            border-right: 1px solid $gray-20;
            width: 10%;

            &:first-of-type {
              width: 30%;
            }
          }
        }

        .heading-table {
          margin-bottom: 0;

          thead {
            color: $black;

            @media screen and ($mobile_sm) {
              font-size: 6vw;
            }

            @media screen and ($mobile_lg) {
              font-size: 30px;
            }

            @media screen and ($min1024) {
              font-size: 1.563vw;
            }

            @media screen and ($min1920) {
              font-size: 30px;
            }

            th {
              vertical-align: middle;
            }

            th:first-of-type {
              text-align: left;
              letter-spacing: 0.35px;

              @media screen and ($min1024) {
                letter-spacing: 0.018vw;
              }

              @media screen and ($min1920) {
                letter-spacing: 0.35px;
              }
            }
          }
        }

        table {
          letter-spacing: 0;
          border-spacing: 0;
          border-collapse: separate;
          table-layout: fixed;
          text-align: center;
          width: 100%;
          font-weight: 300;
          line-height: 1.2;
          margin-bottom: 25px;

          @media screen and ($min1024) {
            margin-bottom: 1.302vw;
          }

          @media screen and ($min1920) {
            margin-bottom: 25px;
          }

          thead {
            color: $blue-1;

            @media screen and ($mobile_sm) {
              font-size: 4.8vw;
            }

            @media screen and ($mobile_lg) {
              font-size: 24px;
            }

            @media screen and ($min1024) {
              font-size: 1.25vw;
            }

            @media screen and ($min1920) {
              font-size: 24px;
            }

            th:first-of-type {
              text-align: left;
              letter-spacing: 0.35px;

              @media screen and ($min1024) {
                letter-spacing: 0.018vw;
              }

              @media screen and ($min1920) {
                letter-spacing: 0.35px;
              }
            }
          }

          tbody tr {
            text-align: left;

            td:first-of-type {
              color: $blue-1;
              font-weight: 700;
            }

            td:not(:first-of-type) {
              color: $blue-15;
            }

            &:nth-of-type(odd) {
              background-color: $blue-22;
            }
          }

          strong,
          b {
            color: $blue-1;
          }

          tr {
            vertical-align: initial;
          }

          img {
            vertical-align: middle;
          }

          th {
            padding: 14px 10px 28px;

            @media screen and ($min1024) {
              padding: 14px 10px 28px;
            }

            @media screen and ($min1920) {
              padding: 14px 10px 28px;
            }
          }

          td {
            padding: 14px 14px 15px 47px;

            @media screen and ($min1024) {
              padding: 0.729vw 0.729vw 0.781vw 2.448vw;
            }

            @media screen and ($min1920) {
              padding: 14px 14px 15px 47px;
            }

            &:first-of-type {
              padding: 18px 15px 26px 37px;

              @media screen and ($min1024) {
                padding: 0.938vw 0.781vw 1.354vw 1.927vw;
              }

              @media screen and ($min1920) {
                padding: 18px 15px 26px 37px;
              }
            }

            &:last-of-type {
              padding-left: 25px;
              padding-right: 20px;

              @media screen and ($min1024) {
                padding-left: 1.302vw;
                padding-right: 1.042vw;
              }

              @media screen and ($min1920) {
                padding-left: 25px;
                padding-right: 20px;
              }
            }
          }

          tbody {
            font-size: 11px;

            @media screen and ($min400) {
              font-size: 12px;
            }

            @media screen and ($min480) {
              font-size: 13px;
            }

            @media screen and ($min640) {
              font-size: 14px;
            }

            @media screen and ($min1024) {
              font-size: 12px;
            }

            @media screen and ($min1280) {
              font-size: 13px;
            }

            @media screen and ($min1440) {
              font-size: 14px;
            }
          }
        }
      }

      &:not(.table-block) {
        table {
          font-weight: 700;
          border-spacing: 0;
          border-collapse: separate;
          table-layout: fixed;
          text-align: center;
          width: 100%;
          border: 1px solid $blue-16;
          border-radius: 25px;

          @media screen and ($mobile_sm) {
            margin: 11vw 0vw;
          }

          @media screen and ($mobile_lg) {
            margin: 55px 0;
          }

          @media screen and ($min1024) {
            margin: 2.865vw 0vw;
            margin-right: (-$contentPadding);
            width: calc(100% + 27px);
          }

          @media screen and ($min1920) {
            margin: 55px 0;
          }

          &.reviews-table {
            width: 90%;

            @media screen and ($min1024) {
              width: calc(90% + 27px);
            }
          }

          thead {
            color: $blue-1;
          }

          th {
            border-bottom: 1px solid $blue-16;
            padding: 23px 15px;

            @media screen and ($min1024) {
              padding: 1.198vw 0.781vw;
            }

            @media screen and ($min1920) {
              padding: 23px 15px;
            }

            &:not(:last-of-type) {
              border-right: 1px solid $blue-16;
            }
          }

          tr:last-of-type {
            td {
              border-bottom: none;

              &:first-child {
                border-bottom-left-radius: 25px;
              }

              &:last-child {
                border-bottom-right-radius: 25px;
              }
            }
          }

          td {
            padding: 15px;

            @media screen and ($min1024) {
              padding: 0.781vw;
            }

            @media screen and ($min1920) {
              padding: 15px;
            }

            border-bottom: 1px solid $blue-16;

            &:not(:last-of-type) {
              border-right: 1px solid $blue-16;
            }
          }

          tbody {
            color: $blue-15;
            line-height: 1.4285;
            font-size: 11px;

            @media screen and ($min400) {
              font-size: 12px;
            }

            @media screen and ($min480) {
              font-size: 13px;
            }

            @media screen and ($min640) {
              font-size: 14px;
            }

            @media screen and ($min1024) {
              font-size: 12px;
            }

            @media screen and ($min1280) {
              font-size: 13px;
            }

            @media screen and ($min1440) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.four-column-table {
  tbody tr {
    td:first-of-type {
      text-align: left;
      color: $gray-18;
    }

    td:not(:first-of-type) {
      color: $gray-19;
    }

    &:nth-of-type(odd) {
      background-color: $blue-20;
    }
  }
}
