// icons

.retail-svg {
  .st0 {
    fill: $blue-1;
  }

  .st1 {
    fill: $green-1;
  }
}

.health-wellness-svg {
  .st0 {
    fill: $green-1;
  }

  .st1 {
    fill: $blue-1;
  }
}

.grocery-svg {
  .st0 {
    fill: $blue-1;
  }

  .st1 {
    fill: $green-1;
  }
}

.ecommerce-svg {
  .st0 {
    fill: $blue-1;
  }

  .st1 {
    fill: $green-1;
  }
}

.restaurant-svg {
  .st0 {
    fill: $green-1;
  }

  .st1 {
    fill: $blue-1;
  }
}

.hospitality-svg {
  .st0 {
    fill: $blue-1;
  }

  .st1 {
    fill: $green-1;
  }
}

.fashion-boutiques-svg {
  .st0 {
    fill: $blue-1;
  }

  .st1 {
    fill: $green-1;
  }
}

.homeware-store-svg {
  .st0 {
    fill: $blue-1;
  }

  .st1 {
    fill: $green-1;
  }
}

.professional-services-svg {
  .st0 {
    fill: $green-1;
  }

  .st1 {
    fill: $blue-1;
  }
}

.wellness-icon-svg {
  .st0 {
    fill: $green-1;
  }

  .st1 {
    fill: $blue-1;
  }
}

.wholesale-icon-svg {
  .st0 {
    fill: $green-1;
  }

  .st1 {
    fill: $blue-1;
  }
}

.non-profit-svg {
  .cls-1 {
    fill: $blue-25;
  }

  .cls-2 {
    fill: $green-1;
  }
}

.beauty-salons-svg {
  .cls-1 {
    fill: $blue-25;
  }

  .cls-2 {
    fill: $green-1;
  }
}

.hardware-svg {
  .cls-1 {
    fill: $blue-25;
  }

  .cls-2 {
    fill: $green-1;
  }
}
