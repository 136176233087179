.txt {
  &-14 {
    font-size: 14px;
  }
  &-15 {
    font-size: 15px;
  }
  &-16 {
    font-size: 16px;
  }
  &-17 {
    font-size: 17px;
  }

  &-18 {
    font-size: 18px;
  }

  &-19 {
    font-size: 19px;
  }

  &-20 {
    font-size: 20px;
  }

  &-21 {
    font-size: 21px;
  }

  &-22 {
    font-size: 22px;
  }

  &-23 {
    font-size: 23px;
  }

  &-24 {
    font-size: 24px;
  }
}

.disclaimer {
  font-size: 12px;
  letter-spacing: 0.6px;
}

.txt {
  &-size-10 {
    font-size: 10px;
  }

  &-size-12 {
    font-size: 12px;
  }

  &-size-14 {
    font-size: 11px;

    @media screen and ($min400) {
      font-size: 12px;
    }

    @media screen and ($min480) {
      font-size: 13px;
    }

    @media screen and ($min640) {
      font-size: 14px;
    }

    @media screen and ($min1024) {
      font-size: 12px;
    }

    @media screen and ($min1280) {
      font-size: 13px;
    }

    @media screen and ($min1440) {
      font-size: 14px;
    }
  }

  &-size-16 {
    font-size: 12px;

    @media screen and ($min480) {
      font-size: 14px;
    }

    @media screen and ($min640) {
      font-size: 16px;
    }

    @media screen and ($min1024) {
      font-size: 11px;
    }

    @media screen and ($min1280) {
      font-size: 12px;
    }

    @media screen and ($min1366) {
      font-size: 13px;
    }

    @media screen and ($min1440) {
      font-size: 14px;
    }

    @media screen and ($min1660) {
      font-size: 15px;
    }

    @media screen and ($min1800) {
      font-size: 16px;
    }
  }

  &-size-17 {
    font-size: 11px;

    @media screen and ($min400) {
      font-size: 13px;
    }

    @media screen and ($min480) {
      font-size: 15px;
    }

    @media screen and ($min640) {
      font-size: 17px;
    }

    @media screen and ($min1024) {
      font-size: 12px;
    }

    @media screen and ($min1280) {
      font-size: 13px;
    }

    @media screen and ($min1366) {
      font-size: 14px;
    }

    @media screen and ($min1440) {
      font-size: 15px;
    }

    @media screen and ($min1660) {
      font-size: 16px;
    }

    @media screen and ($min1800) {
      font-size: 17px;
    }
  }

  &-18 {
    font-size: 18px;
  }

  &-size-18 {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 18px, 500);
    }

    @media screen and ($mobile_lg) {
      font-size: $ft-18;
    }

    @media screen and ($min1024) {
      font-size: 13px;
    }

    @media screen and ($min1280) {
      font-size: 14px;
    }

    @media screen and ($min1366) {
      font-size: 15px;
    }

    @media screen and ($min1440) {
      font-size: 16px;
    }

    @media screen and ($min1660) {
      font-size: 17px;
    }

    @media screen and ($min1800) {
      font-size: $ft-18;
    }
  }

  &-size-21 {
    font-size: 21px;

    @media screen and ($min1024) {
      @include vw('font-size', 21px);
    }

    @media screen and ($min1920) {
      font-size: 21px;
    }
  }

  &-size-22 {
    font-size: 22px;

    @media screen and ($min1024) {
      @include vw('font-size', 22px);
    }

    @media screen and ($min1920) {
      font-size: 22px;
    }
  }

  &-size-24 {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 24px, 500);
    }

    @media screen and ($mobile_lg) {
      font-size: 24px;
    }

    @media screen and ($min1024) {
      @include vw('font-size', 24px);
    }

    @media screen and ($min1920) {
      font-size: 24px;
    }
  }

  &-size-30 {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 30px, 500);
    }

    @media screen and ($mobile_lg) {
      font-size: 30px;
    }

    @media screen and ($min1024) {
      @include vw('font-size', 30px);
    }

    @media screen and ($min1920) {
      font-size: 30px;
    }
  }

  &-size-36 {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 36px, 500);
    }

    @media screen and ($mobile_lg) {
      font-size: 36px;
    }

    @media screen and ($min1024) {
      @include vw('font-size', 36px);
    }

    @media screen and ($min1920) {
      font-size: 36px;
    }
  }

  &-size-38 {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 38px, 500);
    }

    @media screen and ($mobile_lg) {
      font-size: 38px;
    }

    @media screen and ($min1024) {
      @include vw('font-size', 38px);
    }

    @media screen and ($min1920) {
      font-size: 38px;
    }
  }

  &-size-44 {
    font-size: 44px;

    @media screen and ($min1024) {
      @include vw('font-size', 44px);
    }

    @media screen and ($min1920) {
      font-size: 44px;
    }
  }

  &-size-48 {
    font-size: 48px;

    @media screen and ($min1024) {
      @include vw('font-size', 48px);
    }

    @media screen and ($min1920) {
      font-size: 48px;
    }
  }

  &-size-60 {
    font-size: 60px;

    @media screen and ($min1024) {
      @include vw('font-size', 60px);
    }

    @media screen and ($min1920) {
      font-size: 60px;
    }
  }

  &-size-72 {
    font-size: 72px;

    @media screen and ($min1024) {
      @include vw('font-size', 72px);
    }

    @media screen and ($min1920) {
      font-size: 72px;
    }
  }

  &-size-80 {
    font-size: 80px;

    @media screen and ($min1024) {
      @include vw('font-size', 80px);
    }

    @media screen and ($min1920) {
      font-size: 80px;
    }
  }

  &-size-90 {
    font-size: 90px;

    @media screen and ($min1024) {
      @include vw('font-size', 89px);
    }

    @media screen and ($min1920) {
      font-size: 89px;
    }
  }

  &-size-100 {
    @media screen and ($min1024) {
      @include vw('font-size', 100px);
    }

    @media screen and ($min1920) {
      font-size: 100px;
    }
  }

  &-size-120 {
    @media screen and ($min1024) {
      @include vw('font-size', 120px);
    }

    @media screen and ($min1920) {
      font-size: 120px;
    }
  }

  &-size-18-m {
    @media screen and ($mobile_all) {
      font-size: $ft-18;
    }
  }

  &-size-72-m {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 72px, 500px);
    }

    @media screen and ($mobile_lg) {
      font-size: 72px;
    }
  }

  &-size-60-m {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 60px, 500px);
    }

    @media screen and ($mobile_lg) {
      font-size: 60px;
    }
  }

  &-size-57-m {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 57px, 500px);
    }

    @media screen and ($mobile_lg) {
      font-size: 57px;
    }
  }

  &-size-48-m {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 48px, 500px);
    }

    @media screen and ($mobile_lg) {
      font-size: 48px;
    }
  }

  &-size-44-m {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 44px, 500px);
    }

    @media screen and ($mobile_lg) {
      font-size: 44px;
    }
  }

  &-size-36-m {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 36px, 500px);
    }

    @media screen and ($mobile_lg) {
      font-size: 36px;
    }
  }

  &-size-24-m {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 24px, 500px);
    }

    @media screen and ($mobile_lg) {
      font-size: 24px;
    }
  }

  &-size-30-m {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 30px, 500px);
    }

    @media screen and ($mobile_lg) {
      font-size: 30px;
    }
  }

  &-post-rtf {
    font-family: $libre;

    p:not(:last-of-type) {
      color: inherit;
      letter-spacing: inherit;

      margin-bottom: $paragraph-margin-bottom;
    }

    ul,
    ol {
      list-style-position: outside;
    }

    small {
      font-size: inherit;
    }

    a {
      // display: inline-block;
      line-height: inherit;
      color: $green-1;
      font-size: inherit;
      letter-spacing: 0.4px;

      @media screen and ($min1024) {
        @include vw('letter-spacing', 0.4px);
      }

      @media screen and ($min1920) {
        letter-spacing: 0.4px;
      }

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    h2 {
      line-height: 1.25;
      margin-bottom: 15px;

      @media screen and ($mobile_sm) {
        @include vw('font-size', 24px, 500);
      }

      @media screen and ($mobile_lg) {
        font-size: 24px;
      }

      @media screen and ($min1024) {
        @include vw('font-size', 24px);
        @include vw('margin-bottom', 21px);
      }

      @media screen and ($min1920) {
        font-size: 24px;
        margin-bottom: 21px;
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $redhat;
    }

    &.textpage-rtf {
      a {
        font-weight: 700;
        color: inherit;
        text-decoration: underline;
        transition: color 0.5s $easeOutSine;
        letter-spacing: inherit;

        &:hover,
        &:focus {
          color: $blue-1;
        }
      }

      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $blue-1;
        letter-spacing: 0;
        margin-bottom: 25px;
      }

      h2 {
        line-height: 1.25;

        @media screen and ($mobile_sm) {
          @include vw('font-size', 36px, 500);
        }

        @media screen and ($mobile_lg) {
          font-size: 36px;
        }

        @media screen and ($min1024) {
          @include vw('font-size', 36px);
        }

        @media screen and ($min1920) {
          font-size: 36px;
        }
      }

      ol {
        counter-reset: item;

        @media screen and ($mobile_sm) {
          @include vw('margin', 30px 0, 500);
        }

        @media screen and ($mobile_lg) {
          margin: 30px 0;
        }

        @media screen and ($min1024) {
          @include vw('margin', 30px 0);
        }

        @media screen and ($min1920) {
          margin: 30px 0;
        }

        li {
          list-style-type: none;
          position: relative;
          padding-left: 23px;

          // &:not(:last-of-type) {
          //     margin-bottom: 10px;

          //     @media screen and ($min1024) {
          //         @include vw('margin-bottom', 27px);
          //     }

          //     @media screen and ($min1920) {
          //         margin-bottom: $contentPadding;
          //     }
          // }

          &:nth-child(1n + 10) {
            &::before {
              left: -7px;
            }
          }

          &::before {
            content: counter(item) '.';
            display: inline-block;
            counter-increment: item;
            top: 0;
            left: 0;
            position: absolute;
            color: $blue-1;
          }
        }
      }

      // ul {
      //   list-style-type: none;

      //   @media screen and ($mobile_sm) {
      //     @include vw('margin, 30px 0', 500);
      //   }

      //   @media screen and ($mobile_lg) {
      //     margin: 30px 0;
      //   }

      //   @media screen and ($min1024) {
      //     @include vw('margin', 30px 0);
      //   }

      //   @media screen and ($min1920) {
      //     margin: 30px 0;
      //   }

      //   li {
      //     position: relative;
      //     padding-left: 20px;

      //     @media screen and ($min1024) {
      //       @include vw('padding-left', 20px);
      //     }

      //     @media screen and ($min1920) {
      //       padding-left: 20px;
      //     }

      //     // &:not(:last-of-type) {
      //     //     margin-bottom: 10px;

      //     //     @media screen and ($min1024) {
      //     //         @include vw('margin-bottom', 26px);
      //     //     }

      //     //     @media screen and ($min1920) {
      //     //         margin-bottom: 26px;
      //     //     }
      //     // }

      //     &::before {
      //       content: "";
      //       position: absolute;
      //       display: inline-block;
      //       background-color: $black;
      //       border-radius: 50%;
      //       top: 0.65em;
      //       left: 0;
      //       width: 6px;
      //       height: 6px;

      //       @media screen and ($min1024) {
      //         @include vw('width', 6px);
      //         @include vw('height', 6px);
      //       }

      //       @media screen and ($min1920) {
      //         width: 6px;
      //         height: 6px;
      //       }
      //     }
      //   }
      // }

      strong,
      b {
        color: $blue-1;
      }

      p:not(:last-of-type) {
        margin-bottom: 15px;

        @media screen and ($min1024) {
          @include vw('margin-bottom', 27px);
        }

        @media screen and ($min1920) {
          margin-bottom: $contentPadding;
        }
      }
    }

    &.txt-blocks-layout-rtf {
      a {
        font-weight: 700;
        color: inherit;
        text-decoration: underline;
        transition: color 0.5s $easeOutSine;
        letter-spacing: inherit;

        &:hover,
        &:focus {
          color: $blue-1;
        }
      }

      .scroll-btn-switch {
        font-weight: 700;
        color: inherit;
        text-decoration: underline;
        transition: color 0.5s $easeOutSine;
        letter-spacing: inherit;

        &:hover,
        &:focus {
          color: $green-1;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: $redhat;
        color: $blue-1;
        letter-spacing: 0;
        margin-bottom: 22px;
        letter-spacing: 0.5px;

        // @media screen and ($mobile_sm) {
        //     @include vw('letter-spacing, 0.5px', 500);
        // }
        // @media screen and ($mobile_lg) {
        //     letter-spacing: 0.5px;
        // }
        @media screen and ($min1024) {
          @include vw('letter-spacing', 0.5px);
        }

        @media screen and ($min1920) {
          letter-spacing: 0.5px;
        }
      }

      h1 {
        line-height: 1.25;

        @media screen and ($mobile_sm) {
          @include vw('font-size', 60px, 500);
        }

        @media screen and ($mobile_lg) {
          font-size: 60px;
        }

        @media screen and ($min1024) {
          @include vw('font-size', 72px);
        }

        @media screen and ($min1920) {
          font-size: 72px;
        }
      }

      h2 {
        line-height: 1.25;

        @media screen and ($mobile_sm) {
          @include vw('font-size', 60px, 500);
        }

        @media screen and ($mobile_lg) {
          font-size: 60px;
        }

        @media screen and ($min1024) {
          @include vw('font-size', 72px);
        }

        @media screen and ($min1920) {
          font-size: 72px;
        }
      }

      h3 {
        line-height: 1.25;

        @media screen and ($mobile_sm) {
          @include vw('font-size', 48px, 500);
        }

        @media screen and ($mobile_lg) {
          font-size: 48px;
        }

        @media screen and ($min1024) {
          @include vw('font-size', 60px);
        }

        @media screen and ($min1920) {
          font-size: 60px;
        }
      }

      h4 {
        line-height: 1.25;

        @media screen and ($mobile_sm) {
          @include vw('font-size', 36px, 500);
        }

        @media screen and ($mobile_lg) {
          font-size: 36px;
        }

        @media screen and ($min1024) {
          @include vw('font-size', 36px);
        }

        @media screen and ($min1920) {
          font-size: 36px;
        }
      }

      h5 {
        line-height: 1.25;

        @media screen and ($mobile_sm) {
          @include vw('font-size', 24px, 500);
        }

        @media screen and ($mobile_lg) {
          font-size: 24px;
        }

        @media screen and ($min1024) {
          @include vw('font-size', 24px);
        }

        @media screen and ($min1920) {
          font-size: 24px;
        }
      }

      h6 {
        line-height: 1.25;

        @media screen and ($mobile_sm) {
          @include vw('font-size', 21px, 500);
        }

        @media screen and ($mobile_lg) {
          font-size: 21px;
        }

        @media screen and ($min1024) {
          @include vw('font-size', 21px);
        }

        @media screen and ($min1920) {
          font-size: 21px;
        }
      }

      ol {
        counter-reset: item;

        @media screen and ($mobile_sm) {
          @include vw('margin-bottom', 27px, 500);
        }

        @media screen and ($mobile_lg) {
          margin-bottom: $contentPadding;
        }

        @media screen and ($min1024) {
          @include vw('margin-bottom', 27px);
        }

        @media screen and ($min1920) {
          margin-bottom: $contentPadding;
        }

        li {
          list-style-type: none;
          position: relative;
          letter-spacing: 0.35px;
          padding-left: 40px;

          @media screen and ($min1024) {
            @include vw('letter-spacing', 0.35px);
            @include vw('padding-left', 40px);
          }

          @media screen and ($min1920) {
            letter-spacing: 0.35px;
            padding-left: 40px;
          }

          &::before {
            content: counter(item) '.';
            display: inline-block;
            counter-increment: item;
            top: 0;
            left: 0;
            position: absolute;
            color: $blue-1;
          }
        }
      }

      ul {
        list-style-type: none;

        @media screen and ($mobile_sm) {
          @include vw('margin', 30px 0 30px 44px, 500);
        }

        @media screen and ($mobile_lg) {
          margin: 30px 0 30px 44px;
        }

        @media screen and ($min1024) {
          @include vw('margin', 30px 0 30px 44px);
        }

        @media screen and ($min1920) {
          margin: 30px 0 30px 44px;
        }

        li {
          position: relative;
          padding-left: 17px;

          @media screen and ($min1024) {
            @include vw('padding-left', 17px);
          }

          @media screen and ($min1920) {
            padding-left: 17px;
          }

          &::before {
            content: '';
            position: absolute;
            display: inline-block;
            background-color: $black;
            border-radius: 50%;
            top: 0.65em;
            left: 0;
            width: 5px;
            height: 5px;

            @media screen and ($min1024) {
              @include vw('width', 5px);
              @include vw('height', 5px);
            }

            @media screen and ($min1920) {
              width: 5px;
              height: 5px;
            }
          }
        }
      }

      strong,
      b {
        color: $blue-1;
      }

      p:not(:last-of-type) {
        margin-bottom: 15px;

        @media screen and ($min1024) {
          @include vw('margin-bottom', 27px);
        }

        @media screen and ($min1920) {
          margin-bottom: $contentPadding;
        }
      }
    }
  }
}

.text-overline {
  font-family: $libre;
  font-weight: 200;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1.25;
}
ul.checklist,
.green-checkmark {
  list-style-image: url($ROOT_PATH + 'media/svgs/green-check.svg');
  li {
    margin-bottom: 8px;
    padding-left:8px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &:before {
      content:none!important;
    }
  }
}

.form-subheading,
.form-subheading.c-green-1 {
  font-size: 16px;
}

.ff-libre {
  font-family: $libre;
}

.ff-redhat {
  font-family: $redhat;
}

.accent-column {
  border-left: 5px solid $green-1;
  border-right: 5px solid $green-1;

  &.first-accent-column {
    border-top: 5px solid $green-1;
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;
  }
}

// letter-spacing classes
.ltr-spc {
  &-neg-8 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', -8px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: -8px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', -8px);
    }

    @media screen and ($min1920) {
      letter-spacing: -8px;
    }
  }

  &-neg-1_1 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', -1.1px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: -1.1px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', -1.1px);
    }

    @media screen and ($min1920) {
      letter-spacing: -1.1px;
    }
  }

  &-neg-1 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', -1px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: -1px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', -1px);
    }

    @media screen and ($min1920) {
      letter-spacing: -1px;
    }
  }

  &-neg-0_25 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', -0.25px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: -0.25px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', -0.25px);
    }

    @media screen and ($min1920) {
      letter-spacing: -0.25px;
    }
  }

  &-neg-0_2 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', -0.2px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: -0.2px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', -0.2px);
    }

    @media screen and ($min1920) {
      letter-spacing: -0.2px;
    }
  }

  &-neg-0_1 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', -0.1px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: -0.1px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', -0.1px);
    }

    @media screen and ($min1920) {
      letter-spacing: -0.1px;
    }
  }

  &-pos-0_1 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.1px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.1px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.1px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.1px;
    }
  }

  &-pos-0_15 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.15px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.15px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.15px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.15px;
    }
  }

  &-pos-0_2 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.2px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.2px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.2px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.2px;
    }
  }

  &-pos-0_25 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.25px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.25px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.25px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.25px;
    }
  }

  &-pos-0_3 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.3px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.3px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.3px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.3px;
    }
  }

  &-pos-0_35 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.35px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.35px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.35px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.35px;
    }
  }

  &-pos-0_38 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.38px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.38px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.38px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.38px;
    }
  }

  &-pos-0_4 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.4px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.4px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.4px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.4px;
    }
  }

  &-pos-0_45 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.45px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.45px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.45px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.45px;
    }
  }

  &-pos-0_5 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.5px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.5px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.5px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.5px;
    }
  }

  &-pos-0_6 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.6px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.6px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.6px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.6px;
    }
  }

  &-pos-0_7 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.7px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.7px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.7px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.7px;
    }
  }

  &-pos-0_8 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.8px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.8px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.8px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.8px;
    }
  }

  &-pos-0_85 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 0.85px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 0.85px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 0.85px);
    }

    @media screen and ($min1920) {
      letter-spacing: 0.85px;
    }
  }

  &-pos-1 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 1px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 1px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 1px);
    }

    @media screen and ($min1920) {
      letter-spacing: 1px;
    }
  }

  &-pos-1_2 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 1.2px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 1.2px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 1.2px);
    }

    @media screen and ($min1920) {
      letter-spacing: 1.2px;
    }
  }

  &-pos-2_5 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 2.5px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 2.5px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 2.5px);
    }

    @media screen and ($min1920) {
      letter-spacing: 2.5px;
    }
  }

  &-pos-2_75 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 2.75px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 2.75px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 2.75px);
    }

    @media screen and ($min1920) {
      letter-spacing: 2.75px;
    }
  }

  &-pos-2_8 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 2.8px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 2.8px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 2.8px);
    }

    @media screen and ($min1920) {
      letter-spacing: 2.8px;
    }
  }

  &-pos-3_2 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 3.2px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 3.2px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 3.2px);
    }

    @media screen and ($min1920) {
      letter-spacing: 3.2px;
    }
  }

  &-pos-3_6 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 3.6px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 3.6px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 3.6px);
    }

    @media screen and ($min1920) {
      letter-spacing: 3.6px;
    }
  }

  &-pos-3_7 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 3.7px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 3.7px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 3.7px);
    }

    @media screen and ($min1920) {
      letter-spacing: 3.7px;
    }
  }

  &-pos-4_6 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 4.6px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 4.6px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 4.6px);
    }

    @media screen and ($min1920) {
      letter-spacing: 4.6px;
    }
  }

  &-pos-4_8 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 4.8px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 4.8px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 4.8px);
    }

    @media screen and ($min1920) {
      letter-spacing: 4.8px;
    }
  }

  &-pos-5 {
    @media screen and ($mobile_sm) {
      @include vw('letter-spacing', 5px, 500);
    }

    @media screen and ($mobile_lg) {
      letter-spacing: 5px;
    }

    @media screen and ($min1024) {
      @include vw('letter-spacing', 5px);
    }

    @media screen and ($min1920) {
      letter-spacing: 5px;
    }
  }
}

// line-height classes
.lh {
  &-1 {
    line-height: 1;
  }

  &-1_05 {
    line-height: 1.05;
  }

  &-1_09 {
    line-height: 1.09;
  }

  &-1_1 {
    line-height: 1.1;
  }

  &-1_11 {
    line-height: 1.11;
  }

  &-1_2 {
    line-height: 1.2;
  }

  &-1_25 {
    line-height: 1.25;
  }

  &-1_3 {
    line-height: 1.3;
  }

  &-1_33 {
    line-height: 1.33;
  }

  &-1_36 {
    line-height: 1.36;
  }

  &-1_375 {
    line-height: 1.375;
  }

  &-1_4 {
    line-height: 1.4;
  }

  &-1_43 {
    line-height: 1.43;
  }

  &-1_46 {
    line-height: 1.46;
  }

  &-1_55 {
    line-height: 1.55;
  }

  &-1_57 {
    line-height: 1.57;
  }

  &-1_66 {
    line-height: 1.66;
  }

  &-1_6875 {
    line-height: 1.6875;
  }

  &-1_5625 {
    line-height: 1.5625;
  }

  &-1_65 {
    line-height: 1.65;
  }

  &-1_7 {
    line-height: 1.7;
  }

  &-1_75 {
    line-height: 1.75;
  }

  &-1_764 {
    line-height: 1.764;
  }

  &-1_8 {
    line-height: 1.8;
  }

  &-1_875 {
    line-height: 1.875;
  }

  &-2 {
    line-height: 2;
  }
}

// text color classes
.c {
  &-black {
    color: $black;
  }

  &-white {
    color: $white;
  }

  &-blue-1 {
    color: $blue-1;

    a:not(.btn):not(.button) {
      color: $blue-1;
      text-decoration: underline;
    }
  }

  &-red-2 {
    color: $red-2;
  }

  &-blue-7 {
    color: $blue-7;
  }

  &-blue-15 {
    color: $blue-15;
  }

  &-green-1 {
    color: $green-1;
  }

  &-gray-2 {
    color: $gray-2;
  }

  &-gray-8 {
    color: $gray-8;
  }

  &-gray-18 {
    color: $gray-18;
  }

  &-blue-5 {
    color: $blue-5;
  }

  &-green-5 {
    color: $green-5;
  }
}

// font-weight classes
.fw {
  &-300 {
    font-weight: 300;
  }

  &-400 {
    font-weight: 400;
  }

  &-500 {
    font-weight: 500;
  }

  &-600 {
    font-weight: 600;
  }

  &-700 {
    font-weight: 700;
  }

  &-800 {
    font-weight: 800;
  }
}

.txt-size-60 {
  &.statement-analysis {
    @media screen and ($mobile_sm) {
      @include vw('font-size', 58px, 500px);
    }

    @media screen and ($mobile_lg) {
      font-size: 58px;
    }
  }
}
