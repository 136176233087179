.inner-pages-quiz-section-type-2 {
  .side-menu-btns {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    backface-visibility: hidden;

    @media screen and ($mobile_all) {
      padding-top: 25px;
      align-items: flex-start;
      flex-flow: row wrap;
      justify-content: center;
      margin: 10px;
    }

    @media screen and ($min1024) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 30px;
    }
  }

  .side-menu-quiz-btn {
    @media screen and ($mobile_all) {
      margin: 10px;
    }

    @media screen and ($min1024) {
      &:not(:last-of-type) {
        margin-bottom: 17px;
      }
    }

    svg {
      path {
        fill: $blue-1;
      }
    }
  }

  .title-wrap {
    margin-bottom: 50px;

    @media screen and ($min1024) {
      @include vw(max-width, 1100px);
      @include vw(margin-bottom, 107px);
    }

    @media screen and ($min1920) {
      max-width: 1100px;
      margin-bottom: 107px;
    }
  }

  .title-wrap-txt {
    // margin-top: 18px;
    margin-top: 25px;

    @media screen and ($min1024) {
      // @include vw(margin-top, 18px);
      @include vw(margin-top, 56px);
      @include vw(margin-left, 3px);
    }

    @media screen and ($min1920) {
      // margin-top: 18px;
      margin-top: 56px;
      margin-left: 3px;
    }
  }

  background-color: $green-8;
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and ($min1024) {
    @include vw(padding-top, 136px);
    @include vw(padding-bottom, 136px);
  }

  @media screen and ($min1920) {
    padding-top: 136px;
    padding-bottom: 136px;
  }

  .txt-size-12 {
    margin-bottom: 25px;

    @media screen and ($min1024) {
      @include vw(margin-bottom, 38px);
    }

    @media screen and ($min1920) {
      margin-bottom: 38px;
    }
  }

  .txt-size-48 {
    @media screen and ($min1024) {
      @include vw(padding-right, 120px);
    }

    @media screen and ($min1920) {
      padding-right: 120px;
    }
  }

  .btn-wrap {
    text-align: center;
    margin-top: 50px;

    @media screen and ($min1024) {
      @include vw(margin-top, 65px);
      @include vw(padding-left, 63px);
    }

    @media screen and ($min1920) {
      margin-top: 65px;
      padding-left: 63px;
    }
  }

  .quiz-content-wrap {
    @media screen and ($min1024) {
      @include vw(margin-right, -29px);
    }

    @media screen and ($min1920) {
      margin-right: -29px;
    }
  }

  .choices-wrap {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin: 0 0 -15px;

    @media screen and ($min1024) {
      @include vw(margin, -15px -21px);
    }

    @media screen and ($min1920) {
      margin: -15px -21px;
    }
  }

  .choice-item {
    position: relative;
    width: 100%;
    margin: 0 0 15px;

    @media screen and ($min1024) {
      width: calc(50% - 2.188vw);
      @include vw(margin, 15px 21px);
    }

    @media screen and ($min1920) {
      width: calc(50% - 42px);
      margin: 15px 21px;
    }

    [type='checkbox'] {
      display: none;

      &:checked ~ .choice-label {
        background-color: $green-1;
        color: $blue-1;

        &::before {
          background-color: $gray-13;
        }

        &::after {
          opacity: 1;
        }
      }
    }

    .choice-label {
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      color: $black;
      background-color: $white;
      width: 100%;
      box-shadow: $box-shadow-1;
      transition:
        background-color 0.5s $easeOutSine,
        color 0.5s $easeOutSine;
      font-size: 21px;
      padding: 31px 60px 31px 88px;
      border-radius: 45px;

      @media screen and ($min1024) {
        @include vw(font-size, 21px);
        @include vw(padding, 31px 60px 31px 88px);
        @include vw(border-radius, 45px);
      }

      @media screen and ($min1920) {
        font-size: 21px;
        padding: 31px 60px 31px 88px;
        border-radius: 45px;
      }

      &:hover,
      &:focus {
        &::before {
          background-color: $green-9;
        }
      }

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        backface-visibility: hidden;
        border-radius: 50%;
        transition: background-color 0.5s $easeOutSine;
        background-color: $gray-13;
        left: $contentPadding;
        width: 40px;
        height: 40px;

        @media screen and ($min1024) {
          @include vw(left, 27px);
          @include vw(width, 40px);
          @include vw(height, 40px);
        }

        @media screen and ($min1920) {
          left: $contentPadding;
          width: 40px;
          height: 40px;
        }
      }

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        transform: rotate(-45deg) translateY(-50%);
        top: calc(50% - 3px);
        border-bottom: 3px solid $green-1;
        border-left: 3px solid $green-1;
        opacity: 0;
        transition: opacity 0.5s $easeOutSine;
        left: 44px;
        width: 12px;
        height: 6px;

        @media screen and ($min1024) {
          @include vw(left, 44px);
          @include vw(width, 12px);
          @include vw(height, 6px);
        }

        @media screen and ($min1920) {
          left: 44px;
          width: 12px;
          height: 6px;
        }
      }
    }
  }
}
