.background-video-wrap {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media screen and (max-width: 1024px) {
  .background-video-wrap.parallax-img {
    height: calc(100% + 100px);
  }
}
.background-video-wrap::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.background-video {
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.background-video.display-video {
  display: block;
}

.img-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.img-wrapper img {
  object-fit: contain;
  object-position: 50% 0%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.img-wrapper video {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.img-wrapper.aspect-ratio-111-52 {
  padding-top: 46.8468468468%;
}
.img-wrapper.aspect-ratio-180-70 {
  padding-top: 38.8888888889%;
}
.img-wrapper.aspect-ratio-172-117 {
  padding-top: 68.023255814%;
}
.img-wrapper.aspect-ratio-170-170 {
  padding-top: 100%;
}
.img-wrapper.aspect-ratio-21-15 {
  padding-top: 71.4285714286%;
}
.img-wrapper.aspect-ratio-1-1 {
  padding-top: 100%;
}
.img-wrapper.aspect-ratio-518-292 {
  padding-top: 56.3706563707%;
}

@media screen and (max-width: 1024px) {
  .parallax-img {
    height: calc(100% + 100px);
  }
}

.swiper-prev,
.swiper-next {
  cursor: pointer;
}
.swiper-prev.swiper-button-disabled,
.swiper-next.swiper-button-disabled {
  opacity: 0.3 !important;
  cursor: not-allowed;
}

.b-lazy:not(.dont-show) {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.b-lazy:not(.dont-show).b-loaded {
  opacity: 1;
}

.line-outer {
  overflow: hidden;
}

.visually-hidden, .sk-form-hbsp .hs-recaptcha.hs-form-field,
.hbspt-form .hs-recaptcha.hs-form-field, .sr-only {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
  color: transparent !important;
  background-color: transparent !important;
  text-decoration: none;
  font-size: inherit;
  width: fit-content;
  height: fit-content;
}
.visually-hidden br, .sk-form-hbsp .hs-recaptcha.hs-form-field br,
.hbspt-form .hs-recaptcha.hs-form-field br, .sr-only br {
  display: none;
}

.op-0 {
  opacity: 0;
}

.anim-translate-y {
  backface-visibility: hidden;
}

.char {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  will-change: opacity;
}

html {
  scrollbar-color: #17fcc4 rgba(222, 222, 222, 0.75);
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #17fcc4;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(222, 222, 222, 0.75);
  border-radius: 5px;
  box-shadow: inset -30px 10px 12px #f0f0f0;
}

[data-scroll-container-inner] .scrollbar-track {
  background: rgba(222, 222, 222, 0.75);
  border-radius: 5px;
  box-shadow: inset -30px 10px 12px #f0f0f0;
}
[data-scroll-container-inner] .scrollbar-thumb {
  background: #17fcc4;
}

#__bs_notify__,
#__bs_notify__ *,
.bs-msg {
  font-size: 16px !important;
  opacity: 1 !important;
  color: #ffffff !important;
  z-index: 10000000;
}

.hbspt-form .hs-input:not(input[type=checkbox])::placeholder {
  font-size: 14px !important;
  opacity: 1;
}
@media screen and (max-width: 1024px) {
  .hbspt-form .hs-input:not(input[type=checkbox])::placeholder {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 478px) {
  .hbspt-form .hs-input:not(input[type=checkbox])::placeholder {
    font-size: 12px !important;
  }
}

.callback-popup-form .mobile-contact-form .hs-form {
  padding-bottom: 40px;
}

.btn-call-us,
.btn-call-us .btn-bg-el,
.btn-call-us .btn-txt {
  min-width: 100px !important;
  max-width: 100px !important;
}

.btn-call-us .btn-txt {
  padding: 5px 18px !important;
}

.sk-pd-block {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.sk-pd-block {
  overflow: clip;
}

.mb-3 {
  margin-bottom: 27px !important;
}

.gap-30 {
  gap: 30px;
}

.screen-dark::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(90.01deg, rgba(0, 0, 0, 0.56) 0.01%, rgba(0, 0, 0, 0.415015) 40.48%, rgba(0, 0, 0, 0) 80.45%);
}

ol.numbered-list-styled {
  list-style: none;
  counter-reset: item;
}
ol.numbered-list-styled li {
  counter-increment: item;
  margin-bottom: 5px;
}
ol.numbered-list-styled li:before {
  content: counter(item);
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 10px;
  color: #002ea6;
  line-height: 26px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  background: #17fcc4;
  border-radius: 50%;
}

.sk-block,
.sk-block-sk {
  padding-top: 100px;
  padding-bottom: 100px;
}
.sk-block img.size-full,
.sk-block-sk img.size-full {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.sk-block-sk .inner-pages-slider-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 1025px) {
  .sk-block-sk .inner-pages-slider-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media screen and (min-width: 1921px) {
  .sk-block-sk .inner-pages-slider-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.content-block-holder p:empty {
  display: none;
}

.sk-content-block {
  overflow-y: clip;
}

@media screen and (max-width: 500px) {
  .sk-block-0 {
    padding-top: 60px;
  }
}
@media screen and (max-width: 992px) {
  .sk-block-0 {
    padding-top: 100px;
  }
}
.sk-block-0 .img-no {
  width: 20px;
  height: 20px;
}
.sk-block-0 .img-yes {
  width: 24px;
  height: 24px;
}
@media screen and (min-width: 1280px) {
  .sk-block-0 {
    padding-top: 100px;
  }
}
.sk-block-0:not(.block--p) .container .media-block .img-wrap {
  margin-top: 0 !important;
  margin-bottom: 64px !important;
}

html.hide-twilio-chat-btn .chat-info-welcome {
  display: none !important;
}
html.hide-twilio-chat-btn .Twilio-EntryPoint {
  display: none !important;
}

.chat-info-welcome {
  font-size: 16px;
  color: #002ea6;
}

.divider.small {
  height: 15px;
}
@media screen and (max-width: 550px) {
  .divider.small {
    height: 15px;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .divider.small {
    height: 20px;
  }
}
@media screen and (max-width: 767px) {
  .divider.small {
    height: 20px;
  }
}
@media screen and (min-width: 1025px) {
  .divider.small {
    height: 30px;
  }
}
@media screen and (min-width: 1441px) {
  .divider.small {
    height: 30px;
  }
}
.divider.medium {
  height: 30px;
}
@media screen and (max-width: 550px) {
  .divider.medium {
    height: 20px;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .divider.medium {
    height: 30px;
  }
}
@media screen and (max-width: 767px) {
  .divider.medium {
    height: 30px;
  }
}
@media screen and (min-width: 1025px) {
  .divider.medium {
    height: 50px;
  }
}
@media screen and (min-width: 1441px) {
  .divider.medium {
    height: 60px;
  }
}
.divider.large {
  height: 40px;
}
@media screen and (max-width: 550px) {
  .divider.large {
    height: 40px;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .divider.large {
    height: 40px;
  }
}
@media screen and (max-width: 767px) {
  .divider.large {
    height: 40px;
  }
}
@media screen and (min-width: 1025px) {
  .divider.large {
    height: 70px;
  }
}
@media screen and (min-width: 1441px) {
  .divider.large {
    height: 80px;
  }
}
.divider.white {
  background-color: #fff;
}
.divider.grey {
  background-color: rgba(242, 244, 250, 0.6549019608);
}
.divider.blue {
  background-color: #002ea6;
}
.divider.green {
  background-color: #17fcc4;
}
.divider.transparent {
  background-color: transparent;
}

.header-toggled-state .nav-lang {
  padding-top: 18px;
}

.header-opened-dropdown .nav-lang {
  margin-top: 0;
}
.header-opened-dropdown .nav-lang .sl-nav .active,
.header-opened-dropdown .nav-lang .sl-nav i {
  color: #002ea6;
}

@media screen and (min-width: 1025px) {
  .lang-mobile {
    display: none;
  }
}

.nav-lang {
  text-align: center;
  top: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  left: 20px;
  padding-top: 32px;
  transition: margin-top 0.3s ease;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .nav-lang {
    display: none;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1249px) {
  .nav-lang {
    right: 0px;
  }
}
.nav-lang .sl-nav {
  display: inline;
  list-style-type: none;
}
.nav-lang .sl-nav .menu-wrapper {
  width: 85px;
  margin: 0;
  padding: 0;
  padding-inline-start: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  transition: height 0.3s cubic-bezier(0.61, 1, 0.88, 1);
}
.nav-lang .sl-nav .menu-wrapper li {
  cursor: pointer;
  list-style-type: none;
}
.nav-lang .sl-nav .menu-wrapper li .menu-options {
  transition: height 0.3s cubic-bezier(0.61, 1, 0.88, 1), padding-bottom 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  padding: 0;
  position: absolute;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
  height: 0;
}
.nav-lang .sl-nav .menu-wrapper li .menu-options li {
  position: relative;
  text-align: left;
  background: transparent;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  padding-bottom: 0;
  z-index: 2;
  font-size: 15px;
  color: #3c3c3c;
  text-align: center;
}
.nav-lang .sl-nav .menu-wrapper li .menu-options li span {
  display: block;
  padding: 15px 5px;
  color: #002ea6;
}
.nav-lang .sl-nav .menu-wrapper li .menu-options li span:last-of-type {
  padding-bottom: 15px;
}
.nav-lang .sl-nav .menu-wrapper li .menu-options li span:hover, .nav-lang .sl-nav .menu-wrapper li .menu-options li span.active {
  color: #008fc4;
}
.nav-lang:hover, .nav-lang:focus, .nav-lang:focus-within {
  background: #fff;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
}
.nav-lang:hover .sl-nav .active,
.nav-lang:hover .sl-nav .arrow, .nav-lang:focus .sl-nav .active,
.nav-lang:focus .sl-nav .arrow, .nav-lang:focus-within .sl-nav .active,
.nav-lang:focus-within .sl-nav .arrow {
  color: rgb(252, 74, 107);
}
.nav-lang:hover .sl-nav .arrow::before, .nav-lang:focus .sl-nav .arrow::before, .nav-lang:focus-within .sl-nav .arrow::before {
  transform: translateY(-40%) rotate(135deg);
  border-color: rgb(252, 74, 107);
}
.nav-lang:hover .sl-nav li, .nav-lang:focus .sl-nav li, .nav-lang:focus-within .sl-nav li {
  opacity: 1 !important;
}
.nav-lang:hover .sl-nav li .menu-options, .nav-lang:focus .sl-nav li .menu-options, .nav-lang:focus-within .sl-nav li .menu-options {
  padding-bottom: 10px;
  margin-top: 0;
  height: auto;
  width: 85px;
  padding-top: 25px;
  z-index: 1;
}
.nav-lang .active,
.nav-lang .arrow {
  text-transform: uppercase;
}
.nav-lang .arrow {
  margin-left: 9px;
}
.nav-lang .arrow::before {
  content: "";
  display: inline-block;
  backface-visibility: hidden;
  transition: transform 0.3s cubic-bezier(0.61, 1, 0.88, 1), border-color 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  transform: translate(-50%, -50%) rotate(315deg);
  border-left-style: solid;
  border-bottom-style: solid;
  border-color: #17fcc4;
  width: 6px;
  height: 6px;
  border-left-width: 2.5px;
  border-bottom-width: 2.5px;
}
@media screen and (min-width: 1025px) {
  .nav-lang .arrow::before {
    width: 0.313vw;
    height: 0.313vw;
    border-left-width: 0.13vw;
    border-bottom-width: 0.13vw;
  }
}
@media screen and (min-width: 1921px) {
  .nav-lang .arrow::before {
    width: 6px;
    height: 6px;
    border-left-width: 2.5px;
    border-bottom-width: 2.5px;
  }
}

html.open-popup-form {
  overflow: hidden;
}
html.open-popup-form .popup-form {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  z-index: 1000;
}
html.open-header-popup-form {
  overflow: hidden;
}
html.open-header-popup-form .popup-form {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
html.open-download-popup-form {
  overflow: hidden;
}
html.open-download-popup-form .download-popup-form {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
html.open-callback-popup-form {
  overflow: hidden;
}
html.open-callback-popup-form .callback-popup-form {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
html.open-talk-to-us-block-popup-form {
  overflow: hidden;
}
html.open-talk-to-us-block-popup-form .talk-to-us-block-popup-form {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.popup-form {
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  background-color: #002ea6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1), visibility 0.5s cubic-bezier(0.61, 1, 0.88, 1), transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  transform: translateY(-100%);
}
@media screen and (max-width: 550px) {
  .popup-form.calendly-wrap .mobile-contact-form {
    width: auto;
  }
  .popup-form.calendly-wrap .mobile-contact-form h3,
  .popup-form.calendly-wrap .mobile-contact-form p {
    width: 70%;
    margin: 0 auto;
  }
  .popup-form.calendly-wrap .form-popup-close-btn {
    right: 0;
  }
}
@media screen and (min-width: 1025px) {
  .popup-form input {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .popup-form {
    padding-top: 50px;
    align-items: flex-start;
  }
}
.popup-form .mobile-contact-form {
  max-height: 100%;
  width: 70%;
  margin: 0 auto;
  position: relative;
  padding: 40px 0 0;
}
@media screen and (min-width: 1025px) {
  .popup-form .mobile-contact-form {
    padding: 200px 0 0;
    width: 30%;
  }
}

.download-popup-form {
  opacity: 0;
  visibility: hidden;
  z-index: 5;
  background-color: #002ea6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1), visibility 0.5s cubic-bezier(0.61, 1, 0.88, 1), transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  transform: translateY(-100%);
}
@media screen and (min-width: 1025px) {
  .download-popup-form input {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .download-popup-form {
    padding-top: 160px;
    align-items: flex-start;
  }
  .download-popup-form h3,
  .download-popup-form .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .download-popup-form h3,
  .download-popup-form .h3 {
    width: 90%;
  }
}
.download-popup-form p {
  padding-top: 20px;
}
.download-popup-form .mobile-contact-form {
  max-height: 100%;
  width: 70%;
  margin: 0 auto;
  position: relative;
  padding: 40px 0 0;
}
@media screen and (min-width: 1025px) {
  .download-popup-form .mobile-contact-form {
    padding: 200px 0 0;
    width: 30%;
  }
}

.landing-form {
  margin-top: 40px;
}
@media screen and (min-width: 1025px) {
  .landing-form {
    width: 310px;
  }
}
.landing-form .form-row {
  width: 100%;
  margin-bottom: 20px;
}
@media screen and (min-width: 1441px) {
  .landing-form .form-row {
    margin-bottom: 40px;
  }
}
.landing-form div.button-row {
  text-align: left;
}

.circle-img .img-width-wrap {
  position: relative;
  z-index: 3;
}
.circle-img .info-white-section-img {
  z-index: 3;
}

.fade-in-last {
  margin-bottom: 10px;
}
@media screen and (max-width: 550px) {
  .fade-in-last {
    margin-bottom: 10px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--sk-root-font-size);
}

html body {
  transition: padding-top 300ms linear;
}

body {
  margin: 0;
  font-size: var(--sk-body-font-size);
  font-weight: var(--sk-body-font-weight);
  line-height: var(--sk-body-line-height);
  color: var(--sk-body-color);
  text-align: var(--sk-body-text-align);
  background-color: var(--sk-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--sk-border-width) solid;
  opacity: 0.25;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol li {
  margin-bottom: 15px;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 27px;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong,
b {
  font-weight: 600;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--sk-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--sk-link-color-rgb), var(--sk-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --sk-link-color-rgb: var(--sk-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--sk-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--sk-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--sk-body-bg);
  background-color: var(--sk-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--sk-secondary-color);
  text-align: left;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: "Red Hat Display", sans-serif;
}
.h1 b,
.h1 strong,
.h1 b,
h1 b,
h1 strong,
h1 b,
.h2 b,
.h2 strong,
.h2 b,
h2 b,
h2 strong,
h2 b,
.h3 b,
.h3 strong,
.h3 b,
h3 b,
h3 strong,
h3 b,
.h4 b,
.h4 strong,
.h4 b,
h4 b,
h4 strong,
h4 b,
.h5 b,
.h5 strong,
.h5 b,
h5 b,
h5 strong,
h5 b,
.h6 b,
.h6 strong,
.h6 b,
h6 b,
h6 strong,
h6 b {
  font-weight: inherit;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.wrapperRollingTextBox {
  z-index: 3;
}

.wrapperRollingText {
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  opacity: 0;
}
.wrapperRollingText.to-right .rollingText.cloned {
  left: inherit;
  right: 0;
}

.rollingText {
  backface-visibility: hidden;
  display: inline-flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
}
.rollingText.cloned {
  position: absolute;
  top: 0;
  left: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Libre Franklin", sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: auto;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 101%, rgba(0, 0, 0, 0.01) 102%);
}

html,
body {
  width: 100%;
  -webkit-font-smoothing: antialiased;
}

.w-fit {
  width: fit-content;
}

@media screen and (min-width: 400px) and (max-width: 500px) {
  body:not(.sk-lander)::before {
    background-color: #002ea6;
    content: "";
    display: inline-block;
    height: 100px;
    width: 100%;
    position: absolute;
    z-index: -1;
  }
}
body {
  background-color: #fff;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1), padding-top 0.15 ease-in-out;
}

html.skp-sc body {
  overflow: clip;
}

html.w-sc body {
  overflow: hidden;
}

button {
  cursor: pointer;
  border: 0;
  background: transparent;
}

a img {
  border: 0 none;
}

img {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  height: auto;
  width: auto;
  pointer-events: none;
  user-select: none;
}

a {
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
}

.f0 {
  font-size: 0;
}

.animated-in {
  font-size: 1rem;
}

.object {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.clr {
  clear: both;
}

.ul-reset {
  margin: 0;
  padding: 0;
}
.ul-reset li {
  list-style-type: none;
}

.h-reset h1,
.h-reset .h1,
.h-reset h2,
.h-reset .h2,
.h-reset h3,
.h-reset .h3,
.h-reset h4,
.h-reset .h4,
.h-reset p {
  margin: 0;
}

h1 strong,
h1 b,
.h1 strong,
.h1 b,
h2 strong,
h2 b,
.h2 strong,
.h2 b {
  font-weight: inherit;
}

.hidden-label {
  font-size: 16px;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 101%, rgba(0, 0, 0, 0.01) 102%);
}

.background-contrast-fix {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 101%, rgba(0, 0, 0, 0.01) 102%);
}

.w-reset {
  max-width: 100%;
  width: 100%;
}

.h-reset {
  height: auto;
}

.block {
  display: block;
}

.inline_block {
  display: inline-block;
}

@media screen and (max-width: 1024px) {
  .desktop-only {
    display: none !important;
  }
}
@media screen and (min-width: 1025px) {
  .mobile-only {
    display: none !important;
  }
}
.none {
  display: none;
}

.ov-hidden {
  overflow: clip;
}

.skp-sc .ov-hidden {
  overflow: clip;
}

.ov-visible {
  overflow: visible;
}

.ov-o-hidden {
  opacity: 0;
  visibility: hidden;
}

.ov-o-visible {
  opacity: 1;
  visibility: visible;
}

input,
textarea {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

select {
  border-radius: 0;
}

button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

button,
input {
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  appearance: button;
  /* 2 */
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

blockquote {
  padding: 0;
  margin: 0;
}

button {
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
}

.pe-none {
  pointer-events: none;
}

.center {
  text-align: center;
}

.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

.case {
  text-transform: normal;
}

.nocase {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.pull-none {
  float: none;
}

.prel {
  position: relative;
}

.pabs {
  position: absolute;
}

.pfix {
  position: fixed;
}

.valign {
  position: relative;
  display: table;
}
.valign.fit {
  width: 100%;
  height: 100%;
}
.valign .middle {
  display: table-cell;
  vertical-align: middle;
}

.middle {
  vertical-align: middle;
}

.bx {
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-position: 50% 50%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.sf-dump {
  position: relative !important;
  z-index: 999999 !important;
}

.txt-14 {
  font-size: 14px;
}
.txt-15 {
  font-size: 15px;
}
.txt-16 {
  font-size: 16px;
}
.txt-17 {
  font-size: 17px;
}
.txt-18 {
  font-size: 18px;
}
.txt-19 {
  font-size: 19px;
}
.txt-20 {
  font-size: 20px;
}
.txt-21 {
  font-size: 21px;
}
.txt-22 {
  font-size: 22px;
}
.txt-23 {
  font-size: 23px;
}
.txt-24 {
  font-size: 24px;
}

.disclaimer {
  font-size: 12px;
  letter-spacing: 0.6px;
}

.txt-size-10 {
  font-size: 10px;
}
.txt-size-12 {
  font-size: 12px;
}
.txt-size-14 {
  font-size: 11px;
}
@media screen and (min-width: 400px) {
  .txt-size-14 {
    font-size: 12px;
  }
}
@media screen and (min-width: 479px) {
  .txt-size-14 {
    font-size: 13px;
  }
}
@media screen and (min-width: 640px) {
  .txt-size-14 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-size-14 {
    font-size: 12px;
  }
}
@media screen and (min-width: 1281px) {
  .txt-size-14 {
    font-size: 13px;
  }
}
@media screen and (min-width: 1441px) {
  .txt-size-14 {
    font-size: 14px;
  }
}
.txt-size-16 {
  font-size: 12px;
}
@media screen and (min-width: 479px) {
  .txt-size-16 {
    font-size: 14px;
  }
}
@media screen and (min-width: 640px) {
  .txt-size-16 {
    font-size: 16px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-size-16 {
    font-size: 11px;
  }
}
@media screen and (min-width: 1281px) {
  .txt-size-16 {
    font-size: 12px;
  }
}
@media screen and (min-width: 1367px) {
  .txt-size-16 {
    font-size: 13px;
  }
}
@media screen and (min-width: 1441px) {
  .txt-size-16 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1661px) {
  .txt-size-16 {
    font-size: 15px;
  }
}
@media screen and (min-width: 1800px) {
  .txt-size-16 {
    font-size: 16px;
  }
}
.txt-size-17 {
  font-size: 11px;
}
@media screen and (min-width: 400px) {
  .txt-size-17 {
    font-size: 13px;
  }
}
@media screen and (min-width: 479px) {
  .txt-size-17 {
    font-size: 15px;
  }
}
@media screen and (min-width: 640px) {
  .txt-size-17 {
    font-size: 17px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-size-17 {
    font-size: 12px;
  }
}
@media screen and (min-width: 1281px) {
  .txt-size-17 {
    font-size: 13px;
  }
}
@media screen and (min-width: 1367px) {
  .txt-size-17 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1441px) {
  .txt-size-17 {
    font-size: 15px;
  }
}
@media screen and (min-width: 1661px) {
  .txt-size-17 {
    font-size: 16px;
  }
}
@media screen and (min-width: 1800px) {
  .txt-size-17 {
    font-size: 17px;
  }
}
.txt-18 {
  font-size: 18px;
}
@media screen and (max-width: 550px) {
  .txt-size-18 {
    font-size: 3.6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-18 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-size-18 {
    font-size: 13px;
  }
}
@media screen and (min-width: 1281px) {
  .txt-size-18 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1367px) {
  .txt-size-18 {
    font-size: 15px;
  }
}
@media screen and (min-width: 1441px) {
  .txt-size-18 {
    font-size: 16px;
  }
}
@media screen and (min-width: 1661px) {
  .txt-size-18 {
    font-size: 17px;
  }
}
@media screen and (min-width: 1800px) {
  .txt-size-18 {
    font-size: 18px;
  }
}
.txt-size-21 {
  font-size: 21px;
}
@media screen and (min-width: 1025px) {
  .txt-size-21 {
    font-size: 1.094vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-21 {
    font-size: 21px;
  }
}
.txt-size-22 {
  font-size: 22px;
}
@media screen and (min-width: 1025px) {
  .txt-size-22 {
    font-size: 1.146vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-22 {
    font-size: 22px;
  }
}
@media screen and (max-width: 550px) {
  .txt-size-24 {
    font-size: 4.8vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-24 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-size-24 {
    font-size: 1.25vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-24 {
    font-size: 24px;
  }
}
@media screen and (max-width: 550px) {
  .txt-size-30 {
    font-size: 6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-30 {
    font-size: 30px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-size-30 {
    font-size: 1.563vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-30 {
    font-size: 30px;
  }
}
@media screen and (max-width: 550px) {
  .txt-size-36 {
    font-size: 7.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-36 {
    font-size: 36px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-size-36 {
    font-size: 1.875vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-36 {
    font-size: 36px;
  }
}
@media screen and (max-width: 550px) {
  .txt-size-38 {
    font-size: 7.6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-38 {
    font-size: 38px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-size-38 {
    font-size: 1.979vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-38 {
    font-size: 38px;
  }
}
.txt-size-44 {
  font-size: 44px;
}
@media screen and (min-width: 1025px) {
  .txt-size-44 {
    font-size: 2.292vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-44 {
    font-size: 44px;
  }
}
.txt-size-48 {
  font-size: 48px;
}
@media screen and (min-width: 1025px) {
  .txt-size-48 {
    font-size: 2.5vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-48 {
    font-size: 48px;
  }
}
.txt-size-60 {
  font-size: 60px;
}
@media screen and (min-width: 1025px) {
  .txt-size-60 {
    font-size: 3.125vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-60 {
    font-size: 60px;
  }
}
.txt-size-72 {
  font-size: 72px;
}
@media screen and (min-width: 1025px) {
  .txt-size-72 {
    font-size: 3.75vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-72 {
    font-size: 72px;
  }
}
.txt-size-80 {
  font-size: 80px;
}
@media screen and (min-width: 1025px) {
  .txt-size-80 {
    font-size: 4.167vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-80 {
    font-size: 80px;
  }
}
.txt-size-90 {
  font-size: 90px;
}
@media screen and (min-width: 1025px) {
  .txt-size-90 {
    font-size: 4.635vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-90 {
    font-size: 89px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-size-100 {
    font-size: 5.208vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-100 {
    font-size: 100px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-size-120 {
    font-size: 6.25vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-size-120 {
    font-size: 120px;
  }
}
@media screen and (max-width: 1024px) {
  .txt-size-18-m {
    font-size: 18px;
  }
}
@media screen and (max-width: 550px) {
  .txt-size-72-m {
    font-size: 14.4vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-72-m {
    font-size: 72px;
  }
}
@media screen and (max-width: 550px) {
  .txt-size-60-m {
    font-size: 12vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-60-m {
    font-size: 60px;
  }
}
@media screen and (max-width: 550px) {
  .txt-size-57-m {
    font-size: 11.4vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-57-m {
    font-size: 57px;
  }
}
@media screen and (max-width: 550px) {
  .txt-size-48-m {
    font-size: 9.6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-48-m {
    font-size: 48px;
  }
}
@media screen and (max-width: 550px) {
  .txt-size-44-m {
    font-size: 8.8vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-44-m {
    font-size: 44px;
  }
}
@media screen and (max-width: 550px) {
  .txt-size-36-m {
    font-size: 7.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-36-m {
    font-size: 36px;
  }
}
@media screen and (max-width: 550px) {
  .txt-size-24-m {
    font-size: 4.8vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-24-m {
    font-size: 24px;
  }
}
@media screen and (max-width: 550px) {
  .txt-size-30-m {
    font-size: 6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-30-m {
    font-size: 30px;
  }
}
.txt-post-rtf {
  font-family: "Libre Franklin", sans-serif;
}
.txt-post-rtf p:not(:last-of-type) {
  color: inherit;
  letter-spacing: inherit;
  margin-bottom: 1.3rem;
}
.txt-post-rtf ul,
.txt-post-rtf ol {
  list-style-position: outside;
}
.txt-post-rtf small {
  font-size: inherit;
}
.txt-post-rtf a {
  line-height: inherit;
  color: #17fcc4;
  font-size: inherit;
  letter-spacing: 0.4px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf a {
    letter-spacing: 0.021vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf a {
    letter-spacing: 0.4px;
  }
}
.txt-post-rtf a:hover, .txt-post-rtf a:focus {
  text-decoration: underline;
}
.txt-post-rtf h2 {
  line-height: 1.25;
  margin-bottom: 15px;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf h2 {
    font-size: 4.8vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf h2 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf h2 {
    font-size: 1.25vw;
    margin-bottom: 1.094vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf h2 {
    font-size: 24px;
    margin-bottom: 21px;
  }
}
.txt-post-rtf h2,
.txt-post-rtf h3,
.txt-post-rtf h4,
.txt-post-rtf h5,
.txt-post-rtf h6 {
  font-family: "Red Hat Display", sans-serif;
}
.txt-post-rtf.textpage-rtf a {
  font-weight: 700;
  color: inherit;
  text-decoration: underline;
  transition: color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  letter-spacing: inherit;
}
.txt-post-rtf.textpage-rtf a:hover, .txt-post-rtf.textpage-rtf a:focus {
  color: #002ea6;
}
.txt-post-rtf.textpage-rtf h2,
.txt-post-rtf.textpage-rtf h3,
.txt-post-rtf.textpage-rtf h4,
.txt-post-rtf.textpage-rtf h5,
.txt-post-rtf.textpage-rtf h6 {
  color: #002ea6;
  letter-spacing: 0;
  margin-bottom: 25px;
}
.txt-post-rtf.textpage-rtf h2 {
  line-height: 1.25;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.textpage-rtf h2 {
    font-size: 7.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.textpage-rtf h2 {
    font-size: 36px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.textpage-rtf h2 {
    font-size: 1.875vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.textpage-rtf h2 {
    font-size: 36px;
  }
}
.txt-post-rtf.textpage-rtf ol {
  counter-reset: item;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.textpage-rtf ol {
    margin: 6vw 0vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.textpage-rtf ol {
    margin: 30px 0;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.textpage-rtf ol {
    margin: 1.563vw 0vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.textpage-rtf ol {
    margin: 30px 0;
  }
}
.txt-post-rtf.textpage-rtf ol li {
  list-style-type: none;
  position: relative;
  padding-left: 23px;
}
.txt-post-rtf.textpage-rtf ol li:nth-child(1n+10)::before {
  left: -7px;
}
.txt-post-rtf.textpage-rtf ol li::before {
  content: counter(item) ".";
  display: inline-block;
  counter-increment: item;
  top: 0;
  left: 0;
  position: absolute;
  color: #002ea6;
}
.txt-post-rtf.textpage-rtf strong,
.txt-post-rtf.textpage-rtf b {
  color: #002ea6;
}
.txt-post-rtf.textpage-rtf p:not(:last-of-type) {
  margin-bottom: 15px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.textpage-rtf p:not(:last-of-type) {
    margin-bottom: 1.406vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.textpage-rtf p:not(:last-of-type) {
    margin-bottom: 27px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf a {
  font-weight: 700;
  color: inherit;
  text-decoration: underline;
  transition: color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  letter-spacing: inherit;
}
.txt-post-rtf.txt-blocks-layout-rtf a:hover, .txt-post-rtf.txt-blocks-layout-rtf a:focus {
  color: #002ea6;
}
.txt-post-rtf.txt-blocks-layout-rtf .scroll-btn-switch {
  font-weight: 700;
  color: inherit;
  text-decoration: underline;
  transition: color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  letter-spacing: inherit;
}
.txt-post-rtf.txt-blocks-layout-rtf .scroll-btn-switch:hover, .txt-post-rtf.txt-blocks-layout-rtf .scroll-btn-switch:focus {
  color: #17fcc4;
}
.txt-post-rtf.txt-blocks-layout-rtf h1,
.txt-post-rtf.txt-blocks-layout-rtf h2,
.txt-post-rtf.txt-blocks-layout-rtf h3,
.txt-post-rtf.txt-blocks-layout-rtf h4,
.txt-post-rtf.txt-blocks-layout-rtf h5,
.txt-post-rtf.txt-blocks-layout-rtf h6 {
  font-family: "Red Hat Display", sans-serif;
  color: #002ea6;
  letter-spacing: 0;
  margin-bottom: 22px;
  letter-spacing: 0.5px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf h1,
  .txt-post-rtf.txt-blocks-layout-rtf h2,
  .txt-post-rtf.txt-blocks-layout-rtf h3,
  .txt-post-rtf.txt-blocks-layout-rtf h4,
  .txt-post-rtf.txt-blocks-layout-rtf h5,
  .txt-post-rtf.txt-blocks-layout-rtf h6 {
    letter-spacing: 0.026vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf h1,
  .txt-post-rtf.txt-blocks-layout-rtf h2,
  .txt-post-rtf.txt-blocks-layout-rtf h3,
  .txt-post-rtf.txt-blocks-layout-rtf h4,
  .txt-post-rtf.txt-blocks-layout-rtf h5,
  .txt-post-rtf.txt-blocks-layout-rtf h6 {
    letter-spacing: 0.5px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf h1 {
  line-height: 1.25;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.txt-blocks-layout-rtf h1 {
    font-size: 12vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf h1 {
    font-size: 60px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf h1 {
    font-size: 3.75vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf h1 {
    font-size: 72px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf h2 {
  line-height: 1.25;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.txt-blocks-layout-rtf h2 {
    font-size: 12vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf h2 {
    font-size: 60px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf h2 {
    font-size: 3.75vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf h2 {
    font-size: 72px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf h3 {
  line-height: 1.25;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.txt-blocks-layout-rtf h3 {
    font-size: 9.6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf h3 {
    font-size: 48px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf h3 {
    font-size: 3.125vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf h3 {
    font-size: 60px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf h4 {
  line-height: 1.25;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.txt-blocks-layout-rtf h4 {
    font-size: 7.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf h4 {
    font-size: 36px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf h4 {
    font-size: 1.875vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf h4 {
    font-size: 36px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf h5 {
  line-height: 1.25;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.txt-blocks-layout-rtf h5 {
    font-size: 4.8vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf h5 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf h5 {
    font-size: 1.25vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf h5 {
    font-size: 24px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf h6 {
  line-height: 1.25;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.txt-blocks-layout-rtf h6 {
    font-size: 4.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf h6 {
    font-size: 21px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf h6 {
    font-size: 1.094vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf h6 {
    font-size: 21px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf ol {
  counter-reset: item;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.txt-blocks-layout-rtf ol {
    margin-bottom: 5.4vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf ol {
    margin-bottom: 27px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf ol {
    margin-bottom: 1.406vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf ol {
    margin-bottom: 27px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf ol li {
  list-style-type: none;
  position: relative;
  letter-spacing: 0.35px;
  padding-left: 40px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf ol li {
    letter-spacing: 0.018vw;
    padding-left: 2.083vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf ol li {
    letter-spacing: 0.35px;
    padding-left: 40px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf ol li::before {
  content: counter(item) ".";
  display: inline-block;
  counter-increment: item;
  top: 0;
  left: 0;
  position: absolute;
  color: #002ea6;
}
.txt-post-rtf.txt-blocks-layout-rtf ul {
  list-style-type: none;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.txt-blocks-layout-rtf ul {
    margin: 6vw 0vw 6vw 8.8vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf ul {
    margin: 30px 0 30px 44px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf ul {
    margin: 1.563vw 0vw 1.563vw 2.292vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf ul {
    margin: 30px 0 30px 44px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf ul li {
  position: relative;
  padding-left: 17px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf ul li {
    padding-left: 0.885vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf ul li {
    padding-left: 17px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf ul li::before {
  content: "";
  position: absolute;
  display: inline-block;
  background-color: #000;
  border-radius: 50%;
  top: 0.65em;
  left: 0;
  width: 5px;
  height: 5px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf ul li::before {
    width: 0.26vw;
    height: 0.26vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf ul li::before {
    width: 5px;
    height: 5px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf strong,
.txt-post-rtf.txt-blocks-layout-rtf b {
  color: #002ea6;
}
.txt-post-rtf.txt-blocks-layout-rtf p:not(:last-of-type) {
  margin-bottom: 15px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf p:not(:last-of-type) {
    margin-bottom: 1.406vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf p:not(:last-of-type) {
    margin-bottom: 27px;
  }
}

.text-overline, .sk-block .text-overline,
.sk-content .text-overline,
.sk-content-block .text-overline,
.sk-page-modal .text-overline,
.sk-blog-content .article-body > .article-content .text-overline,
.page-block .text-overline {
  font-family: "Libre Franklin", sans-serif;
  font-weight: 200;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1.25;
}

ul.checklist,
.green-checkmark {
  list-style-image: url("/wp-content/themes/sekure/assets/media/svgs/green-check.svg");
}
ul.checklist li,
.green-checkmark li {
  margin-bottom: 8px;
  padding-left: 8px;
}
ul.checklist li:last-of-type,
.green-checkmark li:last-of-type {
  margin-bottom: 0;
}
ul.checklist li:before,
.green-checkmark li:before {
  content: none !important;
}

.form-subheading,
.form-subheading.c-green-1 {
  font-size: 16px;
}

.ff-libre, .form-styles, .newsletter-form {
  font-family: "Libre Franklin", sans-serif;
}

.ff-redhat {
  font-family: "Red Hat Display", sans-serif;
}

.accent-column {
  border-left: 5px solid #17fcc4;
  border-right: 5px solid #17fcc4;
}
.accent-column.first-accent-column {
  border-top: 5px solid #17fcc4;
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
}

@media screen and (max-width: 550px) {
  .ltr-spc-neg-8 {
    letter-spacing: -1.6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-neg-8 {
    letter-spacing: -8px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-neg-8 {
    letter-spacing: -0.417vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-neg-8 {
    letter-spacing: -8px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-neg-1_1 {
    letter-spacing: -0.22vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-neg-1_1 {
    letter-spacing: -1.1px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-neg-1_1 {
    letter-spacing: -0.057vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-neg-1_1 {
    letter-spacing: -1.1px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-neg-1 {
    letter-spacing: -0.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-neg-1 {
    letter-spacing: -1px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-neg-1 {
    letter-spacing: -0.052vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-neg-1 {
    letter-spacing: -1px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-neg-0_25 {
    letter-spacing: -0.05vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-neg-0_25 {
    letter-spacing: -0.25px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-neg-0_25 {
    letter-spacing: -0.013vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-neg-0_25 {
    letter-spacing: -0.25px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-neg-0_2 {
    letter-spacing: -0.04vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-neg-0_2 {
    letter-spacing: -0.2px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-neg-0_2 {
    letter-spacing: -0.01vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-neg-0_2 {
    letter-spacing: -0.2px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-neg-0_1 {
    letter-spacing: -0.02vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-neg-0_1 {
    letter-spacing: -0.1px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-neg-0_1 {
    letter-spacing: -0.005vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-neg-0_1 {
    letter-spacing: -0.1px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_1 {
    letter-spacing: 0.02vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_1 {
    letter-spacing: 0.1px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_1 {
    letter-spacing: 0.005vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_1 {
    letter-spacing: 0.1px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_15 {
    letter-spacing: 0.03vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_15 {
    letter-spacing: 0.15px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_15 {
    letter-spacing: 0.008vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_15 {
    letter-spacing: 0.15px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_2 {
    letter-spacing: 0.04vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_2 {
    letter-spacing: 0.2px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_2 {
    letter-spacing: 0.01vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_2 {
    letter-spacing: 0.2px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_25 {
    letter-spacing: 0.05vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_25 {
    letter-spacing: 0.25px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_25 {
    letter-spacing: 0.013vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_25 {
    letter-spacing: 0.25px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_3 {
    letter-spacing: 0.06vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_3 {
    letter-spacing: 0.3px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_3 {
    letter-spacing: 0.016vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_3 {
    letter-spacing: 0.3px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_35 {
    letter-spacing: 0.07vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_35 {
    letter-spacing: 0.35px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_35 {
    letter-spacing: 0.018vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_35 {
    letter-spacing: 0.35px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_38 {
    letter-spacing: 0.076vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_38 {
    letter-spacing: 0.38px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_38 {
    letter-spacing: 0.02vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_38 {
    letter-spacing: 0.38px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_4 {
    letter-spacing: 0.08vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_4 {
    letter-spacing: 0.4px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_4 {
    letter-spacing: 0.021vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_4 {
    letter-spacing: 0.4px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_45 {
    letter-spacing: 0.09vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_45 {
    letter-spacing: 0.45px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_45 {
    letter-spacing: 0.023vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_45 {
    letter-spacing: 0.45px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_5 {
    letter-spacing: 0.1vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_5 {
    letter-spacing: 0.5px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_5 {
    letter-spacing: 0.026vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_5 {
    letter-spacing: 0.5px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_6 {
    letter-spacing: 0.12vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_6 {
    letter-spacing: 0.6px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_6 {
    letter-spacing: 0.031vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_6 {
    letter-spacing: 0.6px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_7 {
    letter-spacing: 0.14vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_7 {
    letter-spacing: 0.7px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_7 {
    letter-spacing: 0.036vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_7 {
    letter-spacing: 0.7px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_8 {
    letter-spacing: 0.16vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_8 {
    letter-spacing: 0.8px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_8 {
    letter-spacing: 0.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_8 {
    letter-spacing: 0.8px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-0_85 {
    letter-spacing: 0.17vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-0_85 {
    letter-spacing: 0.85px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-0_85 {
    letter-spacing: 0.044vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-0_85 {
    letter-spacing: 0.85px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-1 {
    letter-spacing: 0.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-1 {
    letter-spacing: 1px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-1 {
    letter-spacing: 0.052vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-1 {
    letter-spacing: 1px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-1_2 {
    letter-spacing: 0.24vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-1_2 {
    letter-spacing: 1.2px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-1_2 {
    letter-spacing: 0.063vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-1_2 {
    letter-spacing: 1.2px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-2_5 {
    letter-spacing: 0.5vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-2_5 {
    letter-spacing: 2.5px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-2_5 {
    letter-spacing: 0.13vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-2_5 {
    letter-spacing: 2.5px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-2_75 {
    letter-spacing: 0.55vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-2_75 {
    letter-spacing: 2.75px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-2_75 {
    letter-spacing: 0.143vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-2_75 {
    letter-spacing: 2.75px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-2_8 {
    letter-spacing: 0.56vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-2_8 {
    letter-spacing: 2.8px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-2_8 {
    letter-spacing: 0.146vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-2_8 {
    letter-spacing: 2.8px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-3_2 {
    letter-spacing: 0.64vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-3_2 {
    letter-spacing: 3.2px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-3_2 {
    letter-spacing: 0.167vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-3_2 {
    letter-spacing: 3.2px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-3_6 {
    letter-spacing: 0.72vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-3_6 {
    letter-spacing: 3.6px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-3_6 {
    letter-spacing: 0.188vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-3_6 {
    letter-spacing: 3.6px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-3_7 {
    letter-spacing: 0.74vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-3_7 {
    letter-spacing: 3.7px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-3_7 {
    letter-spacing: 0.193vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-3_7 {
    letter-spacing: 3.7px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-4_6 {
    letter-spacing: 0.92vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-4_6 {
    letter-spacing: 4.6px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-4_6 {
    letter-spacing: 0.24vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-4_6 {
    letter-spacing: 4.6px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-4_8 {
    letter-spacing: 0.96vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-4_8 {
    letter-spacing: 4.8px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-4_8 {
    letter-spacing: 0.25vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-4_8 {
    letter-spacing: 4.8px;
  }
}
@media screen and (max-width: 550px) {
  .ltr-spc-pos-5 {
    letter-spacing: 1vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .ltr-spc-pos-5 {
    letter-spacing: 5px;
  }
}
@media screen and (min-width: 1025px) {
  .ltr-spc-pos-5 {
    letter-spacing: 0.26vw;
  }
}
@media screen and (min-width: 1921px) {
  .ltr-spc-pos-5 {
    letter-spacing: 5px;
  }
}

.lh-1 {
  line-height: 1;
}
.lh-1_05 {
  line-height: 1.05;
}
.lh-1_09 {
  line-height: 1.09;
}
.lh-1_1 {
  line-height: 1.1;
}
.lh-1_11 {
  line-height: 1.11;
}
.lh-1_2 {
  line-height: 1.2;
}
.lh-1_25 {
  line-height: 1.25;
}
.lh-1_3 {
  line-height: 1.3;
}
.lh-1_33 {
  line-height: 1.33;
}
.lh-1_36 {
  line-height: 1.36;
}
.lh-1_375 {
  line-height: 1.375;
}
.lh-1_4 {
  line-height: 1.4;
}
.lh-1_43 {
  line-height: 1.43;
}
.lh-1_46 {
  line-height: 1.46;
}
.lh-1_55 {
  line-height: 1.55;
}
.lh-1_57 {
  line-height: 1.57;
}
.lh-1_66 {
  line-height: 1.66;
}
.lh-1_6875 {
  line-height: 1.6875;
}
.lh-1_5625 {
  line-height: 1.5625;
}
.lh-1_65 {
  line-height: 1.65;
}
.lh-1_7 {
  line-height: 1.7;
}
.lh-1_75 {
  line-height: 1.75;
}
.lh-1_764 {
  line-height: 1.764;
}
.lh-1_8 {
  line-height: 1.8;
}
.lh-1_875 {
  line-height: 1.875;
}
.lh-2 {
  line-height: 2;
}

.c-black {
  color: #000;
}
.c-white {
  color: #fff;
}
.c-blue-1 {
  color: #002ea6;
}
.c-blue-1 a:not(.btn):not(.button) {
  color: #002ea6;
  text-decoration: underline;
}
.c-red-2 {
  color: #ff034a;
}
.c-blue-7 {
  color: #002fa7;
}
.c-blue-15 {
  color: #001a5d;
}
.c-green-1 {
  color: #17fcc4;
}
.c-gray-2 {
  color: rgba(255, 255, 255, 0.4);
}
.c-gray-8 {
  color: rgba(255, 255, 255, 0.7);
}
.c-gray-18 {
  color: #5b5b5b;
}
.c-blue-5 {
  color: #008fc4;
}
.c-green-5 {
  color: #4aad52;
}

.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}

@media screen and (max-width: 550px) {
  .txt-size-60.statement-analysis {
    font-size: 11.6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-size-60.statement-analysis {
    font-size: 58px;
  }
}

.sk-block .h1,
.sk-block h1,
.sk-content .h1,
.sk-content h1,
.sk-content-block .h1,
.sk-content-block h1,
.sk-page-modal .h1,
.sk-page-modal h1,
.sk-blog-content .article-body > .article-content .h1,
.sk-blog-content .article-body > .article-content h1,
.page-block .h1,
.page-block h1 {
  font-family: "Red Hat Display", sans-serif;
  color: #002ea6;
  font-style: normal;
  font-weight: bold;
  margin-bottom: 24px;
}
.sk-block .h1 b,
.sk-block .h1 strong,
.sk-block .h1 b,
.sk-block h1 b,
.sk-block h1 strong,
.sk-block h1 b,
.sk-content .h1 b,
.sk-content .h1 strong,
.sk-content .h1 b,
.sk-content h1 b,
.sk-content h1 strong,
.sk-content h1 b,
.sk-content-block .h1 b,
.sk-content-block .h1 strong,
.sk-content-block .h1 b,
.sk-content-block h1 b,
.sk-content-block h1 strong,
.sk-content-block h1 b,
.sk-page-modal .h1 b,
.sk-page-modal .h1 strong,
.sk-page-modal .h1 b,
.sk-page-modal h1 b,
.sk-page-modal h1 strong,
.sk-page-modal h1 b,
.sk-blog-content .article-body > .article-content .h1 b,
.sk-blog-content .article-body > .article-content .h1 strong,
.sk-blog-content .article-body > .article-content .h1 b,
.sk-blog-content .article-body > .article-content h1 b,
.sk-blog-content .article-body > .article-content h1 strong,
.sk-blog-content .article-body > .article-content h1 b,
.page-block .h1 b,
.page-block .h1 strong,
.page-block .h1 b,
.page-block h1 b,
.page-block h1 strong,
.page-block h1 b {
  font-weight: bold;
}
@media screen and (min-width: 1025px) {
  .sk-block .h1,
  .sk-block h1,
  .sk-content .h1,
  .sk-content h1,
  .sk-content-block .h1,
  .sk-content-block h1,
  .sk-page-modal .h1,
  .sk-page-modal h1,
  .sk-blog-content .article-body > .article-content .h1,
  .sk-blog-content .article-body > .article-content h1,
  .page-block .h1,
  .page-block h1 {
    font-size: 64px;
    line-height: 75px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sk-block .h1,
  .sk-block h1,
  .sk-content .h1,
  .sk-content h1,
  .sk-content-block .h1,
  .sk-content-block h1,
  .sk-page-modal .h1,
  .sk-page-modal h1,
  .sk-blog-content .article-body > .article-content .h1,
  .sk-blog-content .article-body > .article-content h1,
  .page-block .h1,
  .page-block h1 {
    font-size: 64px;
    line-height: 75px;
  }
}
@media screen and (max-width: 767px) {
  .sk-block .h1,
  .sk-block h1,
  .sk-content .h1,
  .sk-content h1,
  .sk-content-block .h1,
  .sk-content-block h1,
  .sk-page-modal .h1,
  .sk-page-modal h1,
  .sk-blog-content .article-body > .article-content .h1,
  .sk-blog-content .article-body > .article-content h1,
  .page-block .h1,
  .page-block h1 {
    font-size: 40px;
    line-height: 38px;
  }
}
.sk-block b,
.sk-block strong,
.sk-block b,
.sk-content b,
.sk-content strong,
.sk-content b,
.sk-content-block b,
.sk-content-block strong,
.sk-content-block b,
.sk-page-modal b,
.sk-page-modal strong,
.sk-page-modal b,
.sk-blog-content .article-body > .article-content b,
.sk-blog-content .article-body > .article-content strong,
.sk-blog-content .article-body > .article-content b,
.page-block b,
.page-block strong,
.page-block b {
  font-weight: 600;
}
.sk-block .h2,
.sk-block h2,
.sk-content .h2,
.sk-content h2,
.sk-content-block .h2,
.sk-content-block h2,
.sk-page-modal .h2,
.sk-page-modal h2,
.sk-blog-content .article-body > .article-content .h2,
.sk-blog-content .article-body > .article-content h2,
.page-block .h2,
.page-block h2 {
  font-family: "Red Hat Display", sans-serif;
  color: #002ea6;
  font-style: normal;
  font-weight: bold;
  margin-bottom: 10px;
}
.sk-block .h2 b,
.sk-block .h2 strong,
.sk-block .h2 b,
.sk-block h2 b,
.sk-block h2 strong,
.sk-block h2 b,
.sk-content .h2 b,
.sk-content .h2 strong,
.sk-content .h2 b,
.sk-content h2 b,
.sk-content h2 strong,
.sk-content h2 b,
.sk-content-block .h2 b,
.sk-content-block .h2 strong,
.sk-content-block .h2 b,
.sk-content-block h2 b,
.sk-content-block h2 strong,
.sk-content-block h2 b,
.sk-page-modal .h2 b,
.sk-page-modal .h2 strong,
.sk-page-modal .h2 b,
.sk-page-modal h2 b,
.sk-page-modal h2 strong,
.sk-page-modal h2 b,
.sk-blog-content .article-body > .article-content .h2 b,
.sk-blog-content .article-body > .article-content .h2 strong,
.sk-blog-content .article-body > .article-content .h2 b,
.sk-blog-content .article-body > .article-content h2 b,
.sk-blog-content .article-body > .article-content h2 strong,
.sk-blog-content .article-body > .article-content h2 b,
.page-block .h2 b,
.page-block .h2 strong,
.page-block .h2 b,
.page-block h2 b,
.page-block h2 strong,
.page-block h2 b {
  font-weight: bold;
}
@media screen and (min-width: 1025px) {
  .sk-block .h2,
  .sk-block h2,
  .sk-content .h2,
  .sk-content h2,
  .sk-content-block .h2,
  .sk-content-block h2,
  .sk-page-modal .h2,
  .sk-page-modal h2,
  .sk-blog-content .article-body > .article-content .h2,
  .sk-blog-content .article-body > .article-content h2,
  .page-block .h2,
  .page-block h2 {
    font-size: 48px;
    line-height: 61px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sk-block .h2,
  .sk-block h2,
  .sk-content .h2,
  .sk-content h2,
  .sk-content-block .h2,
  .sk-content-block h2,
  .sk-page-modal .h2,
  .sk-page-modal h2,
  .sk-blog-content .article-body > .article-content .h2,
  .sk-blog-content .article-body > .article-content h2,
  .page-block .h2,
  .page-block h2 {
    font-size: 48px;
    line-height: 61px;
  }
}
@media screen and (max-width: 767px) {
  .sk-block .h2,
  .sk-block h2,
  .sk-content .h2,
  .sk-content h2,
  .sk-content-block .h2,
  .sk-content-block h2,
  .sk-page-modal .h2,
  .sk-page-modal h2,
  .sk-blog-content .article-body > .article-content .h2,
  .sk-blog-content .article-body > .article-content h2,
  .page-block .h2,
  .page-block h2 {
    font-size: 32px;
    line-height: 40px;
  }
}
.sk-block .h3,
.sk-block h3,
.sk-content .h3,
.sk-content h3,
.sk-content-block .h3,
.sk-content-block h3,
.sk-page-modal .h3,
.sk-page-modal h3,
.sk-blog-content .article-body > .article-content .h3,
.sk-blog-content .article-body > .article-content h3,
.page-block .h3,
.page-block h3 {
  font-family: "Red Hat Display", sans-serif;
  color: #002ea6;
  font-style: normal;
  font-weight: bold;
  margin-bottom: 32px;
}
.sk-block .h3 b,
.sk-block .h3 strong,
.sk-block .h3 b,
.sk-block h3 b,
.sk-block h3 strong,
.sk-block h3 b,
.sk-content .h3 b,
.sk-content .h3 strong,
.sk-content .h3 b,
.sk-content h3 b,
.sk-content h3 strong,
.sk-content h3 b,
.sk-content-block .h3 b,
.sk-content-block .h3 strong,
.sk-content-block .h3 b,
.sk-content-block h3 b,
.sk-content-block h3 strong,
.sk-content-block h3 b,
.sk-page-modal .h3 b,
.sk-page-modal .h3 strong,
.sk-page-modal .h3 b,
.sk-page-modal h3 b,
.sk-page-modal h3 strong,
.sk-page-modal h3 b,
.sk-blog-content .article-body > .article-content .h3 b,
.sk-blog-content .article-body > .article-content .h3 strong,
.sk-blog-content .article-body > .article-content .h3 b,
.sk-blog-content .article-body > .article-content h3 b,
.sk-blog-content .article-body > .article-content h3 strong,
.sk-blog-content .article-body > .article-content h3 b,
.page-block .h3 b,
.page-block .h3 strong,
.page-block .h3 b,
.page-block h3 b,
.page-block h3 strong,
.page-block h3 b {
  font-weight: bold;
}
@media screen and (min-width: 1025px) {
  .sk-block .h3,
  .sk-block h3,
  .sk-content .h3,
  .sk-content h3,
  .sk-content-block .h3,
  .sk-content-block h3,
  .sk-page-modal .h3,
  .sk-page-modal h3,
  .sk-blog-content .article-body > .article-content .h3,
  .sk-blog-content .article-body > .article-content h3,
  .page-block .h3,
  .page-block h3 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sk-block .h3,
  .sk-block h3,
  .sk-content .h3,
  .sk-content h3,
  .sk-content-block .h3,
  .sk-content-block h3,
  .sk-page-modal .h3,
  .sk-page-modal h3,
  .sk-blog-content .article-body > .article-content .h3,
  .sk-blog-content .article-body > .article-content h3,
  .page-block .h3,
  .page-block h3 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media screen and (max-width: 767px) {
  .sk-block .h3,
  .sk-block h3,
  .sk-content .h3,
  .sk-content h3,
  .sk-content-block .h3,
  .sk-content-block h3,
  .sk-page-modal .h3,
  .sk-page-modal h3,
  .sk-blog-content .article-body > .article-content .h3,
  .sk-blog-content .article-body > .article-content h3,
  .page-block .h3,
  .page-block h3 {
    font-size: 24px;
    line-height: 36px;
  }
}
.sk-block .h4,
.sk-block h4,
.sk-content .h4,
.sk-content h4,
.sk-content-block .h4,
.sk-content-block h4,
.sk-page-modal .h4,
.sk-page-modal h4,
.sk-blog-content .article-body > .article-content .h4,
.sk-blog-content .article-body > .article-content h4,
.page-block .h4,
.page-block h4 {
  font-family: "Red Hat Display", sans-serif;
  color: #002ea6;
  font-style: normal;
  font-weight: bold;
  margin-bottom: 32px;
}
.sk-block .h4 b,
.sk-block .h4 strong,
.sk-block .h4 b,
.sk-block h4 b,
.sk-block h4 strong,
.sk-block h4 b,
.sk-content .h4 b,
.sk-content .h4 strong,
.sk-content .h4 b,
.sk-content h4 b,
.sk-content h4 strong,
.sk-content h4 b,
.sk-content-block .h4 b,
.sk-content-block .h4 strong,
.sk-content-block .h4 b,
.sk-content-block h4 b,
.sk-content-block h4 strong,
.sk-content-block h4 b,
.sk-page-modal .h4 b,
.sk-page-modal .h4 strong,
.sk-page-modal .h4 b,
.sk-page-modal h4 b,
.sk-page-modal h4 strong,
.sk-page-modal h4 b,
.sk-blog-content .article-body > .article-content .h4 b,
.sk-blog-content .article-body > .article-content .h4 strong,
.sk-blog-content .article-body > .article-content .h4 b,
.sk-blog-content .article-body > .article-content h4 b,
.sk-blog-content .article-body > .article-content h4 strong,
.sk-blog-content .article-body > .article-content h4 b,
.page-block .h4 b,
.page-block .h4 strong,
.page-block .h4 b,
.page-block h4 b,
.page-block h4 strong,
.page-block h4 b {
  font-weight: bold;
}
@media screen and (min-width: 1025px) {
  .sk-block .h4,
  .sk-block h4,
  .sk-content .h4,
  .sk-content h4,
  .sk-content-block .h4,
  .sk-content-block h4,
  .sk-page-modal .h4,
  .sk-page-modal h4,
  .sk-blog-content .article-body > .article-content .h4,
  .sk-blog-content .article-body > .article-content h4,
  .page-block .h4,
  .page-block h4 {
    font-size: 28px;
    line-height: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sk-block .h4,
  .sk-block h4,
  .sk-content .h4,
  .sk-content h4,
  .sk-content-block .h4,
  .sk-content-block h4,
  .sk-page-modal .h4,
  .sk-page-modal h4,
  .sk-blog-content .article-body > .article-content .h4,
  .sk-blog-content .article-body > .article-content h4,
  .page-block .h4,
  .page-block h4 {
    font-size: 28px;
    line-height: 30px;
  }
}
@media screen and (max-width: 767px) {
  .sk-block .h4,
  .sk-block h4,
  .sk-content .h4,
  .sk-content h4,
  .sk-content-block .h4,
  .sk-content-block h4,
  .sk-page-modal .h4,
  .sk-page-modal h4,
  .sk-blog-content .article-body > .article-content .h4,
  .sk-blog-content .article-body > .article-content h4,
  .page-block .h4,
  .page-block h4 {
    font-size: 22px;
    line-height: 24px;
  }
}
.sk-block .h5,
.sk-block h5,
.sk-content .h5,
.sk-content h5,
.sk-content-block .h5,
.sk-content-block h5,
.sk-page-modal .h5,
.sk-page-modal h5,
.sk-blog-content .article-body > .article-content .h5,
.sk-blog-content .article-body > .article-content h5,
.page-block .h5,
.page-block h5 {
  font-family: "Red Hat Display", sans-serif;
  color: #002ea6;
  font-style: normal;
  font-weight: bold;
  margin-bottom: 32px;
}
.sk-block .h5 b,
.sk-block .h5 strong,
.sk-block .h5 b,
.sk-block h5 b,
.sk-block h5 strong,
.sk-block h5 b,
.sk-content .h5 b,
.sk-content .h5 strong,
.sk-content .h5 b,
.sk-content h5 b,
.sk-content h5 strong,
.sk-content h5 b,
.sk-content-block .h5 b,
.sk-content-block .h5 strong,
.sk-content-block .h5 b,
.sk-content-block h5 b,
.sk-content-block h5 strong,
.sk-content-block h5 b,
.sk-page-modal .h5 b,
.sk-page-modal .h5 strong,
.sk-page-modal .h5 b,
.sk-page-modal h5 b,
.sk-page-modal h5 strong,
.sk-page-modal h5 b,
.sk-blog-content .article-body > .article-content .h5 b,
.sk-blog-content .article-body > .article-content .h5 strong,
.sk-blog-content .article-body > .article-content .h5 b,
.sk-blog-content .article-body > .article-content h5 b,
.sk-blog-content .article-body > .article-content h5 strong,
.sk-blog-content .article-body > .article-content h5 b,
.page-block .h5 b,
.page-block .h5 strong,
.page-block .h5 b,
.page-block h5 b,
.page-block h5 strong,
.page-block h5 b {
  font-weight: bold;
}
@media screen and (min-width: 1025px) {
  .sk-block .h5,
  .sk-block h5,
  .sk-content .h5,
  .sk-content h5,
  .sk-content-block .h5,
  .sk-content-block h5,
  .sk-page-modal .h5,
  .sk-page-modal h5,
  .sk-blog-content .article-body > .article-content .h5,
  .sk-blog-content .article-body > .article-content h5,
  .page-block .h5,
  .page-block h5 {
    font-size: 24px;
    line-height: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sk-block .h5,
  .sk-block h5,
  .sk-content .h5,
  .sk-content h5,
  .sk-content-block .h5,
  .sk-content-block h5,
  .sk-page-modal .h5,
  .sk-page-modal h5,
  .sk-blog-content .article-body > .article-content .h5,
  .sk-blog-content .article-body > .article-content h5,
  .page-block .h5,
  .page-block h5 {
    font-size: 24px;
    line-height: 30px;
  }
}
@media screen and (max-width: 767px) {
  .sk-block .h5,
  .sk-block h5,
  .sk-content .h5,
  .sk-content h5,
  .sk-content-block .h5,
  .sk-content-block h5,
  .sk-page-modal .h5,
  .sk-page-modal h5,
  .sk-blog-content .article-body > .article-content .h5,
  .sk-blog-content .article-body > .article-content h5,
  .page-block .h5,
  .page-block h5 {
    font-size: 18px;
    line-height: 24px;
  }
}
.sk-block .h6,
.sk-block h6,
.sk-content .h6,
.sk-content h6,
.sk-content-block .h6,
.sk-content-block h6,
.sk-page-modal .h6,
.sk-page-modal h6,
.sk-blog-content .article-body > .article-content .h6,
.sk-blog-content .article-body > .article-content h6,
.page-block .h6,
.page-block h6 {
  font-family: "Red Hat Display", sans-serif;
  color: #002ea6;
  font-style: normal;
  font-weight: bold;
  margin-bottom: 32px;
}
.sk-block .h6 b,
.sk-block .h6 strong,
.sk-block .h6 b,
.sk-block h6 b,
.sk-block h6 strong,
.sk-block h6 b,
.sk-content .h6 b,
.sk-content .h6 strong,
.sk-content .h6 b,
.sk-content h6 b,
.sk-content h6 strong,
.sk-content h6 b,
.sk-content-block .h6 b,
.sk-content-block .h6 strong,
.sk-content-block .h6 b,
.sk-content-block h6 b,
.sk-content-block h6 strong,
.sk-content-block h6 b,
.sk-page-modal .h6 b,
.sk-page-modal .h6 strong,
.sk-page-modal .h6 b,
.sk-page-modal h6 b,
.sk-page-modal h6 strong,
.sk-page-modal h6 b,
.sk-blog-content .article-body > .article-content .h6 b,
.sk-blog-content .article-body > .article-content .h6 strong,
.sk-blog-content .article-body > .article-content .h6 b,
.sk-blog-content .article-body > .article-content h6 b,
.sk-blog-content .article-body > .article-content h6 strong,
.sk-blog-content .article-body > .article-content h6 b,
.page-block .h6 b,
.page-block .h6 strong,
.page-block .h6 b,
.page-block h6 b,
.page-block h6 strong,
.page-block h6 b {
  font-weight: bold;
}
@media screen and (min-width: 1025px) {
  .sk-block .h6,
  .sk-block h6,
  .sk-content .h6,
  .sk-content h6,
  .sk-content-block .h6,
  .sk-content-block h6,
  .sk-page-modal .h6,
  .sk-page-modal h6,
  .sk-blog-content .article-body > .article-content .h6,
  .sk-blog-content .article-body > .article-content h6,
  .page-block .h6,
  .page-block h6 {
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sk-block .h6,
  .sk-block h6,
  .sk-content .h6,
  .sk-content h6,
  .sk-content-block .h6,
  .sk-content-block h6,
  .sk-page-modal .h6,
  .sk-page-modal h6,
  .sk-blog-content .article-body > .article-content .h6,
  .sk-blog-content .article-body > .article-content h6,
  .page-block .h6,
  .page-block h6 {
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen and (max-width: 767px) {
  .sk-block .h6,
  .sk-block h6,
  .sk-content .h6,
  .sk-content h6,
  .sk-content-block .h6,
  .sk-content-block h6,
  .sk-page-modal .h6,
  .sk-page-modal h6,
  .sk-blog-content .article-body > .article-content .h6,
  .sk-blog-content .article-body > .article-content h6,
  .page-block .h6,
  .page-block h6 {
    font-size: 16px;
    line-height: 20px;
  }
}
.sk-block blockquote,
.sk-block .quote-by-txt,
.sk-content blockquote,
.sk-content .quote-by-txt,
.sk-content-block blockquote,
.sk-content-block .quote-by-txt,
.sk-page-modal blockquote,
.sk-page-modal .quote-by-txt,
.sk-blog-content .article-body > .article-content blockquote,
.sk-blog-content .article-body > .article-content .quote-by-txt,
.page-block blockquote,
.page-block .quote-by-txt {
  color: #002ea6;
  font-family: "Red Hat Display", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
}
.sk-block p,
.sk-block .text,
.sk-block .txt-content,
.sk-block .p,
.sk-block .txt-post-rtf p,
.sk-content p,
.sk-content .text,
.sk-content .txt-content,
.sk-content .p,
.sk-content .txt-post-rtf p,
.sk-content-block p,
.sk-content-block .text,
.sk-content-block .txt-content,
.sk-content-block .p,
.sk-content-block .txt-post-rtf p,
.sk-page-modal p,
.sk-page-modal .text,
.sk-page-modal .txt-content,
.sk-page-modal .p,
.sk-page-modal .txt-post-rtf p,
.sk-blog-content .article-body > .article-content p,
.sk-blog-content .article-body > .article-content .text,
.sk-blog-content .article-body > .article-content .txt-content,
.sk-blog-content .article-body > .article-content .p,
.sk-blog-content .article-body > .article-content .txt-post-rtf p,
.page-block p,
.page-block .text,
.page-block .txt-content,
.page-block .p,
.page-block .txt-post-rtf p {
  font-family: "Libre Franklin", sans-serif;
  color: #002ea6;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.sk-block li,
.sk-content li,
.sk-content-block li,
.sk-page-modal li,
.sk-blog-content .article-body > .article-content li,
.page-block li {
  font-family: "Libre Franklin", sans-serif;
  color: #002ea6;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.sk-block .text-highlight,
.sk-block p.text-highlight,
.sk-block .title-highlight,
.sk-content .text-highlight,
.sk-content p.text-highlight,
.sk-content .title-highlight,
.sk-content-block .text-highlight,
.sk-content-block p.text-highlight,
.sk-content-block .title-highlight,
.sk-page-modal .text-highlight,
.sk-page-modal p.text-highlight,
.sk-page-modal .title-highlight,
.sk-blog-content .article-body > .article-content .text-highlight,
.sk-blog-content .article-body > .article-content p.text-highlight,
.sk-blog-content .article-body > .article-content .title-highlight,
.page-block .text-highlight,
.page-block p.text-highlight,
.page-block .title-highlight {
  font-family: "Libre Franklin", sans-serif;
  color: #002ea6;
  font-style: normal;
  font-weight: 600;
  display: block;
  margin-bottom: 32px;
}
.sk-block .text-highlight strong,
.sk-block .text-highlight b,
.sk-block p.text-highlight strong,
.sk-block p.text-highlight b,
.sk-block .title-highlight strong,
.sk-block .title-highlight b,
.sk-content .text-highlight strong,
.sk-content .text-highlight b,
.sk-content p.text-highlight strong,
.sk-content p.text-highlight b,
.sk-content .title-highlight strong,
.sk-content .title-highlight b,
.sk-content-block .text-highlight strong,
.sk-content-block .text-highlight b,
.sk-content-block p.text-highlight strong,
.sk-content-block p.text-highlight b,
.sk-content-block .title-highlight strong,
.sk-content-block .title-highlight b,
.sk-page-modal .text-highlight strong,
.sk-page-modal .text-highlight b,
.sk-page-modal p.text-highlight strong,
.sk-page-modal p.text-highlight b,
.sk-page-modal .title-highlight strong,
.sk-page-modal .title-highlight b,
.sk-blog-content .article-body > .article-content .text-highlight strong,
.sk-blog-content .article-body > .article-content .text-highlight b,
.sk-blog-content .article-body > .article-content p.text-highlight strong,
.sk-blog-content .article-body > .article-content p.text-highlight b,
.sk-blog-content .article-body > .article-content .title-highlight strong,
.sk-blog-content .article-body > .article-content .title-highlight b,
.page-block .text-highlight strong,
.page-block .text-highlight b,
.page-block p.text-highlight strong,
.page-block p.text-highlight b,
.page-block .title-highlight strong,
.page-block .title-highlight b {
  font-weight: 600;
}
@media screen and (min-width: 1025px) {
  .sk-block .text-highlight,
  .sk-block p.text-highlight,
  .sk-block .title-highlight,
  .sk-content .text-highlight,
  .sk-content p.text-highlight,
  .sk-content .title-highlight,
  .sk-content-block .text-highlight,
  .sk-content-block p.text-highlight,
  .sk-content-block .title-highlight,
  .sk-page-modal .text-highlight,
  .sk-page-modal p.text-highlight,
  .sk-page-modal .title-highlight,
  .sk-blog-content .article-body > .article-content .text-highlight,
  .sk-blog-content .article-body > .article-content p.text-highlight,
  .sk-blog-content .article-body > .article-content .title-highlight,
  .page-block .text-highlight,
  .page-block p.text-highlight,
  .page-block .title-highlight {
    font-size: 24px;
    line-height: 36px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sk-block .text-highlight,
  .sk-block p.text-highlight,
  .sk-block .title-highlight,
  .sk-content .text-highlight,
  .sk-content p.text-highlight,
  .sk-content .title-highlight,
  .sk-content-block .text-highlight,
  .sk-content-block p.text-highlight,
  .sk-content-block .title-highlight,
  .sk-page-modal .text-highlight,
  .sk-page-modal p.text-highlight,
  .sk-page-modal .title-highlight,
  .sk-blog-content .article-body > .article-content .text-highlight,
  .sk-blog-content .article-body > .article-content p.text-highlight,
  .sk-blog-content .article-body > .article-content .title-highlight,
  .page-block .text-highlight,
  .page-block p.text-highlight,
  .page-block .title-highlight {
    font-size: 24px;
    line-height: 36px;
  }
}
@media screen and (max-width: 767px) {
  .sk-block .text-highlight,
  .sk-block p.text-highlight,
  .sk-block .title-highlight,
  .sk-content .text-highlight,
  .sk-content p.text-highlight,
  .sk-content .title-highlight,
  .sk-content-block .text-highlight,
  .sk-content-block p.text-highlight,
  .sk-content-block .title-highlight,
  .sk-page-modal .text-highlight,
  .sk-page-modal p.text-highlight,
  .sk-page-modal .title-highlight,
  .sk-blog-content .article-body > .article-content .text-highlight,
  .sk-blog-content .article-body > .article-content p.text-highlight,
  .sk-blog-content .article-body > .article-content .title-highlight,
  .page-block .text-highlight,
  .page-block p.text-highlight,
  .page-block .title-highlight {
    font-size: 22px;
    line-height: 30px;
  }
}
.sk-block .list-num,
.sk-content .list-num,
.sk-content-block .list-num,
.sk-page-modal .list-num,
.sk-blog-content .article-body > .article-content .list-num,
.page-block .list-num {
  counter-reset: item;
}
.sk-block .list-num li,
.sk-content .list-num li,
.sk-content-block .list-num li,
.sk-page-modal .list-num li,
.sk-blog-content .article-body > .article-content .list-num li,
.page-block .list-num li {
  list-style-type: none;
  counter-increment: item;
  margin-bottom: 13px;
  position: relative;
  padding-left: 35px;
}
.sk-block .list-num li:before,
.sk-content .list-num li:before,
.sk-content-block .list-num li:before,
.sk-page-modal .list-num li:before,
.sk-blog-content .article-body > .article-content .list-num li:before,
.page-block .list-num li:before {
  content: counter(item);
  background-color: #002ea6;
  position: absolute;
  left: 0;
  top: 2px;
  width: 26px;
  height: 26px;
  text-align: center;
  border-radius: 50%;
  font-family: "Libre Franklin", sans-serif;
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sk-block .disclaimer,
.sk-content .disclaimer,
.sk-content-block .disclaimer,
.sk-page-modal .disclaimer,
.sk-blog-content .article-body > .article-content .disclaimer,
.page-block .disclaimer {
  font-family: "Libre Franklin", sans-serif;
  color: #002ea6;
  font-size: 12px;
  font-style: normal;
  letter-spacing: 0.6px;
  font-weight: 400;
  line-height: 20px;
}
.sk-block .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn),
.sk-content .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn),
.sk-content-block .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn),
.sk-page-modal .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn),
.sk-blog-content .article-body > .article-content .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn),
.page-block .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn) {
  color: #002ea6 !important;
  font-weight: bold;
  text-decoration: underline;
}
.sk-block .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn):hover, .sk-block .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn):focus,
.sk-content .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn):hover,
.sk-content .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn):focus,
.sk-content-block .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn):hover,
.sk-content-block .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn):focus,
.sk-page-modal .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn):hover,
.sk-page-modal .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn):focus,
.sk-blog-content .article-body > .article-content .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn):hover,
.sk-blog-content .article-body > .article-content .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn):focus,
.page-block .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn):hover,
.page-block .disclaimer a[href^="tel:"]:not(.btn-default):not(.contact-section-btn):focus {
  text-decoration: underline;
}
.sk-block .section-title,
.sk-content .section-title,
.sk-content-block .section-title,
.sk-page-modal .section-title,
.sk-blog-content .article-body > .article-content .section-title,
.page-block .section-title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 200 !important;
  letter-spacing: 7px;
}
.sk-block input[type=submit],
.sk-content input[type=submit],
.sk-content-block input[type=submit],
.sk-page-modal input[type=submit],
.sk-blog-content .article-body > .article-content input[type=submit],
.page-block input[type=submit] {
  font-family: "Red Hat Display", sans-serif;
  color: #002ea6;
  font-size: 16px !important;
  font-style: normal;
  font-weight: medium !important;
  line-height: 20px !important;
}
.sk-block .btn-default,
.sk-block .btn-txt,
.sk-content .btn-default,
.sk-content .btn-txt,
.sk-content-block .btn-default,
.sk-content-block .btn-txt,
.sk-page-modal .btn-default,
.sk-page-modal .btn-txt,
.sk-blog-content .article-body > .article-content .btn-default,
.sk-blog-content .article-body > .article-content .btn-txt,
.page-block .btn-default,
.page-block .btn-txt {
  font-family: "Red Hat Display", sans-serif;
  color: #002ea6;
  font-size: 16px;
  font-style: normal;
  font-weight: medium;
  line-height: 20px;
}
.sk-block .btn-default:hover, .sk-block .btn-default:focus,
.sk-block .btn-txt:hover,
.sk-block .btn-txt:focus,
.sk-content .btn-default:hover,
.sk-content .btn-default:focus,
.sk-content .btn-txt:hover,
.sk-content .btn-txt:focus,
.sk-content-block .btn-default:hover,
.sk-content-block .btn-default:focus,
.sk-content-block .btn-txt:hover,
.sk-content-block .btn-txt:focus,
.sk-page-modal .btn-default:hover,
.sk-page-modal .btn-default:focus,
.sk-page-modal .btn-txt:hover,
.sk-page-modal .btn-txt:focus,
.sk-blog-content .article-body > .article-content .btn-default:hover,
.sk-blog-content .article-body > .article-content .btn-default:focus,
.sk-blog-content .article-body > .article-content .btn-txt:hover,
.sk-blog-content .article-body > .article-content .btn-txt:focus,
.page-block .btn-default:hover,
.page-block .btn-default:focus,
.page-block .btn-txt:hover,
.page-block .btn-txt:focus {
  color: #002ea6;
}
.sk-block .section-color-blue:hover .btn-default,
.sk-block .section-color-blue:hover .btn-txt, .sk-block .section-color-blue:focus .btn-default,
.sk-block .section-color-blue:focus .btn-txt,
.sk-content .section-color-blue:hover .btn-default,
.sk-content .section-color-blue:hover .btn-txt,
.sk-content .section-color-blue:focus .btn-default,
.sk-content .section-color-blue:focus .btn-txt,
.sk-content-block .section-color-blue:hover .btn-default,
.sk-content-block .section-color-blue:hover .btn-txt,
.sk-content-block .section-color-blue:focus .btn-default,
.sk-content-block .section-color-blue:focus .btn-txt,
.sk-page-modal .section-color-blue:hover .btn-default,
.sk-page-modal .section-color-blue:hover .btn-txt,
.sk-page-modal .section-color-blue:focus .btn-default,
.sk-page-modal .section-color-blue:focus .btn-txt,
.sk-blog-content .article-body > .article-content .section-color-blue:hover .btn-default,
.sk-blog-content .article-body > .article-content .section-color-blue:hover .btn-txt,
.sk-blog-content .article-body > .article-content .section-color-blue:focus .btn-default,
.sk-blog-content .article-body > .article-content .section-color-blue:focus .btn-txt,
.page-block .section-color-blue:hover .btn-default,
.page-block .section-color-blue:hover .btn-txt,
.page-block .section-color-blue:focus .btn-default,
.page-block .section-color-blue:focus .btn-txt {
  color: #002ea6;
}
.sk-block .section-color-blue .btn-default:hover, .sk-block .section-color-blue .btn-default:focus,
.sk-block .section-color-blue .btn-txt:hover,
.sk-block .section-color-blue .btn-txt:focus,
.sk-content .section-color-blue .btn-default:hover,
.sk-content .section-color-blue .btn-default:focus,
.sk-content .section-color-blue .btn-txt:hover,
.sk-content .section-color-blue .btn-txt:focus,
.sk-content-block .section-color-blue .btn-default:hover,
.sk-content-block .section-color-blue .btn-default:focus,
.sk-content-block .section-color-blue .btn-txt:hover,
.sk-content-block .section-color-blue .btn-txt:focus,
.sk-page-modal .section-color-blue .btn-default:hover,
.sk-page-modal .section-color-blue .btn-default:focus,
.sk-page-modal .section-color-blue .btn-txt:hover,
.sk-page-modal .section-color-blue .btn-txt:focus,
.sk-blog-content .article-body > .article-content .section-color-blue .btn-default:hover,
.sk-blog-content .article-body > .article-content .section-color-blue .btn-default:focus,
.sk-blog-content .article-body > .article-content .section-color-blue .btn-txt:hover,
.sk-blog-content .article-body > .article-content .section-color-blue .btn-txt:focus,
.page-block .section-color-blue .btn-default:hover,
.page-block .section-color-blue .btn-default:focus,
.page-block .section-color-blue .btn-txt:hover,
.page-block .section-color-blue .btn-txt:focus {
  color: #002ea6;
}
.sk-block .section-color-white:hover .btn-default,
.sk-block .section-color-white:hover .btn-txt, .sk-block .section-color-white:focus .btn-default,
.sk-block .section-color-white:focus .btn-txt,
.sk-content .section-color-white:hover .btn-default,
.sk-content .section-color-white:hover .btn-txt,
.sk-content .section-color-white:focus .btn-default,
.sk-content .section-color-white:focus .btn-txt,
.sk-content-block .section-color-white:hover .btn-default,
.sk-content-block .section-color-white:hover .btn-txt,
.sk-content-block .section-color-white:focus .btn-default,
.sk-content-block .section-color-white:focus .btn-txt,
.sk-page-modal .section-color-white:hover .btn-default,
.sk-page-modal .section-color-white:hover .btn-txt,
.sk-page-modal .section-color-white:focus .btn-default,
.sk-page-modal .section-color-white:focus .btn-txt,
.sk-blog-content .article-body > .article-content .section-color-white:hover .btn-default,
.sk-blog-content .article-body > .article-content .section-color-white:hover .btn-txt,
.sk-blog-content .article-body > .article-content .section-color-white:focus .btn-default,
.sk-blog-content .article-body > .article-content .section-color-white:focus .btn-txt,
.page-block .section-color-white:hover .btn-default,
.page-block .section-color-white:hover .btn-txt,
.page-block .section-color-white:focus .btn-default,
.page-block .section-color-white:focus .btn-txt {
  color: #17fcc4;
}
.sk-block .section-color-white .btn-default:hover, .sk-block .section-color-white .btn-default:focus,
.sk-block .section-color-white .btn-txt:hover,
.sk-block .section-color-white .btn-txt:focus,
.sk-content .section-color-white .btn-default:hover,
.sk-content .section-color-white .btn-default:focus,
.sk-content .section-color-white .btn-txt:hover,
.sk-content .section-color-white .btn-txt:focus,
.sk-content-block .section-color-white .btn-default:hover,
.sk-content-block .section-color-white .btn-default:focus,
.sk-content-block .section-color-white .btn-txt:hover,
.sk-content-block .section-color-white .btn-txt:focus,
.sk-page-modal .section-color-white .btn-default:hover,
.sk-page-modal .section-color-white .btn-default:focus,
.sk-page-modal .section-color-white .btn-txt:hover,
.sk-page-modal .section-color-white .btn-txt:focus,
.sk-blog-content .article-body > .article-content .section-color-white .btn-default:hover,
.sk-blog-content .article-body > .article-content .section-color-white .btn-default:focus,
.sk-blog-content .article-body > .article-content .section-color-white .btn-txt:hover,
.sk-blog-content .article-body > .article-content .section-color-white .btn-txt:focus,
.page-block .section-color-white .btn-default:hover,
.page-block .section-color-white .btn-default:focus,
.page-block .section-color-white .btn-txt:hover,
.page-block .section-color-white .btn-txt:focus {
  color: #17fcc4;
}
.sk-block .c-white .h1,
.sk-block .c-white h1,
.sk-block .c-white .h2,
.sk-block .c-white h2,
.sk-block .c-white .h3,
.sk-block .c-white h3,
.sk-block .c-white blockquote,
.sk-block .c-white p,
.sk-block .c-white .text,
.sk-block .c-white .p,
.sk-block .c-white li,
.sk-block .c-white .title-highlight,
.sk-block .c-white .text-highlight,
.sk-content .c-white .h1,
.sk-content .c-white h1,
.sk-content .c-white .h2,
.sk-content .c-white h2,
.sk-content .c-white .h3,
.sk-content .c-white h3,
.sk-content .c-white blockquote,
.sk-content .c-white p,
.sk-content .c-white .text,
.sk-content .c-white .p,
.sk-content .c-white li,
.sk-content .c-white .title-highlight,
.sk-content .c-white .text-highlight,
.sk-content-block .c-white .h1,
.sk-content-block .c-white h1,
.sk-content-block .c-white .h2,
.sk-content-block .c-white h2,
.sk-content-block .c-white .h3,
.sk-content-block .c-white h3,
.sk-content-block .c-white blockquote,
.sk-content-block .c-white p,
.sk-content-block .c-white .text,
.sk-content-block .c-white .p,
.sk-content-block .c-white li,
.sk-content-block .c-white .title-highlight,
.sk-content-block .c-white .text-highlight,
.sk-page-modal .c-white .h1,
.sk-page-modal .c-white h1,
.sk-page-modal .c-white .h2,
.sk-page-modal .c-white h2,
.sk-page-modal .c-white .h3,
.sk-page-modal .c-white h3,
.sk-page-modal .c-white blockquote,
.sk-page-modal .c-white p,
.sk-page-modal .c-white .text,
.sk-page-modal .c-white .p,
.sk-page-modal .c-white li,
.sk-page-modal .c-white .title-highlight,
.sk-page-modal .c-white .text-highlight,
.sk-blog-content .article-body > .article-content .c-white .h1,
.sk-blog-content .article-body > .article-content .c-white h1,
.sk-blog-content .article-body > .article-content .c-white .h2,
.sk-blog-content .article-body > .article-content .c-white h2,
.sk-blog-content .article-body > .article-content .c-white .h3,
.sk-blog-content .article-body > .article-content .c-white h3,
.sk-blog-content .article-body > .article-content .c-white blockquote,
.sk-blog-content .article-body > .article-content .c-white p,
.sk-blog-content .article-body > .article-content .c-white .text,
.sk-blog-content .article-body > .article-content .c-white .p,
.sk-blog-content .article-body > .article-content .c-white li,
.sk-blog-content .article-body > .article-content .c-white .title-highlight,
.sk-blog-content .article-body > .article-content .c-white .text-highlight,
.page-block .c-white .h1,
.page-block .c-white h1,
.page-block .c-white .h2,
.page-block .c-white h2,
.page-block .c-white .h3,
.page-block .c-white h3,
.page-block .c-white blockquote,
.page-block .c-white p,
.page-block .c-white .text,
.page-block .c-white .p,
.page-block .c-white li,
.page-block .c-white .title-highlight,
.page-block .c-white .text-highlight {
  color: #fff !important;
}
.sk-block .c-green .h1,
.sk-block .c-green h1,
.sk-block .c-green .h2,
.sk-block .c-green h2,
.sk-block .c-green .h3,
.sk-block .c-green h3,
.sk-block .c-green blockquote,
.sk-block .c-green p,
.sk-block .c-green .text,
.sk-block .c-green .p,
.sk-block .c-green li,
.sk-block .c-green .title-highlight .text-highlight,
.sk-content .c-green .h1,
.sk-content .c-green h1,
.sk-content .c-green .h2,
.sk-content .c-green h2,
.sk-content .c-green .h3,
.sk-content .c-green h3,
.sk-content .c-green blockquote,
.sk-content .c-green p,
.sk-content .c-green .text,
.sk-content .c-green .p,
.sk-content .c-green li,
.sk-content .c-green .title-highlight .text-highlight,
.sk-content-block .c-green .h1,
.sk-content-block .c-green h1,
.sk-content-block .c-green .h2,
.sk-content-block .c-green h2,
.sk-content-block .c-green .h3,
.sk-content-block .c-green h3,
.sk-content-block .c-green blockquote,
.sk-content-block .c-green p,
.sk-content-block .c-green .text,
.sk-content-block .c-green .p,
.sk-content-block .c-green li,
.sk-content-block .c-green .title-highlight .text-highlight,
.sk-page-modal .c-green .h1,
.sk-page-modal .c-green h1,
.sk-page-modal .c-green .h2,
.sk-page-modal .c-green h2,
.sk-page-modal .c-green .h3,
.sk-page-modal .c-green h3,
.sk-page-modal .c-green blockquote,
.sk-page-modal .c-green p,
.sk-page-modal .c-green .text,
.sk-page-modal .c-green .p,
.sk-page-modal .c-green li,
.sk-page-modal .c-green .title-highlight .text-highlight,
.sk-blog-content .article-body > .article-content .c-green .h1,
.sk-blog-content .article-body > .article-content .c-green h1,
.sk-blog-content .article-body > .article-content .c-green .h2,
.sk-blog-content .article-body > .article-content .c-green h2,
.sk-blog-content .article-body > .article-content .c-green .h3,
.sk-blog-content .article-body > .article-content .c-green h3,
.sk-blog-content .article-body > .article-content .c-green blockquote,
.sk-blog-content .article-body > .article-content .c-green p,
.sk-blog-content .article-body > .article-content .c-green .text,
.sk-blog-content .article-body > .article-content .c-green .p,
.sk-blog-content .article-body > .article-content .c-green li,
.sk-blog-content .article-body > .article-content .c-green .title-highlight .text-highlight,
.page-block .c-green .h1,
.page-block .c-green h1,
.page-block .c-green .h2,
.page-block .c-green h2,
.page-block .c-green .h3,
.page-block .c-green h3,
.page-block .c-green blockquote,
.page-block .c-green p,
.page-block .c-green .text,
.page-block .c-green .p,
.page-block .c-green li,
.page-block .c-green .title-highlight .text-highlight {
  color: #fff !important;
}
.sk-block .text-black,
.sk-block .c-black,
.sk-content .text-black,
.sk-content .c-black,
.sk-content-block .text-black,
.sk-content-block .c-black,
.sk-page-modal .text-black,
.sk-page-modal .c-black,
.sk-blog-content .article-body > .article-content .text-black,
.sk-blog-content .article-body > .article-content .c-black,
.page-block .text-black,
.page-block .c-black {
  color: #000;
}
.sk-block .text-white,
.sk-block .c-white,
.sk-content .text-white,
.sk-content .c-white,
.sk-content-block .text-white,
.sk-content-block .c-white,
.sk-page-modal .text-white,
.sk-page-modal .c-white,
.sk-blog-content .article-body > .article-content .text-white,
.sk-blog-content .article-body > .article-content .c-white,
.page-block .text-white,
.page-block .c-white {
  color: #fff;
}
.sk-block .text-blue-1,
.sk-block .c-blue-1,
.sk-content .text-blue-1,
.sk-content .c-blue-1,
.sk-content-block .text-blue-1,
.sk-content-block .c-blue-1,
.sk-page-modal .text-blue-1,
.sk-page-modal .c-blue-1,
.sk-blog-content .article-body > .article-content .text-blue-1,
.sk-blog-content .article-body > .article-content .c-blue-1,
.page-block .text-blue-1,
.page-block .c-blue-1 {
  color: #002ea6;
}
.sk-block .text-red-2,
.sk-block .c-red-2,
.sk-content .text-red-2,
.sk-content .c-red-2,
.sk-content-block .text-red-2,
.sk-content-block .c-red-2,
.sk-page-modal .text-red-2,
.sk-page-modal .c-red-2,
.sk-blog-content .article-body > .article-content .text-red-2,
.sk-blog-content .article-body > .article-content .c-red-2,
.page-block .text-red-2,
.page-block .c-red-2 {
  color: #ff034a;
}
.sk-block .text-blue-7,
.sk-block .c-blue-7,
.sk-content .text-blue-7,
.sk-content .c-blue-7,
.sk-content-block .text-blue-7,
.sk-content-block .c-blue-7,
.sk-page-modal .text-blue-7,
.sk-page-modal .c-blue-7,
.sk-blog-content .article-body > .article-content .text-blue-7,
.sk-blog-content .article-body > .article-content .c-blue-7,
.page-block .text-blue-7,
.page-block .c-blue-7 {
  color: #002fa7;
}
.sk-block .text-blue-15,
.sk-block .c-blue-15,
.sk-content .text-blue-15,
.sk-content .c-blue-15,
.sk-content-block .text-blue-15,
.sk-content-block .c-blue-15,
.sk-page-modal .text-blue-15,
.sk-page-modal .c-blue-15,
.sk-blog-content .article-body > .article-content .text-blue-15,
.sk-blog-content .article-body > .article-content .c-blue-15,
.page-block .text-blue-15,
.page-block .c-blue-15 {
  color: #001a5d;
}
.sk-block .text-green-1,
.sk-block .c-green-1,
.sk-content .text-green-1,
.sk-content .c-green-1,
.sk-content-block .text-green-1,
.sk-content-block .c-green-1,
.sk-page-modal .text-green-1,
.sk-page-modal .c-green-1,
.sk-blog-content .article-body > .article-content .text-green-1,
.sk-blog-content .article-body > .article-content .c-green-1,
.page-block .text-green-1,
.page-block .c-green-1 {
  color: #17fcc4;
}
.sk-block .text-gray-2,
.sk-block .c-gray-2,
.sk-content .text-gray-2,
.sk-content .c-gray-2,
.sk-content-block .text-gray-2,
.sk-content-block .c-gray-2,
.sk-page-modal .text-gray-2,
.sk-page-modal .c-gray-2,
.sk-blog-content .article-body > .article-content .text-gray-2,
.sk-blog-content .article-body > .article-content .c-gray-2,
.page-block .text-gray-2,
.page-block .c-gray-2 {
  color: rgba(255, 255, 255, 0.4);
}
.sk-block .text-gray-8,
.sk-block .c-gray-8,
.sk-content .text-gray-8,
.sk-content .c-gray-8,
.sk-content-block .text-gray-8,
.sk-content-block .c-gray-8,
.sk-page-modal .text-gray-8,
.sk-page-modal .c-gray-8,
.sk-blog-content .article-body > .article-content .text-gray-8,
.sk-blog-content .article-body > .article-content .c-gray-8,
.page-block .text-gray-8,
.page-block .c-gray-8 {
  color: rgba(255, 255, 255, 0.7);
}
.sk-block .text-gray-18,
.sk-block .c-gray-18,
.sk-content .text-gray-18,
.sk-content .c-gray-18,
.sk-content-block .text-gray-18,
.sk-content-block .c-gray-18,
.sk-page-modal .text-gray-18,
.sk-page-modal .c-gray-18,
.sk-blog-content .article-body > .article-content .text-gray-18,
.sk-blog-content .article-body > .article-content .c-gray-18,
.page-block .text-gray-18,
.page-block .c-gray-18 {
  color: #5b5b5b;
}
.sk-block .text-blue-5,
.sk-block .c-blue-5,
.sk-content .text-blue-5,
.sk-content .c-blue-5,
.sk-content-block .text-blue-5,
.sk-content-block .c-blue-5,
.sk-page-modal .text-blue-5,
.sk-page-modal .c-blue-5,
.sk-blog-content .article-body > .article-content .text-blue-5,
.sk-blog-content .article-body > .article-content .c-blue-5,
.page-block .text-blue-5,
.page-block .c-blue-5 {
  color: #008fc4;
}
.sk-block .text-green-5,
.sk-block .c-green-5,
.sk-content .text-green-5,
.sk-content .c-green-5,
.sk-content-block .text-green-5,
.sk-content-block .c-green-5,
.sk-page-modal .text-green-5,
.sk-page-modal .c-green-5,
.sk-blog-content .article-body > .article-content .text-green-5,
.sk-blog-content .article-body > .article-content .c-green-5,
.page-block .text-green-5,
.page-block .c-green-5 {
  color: #4aad52;
}
.sk-block .fw-300,
.sk-content .fw-300,
.sk-content-block .fw-300,
.sk-page-modal .fw-300,
.sk-blog-content .article-body > .article-content .fw-300,
.page-block .fw-300 {
  font-weight: 300;
}
.sk-block .fw-400,
.sk-content .fw-400,
.sk-content-block .fw-400,
.sk-page-modal .fw-400,
.sk-blog-content .article-body > .article-content .fw-400,
.page-block .fw-400 {
  font-weight: 400;
}
.sk-block .fw-500,
.sk-content .fw-500,
.sk-content-block .fw-500,
.sk-page-modal .fw-500,
.sk-blog-content .article-body > .article-content .fw-500,
.page-block .fw-500 {
  font-weight: 500;
}
.sk-block .fw-600,
.sk-content .fw-600,
.sk-content-block .fw-600,
.sk-page-modal .fw-600,
.sk-blog-content .article-body > .article-content .fw-600,
.page-block .fw-600 {
  font-weight: 600;
}
.sk-block .fw-700,
.sk-content .fw-700,
.sk-content-block .fw-700,
.sk-page-modal .fw-700,
.sk-blog-content .article-body > .article-content .fw-700,
.page-block .fw-700 {
  font-weight: 700;
}
.sk-block .fw-800,
.sk-content .fw-800,
.sk-content-block .fw-800,
.sk-page-modal .fw-800,
.sk-blog-content .article-body > .article-content .fw-800,
.page-block .fw-800 {
  font-weight: 800;
}
.sk-block .list-title,
.sk-block .list-title h3,
.sk-content .list-title,
.sk-content .list-title h3,
.sk-content-block .list-title,
.sk-content-block .list-title h3,
.sk-page-modal .list-title,
.sk-page-modal .list-title h3,
.sk-blog-content .article-body > .article-content .list-title,
.sk-blog-content .article-body > .article-content .list-title h3,
.page-block .list-title,
.page-block .list-title h3 {
  font-family: "Libre Franklin", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.sk-block .text-headline,
.sk-content .text-headline,
.sk-content-block .text-headline,
.sk-page-modal .text-headline,
.sk-blog-content .article-body > .article-content .text-headline,
.page-block .text-headline {
  text-transform: uppercase;
  font-family: "Libre Franklin", sans-serif;
  font-size: 14px;
  font-weight: 250;
  line-height: 24px;
  letter-spacing: 7px;
  text-align: left;
}
.sk-block .phone-icon,
.sk-content .phone-icon,
.sk-content-block .phone-icon,
.sk-page-modal .phone-icon,
.sk-blog-content .article-body > .article-content .phone-icon,
.page-block .phone-icon {
  display: inline-flex;
}
.sk-block .phone-icon:before,
.sk-content .phone-icon:before,
.sk-content-block .phone-icon:before,
.sk-page-modal .phone-icon:before,
.sk-blog-content .article-body > .article-content .phone-icon:before,
.page-block .phone-icon:before {
  margin-right: 15px;
  content: "";
  display: inline-block;
  background-image: url("/wp-content/themes/sekure/assets/media/svgs/phone-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  position: relative;
}
.sk-block .text-white h1,
.sk-block .text-white h2,
.sk-block .text-white h3,
.sk-block .text-white h4,
.sk-block .text-white h5,
.sk-block .text-white h6,
.sk-block .text-white .h1,
.sk-block .text-white .h2,
.sk-block .text-white .h3,
.sk-block .text-white .h4,
.sk-block .text-white .h5,
.sk-block .text-white .h6,
.sk-block .text-white span:not(.btn-txt),
.sk-block .text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-block .text-white .title-highlight,
.sk-block .text-white li,
.sk-block .text-white p, .sk-block.text-white h1,
.sk-block.text-white h2,
.sk-block.text-white h3,
.sk-block.text-white h4,
.sk-block.text-white h5,
.sk-block.text-white h6,
.sk-block.text-white .h1,
.sk-block.text-white .h2,
.sk-block.text-white .h3,
.sk-block.text-white .h4,
.sk-block.text-white .h5,
.sk-block.text-white .h6,
.sk-block.text-white span:not(.btn-txt),
.sk-block.text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-block.text-white .title-highlight,
.sk-block.text-white li,
.sk-block.text-white p,
.sk-content .text-white h1,
.sk-content .text-white h2,
.sk-content .text-white h3,
.sk-content .text-white h4,
.sk-content .text-white h5,
.sk-content .text-white h6,
.sk-content .text-white .h1,
.sk-content .text-white .h2,
.sk-content .text-white .h3,
.sk-content .text-white .h4,
.sk-content .text-white .h5,
.sk-content .text-white .h6,
.sk-content .text-white span:not(.btn-txt),
.sk-content .text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content .text-white .title-highlight,
.sk-content .text-white li,
.sk-content .text-white p,
.sk-content.text-white h1,
.sk-content.text-white h2,
.sk-content.text-white h3,
.sk-content.text-white h4,
.sk-content.text-white h5,
.sk-content.text-white h6,
.sk-content.text-white .h1,
.sk-content.text-white .h2,
.sk-content.text-white .h3,
.sk-content.text-white .h4,
.sk-content.text-white .h5,
.sk-content.text-white .h6,
.sk-content.text-white span:not(.btn-txt),
.sk-content.text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content.text-white .title-highlight,
.sk-content.text-white li,
.sk-content.text-white p,
.sk-content-block .text-white h1,
.sk-content-block .text-white h2,
.sk-content-block .text-white h3,
.sk-content-block .text-white h4,
.sk-content-block .text-white h5,
.sk-content-block .text-white h6,
.sk-content-block .text-white .h1,
.sk-content-block .text-white .h2,
.sk-content-block .text-white .h3,
.sk-content-block .text-white .h4,
.sk-content-block .text-white .h5,
.sk-content-block .text-white .h6,
.sk-content-block .text-white span:not(.btn-txt),
.sk-content-block .text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content-block .text-white .title-highlight,
.sk-content-block .text-white li,
.sk-content-block .text-white p,
.sk-content-block.text-white h1,
.sk-content-block.text-white h2,
.sk-content-block.text-white h3,
.sk-content-block.text-white h4,
.sk-content-block.text-white h5,
.sk-content-block.text-white h6,
.sk-content-block.text-white .h1,
.sk-content-block.text-white .h2,
.sk-content-block.text-white .h3,
.sk-content-block.text-white .h4,
.sk-content-block.text-white .h5,
.sk-content-block.text-white .h6,
.sk-content-block.text-white span:not(.btn-txt),
.sk-content-block.text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content-block.text-white .title-highlight,
.sk-content-block.text-white li,
.sk-content-block.text-white p,
.sk-page-modal .text-white h1,
.sk-page-modal .text-white h2,
.sk-page-modal .text-white h3,
.sk-page-modal .text-white h4,
.sk-page-modal .text-white h5,
.sk-page-modal .text-white h6,
.sk-page-modal .text-white .h1,
.sk-page-modal .text-white .h2,
.sk-page-modal .text-white .h3,
.sk-page-modal .text-white .h4,
.sk-page-modal .text-white .h5,
.sk-page-modal .text-white .h6,
.sk-page-modal .text-white span:not(.btn-txt),
.sk-page-modal .text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-page-modal .text-white .title-highlight,
.sk-page-modal .text-white li,
.sk-page-modal .text-white p,
.sk-page-modal.text-white h1,
.sk-page-modal.text-white h2,
.sk-page-modal.text-white h3,
.sk-page-modal.text-white h4,
.sk-page-modal.text-white h5,
.sk-page-modal.text-white h6,
.sk-page-modal.text-white .h1,
.sk-page-modal.text-white .h2,
.sk-page-modal.text-white .h3,
.sk-page-modal.text-white .h4,
.sk-page-modal.text-white .h5,
.sk-page-modal.text-white .h6,
.sk-page-modal.text-white span:not(.btn-txt),
.sk-page-modal.text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-page-modal.text-white .title-highlight,
.sk-page-modal.text-white li,
.sk-page-modal.text-white p,
.sk-blog-content .article-body > .article-content .text-white h1,
.sk-blog-content .article-body > .article-content .text-white h2,
.sk-blog-content .article-body > .article-content .text-white h3,
.sk-blog-content .article-body > .article-content .text-white h4,
.sk-blog-content .article-body > .article-content .text-white h5,
.sk-blog-content .article-body > .article-content .text-white h6,
.sk-blog-content .article-body > .article-content .text-white .h1,
.sk-blog-content .article-body > .article-content .text-white .h2,
.sk-blog-content .article-body > .article-content .text-white .h3,
.sk-blog-content .article-body > .article-content .text-white .h4,
.sk-blog-content .article-body > .article-content .text-white .h5,
.sk-blog-content .article-body > .article-content .text-white .h6,
.sk-blog-content .article-body > .article-content .text-white span:not(.btn-txt),
.sk-blog-content .article-body > .article-content .text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-blog-content .article-body > .article-content .text-white .title-highlight,
.sk-blog-content .article-body > .article-content .text-white li,
.sk-blog-content .article-body > .article-content .text-white p,
.sk-blog-content .article-body > .article-content.text-white h1,
.sk-blog-content .article-body > .article-content.text-white h2,
.sk-blog-content .article-body > .article-content.text-white h3,
.sk-blog-content .article-body > .article-content.text-white h4,
.sk-blog-content .article-body > .article-content.text-white h5,
.sk-blog-content .article-body > .article-content.text-white h6,
.sk-blog-content .article-body > .article-content.text-white .h1,
.sk-blog-content .article-body > .article-content.text-white .h2,
.sk-blog-content .article-body > .article-content.text-white .h3,
.sk-blog-content .article-body > .article-content.text-white .h4,
.sk-blog-content .article-body > .article-content.text-white .h5,
.sk-blog-content .article-body > .article-content.text-white .h6,
.sk-blog-content .article-body > .article-content.text-white span:not(.btn-txt),
.sk-blog-content .article-body > .article-content.text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-blog-content .article-body > .article-content.text-white .title-highlight,
.sk-blog-content .article-body > .article-content.text-white li,
.sk-blog-content .article-body > .article-content.text-white p,
.page-block .text-white h1,
.page-block .text-white h2,
.page-block .text-white h3,
.page-block .text-white h4,
.page-block .text-white h5,
.page-block .text-white h6,
.page-block .text-white .h1,
.page-block .text-white .h2,
.page-block .text-white .h3,
.page-block .text-white .h4,
.page-block .text-white .h5,
.page-block .text-white .h6,
.page-block .text-white span:not(.btn-txt),
.page-block .text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.page-block .text-white .title-highlight,
.page-block .text-white li,
.page-block .text-white p,
.page-block.text-white h1,
.page-block.text-white h2,
.page-block.text-white h3,
.page-block.text-white h4,
.page-block.text-white h5,
.page-block.text-white h6,
.page-block.text-white .h1,
.page-block.text-white .h2,
.page-block.text-white .h3,
.page-block.text-white .h4,
.page-block.text-white .h5,
.page-block.text-white .h6,
.page-block.text-white span:not(.btn-txt),
.page-block.text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.page-block.text-white .title-highlight,
.page-block.text-white li,
.page-block.text-white p {
  color: #fff;
}
.sk-block .text-blue h1,
.sk-block .text-blue h2,
.sk-block .text-blue h3,
.sk-block .text-blue h4,
.sk-block .text-blue h5,
.sk-block .text-blue h6,
.sk-block .text-blue .h1,
.sk-block .text-blue .h2,
.sk-block .text-blue .h3,
.sk-block .text-blue .h4,
.sk-block .text-blue .h5,
.sk-block .text-blue .h6,
.sk-block .text-blue span:not(.btn-txt),
.sk-block .text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-block .text-blue .title-highlight,
.sk-block .text-blue li,
.sk-block .text-blue p, .sk-block.text-blue h1,
.sk-block.text-blue h2,
.sk-block.text-blue h3,
.sk-block.text-blue h4,
.sk-block.text-blue h5,
.sk-block.text-blue h6,
.sk-block.text-blue .h1,
.sk-block.text-blue .h2,
.sk-block.text-blue .h3,
.sk-block.text-blue .h4,
.sk-block.text-blue .h5,
.sk-block.text-blue .h6,
.sk-block.text-blue span:not(.btn-txt),
.sk-block.text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-block.text-blue .title-highlight,
.sk-block.text-blue li,
.sk-block.text-blue p,
.sk-content .text-blue h1,
.sk-content .text-blue h2,
.sk-content .text-blue h3,
.sk-content .text-blue h4,
.sk-content .text-blue h5,
.sk-content .text-blue h6,
.sk-content .text-blue .h1,
.sk-content .text-blue .h2,
.sk-content .text-blue .h3,
.sk-content .text-blue .h4,
.sk-content .text-blue .h5,
.sk-content .text-blue .h6,
.sk-content .text-blue span:not(.btn-txt),
.sk-content .text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content .text-blue .title-highlight,
.sk-content .text-blue li,
.sk-content .text-blue p,
.sk-content.text-blue h1,
.sk-content.text-blue h2,
.sk-content.text-blue h3,
.sk-content.text-blue h4,
.sk-content.text-blue h5,
.sk-content.text-blue h6,
.sk-content.text-blue .h1,
.sk-content.text-blue .h2,
.sk-content.text-blue .h3,
.sk-content.text-blue .h4,
.sk-content.text-blue .h5,
.sk-content.text-blue .h6,
.sk-content.text-blue span:not(.btn-txt),
.sk-content.text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content.text-blue .title-highlight,
.sk-content.text-blue li,
.sk-content.text-blue p,
.sk-content-block .text-blue h1,
.sk-content-block .text-blue h2,
.sk-content-block .text-blue h3,
.sk-content-block .text-blue h4,
.sk-content-block .text-blue h5,
.sk-content-block .text-blue h6,
.sk-content-block .text-blue .h1,
.sk-content-block .text-blue .h2,
.sk-content-block .text-blue .h3,
.sk-content-block .text-blue .h4,
.sk-content-block .text-blue .h5,
.sk-content-block .text-blue .h6,
.sk-content-block .text-blue span:not(.btn-txt),
.sk-content-block .text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content-block .text-blue .title-highlight,
.sk-content-block .text-blue li,
.sk-content-block .text-blue p,
.sk-content-block.text-blue h1,
.sk-content-block.text-blue h2,
.sk-content-block.text-blue h3,
.sk-content-block.text-blue h4,
.sk-content-block.text-blue h5,
.sk-content-block.text-blue h6,
.sk-content-block.text-blue .h1,
.sk-content-block.text-blue .h2,
.sk-content-block.text-blue .h3,
.sk-content-block.text-blue .h4,
.sk-content-block.text-blue .h5,
.sk-content-block.text-blue .h6,
.sk-content-block.text-blue span:not(.btn-txt),
.sk-content-block.text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content-block.text-blue .title-highlight,
.sk-content-block.text-blue li,
.sk-content-block.text-blue p,
.sk-page-modal .text-blue h1,
.sk-page-modal .text-blue h2,
.sk-page-modal .text-blue h3,
.sk-page-modal .text-blue h4,
.sk-page-modal .text-blue h5,
.sk-page-modal .text-blue h6,
.sk-page-modal .text-blue .h1,
.sk-page-modal .text-blue .h2,
.sk-page-modal .text-blue .h3,
.sk-page-modal .text-blue .h4,
.sk-page-modal .text-blue .h5,
.sk-page-modal .text-blue .h6,
.sk-page-modal .text-blue span:not(.btn-txt),
.sk-page-modal .text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-page-modal .text-blue .title-highlight,
.sk-page-modal .text-blue li,
.sk-page-modal .text-blue p,
.sk-page-modal.text-blue h1,
.sk-page-modal.text-blue h2,
.sk-page-modal.text-blue h3,
.sk-page-modal.text-blue h4,
.sk-page-modal.text-blue h5,
.sk-page-modal.text-blue h6,
.sk-page-modal.text-blue .h1,
.sk-page-modal.text-blue .h2,
.sk-page-modal.text-blue .h3,
.sk-page-modal.text-blue .h4,
.sk-page-modal.text-blue .h5,
.sk-page-modal.text-blue .h6,
.sk-page-modal.text-blue span:not(.btn-txt),
.sk-page-modal.text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-page-modal.text-blue .title-highlight,
.sk-page-modal.text-blue li,
.sk-page-modal.text-blue p,
.sk-blog-content .article-body > .article-content .text-blue h1,
.sk-blog-content .article-body > .article-content .text-blue h2,
.sk-blog-content .article-body > .article-content .text-blue h3,
.sk-blog-content .article-body > .article-content .text-blue h4,
.sk-blog-content .article-body > .article-content .text-blue h5,
.sk-blog-content .article-body > .article-content .text-blue h6,
.sk-blog-content .article-body > .article-content .text-blue .h1,
.sk-blog-content .article-body > .article-content .text-blue .h2,
.sk-blog-content .article-body > .article-content .text-blue .h3,
.sk-blog-content .article-body > .article-content .text-blue .h4,
.sk-blog-content .article-body > .article-content .text-blue .h5,
.sk-blog-content .article-body > .article-content .text-blue .h6,
.sk-blog-content .article-body > .article-content .text-blue span:not(.btn-txt),
.sk-blog-content .article-body > .article-content .text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-blog-content .article-body > .article-content .text-blue .title-highlight,
.sk-blog-content .article-body > .article-content .text-blue li,
.sk-blog-content .article-body > .article-content .text-blue p,
.sk-blog-content .article-body > .article-content.text-blue h1,
.sk-blog-content .article-body > .article-content.text-blue h2,
.sk-blog-content .article-body > .article-content.text-blue h3,
.sk-blog-content .article-body > .article-content.text-blue h4,
.sk-blog-content .article-body > .article-content.text-blue h5,
.sk-blog-content .article-body > .article-content.text-blue h6,
.sk-blog-content .article-body > .article-content.text-blue .h1,
.sk-blog-content .article-body > .article-content.text-blue .h2,
.sk-blog-content .article-body > .article-content.text-blue .h3,
.sk-blog-content .article-body > .article-content.text-blue .h4,
.sk-blog-content .article-body > .article-content.text-blue .h5,
.sk-blog-content .article-body > .article-content.text-blue .h6,
.sk-blog-content .article-body > .article-content.text-blue span:not(.btn-txt),
.sk-blog-content .article-body > .article-content.text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-blog-content .article-body > .article-content.text-blue .title-highlight,
.sk-blog-content .article-body > .article-content.text-blue li,
.sk-blog-content .article-body > .article-content.text-blue p,
.page-block .text-blue h1,
.page-block .text-blue h2,
.page-block .text-blue h3,
.page-block .text-blue h4,
.page-block .text-blue h5,
.page-block .text-blue h6,
.page-block .text-blue .h1,
.page-block .text-blue .h2,
.page-block .text-blue .h3,
.page-block .text-blue .h4,
.page-block .text-blue .h5,
.page-block .text-blue .h6,
.page-block .text-blue span:not(.btn-txt),
.page-block .text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.page-block .text-blue .title-highlight,
.page-block .text-blue li,
.page-block .text-blue p,
.page-block.text-blue h1,
.page-block.text-blue h2,
.page-block.text-blue h3,
.page-block.text-blue h4,
.page-block.text-blue h5,
.page-block.text-blue h6,
.page-block.text-blue .h1,
.page-block.text-blue .h2,
.page-block.text-blue .h3,
.page-block.text-blue .h4,
.page-block.text-blue .h5,
.page-block.text-blue .h6,
.page-block.text-blue span:not(.btn-txt),
.page-block.text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.page-block.text-blue .title-highlight,
.page-block.text-blue li,
.page-block.text-blue p {
  color: #002ea6;
}
.sk-block .mb-3,
.sk-content .mb-3,
.sk-content-block .mb-3,
.sk-page-modal .mb-3,
.sk-blog-content .article-body > .article-content .mb-3,
.page-block .mb-3 {
  margin-bottom: 27px !important;
}
.sk-block p,
.sk-content p,
.sk-content-block p,
.sk-page-modal p,
.sk-blog-content .article-body > .article-content p,
.page-block p {
  margin-bottom: 27px;
}
.sk-block .text-white h1,
.sk-block .text-white h2,
.sk-block .text-white h3,
.sk-block .text-white h4,
.sk-block .text-white h5,
.sk-block .text-white h6,
.sk-block .text-white .h1,
.sk-block .text-white .h2,
.sk-block .text-white .h3,
.sk-block .text-white .h4,
.sk-block .text-white .h5,
.sk-block .text-white .h6,
.sk-block .text-white span:not(.btn-txt),
.sk-block .text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-block .text-white .title-highlight,
.sk-block .text-white li,
.sk-block .text-white p, .sk-block.text-white h1,
.sk-block.text-white h2,
.sk-block.text-white h3,
.sk-block.text-white h4,
.sk-block.text-white h5,
.sk-block.text-white h6,
.sk-block.text-white .h1,
.sk-block.text-white .h2,
.sk-block.text-white .h3,
.sk-block.text-white .h4,
.sk-block.text-white .h5,
.sk-block.text-white .h6,
.sk-block.text-white span:not(.btn-txt),
.sk-block.text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-block.text-white .title-highlight,
.sk-block.text-white li,
.sk-block.text-white p,
.sk-content .text-white h1,
.sk-content .text-white h2,
.sk-content .text-white h3,
.sk-content .text-white h4,
.sk-content .text-white h5,
.sk-content .text-white h6,
.sk-content .text-white .h1,
.sk-content .text-white .h2,
.sk-content .text-white .h3,
.sk-content .text-white .h4,
.sk-content .text-white .h5,
.sk-content .text-white .h6,
.sk-content .text-white span:not(.btn-txt),
.sk-content .text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content .text-white .title-highlight,
.sk-content .text-white li,
.sk-content .text-white p,
.sk-content.text-white h1,
.sk-content.text-white h2,
.sk-content.text-white h3,
.sk-content.text-white h4,
.sk-content.text-white h5,
.sk-content.text-white h6,
.sk-content.text-white .h1,
.sk-content.text-white .h2,
.sk-content.text-white .h3,
.sk-content.text-white .h4,
.sk-content.text-white .h5,
.sk-content.text-white .h6,
.sk-content.text-white span:not(.btn-txt),
.sk-content.text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content.text-white .title-highlight,
.sk-content.text-white li,
.sk-content.text-white p,
.sk-content-block .text-white h1,
.sk-content-block .text-white h2,
.sk-content-block .text-white h3,
.sk-content-block .text-white h4,
.sk-content-block .text-white h5,
.sk-content-block .text-white h6,
.sk-content-block .text-white .h1,
.sk-content-block .text-white .h2,
.sk-content-block .text-white .h3,
.sk-content-block .text-white .h4,
.sk-content-block .text-white .h5,
.sk-content-block .text-white .h6,
.sk-content-block .text-white span:not(.btn-txt),
.sk-content-block .text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content-block .text-white .title-highlight,
.sk-content-block .text-white li,
.sk-content-block .text-white p,
.sk-content-block.text-white h1,
.sk-content-block.text-white h2,
.sk-content-block.text-white h3,
.sk-content-block.text-white h4,
.sk-content-block.text-white h5,
.sk-content-block.text-white h6,
.sk-content-block.text-white .h1,
.sk-content-block.text-white .h2,
.sk-content-block.text-white .h3,
.sk-content-block.text-white .h4,
.sk-content-block.text-white .h5,
.sk-content-block.text-white .h6,
.sk-content-block.text-white span:not(.btn-txt),
.sk-content-block.text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content-block.text-white .title-highlight,
.sk-content-block.text-white li,
.sk-content-block.text-white p,
.sk-page-modal .text-white h1,
.sk-page-modal .text-white h2,
.sk-page-modal .text-white h3,
.sk-page-modal .text-white h4,
.sk-page-modal .text-white h5,
.sk-page-modal .text-white h6,
.sk-page-modal .text-white .h1,
.sk-page-modal .text-white .h2,
.sk-page-modal .text-white .h3,
.sk-page-modal .text-white .h4,
.sk-page-modal .text-white .h5,
.sk-page-modal .text-white .h6,
.sk-page-modal .text-white span:not(.btn-txt),
.sk-page-modal .text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-page-modal .text-white .title-highlight,
.sk-page-modal .text-white li,
.sk-page-modal .text-white p,
.sk-page-modal.text-white h1,
.sk-page-modal.text-white h2,
.sk-page-modal.text-white h3,
.sk-page-modal.text-white h4,
.sk-page-modal.text-white h5,
.sk-page-modal.text-white h6,
.sk-page-modal.text-white .h1,
.sk-page-modal.text-white .h2,
.sk-page-modal.text-white .h3,
.sk-page-modal.text-white .h4,
.sk-page-modal.text-white .h5,
.sk-page-modal.text-white .h6,
.sk-page-modal.text-white span:not(.btn-txt),
.sk-page-modal.text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-page-modal.text-white .title-highlight,
.sk-page-modal.text-white li,
.sk-page-modal.text-white p,
.sk-blog-content .article-body > .article-content .text-white h1,
.sk-blog-content .article-body > .article-content .text-white h2,
.sk-blog-content .article-body > .article-content .text-white h3,
.sk-blog-content .article-body > .article-content .text-white h4,
.sk-blog-content .article-body > .article-content .text-white h5,
.sk-blog-content .article-body > .article-content .text-white h6,
.sk-blog-content .article-body > .article-content .text-white .h1,
.sk-blog-content .article-body > .article-content .text-white .h2,
.sk-blog-content .article-body > .article-content .text-white .h3,
.sk-blog-content .article-body > .article-content .text-white .h4,
.sk-blog-content .article-body > .article-content .text-white .h5,
.sk-blog-content .article-body > .article-content .text-white .h6,
.sk-blog-content .article-body > .article-content .text-white span:not(.btn-txt),
.sk-blog-content .article-body > .article-content .text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-blog-content .article-body > .article-content .text-white .title-highlight,
.sk-blog-content .article-body > .article-content .text-white li,
.sk-blog-content .article-body > .article-content .text-white p,
.sk-blog-content .article-body > .article-content.text-white h1,
.sk-blog-content .article-body > .article-content.text-white h2,
.sk-blog-content .article-body > .article-content.text-white h3,
.sk-blog-content .article-body > .article-content.text-white h4,
.sk-blog-content .article-body > .article-content.text-white h5,
.sk-blog-content .article-body > .article-content.text-white h6,
.sk-blog-content .article-body > .article-content.text-white .h1,
.sk-blog-content .article-body > .article-content.text-white .h2,
.sk-blog-content .article-body > .article-content.text-white .h3,
.sk-blog-content .article-body > .article-content.text-white .h4,
.sk-blog-content .article-body > .article-content.text-white .h5,
.sk-blog-content .article-body > .article-content.text-white .h6,
.sk-blog-content .article-body > .article-content.text-white span:not(.btn-txt),
.sk-blog-content .article-body > .article-content.text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-blog-content .article-body > .article-content.text-white .title-highlight,
.sk-blog-content .article-body > .article-content.text-white li,
.sk-blog-content .article-body > .article-content.text-white p,
.page-block .text-white h1,
.page-block .text-white h2,
.page-block .text-white h3,
.page-block .text-white h4,
.page-block .text-white h5,
.page-block .text-white h6,
.page-block .text-white .h1,
.page-block .text-white .h2,
.page-block .text-white .h3,
.page-block .text-white .h4,
.page-block .text-white .h5,
.page-block .text-white .h6,
.page-block .text-white span:not(.btn-txt),
.page-block .text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.page-block .text-white .title-highlight,
.page-block .text-white li,
.page-block .text-white p,
.page-block.text-white h1,
.page-block.text-white h2,
.page-block.text-white h3,
.page-block.text-white h4,
.page-block.text-white h5,
.page-block.text-white h6,
.page-block.text-white .h1,
.page-block.text-white .h2,
.page-block.text-white .h3,
.page-block.text-white .h4,
.page-block.text-white .h5,
.page-block.text-white .h6,
.page-block.text-white span:not(.btn-txt),
.page-block.text-white a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.page-block.text-white .title-highlight,
.page-block.text-white li,
.page-block.text-white p {
  color: #fff;
}
.sk-block .text-blue h1,
.sk-block .text-blue h2,
.sk-block .text-blue h3,
.sk-block .text-blue h4,
.sk-block .text-blue h5,
.sk-block .text-blue h6,
.sk-block .text-blue .h1,
.sk-block .text-blue .h2,
.sk-block .text-blue .h3,
.sk-block .text-blue .h4,
.sk-block .text-blue .h5,
.sk-block .text-blue .h6,
.sk-block .text-blue span:not(.btn-txt),
.sk-block .text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-block .text-blue .title-highlight,
.sk-block .text-blue li,
.sk-block .text-blue p, .sk-block.text-blue h1,
.sk-block.text-blue h2,
.sk-block.text-blue h3,
.sk-block.text-blue h4,
.sk-block.text-blue h5,
.sk-block.text-blue h6,
.sk-block.text-blue .h1,
.sk-block.text-blue .h2,
.sk-block.text-blue .h3,
.sk-block.text-blue .h4,
.sk-block.text-blue .h5,
.sk-block.text-blue .h6,
.sk-block.text-blue span:not(.btn-txt),
.sk-block.text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-block.text-blue .title-highlight,
.sk-block.text-blue li,
.sk-block.text-blue p,
.sk-content .text-blue h1,
.sk-content .text-blue h2,
.sk-content .text-blue h3,
.sk-content .text-blue h4,
.sk-content .text-blue h5,
.sk-content .text-blue h6,
.sk-content .text-blue .h1,
.sk-content .text-blue .h2,
.sk-content .text-blue .h3,
.sk-content .text-blue .h4,
.sk-content .text-blue .h5,
.sk-content .text-blue .h6,
.sk-content .text-blue span:not(.btn-txt),
.sk-content .text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content .text-blue .title-highlight,
.sk-content .text-blue li,
.sk-content .text-blue p,
.sk-content.text-blue h1,
.sk-content.text-blue h2,
.sk-content.text-blue h3,
.sk-content.text-blue h4,
.sk-content.text-blue h5,
.sk-content.text-blue h6,
.sk-content.text-blue .h1,
.sk-content.text-blue .h2,
.sk-content.text-blue .h3,
.sk-content.text-blue .h4,
.sk-content.text-blue .h5,
.sk-content.text-blue .h6,
.sk-content.text-blue span:not(.btn-txt),
.sk-content.text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content.text-blue .title-highlight,
.sk-content.text-blue li,
.sk-content.text-blue p,
.sk-content-block .text-blue h1,
.sk-content-block .text-blue h2,
.sk-content-block .text-blue h3,
.sk-content-block .text-blue h4,
.sk-content-block .text-blue h5,
.sk-content-block .text-blue h6,
.sk-content-block .text-blue .h1,
.sk-content-block .text-blue .h2,
.sk-content-block .text-blue .h3,
.sk-content-block .text-blue .h4,
.sk-content-block .text-blue .h5,
.sk-content-block .text-blue .h6,
.sk-content-block .text-blue span:not(.btn-txt),
.sk-content-block .text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content-block .text-blue .title-highlight,
.sk-content-block .text-blue li,
.sk-content-block .text-blue p,
.sk-content-block.text-blue h1,
.sk-content-block.text-blue h2,
.sk-content-block.text-blue h3,
.sk-content-block.text-blue h4,
.sk-content-block.text-blue h5,
.sk-content-block.text-blue h6,
.sk-content-block.text-blue .h1,
.sk-content-block.text-blue .h2,
.sk-content-block.text-blue .h3,
.sk-content-block.text-blue .h4,
.sk-content-block.text-blue .h5,
.sk-content-block.text-blue .h6,
.sk-content-block.text-blue span:not(.btn-txt),
.sk-content-block.text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-content-block.text-blue .title-highlight,
.sk-content-block.text-blue li,
.sk-content-block.text-blue p,
.sk-page-modal .text-blue h1,
.sk-page-modal .text-blue h2,
.sk-page-modal .text-blue h3,
.sk-page-modal .text-blue h4,
.sk-page-modal .text-blue h5,
.sk-page-modal .text-blue h6,
.sk-page-modal .text-blue .h1,
.sk-page-modal .text-blue .h2,
.sk-page-modal .text-blue .h3,
.sk-page-modal .text-blue .h4,
.sk-page-modal .text-blue .h5,
.sk-page-modal .text-blue .h6,
.sk-page-modal .text-blue span:not(.btn-txt),
.sk-page-modal .text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-page-modal .text-blue .title-highlight,
.sk-page-modal .text-blue li,
.sk-page-modal .text-blue p,
.sk-page-modal.text-blue h1,
.sk-page-modal.text-blue h2,
.sk-page-modal.text-blue h3,
.sk-page-modal.text-blue h4,
.sk-page-modal.text-blue h5,
.sk-page-modal.text-blue h6,
.sk-page-modal.text-blue .h1,
.sk-page-modal.text-blue .h2,
.sk-page-modal.text-blue .h3,
.sk-page-modal.text-blue .h4,
.sk-page-modal.text-blue .h5,
.sk-page-modal.text-blue .h6,
.sk-page-modal.text-blue span:not(.btn-txt),
.sk-page-modal.text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-page-modal.text-blue .title-highlight,
.sk-page-modal.text-blue li,
.sk-page-modal.text-blue p,
.sk-blog-content .article-body > .article-content .text-blue h1,
.sk-blog-content .article-body > .article-content .text-blue h2,
.sk-blog-content .article-body > .article-content .text-blue h3,
.sk-blog-content .article-body > .article-content .text-blue h4,
.sk-blog-content .article-body > .article-content .text-blue h5,
.sk-blog-content .article-body > .article-content .text-blue h6,
.sk-blog-content .article-body > .article-content .text-blue .h1,
.sk-blog-content .article-body > .article-content .text-blue .h2,
.sk-blog-content .article-body > .article-content .text-blue .h3,
.sk-blog-content .article-body > .article-content .text-blue .h4,
.sk-blog-content .article-body > .article-content .text-blue .h5,
.sk-blog-content .article-body > .article-content .text-blue .h6,
.sk-blog-content .article-body > .article-content .text-blue span:not(.btn-txt),
.sk-blog-content .article-body > .article-content .text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-blog-content .article-body > .article-content .text-blue .title-highlight,
.sk-blog-content .article-body > .article-content .text-blue li,
.sk-blog-content .article-body > .article-content .text-blue p,
.sk-blog-content .article-body > .article-content.text-blue h1,
.sk-blog-content .article-body > .article-content.text-blue h2,
.sk-blog-content .article-body > .article-content.text-blue h3,
.sk-blog-content .article-body > .article-content.text-blue h4,
.sk-blog-content .article-body > .article-content.text-blue h5,
.sk-blog-content .article-body > .article-content.text-blue h6,
.sk-blog-content .article-body > .article-content.text-blue .h1,
.sk-blog-content .article-body > .article-content.text-blue .h2,
.sk-blog-content .article-body > .article-content.text-blue .h3,
.sk-blog-content .article-body > .article-content.text-blue .h4,
.sk-blog-content .article-body > .article-content.text-blue .h5,
.sk-blog-content .article-body > .article-content.text-blue .h6,
.sk-blog-content .article-body > .article-content.text-blue span:not(.btn-txt),
.sk-blog-content .article-body > .article-content.text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.sk-blog-content .article-body > .article-content.text-blue .title-highlight,
.sk-blog-content .article-body > .article-content.text-blue li,
.sk-blog-content .article-body > .article-content.text-blue p,
.page-block .text-blue h1,
.page-block .text-blue h2,
.page-block .text-blue h3,
.page-block .text-blue h4,
.page-block .text-blue h5,
.page-block .text-blue h6,
.page-block .text-blue .h1,
.page-block .text-blue .h2,
.page-block .text-blue .h3,
.page-block .text-blue .h4,
.page-block .text-blue .h5,
.page-block .text-blue .h6,
.page-block .text-blue span:not(.btn-txt),
.page-block .text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.page-block .text-blue .title-highlight,
.page-block .text-blue li,
.page-block .text-blue p,
.page-block.text-blue h1,
.page-block.text-blue h2,
.page-block.text-blue h3,
.page-block.text-blue h4,
.page-block.text-blue h5,
.page-block.text-blue h6,
.page-block.text-blue .h1,
.page-block.text-blue .h2,
.page-block.text-blue .h3,
.page-block.text-blue .h4,
.page-block.text-blue .h5,
.page-block.text-blue .h6,
.page-block.text-blue span:not(.btn-txt),
.page-block.text-blue a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.page-block.text-blue .title-highlight,
.page-block.text-blue li,
.page-block.text-blue p {
  color: #002ea6;
}
.sk-block p,
.sk-content p,
.sk-content-block p,
.sk-page-modal p,
.sk-blog-content .article-body > .article-content p,
.page-block p {
  margin-bottom: 27px;
}
.sk-block p:last-child,
.sk-content p:last-child,
.sk-content-block p:last-child,
.sk-page-modal p:last-child,
.sk-blog-content .article-body > .article-content p:last-child,
.page-block p:last-child {
  margin-bottom: 0;
}
.sk-block h5,
.sk-block .h5,
.sk-content h5,
.sk-content .h5,
.sk-content-block h5,
.sk-content-block .h5,
.sk-page-modal h5,
.sk-page-modal .h5,
.sk-blog-content .article-body > .article-content h5,
.sk-blog-content .article-body > .article-content .h5,
.page-block h5,
.page-block .h5 {
  font-weight: 600;
}
.sk-block .char,
.sk-content .char,
.sk-content-block .char,
.sk-page-modal .char,
.sk-blog-content .article-body > .article-content .char,
.page-block .char {
  opacity: 1 !important;
}

.plain-link-btn {
  font-weight: 700;
  color: #008fc4;
  line-height: 1;
  display: inline-block;
  position: relative;
  margin-right: 20px;
  font-size: 12px;
  z-index: 4;
}
@media screen and (min-width: 400px) {
  .plain-link-btn {
    font-size: 14px;
  }
}
@media screen and (min-width: 479px) {
  .plain-link-btn {
    font-size: 16px;
  }
}
@media screen and (min-width: 640px) {
  .plain-link-btn {
    font-size: 18px;
  }
}
@media screen and (min-width: 1025px) {
  .plain-link-btn {
    font-size: 0.938vw;
    margin-right: 1.042vw;
  }
  .plain-link-btn:hover::before, .plain-link-btn:focus::before {
    transform: scaleX(1.25) translateY(-50%);
  }
}
@media screen and (min-width: 1921px) {
  .plain-link-btn {
    font-size: 18px;
    margin-right: 20px;
  }
}
.plain-link-btn::after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 1px;
  width: 100%;
  backface-visibility: hidden;
  background-color: #008fc4;
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  transform-origin: 100% 50%;
  transform: scaleX(0);
  will-change: transform;
}
.plain-link-btn::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  backface-visibility: hidden;
  background-color: #008fc4;
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  transform-origin: 0% 50%;
  will-change: transform;
  width: 11px;
  height: 2px;
  right: -20px;
}
@media screen and (min-width: 1025px) {
  .plain-link-btn::before {
    width: 0.573vw;
    height: 0.104vw;
    right: -1.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .plain-link-btn::before {
    width: 11px;
    height: 2px;
    right: -20px;
  }
}

.round-btn-submit {
  flex: 0 0 auto;
  position: relative;
  display: inline-block;
  position: relative;
  color: #002ea6;
  line-height: 1;
  font-weight: 700;
}
.round-btn-submit .background-el {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: inline-block;
  z-index: 1;
  background-color: #17fcc4;
  border-radius: 50%;
  backface-visibility: hidden;
}
.round-btn-submit .btn-txt {
  pointer-events: none;
  display: inline-block;
  position: relative;
  z-index: 2;
  overflow: hidden;
}
@media screen and (max-width: 550px) {
  .round-btn-submit {
    font-size: 4.8vw;
    width: 45.6vw;
    height: 45.6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .round-btn-submit {
    font-size: 24px;
    width: 228px;
    height: 228px;
  }
}
@media screen and (min-width: 1025px) {
  .round-btn-submit {
    font-size: 1.25vw;
    width: 11.875vw;
    height: 11.875vw;
  }
}
@media screen and (min-width: 1921px) {
  .round-btn-submit {
    font-size: 24px;
    width: 228px;
    height: 228px;
  }
}

.scroll-btn-border-svg {
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  top: -2px;
  left: -2px;
}
.scroll-btn-border-svg .scroll-btn-border-circle {
  stroke: rgba(255, 255, 255, 0.4);
}

.btn-scroll-next {
  position: absolute;
  border-radius: 50%;
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .btn-scroll-next {
    width: 141px;
    height: 141px;
  }
}
@media screen and (min-width: 1025px) {
  .btn-scroll-next:hover .scroll-btn-img, .btn-scroll-next:focus .scroll-btn-img {
    animation: arrow-move 1.5s ease-in-out infinite;
  }
}
@media screen and (min-width: 1921px) {
  .btn-scroll-next {
    width: 141px;
    height: 141px;
  }
}

.section-scroll-btn {
  border-radius: 50%;
  position: relative;
}
@media screen and (max-width: 550px) {
  .section-scroll-btn {
    width: 28.2vw;
    height: 28.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .section-scroll-btn {
    width: 141px;
    height: 141px;
  }
}
@media screen and (min-width: 1025px) {
  .section-scroll-btn {
    width: 7.344vw;
    height: 7.344vw;
  }
  .section-scroll-btn:hover .scroll-btn-img, .section-scroll-btn:focus .scroll-btn-img {
    animation: arrow-move 1.5s ease-in-out infinite;
  }
}
@media screen and (min-width: 1921px) {
  .section-scroll-btn {
    width: 141px;
    height: 141px;
  }
}
.section-scroll-btn .scroll-btn-img {
  will-change: transform;
  backface-visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 550px) {
  .section-scroll-btn .scroll-btn-img {
    width: 7.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .section-scroll-btn .scroll-btn-img {
    width: 36px;
  }
}
@media screen and (min-width: 1025px) {
  .section-scroll-btn .scroll-btn-img {
    width: 1.875vw;
  }
}
@media screen and (min-width: 1921px) {
  .section-scroll-btn .scroll-btn-img {
    width: 36px;
  }
}

.query-button-next .btn-txt {
  display: inline-block;
  backface-visibility: hidden;
}
.query-button-next svg {
  transform: rotate(-180deg);
}
.query-button-next .scroll-btn-img {
  will-change: transform;
  backface-visibility: hidden;
  position: absolute;
  top: 50%;
  left: 47%;
  transform: translate(-50%, -50%) rotate(-90deg);
}
@media screen and (max-width: 550px) {
  .query-button-next .scroll-btn-img {
    width: 7.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .query-button-next .scroll-btn-img {
    width: 36px;
  }
}
@media screen and (min-width: 1025px) {
  .query-button-next .scroll-btn-img {
    width: 1.875vw;
  }
}
@media screen and (min-width: 1921px) {
  .query-button-next .scroll-btn-img {
    width: 36px;
  }
}
.query-button-next .scroll-btn-img.arrow-right {
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 550px) {
  .query-button-next .scroll-btn-img.arrow-right {
    width: 11.4vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .query-button-next .scroll-btn-img.arrow-right {
    width: 57px;
  }
}
@media screen and (min-width: 1025px) {
  .query-button-next .scroll-btn-img.arrow-right {
    width: 2.969vw;
  }
}
@media screen and (min-width: 1921px) {
  .query-button-next .scroll-btn-img.arrow-right {
    width: 57px;
  }
}

.contact-section-btn {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 40px;
  padding: 9px 45px 9px 89px;
  width: 300px;
  min-height: 80px;
  transition: border 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
@media screen and (max-width: 1024px) {
  .contact-section-btn {
    width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .contact-section-btn {
    border-radius: 2.083vw;
    padding: 0.469vw 2.344vw 0.469vw 4.635vw;
    width: 15.625vw;
    min-height: 4.167vw;
  }
}
@media screen and (min-width: 1921px) {
  .contact-section-btn {
    border-radius: 40px;
    padding: 9px 45px 9px 89px;
    width: 350;
    min-height: 80px;
  }
}
.contact-section-btn .contact-btn-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
  width: 60px;
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  transform-origin: 50% 0%;
  backface-visibility: hidden;
}
@media screen and (min-width: 1025px) {
  .contact-section-btn .contact-btn-icon {
    left: 0.677vw;
    width: 3.125vw;
  }
}
@media screen and (min-width: 1921px) {
  .contact-section-btn .contact-btn-icon {
    left: 13px;
    width: 60px;
  }
}
.contact-section-btn .btn-txt {
  will-change: transform;
  backface-visibility: hidden;
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  font-family: "Red Hat Display", sans-serif !important;
}

.blog-social-media .contact-section-btn {
  border: none;
}
.blog-social-media .contact-section-btn:hover, .blog-social-media .contact-section-btn:focus {
  border: none;
}

.side-menu-quiz-btn {
  overflow: hidden;
  position: relative;
  border: 1px solid #d9d9d9;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 15px 60px;
  font-size: 18px;
}
.side-menu-quiz-btn:hover, .side-menu-quiz-btn:focus {
  background-color: #17fcc4;
  border-color: #17fcc4 !important;
  color: #002ea6;
}
.side-menu-quiz-btn:hover .btn-txt, .side-menu-quiz-btn:focus .btn-txt {
  color: #002ea6;
}
.side-menu-quiz-btn:hover .side-menu-quiz-btn-svg path, .side-menu-quiz-btn:focus .side-menu-quiz-btn-svg path {
  fill: #002ea6;
  transition: all 0.3s ease;
}
.side-menu-quiz-btn .btn-txt {
  display: inline-block;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  backface-visibility: hidden;
  transition: opacity 0.2s cubic-bezier(0.61, 1, 0.88, 1);
}
.side-menu-quiz-btn .side-menu-quiz-btn-svg {
  flex: 0 0 auto;
  position: relative;
  z-index: 2;
  height: 34px;
  width: 34px;
  margin-right: 10px;
}
.side-menu-quiz-btn .side-menu-quiz-btn-svg path {
  fill: #fff;
}

.popup-close-btn {
  position: absolute;
  background-color: #002ea6;
  border-radius: 50%;
  width: 39px;
  height: 39px;
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  backface-visibility: hidden;
  bottom: calc(100% + 7px);
}
@media screen and (max-width: 1024px) {
  .popup-close-btn {
    right: 0;
  }
}
@media screen and (min-width: 1025px) {
  .popup-close-btn {
    left: calc(100% + 3px);
  }
}
.popup-close-btn:hover, .popup-close-btn:focus {
  transform: rotate(180deg);
}
.popup-close-btn .x-icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  backface-visibility: hidden;
  width: 19px;
  height: 19px;
}
.popup-close-btn .x-icon::before, .popup-close-btn .x-icon::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  backface-visibility: hidden;
  background-color: #fff;
  width: 100%;
  height: 1px;
  transform-origin: 50% 50%;
}
.popup-close-btn .x-icon::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.popup-close-btn .x-icon::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.industry-video {
  text-align: left;
}

.form-popup-close-btn {
  position: absolute;
  background-color: #002ea6;
  border-radius: 50%;
  width: 39px;
  height: 39px;
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  backface-visibility: hidden;
  right: -30px;
  top: 9.5vh;
}
.form-popup-close-btn:hover, .form-popup-close-btn:focus {
  transform: rotate(180deg);
}
@media (max-width: 1024px) {
  .form-popup-close-btn {
    top: 0;
  }
}
.form-popup-close-btn .x-icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  backface-visibility: hidden;
  width: 19px;
  height: 19px;
}
.form-popup-close-btn .x-icon::before, .form-popup-close-btn .x-icon::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  backface-visibility: hidden;
  background-color: #fff;
  width: 100%;
  height: 1px;
  transform-origin: 50% 50%;
}
.form-popup-close-btn .x-icon::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.form-popup-close-btn .x-icon::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.single-landings .form-popup-close-btn {
  top: 0;
}

.btn-default {
  display: inline-block;
  font-family: "Red Hat Display", sans-serif;
  text-align: center;
  position: relative;
  line-height: 1;
  transition: color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  padding: 20px 25px;
  min-width: 170px;
  font-size: 16px;
}
@media screen and (max-width: 1024px) {
  .btn-default {
    padding: 15px 25px;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .btn-default {
    font-size: 16px;
  }
}
@media screen and (min-width: 1921px) {
  .btn-default {
    font-size: 16px;
  }
}
.btn-default.size-18-txt {
  font-size: 16px;
  line-height: 20px;
}
.btn-default[disabled] {
  opacity: 0.65 !important;
  pointer-events: none;
}
.btn-default.btn-ghost-white .btn-txt {
  color: #fff;
}
.btn-default.btn-ghost-white .btn-bg-el {
  border: solid 1px #fff;
}
.btn-default.btn-ghost-white .btn-bg-el:before {
  background-color: #fff;
}
.btn-default.btn-ghost-white:hover .btn-txt, .btn-default.btn-ghost-white:focus .btn-txt {
  color: #002ea6;
}
.btn-default .btn-bg-el {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 40px;
  z-index: 0;
  transition: transform 0.6s cubic-bezier(0.61, 1, 0.88, 1), background-color 0.6s cubic-bezier(0.61, 1, 0.88, 1);
  backface-visibility: hidden;
}
.btn-default .btn-bg-el::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 130%;
  height: calc(100% + 10px);
  z-index: 0;
  transform-origin: 0 0;
  transform: translateX(105%) skewX(-45deg);
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1), background-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  backface-visibility: hidden;
}
.btn-default:hover:not([disabled]) .btn-bg-el, .btn-default:focus:not([disabled]) .btn-bg-el {
  transform: scale(1.05);
}
.btn-default:hover:not([disabled]) .btn-bg-el::before, .btn-default:focus:not([disabled]) .btn-bg-el::before {
  transform: translateX(0) skewX(-45deg) scale(1.05);
}
.btn-default .btn-txt {
  display: inline-block;
  position: relative;
  z-index: 1;
  backface-visibility: hidden;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 101%, rgba(0, 0, 0, 0.01) 102%);
  font-weight: 700;
  font-family: "Red Hat Display", sans-serif;
}
.btn-default.btn-white.section-color-blue .btn-bg-el {
  background-color: #fff;
}
.btn-default.btn-white.section-color-blue .btn-bg-el::before {
  background-color: #17fcc4;
}
.btn-default.btn-green-1.section-color-blue .btn-bg-el {
  background-color: #17fcc4;
}
.btn-default.btn-green-1.section-color-blue .btn-bg-el::before {
  background-color: #fff;
}
.btn-default.btn-green-1.section-color-white .btn-bg-el {
  background-color: #17fcc4;
}
.btn-default.btn-green-1.section-color-white .btn-bg-el::before {
  background-color: #002ea6;
}
.btn-default.btn-green-1.section-color-white:hover:not([disabled]), .btn-default.btn-green-1.section-color-white:focus:not([disabled]) {
  color: #17fcc4;
}
.btn-default.btn-green-1.section-color-white:hover:not([disabled]) .btn-txt, .btn-default.btn-green-1.section-color-white:focus:not([disabled]) .btn-txt {
  color: #17fcc4;
  text-decoration: none;
}
.btn-default.btn-green-1.read-more-button .btn-bg-el {
  background-color: #17fcc4;
}
.btn-txt-arrow {
  position: relative;
  display: inline-block;
  padding-right: 31px;
}
.btn-txt-arrow .green-arrow-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.btn-business {
  margin-top: 25px;
  margin-right: 20px;
}
@media screen and (min-width: 1025px) {
  .btn-business {
    margin-top: 2.5vw;
    margin-left: -0.208vw;
  }
}
.btn-business-2 {
  margin-top: 25px;
  margin-left: 0px;
}
@media screen and (min-width: 1025px) {
  .btn-business-2 {
    margin-top: 1.458vw;
  }
}
.btn-play-video-plain {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 380px;
}
@media screen and (min-width: 1025px) {
  .btn-play-video-plain {
    max-width: 19.792vw;
  }
}
@media screen and (min-width: 1921px) {
  .btn-play-video-plain {
    max-width: 380px;
  }
}
.btn-play-video-plain:hover .play-video-plain-icon, .btn-play-video-plain:focus .play-video-plain-icon {
  background-color: #17fcc4;
}
.btn-play-video-plain:hover .play-video-plain-icon::before, .btn-play-video-plain:focus .play-video-plain-icon::before {
  border-color: transparent transparent transparent #002ea6;
}
.btn-play-video-plain:hover .btn-txt, .btn-play-video-plain:focus .btn-txt {
  transform: translateY(-5px);
}
.btn-play-video-plain .btn-txt {
  backface-visibility: hidden;
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.btn-play-video-plain .play-video-plain-icon {
  background-color: #002ea6;
  position: relative;
  flex: 0 0 auto;
  display: flex;
  border-radius: 50%;
  width: 63px;
  height: 63px;
  margin-right: 19px;
  transition: background-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
@media screen and (min-width: 1025px) {
  .btn-play-video-plain .play-video-plain-icon {
    width: 3.281vw;
    height: 3.281vw;
    margin-right: 0.99vw;
    margin-left: 0.104vw;
  }
}
@media screen and (min-width: 1921px) {
  .btn-play-video-plain .play-video-plain-icon {
    width: 63px;
    height: 63px;
    margin-right: 19px;
    margin-left: 2px;
  }
}
.btn-play-video-plain .play-video-plain-icon::before {
  content: "";
  position: absolute;
  display: inline-block;
  backface-visibility: hidden;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
  top: calc(50% - 6px);
  left: calc(50% - 5px);
  border-width: 6px 0 6px 11px;
  transition: border-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
@media screen and (min-width: 1025px) {
  .btn-play-video-plain .play-video-plain-icon::before {
    top: calc(50% - 0.313vw);
    left: calc(50% - 0.26vw);
    border-width: 0.313vw 0vw 0.313vw 0.573vw;
  }
}
@media screen and (min-width: 1921px) {
  .btn-play-video-plain .play-video-plain-icon::before {
    top: calc(50% - 6px);
    left: calc(50% - 5px);
    border-width: 6px 0 6px 11px;
  }
}
.btn-txt-arrow-hover .btn-txt {
  display: unset;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.5s cubic-bezier(0.61, 1, 0.88, 1), color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.btn-txt-arrow-hover:hover .green-arrow-icon, .btn-txt-arrow-hover:focus .green-arrow-icon {
  animation: btn-txt-arrow-hover 1.5s ease-in-out infinite;
}
.btn-txt-arrow-hover:hover .btn-txt, .btn-txt-arrow-hover:focus .btn-txt {
  background-size: 100% 1px;
}

@media screen and (min-width: 768px) {
  .btn-group {
    max-width: 45vw;
  }
}
@media screen and (min-width: 1025px) {
  .btn-group {
    max-width: 25vw;
  }
}

#scroll-back-tooltip {
  transition: all 500ms ease-in-out;
}
#scroll-back-tooltip .tooltip-scroll {
  position: fixed;
  bottom: 10px;
  left: 0;
  font-size: 13px;
  width: 100%;
  z-index: 10000;
  display: flex;
  justify-content: center;
  transition: all 500ms ease-in-out;
}
#scroll-back-tooltip .tooltip-scroll .closing {
  line-height: 1;
  display: block;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
#scroll-back-tooltip .tooltip-scroll .tooltip-content {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-right: transparent;
  color: white;
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
#scroll-back-tooltip .tooltip-scroll .tooltip-content:hover {
  background: rgba(255, 255, 255, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
  color: #002ea6;
}
#scroll-back-tooltip .tooltip-scroll .tooltip-close-btn {
  font-size: 15px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-left: transparent;
  border-right: transparent;
  transition: all 0.3s ease-in-out;
  padding: 10px;
}
#scroll-back-tooltip .tooltip-scroll .tooltip-close-btn span {
  font-size: 15px;
  color: #fff;
}
#scroll-back-tooltip .tooltip-scroll .tooltip-close-btn svg {
  fill: #fff;
  transition: all 0.3s ease-in-out;
}
#scroll-back-tooltip .tooltip-scroll .tooltip-close-btn:hover {
  background: rgba(255, 255, 255, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
  color: #fe0249;
}
#scroll-back-tooltip .tooltip-scroll .tooltip-close-btn:hover svg {
  fill: #fe0249;
}
#scroll-back-tooltip .tooltip-scroll .tooltip-skip-btn {
  font-size: 15px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-left: transparent;
  padding: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
#scroll-back-tooltip .tooltip-scroll .tooltip-skip-btn span {
  transition: all 0.3s ease-in-out;
  font-size: 15px;
  color: #fff;
}
#scroll-back-tooltip .tooltip-scroll .tooltip-skip-btn svg {
  fill: #fff;
  transition: all 0.3s ease-in-out;
}
#scroll-back-tooltip .tooltip-scroll .tooltip-skip-btn:hover {
  background: rgba(255, 255, 255, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
  color: #fe0249;
}
#scroll-back-tooltip .tooltip-scroll .tooltip-skip-btn:hover svg {
  fill: #fe0249;
}
#scroll-back-tooltip.hiding {
  opacity: 0;
}

.breadcrumb-wrap {
  z-index: 2;
  padding-top: 40px;
}
.breadcrumb-wrap.single-article-breadcrumb li::after {
  border-color: #002ea6;
}
.breadcrumb-wrap.single-article-breadcrumb a {
  color: #002ea6;
}
.breadcrumb-wrap.single-article-breadcrumb a:not(.btn):not(.button) {
  text-decoration: none;
}
.breadcrumb-wrap.single-article-breadcrumb a:not(.btn):not(.button):hover {
  text-decoration: underline;
}
.breadcrumb-wrap.single-article-breadcrumb .back-to-all {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.breadcrumb-wrap.single-article-breadcrumb .back-to-all img {
  position: relative;
  right: 0;
  transition: right 0.3s ease;
}
.breadcrumb-wrap.single-article-breadcrumb .back-to-all:hover img, .breadcrumb-wrap.single-article-breadcrumb .back-to-all:focus img {
  right: -10px;
  transition: right 0.3s ease;
}
.breadcrumb-wrap .breadcrumb-list {
  list-style-type: none;
  margin-bottom: 20px;
  padding-left: 0;
}
.breadcrumb-wrap li {
  display: inline-block;
  position: relative;
}
.breadcrumb-wrap li::after {
  content: "";
  pointer-events: none;
  display: inline-block;
  position: absolute;
  backface-visibility: hidden;
  top: 50%;
  right: 0;
  transition: transform 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  transform: translate(50%, -50%) rotate(-135deg);
  border-left-style: solid;
  border-bottom-style: solid;
  border-color: #fff;
  width: 4px !important;
  height: 4px;
  border-left-width: 1px;
  border-bottom-width: 1px;
}
.breadcrumb-wrap li:last-of-type::after {
  border-left-style: solid;
  transform: translate(50%, -75%) rotate(-45deg);
}
.breadcrumb-wrap a,
.breadcrumb-wrap span {
  font-size: 12px;
  color: inherit;
  display: inline-block;
  position: relative;
  padding-right: 15px;
}
.breadcrumb-wrap a:not(.btn):not(.button) {
  text-decoration: none;
}
.breadcrumb-wrap a:not(.btn):not(.button):hover {
  text-decoration: underline;
}
.breadcrumb-wrap .current-page-breadcrumb-title {
  font-size: 12px;
}

.sk-block .breadcrumb-wrap {
  padding-top: 0;
  padding-bottom: 80px;
}
.sk-block.bg-blue .breadcrumb-wrap a {
  color: #fff !important;
}

.retail-svg .st0 {
  fill: #002ea6;
}
.retail-svg .st1 {
  fill: #17fcc4;
}

.health-wellness-svg .st0 {
  fill: #17fcc4;
}
.health-wellness-svg .st1 {
  fill: #002ea6;
}

.grocery-svg .st0 {
  fill: #002ea6;
}
.grocery-svg .st1 {
  fill: #17fcc4;
}

.ecommerce-svg .st0 {
  fill: #002ea6;
}
.ecommerce-svg .st1 {
  fill: #17fcc4;
}

.restaurant-svg .st0 {
  fill: #17fcc4;
}
.restaurant-svg .st1 {
  fill: #002ea6;
}

.hospitality-svg .st0 {
  fill: #002ea6;
}
.hospitality-svg .st1 {
  fill: #17fcc4;
}

.fashion-boutiques-svg .st0 {
  fill: #002ea6;
}
.fashion-boutiques-svg .st1 {
  fill: #17fcc4;
}

.homeware-store-svg .st0 {
  fill: #002ea6;
}
.homeware-store-svg .st1 {
  fill: #17fcc4;
}

.professional-services-svg .st0 {
  fill: #17fcc4;
}
.professional-services-svg .st1 {
  fill: #002ea6;
}

.wellness-icon-svg .st0 {
  fill: #17fcc4;
}
.wellness-icon-svg .st1 {
  fill: #002ea6;
}

.wholesale-icon-svg .st0 {
  fill: #17fcc4;
}
.wholesale-icon-svg .st1 {
  fill: #002ea6;
}

.non-profit-svg .cls-1 {
  fill: #233e96;
}
.non-profit-svg .cls-2 {
  fill: #17fcc4;
}

.beauty-salons-svg .cls-1 {
  fill: #233e96;
}
.beauty-salons-svg .cls-2 {
  fill: #17fcc4;
}

.hardware-svg .cls-1 {
  fill: #233e96;
}
.hardware-svg .cls-2 {
  fill: #17fcc4;
}

@media screen and (min-width: 1025px) {
  .single-rates .page--i-rate-guarantee.sk-block {
    padding-top: 150px;
    padding-bottom: 150px;
    margin-bottom: 0;
  }
}

.page--i-rate-guarantee {
  overflow: hidden;
  --circle-size: 405px;
}
@media screen and (max-width: 1024px) {
  .page--i-rate-guarantee .circle-info-white-block {
    flex-flow: row wrap;
  }
}
.page--i-rate-guarantee .circle-info-white-block .arc-border-pink, .page--i-rate-guarantee .circle-info-white-block .arc-light-pink {
  border-radius: 50%;
  height: var(--circle-size);
  width: var(--circle-size);
}
@media screen and (max-width: 1024px) {
  .page--i-rate-guarantee .circle-info-white-block .arc-border-pink, .page--i-rate-guarantee .circle-info-white-block .arc-light-pink {
    display: none;
  }
}
.page--i-rate-guarantee .circle-info-white-block .arc-border-pink {
  border: 1px solid #002ea6;
  z-index: -1;
  transform: translateY(73.5%);
  opacity: 0.2;
}
.page--i-rate-guarantee .circle-info-white-block .arc-light-pink {
  z-index: -2;
  background-color: #002ea6;
  transform: translate(78%, 73.5%);
  opacity: 0.05;
}
.page--i-rate-guarantee .circle-info-white-block .img-wrap {
  position: relative;
  height: var(--circle-size);
  width: var(--circle-size);
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .page--i-rate-guarantee .circle-info-white-block .img-wrap {
    right: -5vw;
  }
}
.page--i-rate-guarantee .circle-info-white-block .img-wrap picture {
  width: 100%;
  height: 100%;
}
.page--i-rate-guarantee .circle-info-white-block .img-wrap picture img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 767px) {
  .page--i-rate-guarantee .circle-info-white-block .img-wrap {
    height: fit-content;
    width: fit-content;
    margin: auto;
  }
}
@media screen and (min-width: 1025px) {
  .page--i-rate-guarantee .circle-info-white-block .img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page--i-rate-guarantee .circle-info-white-block .img-wrap img {
    width: 100%;
    height: 100%;
    max-height: var(--circle-size);
    max-width: var(--circle-size);
  }
}
@media screen and (max-width: 1024px) {
  .page--i-rate-guarantee .circle-info-white-block .img-wrap img {
    max-height: var(--circle-size);
    max-width: var(--circle-size);
  }
}
@media screen and (max-width: 400px) {
  .page--i-rate-guarantee .circle-info-white-block .img-wrap {
    max-height: 375px;
    max-width: 375px;
  }
  .page--i-rate-guarantee .circle-info-white-block .img-wrap img {
    height: auto !important;
    width: 375px !important;
    max-width: 80vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .page--i-rate-guarantee .circle-info-white-block .img-wrap {
    height: 390px;
    width: 390px;
    display: inline-block;
    vertical-align: top;
    margin-left: 10%;
  }
  .page--i-rate-guarantee .circle-info-white-block .img-wrap img {
    max-height: 390px;
    max-width: 390px;
  }
}
.page--i-rate-guarantee .circle-info-white-block .txt-content {
  flex: 0 0 auto;
}
@media screen and (min-width: 1921px) {
  .page--i-rate-guarantee .circle-info-white-block .txt-content {
    padding-bottom: 8px;
  }
}
.page--i-rate-guarantee .circle-info-white-block .img-txt-col {
  z-index: 1;
}
@media screen and (min-width: 1025px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col {
    justify-content: flex-end;
    padding-right: 0;
  }
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .inner-content {
    width: fit-content;
    display: flex;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col {
    position: relative;
    margin-top: 25px;
  }
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .inner-content {
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (min-width: 1025px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col {
    display: flex;
    flex: 0 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col {
    margin-top: 35px;
  }
}
.page--i-rate-guarantee .circle-info-white-block .img-txt-col .quote-details {
  position: relative;
  padding-left: 52px;
}
.page--i-rate-guarantee .circle-info-white-block .img-txt-col .quote-details::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 25px;
  background-color: #fff;
  height: 2px;
  top: 15px;
  width: 18px;
}
.page--i-rate-guarantee .circle-info-white-block .img-txt-col-person-position, .page--i-rate-guarantee .circle-info-white-block .img-txt-col-person-name {
  font-weight: 400;
  font-size: 16px;
  font-family: "Libre Franklin", sans-serif;
  line-height: 30px;
  letter-spacing: 0.6px;
  color: #fff;
  margin-bottom: 0;
}
.page--i-rate-guarantee .circle-info-white-block .img-txt-col-person-name {
  font-weight: 600;
}
.page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(0, 46, 166, 0.85);
}
@media screen and (max-width: 1024px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content {
    padding-top: 25px;
  }
}
@media screen and (min-width: 1025px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content {
    height: var(--circle-size);
    width: var(--circle-size);
  }
}
@media screen and (max-width: 767px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content {
    min-height: var(--circle-size);
    max-width: var(--circle-size);
    margin: auto;
    margin-top: -70px;
    z-index: 1;
    position: relative;
    border-radius: 15px;
    max-height: unset;
    padding-top: 0;
  }
}
@media screen and (max-width: 400px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content {
    max-width: 280px;
    padding: 15px 15px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content {
    position: relative;
    display: inline-flex;
    margin-left: -15%;
    height: 390px;
    width: 390px;
  }
}
@media screen and (min-width: 1025px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content {
    z-index: 1;
    margin-left: -90px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content {
    margin-right: -65px;
  }
}
.page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content .inner-text {
  position: relative;
}
@media screen and (min-width: 1025px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content .inner-text {
    max-width: 300px;
    max-height: 350px;
    padding-top: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content .inner-text {
    max-width: 300px;
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content .inner-text {
    max-width: 300px;
    max-height: 350px;
  }
}
@media screen and (max-width: 400px) {
  .page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content .inner-text {
    max-width: 240px;
    max-height: unset;
  }
}
.page--i-rate-guarantee .circle-info-white-block .img-txt-col .img-txt-content .img-txt-quote {
  color: #fff;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  position: relative;
  margin-bottom: 0;
}

.inner-table-heading {
  background-color: transparent !important;
  margin-top: 25px !important;
  display: block !important;
}
.inner-table-heading td {
  color: #002ea6 !important;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}

@media screen and (max-width: 1024px) {
  .reviews-table-block {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .reviews-table-block .table-cols-4 {
    min-width: 600px;
  }
  .reviews-table-block .table-cols-8 {
    min-width: 800px;
  }
}
.reviews-table-block .table-cols-4 th {
  width: 25%;
}
.reviews-table-block .table-cols-4 td {
  width: 25%;
}
.reviews-table-block .table-cols-8 th {
  border-right: 1px solid #e0ece9;
  width: 10%;
}
.reviews-table-block .table-cols-8 th:first-of-type {
  width: 30%;
}
.reviews-table-block .table-cols-8 td {
  border-right: 1px solid #e0ece9;
  width: 10%;
}
.reviews-table-block .table-cols-8 td:first-of-type {
  width: 30%;
}
.reviews-table-block .heading-table {
  margin-bottom: 0;
}
.reviews-table-block .heading-table thead {
  color: #000;
}
@media screen and (max-width: 550px) {
  .reviews-table-block .heading-table thead {
    font-size: 6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .reviews-table-block .heading-table thead {
    font-size: 30px;
  }
}
@media screen and (min-width: 1025px) {
  .reviews-table-block .heading-table thead {
    font-size: 1.563vw;
  }
}
@media screen and (min-width: 1921px) {
  .reviews-table-block .heading-table thead {
    font-size: 30px;
  }
}
.reviews-table-block .heading-table thead th {
  vertical-align: middle;
}
.reviews-table-block .heading-table thead th:first-of-type {
  text-align: left;
  letter-spacing: 0.35px;
}
@media screen and (min-width: 1025px) {
  .reviews-table-block .heading-table thead th:first-of-type {
    letter-spacing: 0.018vw;
  }
}
@media screen and (min-width: 1921px) {
  .reviews-table-block .heading-table thead th:first-of-type {
    letter-spacing: 0.35px;
  }
}
.reviews-table-block table {
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
  text-align: center;
  width: 100%;
  margin-bottom: 25px;
  font-weight: 700;
}
@media screen and (min-width: 1025px) {
  .reviews-table-block table {
    margin-bottom: 1.302vw;
  }
}
@media screen and (min-width: 1921px) {
  .reviews-table-block table {
    margin-bottom: 25px;
  }
}
.reviews-table-block table thead {
  color: #002ea6;
}
@media screen and (max-width: 550px) {
  .reviews-table-block table thead {
    font-size: 4.8vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .reviews-table-block table thead {
    font-size: 24px;
  }
}
@media screen and (min-width: 1025px) {
  .reviews-table-block table thead {
    font-size: 1.25vw;
  }
}
@media screen and (min-width: 1921px) {
  .reviews-table-block table thead {
    font-size: 24px;
  }
}
.reviews-table-block table thead th:first-of-type {
  text-align: left;
  letter-spacing: 0.35px;
}
@media screen and (min-width: 1025px) {
  .reviews-table-block table thead th:first-of-type {
    letter-spacing: 0.018vw;
  }
}
@media screen and (min-width: 1921px) {
  .reviews-table-block table thead th:first-of-type {
    letter-spacing: 0.35px;
  }
}
.reviews-table-block table tbody tr td:first-of-type {
  text-align: left;
  color: #5b5b5b;
}
.reviews-table-block table tbody tr td:not(:first-of-type) {
  color: #666666;
}
.reviews-table-block table tbody tr:nth-of-type(odd) {
  background-color: #f3fffc;
}
.reviews-table-block table strong,
.reviews-table-block table b {
  color: #002ea6;
}
.reviews-table-block table tr {
  vertical-align: initial;
}
.reviews-table-block table tr.four-columns-heading-row {
  vertical-align: middle;
}
.reviews-table-block table tr.four-columns-heading-row th {
  text-align: center;
}
.reviews-table-block table tr.four-columns-heading-row th:first-child {
  text-align: left;
}
.reviews-table-block table tr.four-columns-heading-row th img {
  display: block;
  margin: 0 auto;
}
.reviews-table-block table img {
  vertical-align: middle;
}
.reviews-table-block table th {
  padding: 3px;
}
.reviews-table-block table th:first-of-type {
  padding-left: 0;
  padding-right: 0;
}
.reviews-table-block table td {
  padding: 4px;
}
.reviews-table-block table td:first-of-type {
  padding-left: 0;
  padding-right: 0;
}
.reviews-table-block table tbody {
  font-size: 11px;
}
@media screen and (min-width: 400px) {
  .reviews-table-block table tbody {
    font-size: 12px;
  }
}
@media screen and (min-width: 479px) {
  .reviews-table-block table tbody {
    font-size: 14px;
  }
}
@media screen and (min-width: 640px) {
  .reviews-table-block table tbody {
    font-size: 16px;
  }
}
@media screen and (min-width: 1025px) {
  .reviews-table-block table tbody {
    font-size: 12px;
  }
}
@media screen and (min-width: 1281px) {
  .reviews-table-block table tbody {
    font-size: 14px;
  }
}
@media screen and (min-width: 1441px) {
  .reviews-table-block table tbody {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .table-for-reviews {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .txt-post-rtf.textpage-rtf .table-wrapper {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .txt-post-rtf.textpage-rtf .table-wrapper table {
    min-width: 550px;
  }
}
.txt-post-rtf.textpage-rtf table {
  font-weight: 700;
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
  text-align: center;
  width: 100%;
  border: 1px solid #cedbff;
  border-radius: 25px;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.textpage-rtf table {
    margin: 11vw 0vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.textpage-rtf table {
    margin: 55px 0;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.textpage-rtf table {
    margin: 2.865vw 0vw;
    margin-right: -27px;
    width: calc(100% + 27px);
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.textpage-rtf table {
    margin: 55px 0;
  }
}
.txt-post-rtf.textpage-rtf table thead {
  color: #002ea6;
}
.txt-post-rtf.textpage-rtf table th {
  border-bottom: 1px solid #cedbff;
  padding: 23px 15px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.textpage-rtf table th {
    padding: 1.198vw 0.781vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.textpage-rtf table th {
    padding: 23px 15px;
  }
}
.txt-post-rtf.textpage-rtf table th:not(:last-of-type) {
  border-right: 1px solid #cedbff;
}
.txt-post-rtf.textpage-rtf table tr:last-of-type td {
  border-bottom: none;
}
.txt-post-rtf.textpage-rtf table td {
  padding: 15px;
  border-bottom: 1px solid #cedbff;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.textpage-rtf table td {
    padding: 0.781vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.textpage-rtf table td {
    padding: 15px;
  }
}
.txt-post-rtf.textpage-rtf table td:not(:last-of-type) {
  border-right: 1px solid #cedbff;
}
.txt-post-rtf.textpage-rtf table tbody {
  color: #001a5d;
  line-height: 1.4285;
  font-size: 11px;
}
@media screen and (min-width: 400px) {
  .txt-post-rtf.textpage-rtf table tbody {
    font-size: 12px;
  }
}
@media screen and (min-width: 479px) {
  .txt-post-rtf.textpage-rtf table tbody {
    font-size: 13px;
  }
}
@media screen and (min-width: 640px) {
  .txt-post-rtf.textpage-rtf table tbody {
    font-size: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.textpage-rtf table tbody {
    font-size: 12px;
  }
}
@media screen and (min-width: 1281px) {
  .txt-post-rtf.textpage-rtf table tbody {
    font-size: 13px;
  }
}
@media screen and (min-width: 1441px) {
  .txt-post-rtf.textpage-rtf table tbody {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf .table-wrapper {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .txt-post-rtf.txt-blocks-layout-rtf .table-wrapper table {
    min-width: 550px;
  }
}
@media screen and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-3 {
    min-width: 400px;
  }
  .txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-4 {
    min-width: 600px;
  }
  .txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-8 {
    min-width: 800px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-3 th {
  width: 33.33%;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-3 th {
    padding-top: 40px;
  }
  .txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-3 th .first-accent-column {
    padding-top: 35px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-3 td {
  width: 33.33%;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-4 th {
  width: 25%;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-4 td {
  width: 25%;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-8 th {
  border-right: 1px solid #e0ece9;
  width: 10%;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-8 th:first-of-type {
  width: 30%;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-8 td {
  border-right: 1px solid #e0ece9;
  width: 10%;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block .table-cols-8 td:first-of-type {
  width: 30%;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block .heading-table {
  margin-bottom: 0;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block .heading-table thead {
  color: #000;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block .heading-table thead {
    font-size: 6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block .heading-table thead {
    font-size: 30px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block .heading-table thead {
    font-size: 1.563vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block .heading-table thead {
    font-size: 30px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block .heading-table thead th {
  vertical-align: middle;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block .heading-table thead th:first-of-type {
  text-align: left;
  letter-spacing: 0.35px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block .heading-table thead th:first-of-type {
    letter-spacing: 0.018vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block .heading-table thead th:first-of-type {
    letter-spacing: 0.35px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table {
  letter-spacing: 0;
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
  text-align: center;
  width: 100%;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 25px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table {
    margin-bottom: 1.302vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table {
    margin-bottom: 25px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table thead {
  color: #002ea6;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table thead {
    font-size: 4.8vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table thead {
    font-size: 24px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table thead {
    font-size: 1.25vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table thead {
    font-size: 24px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table thead th:first-of-type {
  text-align: left;
  letter-spacing: 0.35px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table thead th:first-of-type {
    letter-spacing: 0.018vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table thead th:first-of-type {
    letter-spacing: 0.35px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table tbody tr {
  text-align: left;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table tbody tr td:first-of-type {
  color: #002ea6;
  font-weight: 700;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table tbody tr td:not(:first-of-type) {
  color: #001a5d;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table tbody tr:nth-of-type(odd) {
  background-color: #effffb;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table strong,
.txt-post-rtf.txt-blocks-layout-rtf.table-block table b {
  color: #002ea6;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table tr {
  vertical-align: initial;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table img {
  vertical-align: middle;
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table th {
  padding: 14px 10px 28px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table th {
    padding: 14px 10px 28px;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table th {
    padding: 14px 10px 28px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table td {
  padding: 14px 14px 15px 47px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table td {
    padding: 0.729vw 0.729vw 0.781vw 2.448vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table td {
    padding: 14px 14px 15px 47px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table td:first-of-type {
  padding: 18px 15px 26px 37px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table td:first-of-type {
    padding: 0.938vw 0.781vw 1.354vw 1.927vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table td:first-of-type {
    padding: 18px 15px 26px 37px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table td:last-of-type {
  padding-left: 25px;
  padding-right: 20px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table td:last-of-type {
    padding-left: 1.302vw;
    padding-right: 1.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table td:last-of-type {
    padding-left: 25px;
    padding-right: 20px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf.table-block table tbody {
  font-size: 11px;
}
@media screen and (min-width: 400px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table tbody {
    font-size: 12px;
  }
}
@media screen and (min-width: 479px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table tbody {
    font-size: 13px;
  }
}
@media screen and (min-width: 640px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table tbody {
    font-size: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table tbody {
    font-size: 12px;
  }
}
@media screen and (min-width: 1281px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table tbody {
    font-size: 13px;
  }
}
@media screen and (min-width: 1441px) {
  .txt-post-rtf.txt-blocks-layout-rtf.table-block table tbody {
    font-size: 14px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table {
  font-weight: 700;
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
  text-align: center;
  width: 100%;
  border: 1px solid #cedbff;
  border-radius: 25px;
}
@media screen and (max-width: 550px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table {
    margin: 11vw 0vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table {
    margin: 55px 0;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table {
    margin: 2.865vw 0vw;
    margin-right: -27px;
    width: calc(100% + 27px);
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table {
    margin: 55px 0;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table.reviews-table {
  width: 90%;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table.reviews-table {
    width: calc(90% + 27px);
  }
}
.txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table thead {
  color: #002ea6;
}
.txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table th {
  border-bottom: 1px solid #cedbff;
  padding: 23px 15px;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table th {
    padding: 1.198vw 0.781vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table th {
    padding: 23px 15px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table th:not(:last-of-type) {
  border-right: 1px solid #cedbff;
}
.txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table tr:last-of-type td {
  border-bottom: none;
}
.txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table tr:last-of-type td:first-child {
  border-bottom-left-radius: 25px;
}
.txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table tr:last-of-type td:last-child {
  border-bottom-right-radius: 25px;
}
.txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table td {
  padding: 15px;
  border-bottom: 1px solid #cedbff;
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table td {
    padding: 0.781vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table td {
    padding: 15px;
  }
}
.txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table td:not(:last-of-type) {
  border-right: 1px solid #cedbff;
}
.txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table tbody {
  color: #001a5d;
  line-height: 1.4285;
  font-size: 11px;
}
@media screen and (min-width: 400px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table tbody {
    font-size: 12px;
  }
}
@media screen and (min-width: 479px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table tbody {
    font-size: 13px;
  }
}
@media screen and (min-width: 640px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table tbody {
    font-size: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table tbody {
    font-size: 12px;
  }
}
@media screen and (min-width: 1281px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table tbody {
    font-size: 13px;
  }
}
@media screen and (min-width: 1441px) {
  .txt-post-rtf.txt-blocks-layout-rtf:not(.table-block) table tbody {
    font-size: 14px;
  }
}

.four-column-table tbody tr td:first-of-type {
  text-align: left;
  color: #5b5b5b;
}
.four-column-table tbody tr td:not(:first-of-type) {
  color: #666666;
}
.four-column-table tbody tr:nth-of-type(odd) {
  background-color: #f3fffc;
}

.wp-block-embed-youtube iframe {
  width: 100%;
  min-height: 465px;
}

.inner-pages-contact-section.contact-block .hbspt-form .hs-form-field label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
  background-color: transparent !important;
}
@media screen and (min-width: 920px) {
  .inner-pages-contact-section.contact-block .hbspt-form .hs-form-field label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
    background-color: #0f7095 !important;
  }
}

@media screen and (min-width: 1025px) {
  .footer-landing-page-template {
    padding-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .footer-landing-page-template {
    padding-bottom: 80px;
  }
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .content-type-1 {
    padding-top: 4.427vw;
    padding-bottom: 3.125vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer-landing-page-template .content-type-1 {
    padding-top: 85px;
    padding-bottom: 60px;
  }
}
.footer-landing-page-template .content-type-1 .form-content-wrap {
  margin: 0 auto;
  padding-top: 62px;
  max-width: 633px;
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .content-type-1 .form-content-wrap {
    padding-top: 3.229vw;
    max-width: 32.969vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer-landing-page-template .content-type-1 .form-content-wrap {
    padding-top: 62px;
    max-width: 633px;
  }
}
.footer-landing-page-template .content-type-1 .form-content-title {
  margin-bottom: 5px;
}
.footer-landing-page-template .content-type-1 .txt-post-rtf {
  padding-left: 0;
  padding-right: 0;
}
.footer-landing-page-template .content-type-1 .form-design-3 {
  margin-top: 26px;
}
.footer-landing-page-template .content-type-1 .button-row {
  margin-top: 25px;
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .content-type-1 .button-row {
    margin-top: 1.302vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer-landing-page-template .content-type-1 .button-row {
    margin-top: 25px;
  }
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .content-type-2 {
    padding-top: 5.208vw;
    padding-bottom: 3.125vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer-landing-page-template .content-type-2 {
    padding-top: 100px;
    padding-bottom: 60px;
  }
}
.footer-landing-page-template .content-type-2 .btn-default {
  margin-top: 52px;
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .content-type-2 .btn-default {
    margin-top: 2.708vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer-landing-page-template .content-type-2 .btn-default {
    margin-top: 52px;
  }
}
.footer-landing-page-template .content-type-2 .images-hold-item img {
  width: 100%;
  max-width: 120px;
  max-height: 100%;
}
.footer-landing-page-template .txt-content {
  width: 100%;
  margin: 0 auto;
  max-width: 970px;
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .txt-content {
    max-width: 50.521vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer-landing-page-template .txt-content {
    max-width: 970px;
  }
}
.footer-landing-page-template .txt-size-36 {
  margin-bottom: 35px;
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .txt-size-36 {
    margin-bottom: 1.823vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer-landing-page-template .txt-size-36 {
    margin-bottom: 35px;
  }
}
.footer-landing-page-template .txt-size-36 a[href^="tel:"] {
  color: #17fcc4;
  text-decoration: none;
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .txt-post-rtf {
    padding: 0vw 7.292vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer-landing-page-template .txt-post-rtf {
    padding: 0 140px;
  }
}
.footer-landing-page-template .txt-post-rtf a {
  color: #17fcc4;
  text-decoration: underline;
}
.footer-landing-page-template .txt-post-rtf a:hover, .footer-landing-page-template .txt-post-rtf a:focus {
  text-decoration: none;
}
.footer-landing-page-template .txt-post-rtf ul,
.footer-landing-page-template .txt-post-rtf ol {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;
}
@media screen and (max-width: 550px) {
  .footer-landing-page-template .txt-post-rtf ul,
  .footer-landing-page-template .txt-post-rtf ol {
    letter-spacing: 0.04vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .footer-landing-page-template .txt-post-rtf ul,
  .footer-landing-page-template .txt-post-rtf ol {
    letter-spacing: 0.2px;
  }
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .txt-post-rtf ul,
  .footer-landing-page-template .txt-post-rtf ol {
    letter-spacing: 0.01vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer-landing-page-template .txt-post-rtf ul,
  .footer-landing-page-template .txt-post-rtf ol {
    letter-spacing: 0.2px;
  }
}
.footer-landing-page-template .txt-post-rtf li {
  position: relative;
  padding-left: 37px;
}
.footer-landing-page-template .txt-post-rtf ol {
  counter-reset: item;
}
.footer-landing-page-template .txt-post-rtf ol li::before {
  content: counter(item) ".";
  display: inline-block;
  position: absolute;
  counter-increment: item;
  top: 0;
  left: 2px;
  color: #17fcc4;
}
.footer-landing-page-template .txt-post-rtf ul li::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(0.5em + 4px);
  left: 2px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
}
.footer-landing-page-template .images-wrap {
  width: 100%;
  margin-top: 43px;
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .images-wrap {
    margin-top: 2.24vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer-landing-page-template .images-wrap {
    margin-top: 43px;
  }
}
.footer-landing-page-template .images-hold {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: -10px -20px;
}
@media screen and (max-width: 1024px) {
  .footer-landing-page-template .images-hold {
    flex-flow: row wrap;
  }
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .images-hold {
    margin: -0.521vw -1.406vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer-landing-page-template .images-hold {
    margin: -37px;
  }
}
.footer-landing-page-template .images-hold-item {
  flex: 0 1 auto;
  margin: 10px 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 65px;
}
@media screen and (max-width: 1024px) {
  .footer-landing-page-template .images-hold-item {
    width: calc(50% - 40px);
  }
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .images-hold-item {
    margin: 0.521vw 1.406vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer-landing-page-template .images-hold-item {
    margin: 10px 27px;
  }
}
.footer-landing-page-template .images-hold-item-img {
  max-height: 100%;
}
@media screen and (max-width: 1024px) {
  .footer-landing-page-template .custom-footer-content-wrap {
    padding-bottom: 50px;
  }
}
.footer-landing-page-template .custom-footer-bottom-wrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding-top: 0;
}
@media screen and (max-width: 1280px) {
  .footer-landing-page-template .custom-footer-bottom-wrap {
    flex-flow: column nowrap;
    text-align: center;
  }
}
.footer-landing-page-template .custom-footer-bottom-item {
  list-style-type: none;
  flex: 0 1 auto;
  position: relative;
  margin: 0 4px 4px;
  font-family: "Libre Franklin", sans-serif;
  font-size: 12px;
  font-style: normal;
  letter-spacing: 0.6px;
  font-weight: 400;
  line-height: 20px;
}
@media screen and (min-width: 1025px) {
  .footer-landing-page-template .custom-footer-bottom-item {
    margin: 0 8px;
  }
}
@media screen and (min-width: 1281px) {
  .footer-landing-page-template .custom-footer-bottom-item:not(:last-of-type)::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -9px;
    width: 2px;
    height: 80%;
    background-color: #fff;
    transform: translateY(-50%);
    backface-visibility: hidden;
  }
}

.footer {
  background-color: #002ea6;
  padding: 25px 10px;
}
.footer.footer-default {
  margin-top: -1px;
  padding: 100px 10px 35px;
}
@media screen and (min-width: 1025px) {
  .footer.footer-default {
    padding-left: 8.229vw;
    padding-right: 8.229vw;
  }
}
.footer .footer-logo-link {
  flex: 0 0 auto;
  width: 217px;
  height: 72px;
  margin-bottom: 40px;
}
@media screen and (min-width: 1025px) {
  .footer .footer-logo-link {
    width: 11.302vw;
    height: 3.75vw;
    margin-bottom: 2.24vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .footer-logo-link {
    width: 217px;
    height: 72px;
    margin-bottom: 43px;
  }
}
@media screen and (max-width: 1024px) {
  .footer .footer-info-col-item br {
    display: none;
  }
}
.footer .footer-info-col-item a {
  display: unset !important;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.footer .footer-info-col-item a:hover,
.footer .footer-info-col-item a:focus {
  background-size: 100% 2px;
}
.footer .footer-info-col-title {
  margin-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  .footer .footer-info-col-title br {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .footer .footer-info-col-title {
    margin-bottom: 1.146vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .footer-info-col-title {
    margin-bottom: 22px;
  }
}
.footer:not(.footer-landing-page-template) a:not(.site-by-link):not(.social-item) {
  color: inherit;
  display: inline-block;
}
.footer .custom-footer-bottom-wrap a {
  color: inherit;
  display: inline-block;
}
.footer .txt-pages-list,
.footer .footer-links-col,
.footer .footer-links-inner-col {
  list-style-type: none;
}
.footer .contact-col a:not(.social-item) {
  color: #17fcc4 !important;
  display: unset !important;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.footer .contact-col a:hover,
.footer .contact-col a:focus {
  background-size: 100% 1px;
}
.footer .contact-col .txt-size-10 {
  margin-top: 14px;
}
@media screen and (min-width: 1025px) {
  .footer .contact-col .txt-size-10 {
    margin-top: 0.729vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .contact-col .txt-size-10 {
    margin-top: 14px;
  }
}
.footer .newsletter-form {
  max-width: 281px;
}
@media screen and (min-width: 1025px) {
  .footer .socials-wrap {
    margin-top: 2.292vw;
    margin-bottom: 1.927vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .socials-wrap {
    margin-top: 44px;
    margin-bottom: 37px;
  }
}
.footer .socials-wrap .txt-size-16 {
  margin-bottom: 10px;
}
@media screen and (min-width: 1025px) {
  .footer .socials-wrap .txt-size-16 {
    margin-bottom: 0.521vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .socials-wrap .txt-size-16 {
    margin-bottom: 10px;
  }
}
.footer .social-items-hold {
  display: flex;
  align-items: flex-start;
  margin: -9.5px;
  flex-wrap: wrap;
}
@media screen and (min-width: 1025px) {
  .footer .social-items-hold {
    margin: -0.495vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .social-items-hold {
    margin: -9.5px;
  }
}
.footer .social-item {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  transition: background-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  background-color: #fff;
  width: 40px;
  height: 40px;
  margin: 9.5px;
  color: #000;
}
@media screen and (min-width: 1025px) {
  .footer .social-item {
    width: 2.083vw;
    height: 2.083vw;
    margin: 0.495vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .social-item {
    width: 40px;
    height: 40px;
    margin: 9.5px;
  }
}
.footer .social-item:hover, .footer .social-item:focus {
  background-color: #17fcc4;
}
.footer .social-svg-icon path {
  fill: #002ea6;
}
.footer .twitter-icon {
  width: 100%;
}
.footer .facebook-icon {
  width: 100%;
}
.footer .linkedin-icon {
  width: 100%;
}
.footer .instagram-icon {
  width: 100%;
}
.footer .footer-top-wrap {
  display: flex;
  align-items: flex-start;
  padding-bottom: 40px;
  border-bottom: 1px solid #3358b8;
}
@media screen and (max-width: 1024px) {
  .footer .footer-top-wrap {
    flex-flow: row wrap;
  }
}
@media screen and (min-width: 1025px) {
  .footer .footer-top-wrap {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding-bottom: 2.708vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .footer-top-wrap {
    padding-bottom: 52px;
  }
}
.footer .footer-icons-wrap {
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #3358b8;
}
@media screen and (max-width: 1024px) {
  .footer .footer-icons-wrap {
    flex-flow: column nowrap;
  }
}
@media screen and (min-width: 1025px) {
  .footer .footer-icons-wrap {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    justify-content: space-between;
    padding-top: 1.354vw;
    padding-bottom: 3.594vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .footer-icons-wrap {
    padding-top: 26px;
    padding-bottom: 69px;
  }
}
.footer .footer-bottom-wrap {
  display: flex;
  align-items: flex-start;
  padding-top: 25px;
}
@media screen and (max-width: 1024px) {
  .footer .footer-bottom-wrap {
    flex-flow: column nowrap;
  }
}
@media screen and (min-width: 1025px) {
  .footer .footer-bottom-wrap {
    padding-top: 1.771vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .footer-bottom-wrap {
    padding-top: 34px;
  }
}
@media screen and (min-width: 1025px) {
  .footer .footer-mobile-wrap {
    display: none;
  }
}
.footer .footer-mobile-wrap .socials-wrap {
  padding-top: 40px;
}
.footer .footer-mobile-wrap .footer-contact-info {
  padding-top: 40px;
}
.footer .footer-mobile-wrap .newsletter-form-wrap {
  padding-top: 25px;
  padding-bottom: 40px;
  border-bottom: 1px solid #3358b8;
}
.footer .footer-mobile-wrap .newsletter-form {
  max-width: 100%;
}
.footer .footer-mobile-wrap a {
  color: #17fcc4;
}
.footer .footer-mobile-wrap a:hover, .footer .footer-mobile-wrap a:focus {
  text-decoration: underline;
}
.footer .footer-mobile-wrap .txt-size-10 {
  margin-top: 14px;
}
@media screen and (min-width: 1025px) {
  .footer .footer-mobile-wrap .txt-size-10 {
    margin-top: 0.729vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .footer-mobile-wrap .txt-size-10 {
    margin-top: 14px;
  }
}
.footer .footer-mobile-wrap .socials-wrap {
  width: 100%;
}
.footer .footer-mobile-wrap .newsletter-form {
  width: 100%;
}
.footer .logo-col {
  width: 100%;
  flex: 0 0 auto;
}
@media screen and (max-width: 1024px) {
  .footer .logo-col .footer-info-col {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .footer .logo-col {
    width: 27.9%;
    padding-right: 5.208vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .logo-col {
    padding-right: 100px;
  }
}
.footer .footer-links-wrap {
  flex: 0 0 auto;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .footer .footer-links-wrap {
    width: 48.5%;
    margin-top: 0.677vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .footer-links-wrap {
    margin-top: 13px;
  }
}
.footer .footer-links-wrap .footer-info-col-title {
  margin-bottom: 10px;
}
@media screen and (min-width: 1025px) {
  .footer .footer-links-wrap .footer-info-col-title {
    margin-bottom: 0.938vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .footer-links-wrap .footer-info-col-title {
    margin-bottom: 18px;
  }
}
.footer .footer-links-wrap .footer-links-hold {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin: 0 0 -20px;
}
@media screen and (min-width: 1025px) {
  .footer .footer-links-wrap .footer-links-hold {
    margin: 0vw -1.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .footer-links-wrap .footer-links-hold {
    margin: 0 -20px;
  }
}
.footer .footer-links-wrap .footer-links-col {
  margin: 0 0 25px;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .footer .footer-links-wrap .footer-links-col {
    margin: 0vw 1.042vw;
    width: calc(33.33% - 2.084vw);
  }
}
@media screen and (min-width: 1921px) {
  .footer .footer-links-wrap .footer-links-col {
    margin: 0 20px;
    width: calc(33.33% - 40px);
  }
}
.footer .footer-links-wrap .footer-links-inner-col:not(:last-of-type) {
  margin-bottom: 25px;
}
@media screen and (min-width: 1025px) {
  .footer .footer-links-wrap .footer-links-inner-col:not(:last-of-type) {
    margin-bottom: 2.708vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .footer-links-wrap .footer-links-inner-col:not(:last-of-type) {
    margin-bottom: 52px;
  }
}
.footer .seperated-links-wrap {
  flex: 0 0 auto;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .footer .seperated-links-wrap {
    width: 23.6%;
    margin-top: 0.677vw;
    padding-left: 7.656vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .seperated-links-wrap {
    margin-top: 13px;
    padding-left: 147px;
  }
}
.footer .seperated-links-wrap::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: rgba(255, 255, 255, 0.1);
  height: 103%;
  width: 1px;
}
@media screen and (max-width: 1024px) {
  .footer .seperated-links-wrap::before {
    content: none;
  }
}
@media screen and (min-width: 1025px) {
  .footer .seperated-links-wrap::before {
    left: 3.333vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .seperated-links-wrap::before {
    left: 64px;
  }
}
@media screen and (max-width: 1024px) {
  .footer .reviews-wrap {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .footer .associations-wrap {
    margin-top: 50px;
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .footer .associations-wrap-title {
    text-align: center;
  }
}
@media screen and (max-width: 1024px) {
  .footer .reviews-wrap-title {
    text-align: center;
  }
}
@media screen and (min-width: 1025px) {
  .footer .reviews-wrap-title {
    padding-left: 0.365vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .reviews-wrap-title {
    padding-left: 7px;
  }
}
@media screen and (max-width: 1024px) {
  .footer .reviews-hold {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    justify-content: center;
    margin: -20px;
  }
}
@media screen and (min-width: 1025px) {
  .footer .reviews-hold {
    display: grid;
    gap: 3.35vw;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: end;
    height: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .footer .review-item {
    text-align: center;
    margin: 20px;
    width: calc(33.33% - 40px);
    display: flex !important;
    align-items: flex-end !important;
    max-width: 60px !important;
  }
}
.footer .associations-hold {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 1024px) {
  .footer .associations-hold {
    padding-top: 10px;
    flex-flow: row wrap;
    justify-content: center;
    margin: -20px;
  }
}
@media screen and (min-width: 1025px) {
  .footer .associations-hold {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1.432vw;
  }
}
@media screen and (max-width: 1024px) {
  .footer .associations-item {
    text-align: center;
    margin: 20px;
    width: calc(33.33% - 40px);
    display: flex !important;
    align-items: flex-end !important;
    max-width: 60px !important;
  }
}
@media screen and (max-width: 1024px) {
  .footer .copyright-txt {
    order: 2;
    margin-bottom: 40px;
  }
}
.footer .txt-pages-list {
  display: flex;
  align-items: flex-start;
  margin-left: -11.5px;
  margin-right: -11.5px;
}
@media screen and (max-width: 450px) {
  .footer .txt-pages-list {
    flex-flow: column nowrap;
  }
}
@media screen and (max-width: 1024px) {
  .footer .txt-pages-list {
    order: 1;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1025px) {
  .footer .txt-pages-list {
    padding-left: 4.219vw;
    margin-top: -0.156vw;
    margin-left: -0.599vw;
    margin-right: -0.599vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .txt-pages-list {
    padding-left: 81px;
    margin-top: -3px;
    margin-left: -11.5px;
    margin-right: -11.5px;
  }
}
.footer .txt-pages-item {
  margin: 0 11.5px;
}
@media screen and (max-width: 450px) {
  .footer .txt-pages-item:not(:last-of-type) {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1025px) {
  .footer .txt-pages-item {
    margin: 0vw 0.599vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .txt-pages-item {
    margin: 0 11.5px;
  }
}
.footer .txt-pages-item a {
  display: unset !important;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.5s cubic-bezier(0.61, 1, 0.88, 1), color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.footer .txt-pages-item a:hover,
.footer .txt-pages-item a:focus {
  color: #fff;
  background-size: 100% 1px;
}
.footer .txt-pages-item:not(:last-of-type)::after {
  content: "";
  display: inline-block;
  position: absolute;
  backface-visibility: hidden;
  transform: translateY(-50%);
  top: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  width: 2px;
  height: 12px;
  right: -13px;
}
@media screen and (max-width: 450px) {
  .footer .txt-pages-item:not(:last-of-type)::after {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .footer .txt-pages-item:not(:last-of-type)::after {
    right: -0.677vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .txt-pages-item:not(:last-of-type)::after {
    right: -13px;
  }
}
.footer .site-by-link {
  display: inline-flex;
  align-items: center;
  backface-visibility: hidden;
  opacity: 0.5;
}
@media screen and (max-width: 1024px) {
  .footer .site-by-link {
    order: 3;
    margin-left: auto;
  }
}
@media screen and (min-width: 1025px) {
  .footer .site-by-link {
    margin-left: auto;
    margin-top: -11px;
  }
}
.footer .site-by-link:hover .edesign-hand, .footer .site-by-link:focus .edesign-hand {
  animation-name: wave-animation;
  animation-duration: 1.75s;
  animation-iteration-count: 1;
}
.footer .icon-wrap {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.footer .edesign-hand {
  backface-visibility: hidden;
  transition: transform 0.3s ease-out;
  transform: translate3d(0, 0, 0);
  transform-origin: 70% 70%;
  width: 30px;
}
.footer .review-bbb {
  width: 103px;
  height: 94px;
}
@media screen and (min-width: 1025px) {
  .footer .review-bbb {
    height: auto;
    width: auto;
  }
}
.footer .review-fb {
  width: 136px;
  height: 61px;
}
@media screen and (min-width: 1025px) {
  .footer .review-fb {
    width: 7.083vw;
    height: 3.177vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .review-fb {
    width: 136px;
    height: 61px;
  }
}
.footer .review-google {
  width: 114px;
  height: 62px;
}
@media screen and (min-width: 1025px) {
  .footer .review-google {
    width: 5.938vw;
    height: 3.229vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .review-google {
    width: 114px;
    height: 62px;
  }
}
.footer .associations-bbb {
  height: 34px;
}
@media screen and (min-width: 1025px) {
  .footer .associations-bbb {
    height: 1.771vw;
    width: 6.927vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .associations-bbb {
    height: 34px;
    width: 133px;
  }
}
.footer .associations-nrf {
  height: 33px;
}
@media screen and (min-width: 1025px) {
  .footer .associations-nrf {
    height: 1.719vw;
    width: 4.844vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .associations-nrf {
    height: 33px;
    width: 93px;
  }
}
.footer .associations-ahla {
  height: 61px;
}
@media screen and (min-width: 1025px) {
  .footer .associations-ahla {
    height: 3.177vw;
    width: 5.677vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .associations-ahla {
    height: 61px;
    width: 109px;
  }
}
.footer .associations-nra {
  height: 55px;
}
@media screen and (min-width: 1025px) {
  .footer .associations-nra {
    height: 2.865vw;
    width: 6.51vw;
  }
}
@media screen and (min-width: 1921px) {
  .footer .associations-nra {
    height: 55px;
    width: 125px;
  }
}

@media screen and (max-width: 1024px) {
  .custom-footer-bottom-wrap {
    padding: 0 !important;
  }
}
html[lang=es-US] .footer .footer-links-wrap,
html[lang=es-US] .footer .seperated-links-wrap {
  display: none;
}

.article-content .hbspt-form .hs-input:not(input[type=checkbox]),
.sk-lander .hbspt-form .hs-input:not(input[type=checkbox]) {
  background-color: transparent !important;
  border: 1px solid #6682ca;
  font-weight: 400;
  color: #fff;
  font-size: 18px !important;
  line-height: 30px !important;
  letter-spacing: 0.4px;
  transition: border 0.5s cubic-bezier(0.61, 1, 0.88, 1), color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  font-family: "Libre Franklin", sans-serif;
  display: flex;
  height: 42px !important;
  width: 100% !important;
  padding: 0 15px;
  line-height: 42px;
}
.article-content .hbspt-form .hs-input::placeholder,
.sk-lander .hbspt-form .hs-input::placeholder {
  opacity: 1;
  font-size: 12px;
  font-style: normal;
  color: #fff;
}
.article-content .hbspt-form textarea.hs-input:not(input[type=checkbox]),
.sk-lander .hbspt-form textarea.hs-input:not(input[type=checkbox]) {
  height: auto !important;
  min-height: 125px !important;
  padding: 10px 15px;
  line-height: 1.5em !important;
}
.article-content .hbspt-form .hs-input[type=number]::placeholder,
.sk-lander .hbspt-form .hs-input[type=number]::placeholder {
  opacity: 1;
  font-size: 14px;
  color: #fff;
}
.article-content .hbspt-form .hs-fieldtype-checkbox,
.sk-lander .hbspt-form .hs-fieldtype-checkbox {
  border: 1px solid #6682ca;
  display: flex;
  flex-direction: column;
}
.article-content .hbspt-form .hs-fieldtype-checkbox .input,
.sk-lander .hbspt-form .hs-fieldtype-checkbox .input {
  margin-right: 0 !important;
}
.article-content .hbspt-form .hs-fieldtype-checkbox label,
.sk-lander .hbspt-form .hs-fieldtype-checkbox label {
  position: relative;
  top: auto;
  left: auto;
  padding: 0;
}
@media screen and (max-width: 1024px) {
  .article-content .hbspt-form .hs-fieldtype-checkbox > label,
  .sk-lander .hbspt-form .hs-fieldtype-checkbox > label {
    padding-top: 10px !important;
  }
}
.article-content .hbspt-form .hs-fieldtype-checkbox .inputs-list,
.sk-lander .hbspt-form .hs-fieldtype-checkbox .inputs-list {
  padding-top: 0;
  padding-left: 20px;
  list-style-type: none;
  margin-bottom: 20px;
}
@media screen and (min-width: 1025px) {
  .article-content .hbspt-form .hs-fieldtype-checkbox .inputs-list,
  .sk-lander .hbspt-form .hs-fieldtype-checkbox .inputs-list {
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 20px;
  }
}
.article-content .hbspt-form .hs-fieldtype-checkbox .inputs-list.hs-error-msgs,
.sk-lander .hbspt-form .hs-fieldtype-checkbox .inputs-list.hs-error-msgs {
  font-family: "Libre Franklin", sans-serif;
  padding-top: 0;
  padding-left: 20px;
  padding-bottom: 10px;
}
.article-content .hbspt-form .hs-fieldtype-checkbox .inputs-list li,
.sk-lander .hbspt-form .hs-fieldtype-checkbox .inputs-list li {
  margin-bottom: 15px;
  color: #fff;
}
.article-content .hbspt-form .hs-fieldtype-checkbox .inputs-list li:last-child,
.sk-lander .hbspt-form .hs-fieldtype-checkbox .inputs-list li:last-child {
  margin-bottom: 0;
}
.article-content .hbspt-form .hs-fieldtype-checkbox .inputs-list li > label > input,
.sk-lander .hbspt-form .hs-fieldtype-checkbox .inputs-list li > label > input {
  margin-top: 3px;
}
.article-content .hbspt-form .hs-fieldtype-checkbox .inputs-list .check-desc,
.sk-lander .hbspt-form .hs-fieldtype-checkbox .inputs-list .check-desc {
  display: block;
  font-size: 12px;
  letter-spacing: 0.25px;
}
.article-content .hbspt-form .hs-fieldtype-checkbox .hs-form-checkbox-display,
.sk-lander .hbspt-form .hs-fieldtype-checkbox .hs-form-checkbox-display {
  display: flex;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.6px;
  font-family: "Libre Franklin", sans-serif;
}
.article-content .hbspt-form .hs-fieldtype-file label,
.sk-lander .hbspt-form .hs-fieldtype-file label {
  position: relative;
  top: auto;
  left: auto;
  padding: 0;
}
.article-content .hbspt-form .inputs-list,
.sk-lander .hbspt-form .inputs-list {
  padding-left: 0px;
}
.article-content .hbspt-form .hs-input[type=checkbox],
.sk-lander .hbspt-form .hs-input[type=checkbox] {
  display: inline-block !important;
  height: 15px !important;
  width: 15px !important;
  margin-right: 5px;
  padding: 0;
  background: transparent !important;
  border: solid 1px #6682ca;
  appearance: auto !important;
}
.article-content .hbspt-form select.hs-input,
.sk-lander .hbspt-form select.hs-input {
  width: 100% !important;
  height: 42px;
  padding: 0 15px;
  color: #fff;
  background: transparent !important;
  border: 1px solid #6682ca;
}
.article-content .hbspt-form select.hs-input option,
.sk-lander .hbspt-form select.hs-input option {
  font-size: 18px;
  color: #002ea6;
  background: #fff;
}
.article-content .hbspt-form .input .hs-form-booleancheckbox-display a,
.sk-lander .hbspt-form .input .hs-form-booleancheckbox-display a {
  color: #17fcc4;
  text-decoration: underline;
}
.article-content .hbspt-form .hs-recaptcha,
.sk-lander .hbspt-form .hs-recaptcha {
  margin-top: 20px;
}
.article-content .hbspt-form .hs-button:not([type=submit]),
.sk-lander .hbspt-form .hs-button:not([type=submit]) {
  width: auto !important;
  padding: 1.198vw 3.125vw !important;
  transition: all 0.3s ease;
  cursor: pointer;
}
.article-content .hbspt-form .hs-button:not([type=submit]):hover,
.sk-lander .hbspt-form .hs-button:not([type=submit]):hover {
  background: #fff !important;
}
.article-content .hbspt-form .hs-submit .hs-button,
.sk-lander .hbspt-form .hs-submit .hs-button {
  background-color: #17fcc4;
  color: #002ea6;
  width: auto;
  height: auto;
  margin: 0;
  overflow: hidden;
  border-radius: 40px;
  z-index: 0;
  transition: transform 0.6s cubic-bezier(0.61, 1, 0.88, 1), background-color 0.6s cubic-bezier(0.61, 1, 0.88, 1);
  backface-visibility: hidden;
  font-weight: 700;
  border: none;
}
@media screen and (max-width: 550px) {
  .article-content .hbspt-form .hs-submit .hs-button,
  .sk-lander .hbspt-form .hs-submit .hs-button {
    padding: 4.2vw 9vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .article-content .hbspt-form .hs-submit .hs-button,
  .sk-lander .hbspt-form .hs-submit .hs-button {
    padding: 21px 45px;
    font-size: 18px;
  }
}
@media screen and (min-width: 1025px) {
  .article-content .hbspt-form .hs-submit .hs-button,
  .sk-lander .hbspt-form .hs-submit .hs-button {
    padding: 1.094vw 2.344vw;
  }
}
@media screen and (min-width: 1921px) {
  .article-content .hbspt-form .hs-submit .hs-button,
  .sk-lander .hbspt-form .hs-submit .hs-button {
    padding: 21px 45px;
    font-size: 18px;
  }
}
.article-content .hbspt-form .hs-submit .hs-button::before,
.sk-lander .hbspt-form .hs-submit .hs-button::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 130%;
  height: calc(100% + 10px);
  z-index: 0;
  transform-origin: 0 0;
  transform: translateX(100%) skewX(-45deg);
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1), background-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  backface-visibility: hidden;
}
.article-content .hbspt-form .hs-submit .hs-button:hover,
.sk-lander .hbspt-form .hs-submit .hs-button:hover {
  background-color: #fff;
  transform: scale(1.05);
}
.article-content .hbspt-form .hs-submit .hs-button:hover::before,
.sk-lander .hbspt-form .hs-submit .hs-button:hover::before {
  transform: translateX(0) skewX(-45deg) scale(1.05);
}

.article-content .hbspt-form,
.sk-lander .hbspt-form {
  padding: 45px;
  background: #002ea6;
  border-radius: 50px;
}
.article-content .hbspt-form h2,
.article-content .hbspt-form h3,
.article-content .hbspt-form h4,
.article-content .hbspt-form h5,
.article-content .hbspt-form h6,
.sk-lander .hbspt-form h2,
.sk-lander .hbspt-form h3,
.sk-lander .hbspt-form h4,
.sk-lander .hbspt-form h5,
.sk-lander .hbspt-form h6 {
  color: #fff !important;
}
.article-content .hbspt-form p,
.sk-lander .hbspt-form p {
  font-family: "Libre Franklin", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #fff !important;
  line-height: 30px;
}
.article-content .hbspt-form fieldset,
.sk-lander .hbspt-form fieldset {
  max-width: none !important;
  width: 100%;
  margin-bottom: 0px !important;
}
@media screen and (max-width: 1024px) {
  .article-content .hbspt-form .form-columns-2 .hs-form-field,
  .sk-lander .hbspt-form .form-columns-2 .hs-form-field {
    width: 100% !important;
  }
}
.article-content .hbspt-form .submitted-message.hs-main-font-element,
.sk-lander .hbspt-form .submitted-message.hs-main-font-element {
  color: #fff !important;
}
.article-content .hbspt-form .submitted-message.hs-main-font-element p,
.article-content .hbspt-form .submitted-message.hs-main-font-element *,
.sk-lander .hbspt-form .submitted-message.hs-main-font-element p,
.sk-lander .hbspt-form .submitted-message.hs-main-font-element * {
  color: #fff !important;
}
.article-content .hbspt-form .hs-error-msgs,
.sk-lander .hbspt-form .hs-error-msgs {
  font-family: "Libre Franklin", sans-serif;
  padding-left: 0;
  list-style: none;
}
.article-content .hbspt-form .hs-error-msgs li,
.sk-lander .hbspt-form .hs-error-msgs li {
  list-style: none;
}
.article-content .hbspt-form .hs-error-msgs li::before,
.sk-lander .hbspt-form .hs-error-msgs li::before {
  content: none !important;
}
.article-content .talk-us-block .hbspt-form,
.sk-lander .talk-us-block .hbspt-form {
  padding: 0;
  background: transparent;
  border-radius: 0px;
}
.article-content .talk-us-block .hbspt-form form,
.sk-lander .talk-us-block .hbspt-form form {
  flex-direction: column;
}
.article-content .talk-us-block .hbspt-form .hs-form-field,
.sk-lander .talk-us-block .hbspt-form .hs-form-field {
  margin-bottom: 20px !important;
}
.article-content .talk-us-block .hbspt-form input[type=email],
.sk-lander .talk-us-block .hbspt-form input[type=email] {
  height: auto !important;
  line-height: 30px !important;
  padding: 0.938vw 0.781vw !important;
  border: 1px solid hsla(0, 0%, 100%, 0.5) !important;
}
.article-content .talk-us-block .hbspt-form .hs-error-msgs.inputs-list,
.sk-lander .talk-us-block .hbspt-form .hs-error-msgs.inputs-list {
  margin-top: 0;
}
.article-content .talk-us-block .hbspt-form .hs-error-msgs.inputs-list li,
.sk-lander .talk-us-block .hbspt-form .hs-error-msgs.inputs-list li {
  padding-left: 0;
}
.article-content .talk-us-block .hbspt-form .hs-error-msg,
.sk-lander .talk-us-block .hbspt-form .hs-error-msg {
  background: 0 0;
  color: #17fcc4 !important;
}
.article-content .talk-us-block .hbspt-form .hs-submit .hs-button,
.sk-lander .talk-us-block .hbspt-form .hs-submit .hs-button {
  width: 100% !important;
}

.content-block-holder.bg-blue .hbspt-form {
  padding: 0;
  background: transparent;
  border-radius: 0;
}

.sekure-block.bg-blue .hbspt-form {
  padding: 0;
  background: transparent;
  border-radius: 0;
}

.hs-error-msgs .hs-main-font-element {
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.6px;
  line-height: 20px;
}

.hs-form-booleancheckbox-display {
  display: flex;
  padding-left: 0 !important;
}

.hs-form-booleancheckbox {
  list-style-type: none;
}
.hs-form-booleancheckbox::before {
  content: none !important;
}

#ui-datepicker-div {
  display: none;
  width: 240px;
  padding: 15px;
  background: #fff;
  border-radius: 0 0 8px 8px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
}
#ui-datepicker-div .ui-datepicker-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#ui-datepicker-div .ui-datepicker-header a {
  text-decoration: none;
  cursor: pointer;
}
#ui-datepicker-div .ui-datepicker-title {
  display: flex;
  width: 100%;
}
#ui-datepicker-div .ui-datepicker-title select {
  flex: 1;
}
#ui-datepicker-div .ui-datepicker-calendar {
  width: 100%;
}
#ui-datepicker-div .ui-datepicker-calendar th {
  text-align: center;
}
#ui-datepicker-div .ui-datepicker-calendar td {
  text-align: center;
}

.landing-page-form .hbspt-form {
  padding: 0;
  background: transparent;
  border-radius: 0;
}
.landing-page-form .hbspt-form fieldset {
  margin-bottom: 0 !important;
}
.landing-page-form .hbspt-form .hs-error-msgs {
  padding-left: 0;
  list-style: none;
}

.firefox .hbspt-form .hs-fieldtype-file label {
  position: relative;
  top: auto;
  left: auto;
  padding: 0;
  background: transparent;
}
.firefox .hbspt-form fieldset .hs-input[type=file] {
  padding: 0;
  line-height: unset;
  border: none;
}
.firefox .hs-fieldtype-file .hs-input {
  background-color: transparent !important;
  font-size: 0.9rem;
}
.firefox .hs-fieldtype-file input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #17fcc4;
  padding: 5px 10px;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 20px;
  color: #002ea6;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}
.firefox .hs-fieldtype-file input[type=file]::file-selector-button:hover {
  background: #fff;
}
.firefox .hs-fieldtype-file input[type=file]::file-selector-button:active {
  background: #fff;
}

@media screen and (max-width: 880px) {
  .hbspt-form .hs-form-field label {
    font-size: 14px;
  }
  .hbspt-form .hs-form-field label#label-how_has_partnering_with_sekure_helped_to_improve_your_business_and_what_challenges_did_you_face_bef-8da3bd32-0a4b-430a-bc8e-6ee0c9f08f38 {
    top: -30px;
  }
  .hbspt-form fieldset .hs-richtext p {
    font-size: 18px;
  }
  .hbspt-form .hs-button {
    padding: 0.99rem 2.125rem !important;
  }
}
@media screen and (max-width: 640px) {
  .hbspt-form {
    padding: 45px 30px;
  }
  .hbspt-form .hs-form-field label {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .hbspt-form .hs-form-field {
    margin-bottom: 20px;
  }
  .hbspt-form .hs-form-field label#label-how_has_partnering_with_sekure_helped_to_improve_your_business_and_what_challenges_did_you_face_bef-8da3bd32-0a4b-430a-bc8e-6ee0c9f08f38 {
    top: auto;
  }
  .hbspt-form .hs-form-field label {
    display: inline-block;
    position: relative;
    top: auto;
    left: auto;
    padding: 0;
    margin-bottom: 10px;
    letter-spacing: 0;
    background: transparent;
  }
  .hbspt-form fieldset {
    margin-bottom: 0 !important;
  }
  .hbspt-form fieldset .input {
    margin-right: 0 !important;
  }
  .hbspt-form fieldset .hs-input {
    width: 100% !important;
  }
  .hs-fieldtype-file .input {
    display: flex;
    align-items: center;
  }
}
textarea {
  resize: none;
}

form:not(.guide-form):not(.form-styles) {
  position: relative;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
form:not(.guide-form):not(.form-styles).form-loading {
  opacity: 0.6;
  cursor: not-allowed;
}
form:not(.guide-form):not(.form-styles).form-loading > * {
  pointer-events: none;
}
form:not(.guide-form):not(.form-styles) .form-row {
  position: relative;
  transition: border 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
form:not(.guide-form):not(.form-styles) .form-row:not(.custom-select-row):not(.checkbox-row) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 1024px) {
  form:not(.guide-form):not(.form-styles) .form-row:not(:last-of-type) {
    margin-bottom: 25px;
  }
}
form:not(.guide-form):not(.form-styles) .form-row.user-typing .form-label {
  transform: translateY(-100%);
  opacity: 0;
}
form:not(.guide-form):not(.form-styles) .form-row:not(.checkbox-row):not(.custom-select-row).form-error {
  border: 1px solid #fd8096;
}
form:not(.guide-form):not(.form-styles) .form-row:not(.checkbox-row):not(.custom-select-row).form-error .form-input {
  color: #fd8096;
}
form:not(.guide-form):not(.form-styles) .form-row:not(.checkbox-row):not(.custom-select-row).form-error .form-label {
  color: #fd8096;
}
form:not(.guide-form):not(.form-styles) .form-row:not(.checkbox-row):not(.custom-select-row).form-error[data-error-txt]::after {
  opacity: 1;
}
form:not(.guide-form):not(.form-styles) .form-row[data-error-txt]::after {
  content: attr(data-error-txt);
  line-height: 1;
  color: #fd8096;
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  top: calc(100% + 0.5em);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  font-size: 12px;
  letter-spacing: 0.3px;
  opacity: 0;
  left: 35px;
}
@media screen and (min-width: 1025px) {
  form:not(.guide-form):not(.form-styles) .form-row[data-error-txt]::after {
    font-size: 10px;
    left: 1.823vw;
  }
}
@media screen and (min-width: 1281px) {
  form:not(.guide-form):not(.form-styles) .form-row[data-error-txt]::after {
    font-size: 11px;
  }
}
@media screen and (min-width: 1441px) {
  form:not(.guide-form):not(.form-styles) .form-row[data-error-txt]::after {
    font-size: 12px;
  }
}
@media screen and (min-width: 1921px) {
  form:not(.guide-form):not(.form-styles) .form-row[data-error-txt]::after {
    left: 35px;
  }
}
form:not(.guide-form):not(.form-styles) .form-success-msg {
  box-shadow: 0px 5px 24px 0px rgba(0, 46, 166, 0.1);
  padding: 20px;
  border-radius: 25px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  backface-visibility: hidden;
  max-width: calc(100% - 50px);
  text-align: center;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1), visibility 0.5s cubic-bezier(0.61, 1, 0.88, 1), transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
form:not(.guide-form):not(.form-styles) .form-success-msg.show-success {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%);
}
form:not(.guide-form):not(.form-styles) .form-rows-wrap {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 25px;
}
@media screen and (min-width: 1025px) {
  form:not(.guide-form):not(.form-styles) .form-rows-wrap {
    margin-left: -0.521vw;
    margin-right: -0.521vw;
    margin-bottom: 3.125vw;
  }
}
@media screen and (min-width: 1921px) {
  form:not(.guide-form):not(.form-styles) .form-rows-wrap {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 60px;
  }
}
form:not(.guide-form):not(.form-styles) .form-rows-wrap[data-cols] .form-row {
  width: 100%;
}
@media screen and (min-width: 1025px) {
  form:not(.guide-form):not(.form-styles) .form-rows-wrap[data-cols="2"] .form-row {
    margin: 0vw 0.521vw;
    width: calc(50% - 1.042vw);
  }
  form:not(.guide-form):not(.form-styles) .form-rows-wrap[data-cols="3"] .form-row {
    margin: 0vw 0.521vw;
    width: calc(33.33% - 1.042vw);
  }
}
@media screen and (min-width: 1921px) {
  form:not(.guide-form):not(.form-styles) .form-rows-wrap[data-cols="2"] .form-row {
    margin: 0 10px;
    width: calc(50% - 40px);
  }
  form:not(.guide-form):not(.form-styles) .form-rows-wrap[data-cols="3"] .form-row {
    margin: 0 10px;
    width: calc(33.33% - 40px);
  }
}
form:not(.guide-form):not(.form-styles) .form-label {
  line-height: 1;
  pointer-events: none;
  font-weight: 400;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  backface-visibility: hidden;
  transform-origin: 0% 50%;
  transition: top 0.25s cubic-bezier(0.61, 1, 0.88, 1), color 0.25s cubic-bezier(0.61, 1, 0.88, 1), transform 0.25s cubic-bezier(0.61, 1, 0.88, 1), opacity 0.25s cubic-bezier(0.61, 1, 0.88, 1);
  z-index: 0;
  left: 0;
  letter-spacing: 0.4px;
  font-size: 12px;
}
@media screen and (min-width: 1025px) {
  form:not(.guide-form):not(.form-styles) .form-label {
    letter-spacing: 0.021vw;
  }
}
@media screen and (min-width: 1921px) {
  form:not(.guide-form):not(.form-styles) .form-label {
    letter-spacing: 0.4px;
  }
}
form:not(.guide-form):not(.form-styles) .form-input {
  line-height: 1;
  font-weight: 700;
  color: #fff;
  border: none;
  width: 100%;
  background: transparent;
  position: relative;
  transition: color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  z-index: 1;
  padding: 17px 0;
  letter-spacing: 0.4px;
}
@media screen and (max-width: 550px) {
  form:not(.guide-form):not(.form-styles) .form-input {
    font-size: 4.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  form:not(.guide-form):not(.form-styles) .form-input {
    font-size: 21px;
  }
}
@media screen and (min-width: 1025px) {
  form:not(.guide-form):not(.form-styles) .form-input {
    font-size: 1.094vw;
    padding: 0.885vw 0vw;
    letter-spacing: 0.021vw;
  }
}
@media screen and (min-width: 1921px) {
  form:not(.guide-form):not(.form-styles) .form-input {
    font-size: 21px;
    padding: 17px 0;
    letter-spacing: 0.4px;
  }
}
form:not(.guide-form):not(.form-styles) .form-input[disabled] {
  cursor: not-allowed;
  color: #6f6f6f;
}
form:not(.guide-form):not(.form-styles) .form-input[disabled] ~ .form-label {
  color: #6f6f6f;
}
@media screen and (min-width: 1025px) {
  form:not(.guide-form):not(.form-styles) .message-row {
    margin-top: 4.375vw;
  }
  form:not(.guide-form):not(.form-styles) .message-row .form-label {
    top: 3px;
  }
}
@media screen and (min-width: 1921px) {
  form:not(.guide-form):not(.form-styles) .message-row {
    margin-top: 84px;
  }
}

.guide-form .contact-form-row {
  border: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
  transition: border 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.guide-form .contact-form-row.user-typing .form-label {
  transform: translateY(-100%);
  opacity: 0;
}
.guide-form .contact-form-row[data-error-txt]::after {
  content: attr(data-error-txt);
  line-height: 1;
  color: #fd8096;
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  top: calc(100% + 0.5em);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  font-size: 12px;
  letter-spacing: 0.3px;
  opacity: 0;
  left: 35px;
}
@media screen and (min-width: 1025px) {
  .guide-form .contact-form-row[data-error-txt]::after {
    font-size: 10px;
    left: 1.823vw;
  }
}
@media screen and (min-width: 1281px) {
  .guide-form .contact-form-row[data-error-txt]::after {
    font-size: 11px;
  }
}
@media screen and (min-width: 1441px) {
  .guide-form .contact-form-row[data-error-txt]::after {
    font-size: 12px;
  }
}
@media screen and (min-width: 1921px) {
  .guide-form .contact-form-row[data-error-txt]::after {
    left: 35px;
  }
}
.guide-form .form-input {
  color: #fff;
  line-height: 1;
  background-color: transparent;
  border: none;
  width: 100%;
  font-size: 18px;
  padding: 18px 15px;
}
@media screen and (min-width: 1025px) {
  .guide-form .form-input {
    font-size: 0.938vw;
    padding: 0.938vw 0.781vw;
  }
}
@media screen and (min-width: 1921px) {
  .guide-form .form-input {
    font-size: 18px;
    padding: 18px 15px;
  }
}
.guide-form .form-label {
  font-style: italic;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1;
  pointer-events: none;
  position: absolute;
  top: 50%;
  font-weight: 300;
  transform: translateY(-50%);
  left: 15px;
  font-size: 18px;
  letter-spacing: -0.2px;
}
@media screen and (min-width: 1025px) {
  .guide-form .form-label {
    left: 0.781vw;
    font-size: 0.938vw;
    letter-spacing: -0.01vw;
  }
}
@media screen and (min-width: 1921px) {
  .guide-form .form-label {
    left: 15px;
    font-size: 18px;
    letter-spacing: -0.2px;
  }
}
.guide-form.blog-newsletter-form {
  display: flex;
}
.guide-form.blog-newsletter-form .contact-form-row {
  width: 420px;
  border-radius: 50px;
  margin-right: 20px;
}
@media screen and (max-width: 767px) {
  .guide-form.blog-newsletter-form .contact-form-row {
    width: 100%;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 767px) {
  .guide-form.blog-newsletter-form {
    flex-direction: column;
  }
}
.guide-form.blog-newsletter-form input::placeholder {
  opacity: 1;
  font-style: normal;
}

[type=checkbox] {
  display: none;
}
[type=checkbox]:checked ~ .checkbox-label::after {
  opacity: 1;
}
[type=checkbox]:checked ~ .checkbox-label::before {
  border: 1px solid #17fcc4;
}

.checkbox-row {
  border: none;
  border-radius: inherit;
}
.checkbox-row.form-error .checkbox-label::before {
  border: 1px solid #fe0249;
}
.checkbox-row.form-error[data-error-txt]::after {
  opacity: 1;
}
.checkbox-row[data-error-txt]::after {
  left: 46px;
}
@media screen and (min-width: 1025px) {
  .checkbox-row[data-error-txt]::after {
    left: 2.396vw;
  }
}
@media screen and (min-width: 1921px) {
  .checkbox-row[data-error-txt]::after {
    left: 46px;
  }
}

.checkbox-label {
  cursor: pointer;
  color: #fff;
  line-height: 1.25;
  position: relative;
  display: inline-block;
  font-size: 20px;
  letter-spacing: 0.3px;
  padding: 9px 70px;
}
@media screen and (min-width: 1025px) {
  .checkbox-label {
    font-size: 1.042vw;
    letter-spacing: 0.016vw;
    padding: 0.469vw 3.646vw;
  }
}
@media screen and (min-width: 1921px) {
  .checkbox-label {
    font-size: 20px;
    letter-spacing: 0.3px;
    padding: 9px 70px;
  }
}
.checkbox-label::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  backface-visibility: hidden;
  transition: border 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  border: 1px solid #fff;
  border-radius: 10px;
  width: 38px;
  height: 38px;
}
@media screen and (min-width: 1025px) {
  .checkbox-label::before {
    border-radius: 0.521vw;
    width: 1.979vw;
    height: 1.979vw;
  }
}
@media screen and (min-width: 1921px) {
  .checkbox-label::before {
    border-radius: 10px;
    width: 38px;
    height: 38px;
  }
}
.checkbox-label::after {
  content: "";
  display: inline-block;
  position: absolute;
  transform: rotate(-45deg) translateY(-50%);
  top: calc(50% - 3px);
  border-bottom: 3px solid #17fcc4;
  border-left: 3px solid #17fcc4;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  left: 15px;
  width: 12px;
  height: 6px;
}
@media screen and (min-width: 1025px) {
  .checkbox-label::after {
    left: 0.781vw;
    width: 0.625vw;
    height: 0.313vw;
  }
}
@media screen and (min-width: 1921px) {
  .checkbox-label::after {
    left: 15px;
    width: 12px;
    height: 6px;
  }
}
.checkbox-label a {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: #fff;
}

.newsletter-form {
  position: relative;
}
.newsletter-form .form-input {
  font-weight: 300;
  font-size: 11px;
}
@media screen and (min-width: 400px) {
  .newsletter-form .form-input {
    font-size: 12px;
  }
}
@media screen and (min-width: 479px) {
  .newsletter-form .form-input {
    font-size: 13px;
  }
}
@media screen and (min-width: 640px) {
  .newsletter-form .form-input {
    font-size: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .newsletter-form .form-input {
    font-size: 12px;
  }
}
@media screen and (min-width: 1281px) {
  .newsletter-form .form-input {
    font-size: 13px;
  }
}
@media screen and (min-width: 1441px) {
  .newsletter-form .form-input {
    font-size: 14px;
  }
}
.newsletter-form .form-label {
  font-weight: 300;
  left: 3px;
  font-size: 11px;
}
@media screen and (min-width: 400px) {
  .newsletter-form .form-label {
    font-size: 12px;
  }
}
@media screen and (min-width: 479px) {
  .newsletter-form .form-label {
    font-size: 13px;
  }
}
@media screen and (min-width: 640px) {
  .newsletter-form .form-label {
    font-size: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .newsletter-form .form-label {
    font-size: 12px;
  }
}
@media screen and (min-width: 1281px) {
  .newsletter-form .form-label {
    font-size: 13px;
  }
}
@media screen and (min-width: 1441px) {
  .newsletter-form .form-label {
    font-size: 14px;
  }
}
.newsletter-form .green-arrow-submit-btn {
  position: absolute;
  backface-visibility: hidden;
  transform: translateY(-50%);
  top: 53%;
  z-index: 2;
  right: 4px;
  width: 23px;
  height: 19px;
}
.newsletter-form .form-row:not(.custom-select-row):not(.checkbox-row) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.form-styles.form-design-3 {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin: 0 0 -40px;
}
@media screen and (min-width: 1025px) {
  .form-styles.form-design-3 {
    margin: -1.042vw -1.146vw;
  }
}
@media screen and (min-width: 1921px) {
  .form-styles.form-design-3 {
    margin: -20px -22px;
  }
}
.form-styles.form-design-3 .form-row {
  flex: 0 0 auto;
  width: 100%;
  margin: 0 0 40px;
}
@media screen and (min-width: 1025px) {
  .form-styles.form-design-3 .form-row {
    width: calc(50% - 44px);
    margin: 1.042vw 1.146vw;
  }
}
@media screen and (min-width: 1921px) {
  .form-styles.form-design-3 .form-row {
    width: calc(50% - 44px);
    margin: 20px 22px;
  }
}
@media screen and (max-width: 550px) {
  .form-styles .form-styles-input-label-styles-standard, .form-styles .form-label, .form-styles .form-input {
    letter-spacing: 0.04vw;
    font-size: 3.6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .form-styles .form-styles-input-label-styles-standard, .form-styles .form-label, .form-styles .form-input {
    font-size: 18px;
    letter-spacing: 0.2px;
  }
}
@media screen and (min-width: 1025px) {
  .form-styles .form-styles-input-label-styles-standard, .form-styles .form-label, .form-styles .form-input {
    font-size: 0.938vw;
    letter-spacing: 0.01vw;
  }
}
@media screen and (min-width: 1921px) {
  .form-styles .form-styles-input-label-styles-standard, .form-styles .form-label, .form-styles .form-input {
    font-size: 18px;
    letter-spacing: 0.2px;
  }
}
.form-styles .form-input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid #6682ca;
  font-weight: 400;
  color: #fff;
  transition: border 0.5s cubic-bezier(0.61, 1, 0.88, 1), color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  padding: 13px;
}
.form-styles .form-input:focus, .form-styles .form-input:active {
  border: 1px solid #17fcc4;
}
@media screen and (min-width: 1025px) {
  .form-styles .form-input {
    padding: 0.677vw;
  }
}
@media screen and (min-width: 1921px) {
  .form-styles .form-input {
    padding: 13px;
  }
}
.form-styles .form-label {
  background-color: #002ea6;
  line-height: 1;
  pointer-events: none;
  font-weight: 300;
  color: #fff;
  position: absolute;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  transition: color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  z-index: 0;
  top: -12px;
  left: 22px;
  padding: 0 10px 0 13px;
}
@media screen and (min-width: 1025px) {
  .form-styles .form-label {
    top: -0.625vw;
    left: 1.146vw;
    padding: 0vw 0.521vw 0vw 0.677vw;
  }
}
@media screen and (min-width: 1921px) {
  .form-styles .form-label {
    top: -12px;
    left: 22px;
    padding: 0 10px 0 13px;
  }
}
.form-styles .button-row {
  width: 100%;
  text-align: center;
}
.form-styles .button-row.right-aligned {
  text-align: right;
}
@media screen and (min-width: 1025px) {
  .form-styles .button-row.right-aligned {
    margin-right: 20px;
    margin-top: 20px;
  }
}
.form-styles .form-row {
  position: relative;
}
.form-styles .form-row.user-typing .form-label {
  color: #17fcc4;
}
.form-styles .form-row.user-typing .form-input {
  border: 1px solid #17fcc4;
}
.form-styles .form-row.form-error .form-input {
  border: 1px solid #fd8096;
  color: #fd8096;
}
.form-styles .form-row.form-error .form-label {
  color: #fd8096;
}
.form-styles .form-row.form-error[data-error-txt]::after {
  opacity: 1;
}
.form-styles .form-row[data-error-txt]::after {
  content: attr(data-error-txt);
  line-height: 1;
  color: #fd8096;
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  top: calc(100% + 0.5em);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  font-size: 12px;
  letter-spacing: 0.3px;
  opacity: 0;
  left: 35px;
}
@media screen and (min-width: 1025px) {
  .form-styles .form-row[data-error-txt]::after {
    font-size: 10px;
    left: 1.823vw;
  }
}
@media screen and (min-width: 1281px) {
  .form-styles .form-row[data-error-txt]::after {
    font-size: 11px;
  }
}
@media screen and (min-width: 1441px) {
  .form-styles .form-row[data-error-txt]::after {
    font-size: 12px;
  }
}
@media screen and (min-width: 1921px) {
  .form-styles .form-row[data-error-txt]::after {
    left: 35px;
  }
}
.form-styles .form-success-msg {
  box-shadow: 0px 5px 24px 0px rgba(0, 46, 166, 0.1);
  padding: 20px;
  border-radius: 25px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  backface-visibility: hidden;
  max-width: calc(100% - 50px);
  text-align: center;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1), visibility 0.5s cubic-bezier(0.61, 1, 0.88, 1), transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.form-styles .form-success-msg.show-success {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%);
}

.upload-btn {
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .form-row.full-width-row {
    width: calc(100% - 44px) !important;
  }
}
@media screen and (min-width: 1441px) {
  .form-row.full-width-row textarea {
    height: 100px;
  }
}

.inline-form {
  border-radius: 40px;
  margin-top: 35px;
}

.ui-datepicker-calendar {
  font-size: 16px;
  background-color: #fff;
}
.ui-datepicker-calendar thead th {
  color: #002ea6;
}
.ui-datepicker-calendar td {
  color: #002ea6;
}

.ui-datepicker-month,
.ui-datepicker-year {
  font-size: 16px;
  color: #002ea6;
}

.contacts-tab-form input,
.contacts-tab-form select {
  background-color: transparent;
  border: 1px solid #6682ca;
  font-weight: 400;
  color: #fff;
  transition: border 0.5s cubic-bezier(0.61, 1, 0.88, 1), color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  color: #002ea6;
  padding: 13px;
  width: 100%;
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .contacts-tab-form input,
  .contacts-tab-form select {
    font-size: 21px;
  }
}
@media screen and (min-width: 1025px) {
  .contacts-tab-form input,
  .contacts-tab-form select {
    padding: 0.521vw;
  }
}
@media screen and (min-width: 1921px) {
  .contacts-tab-form input,
  .contacts-tab-form select {
    font-size: 21px;
    padding: 10px;
    letter-spacing: 0.4px;
  }
}
.contacts-tab-form input:focus, .contacts-tab-form input:active,
.contacts-tab-form select:focus,
.contacts-tab-form select:active {
  border: 1px solid #17fcc4;
}
.contacts-tab-form input[type=submit],
.contacts-tab-form select[type=submit] {
  background-color: #17fcc4;
  color: #002ea6;
  width: auto;
  height: auto;
  margin: 0;
  overflow: hidden;
  border-radius: 40px;
  z-index: 0;
  transition: transform 0.6s cubic-bezier(0.61, 1, 0.88, 1), background-color 0.6s cubic-bezier(0.61, 1, 0.88, 1);
  backface-visibility: hidden;
}
@media screen and (max-width: 550px) {
  .contacts-tab-form input[type=submit],
  .contacts-tab-form select[type=submit] {
    padding: 4.2vw 9vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .contacts-tab-form input[type=submit],
  .contacts-tab-form select[type=submit] {
    padding: 21px 45px;
    font-size: 18px;
  }
}
@media screen and (min-width: 1025px) {
  .contacts-tab-form input[type=submit],
  .contacts-tab-form select[type=submit] {
    padding: 1.094vw 2.344vw;
  }
}
@media screen and (min-width: 1921px) {
  .contacts-tab-form input[type=submit],
  .contacts-tab-form select[type=submit] {
    padding: 21px 45px;
    font-size: 18px;
  }
}
.contacts-tab-form input[type=submit]::before,
.contacts-tab-form select[type=submit]::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 130%;
  height: calc(100% + 10px);
  z-index: 0;
  transform-origin: 0 0;
  transform: translateX(100%) skewX(-45deg);
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1), background-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  backface-visibility: hidden;
}
.contacts-tab-form input[type=submit]:hover, .contacts-tab-form input[type=submit]:focus,
.contacts-tab-form select[type=submit]:hover,
.contacts-tab-form select[type=submit]:focus {
  background-color: #fff;
  transform: scale(1.05);
}
.contacts-tab-form input[type=submit]:hover::before, .contacts-tab-form input[type=submit]:focus::before,
.contacts-tab-form select[type=submit]:hover::before,
.contacts-tab-form select[type=submit]:focus::before {
  transform: translateX(0) skewX(-45deg) scale(1.05);
}
.contacts-tab-form select {
  padding: 13px 30px 13px 13px;
}
@media screen and (min-width: 1025px) {
  .contacts-tab-form select {
    padding: 0.521vw 1.563vw 0.521vw 0.521vw;
  }
}
@media screen and (min-width: 1921px) {
  .contacts-tab-form select {
    padding: 10px 30px 10px 10px;
  }
}
.contacts-tab-form textarea {
  width: 100%;
  border: 1px solid #6682ca;
  color: #002ea6;
}
.contacts-tab-form textarea:focus, .contacts-tab-form textarea:active {
  border: 1px solid #17fcc4;
}
@media screen and (max-width: 550px) {
  .contacts-tab-form textarea {
    height: 120px;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .contacts-tab-form textarea {
    font-size: 21px;
    height: 120px;
  }
}
@media screen and (min-width: 1025px) {
  .contacts-tab-form textarea {
    padding: 0.521vw;
    height: 100px;
  }
}
@media screen and (min-width: 1921px) {
  .contacts-tab-form textarea {
    font-size: 21px;
    padding: 10px;
    letter-spacing: 0.4px;
  }
}

::-webkit-file-upload-button {
  display: none;
}

.newsletter-form form {
  display: flex;
  margin-top: 39px;
  width: 100%;
}
.newsletter-form input {
  border: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
  transition: border 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  color: #fff;
  line-height: 30px;
  background-color: transparent;
  width: 100%;
  font-size: 18px;
  padding: 18px 15px;
}
.newsletter-form input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-style: normal;
  line-height: 30px;
  font-size: 18px;
  left: 15px;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.6px;
}
@media screen and (min-width: 1025px) {
  .newsletter-form input::placeholder {
    left: 0.781vw;
    letter-spacing: 0.6px;
  }
}
@media screen and (min-width: 1025px) {
  .newsletter-form input {
    padding: 0.938vw 0.781vw;
  }
}
@media screen and (min-width: 1921px) {
  .newsletter-form input {
    padding: 18px 15px;
  }
}
.newsletter-form input[type=submit] {
  background-color: #17fcc4;
  color: #002ea6;
  font-weight: 700;
  border-radius: 40px;
  margin-top: 20px;
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1), background-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
@media screen and (max-width: 550px) {
  .newsletter-form input[type=submit] {
    padding: 4.8vw 10.8vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .newsletter-form input[type=submit] {
    padding: 24px 54px;
  }
}
@media screen and (min-width: 1025px) {
  .newsletter-form input[type=submit] {
    padding: 1.25vw 2.813vw;
  }
}
@media screen and (min-width: 1921px) {
  .newsletter-form input[type=submit] {
    padding: 24px 54px;
  }
}
.newsletter-form input[type=submit]:hover, .newsletter-form input[type=submit]:focus {
  background-color: #fff;
  transform: scale(1.05);
  cursor: pointer;
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1), background-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}

.hsForm_97f7b5a5-d133-4b59-8d6e-8bac13ebce66 input::placeholder,
.hsForm_97f7b5a5-d133-4b59-8d6e-8bac13ebce66 textarea::placeholder,
.hsForm_b326454f-bfac-493a-a150-e350a093ec64 input::placeholder,
.hsForm_b326454f-bfac-493a-a150-e350a093ec64 textarea::placeholder,
.hsForm_901cd0db-667a-4cae-a3a3-d3f2fc3a166f input::placeholder,
.hsForm_901cd0db-667a-4cae-a3a3-d3f2fc3a166f textarea::placeholder,
.hsForm_c40aa767-76d5-44b2-a35d-9df6b69bd63b input::placeholder,
.hsForm_c40aa767-76d5-44b2-a35d-9df6b69bd63b textarea::placeholder,
.hs-form input::placeholder,
.hs-form textarea::placeholder {
  opacity: 0;
}

.callback-popup-form .mobile-contact-form .hs-form input::placeholder,
.callback-popup-form .mobile-contact-form .hs-form textarea::placeholder {
  opacity: 0 !important;
}

.home-page-form input::placeholder,
.home-page-form textarea::placeholder {
  opacity: 0 !important;
}
.home-page-form form {
  display: flex;
}
.home-page-form .message-input {
  width: 104%;
  display: block;
}
.home-page-form textarea {
  padding: 10px 15px 0 !important;
  line-height: 30px !important;
}
.home-page-form textarea::placeholder {
  line-height: 30px;
  font-weight: 400;
  color: #fff;
  backface-visibility: hidden;
  transform-origin: 0% 50%;
  transition: top 0.25s cubic-bezier(0.61, 1, 0.88, 1), color 0.25s cubic-bezier(0.61, 1, 0.88, 1), transform 0.25s cubic-bezier(0.61, 1, 0.88, 1), opacity 0.25s cubic-bezier(0.61, 1, 0.88, 1);
  z-index: 0;
  left: 0;
  letter-spacing: 0.4px;
  font-size: 16px;
}
.home-page-form input {
  height: 50px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  border: none;
  width: 100%;
  background: transparent;
  position: relative;
  transition: color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  z-index: 1;
  padding: 17px 0;
  letter-spacing: 0.4px;
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .home-page-form input {
    font-size: 21px;
  }
}
@media screen and (min-width: 1025px) {
  .home-page-form input {
    padding: 0.885vw 0vw;
  }
}
@media screen and (min-width: 1921px) {
  .home-page-form input {
    padding: 17px 0;
    letter-spacing: 0.4px;
  }
}
.home-page-form input[disabled] {
  cursor: not-allowed;
  color: #6f6f6f;
}
.home-page-form input::placeholder {
  line-height: 30px;
  font-weight: 400;
  color: #fff;
  backface-visibility: hidden;
  transform-origin: 0% 50%;
  transition: top 0.25s cubic-bezier(0.61, 1, 0.88, 1), color 0.25s cubic-bezier(0.61, 1, 0.88, 1), transform 0.25s cubic-bezier(0.61, 1, 0.88, 1), opacity 0.25s cubic-bezier(0.61, 1, 0.88, 1);
  z-index: 0;
  left: 0;
  letter-spacing: 0.4px;
  font-size: 16px;
}
@media screen and (min-width: 1025px) {
  .home-page-form input::placeholder {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 30px;
    top: 6px;
    padding: 0 15px;
    background: 0 0;
    backface-visibility: hidden;
    color: #fff;
    font-size: 16px !important;
    font-weight: 400;
    left: 0;
    letter-spacing: 0.4px;
    line-height: 30px;
    transform-origin: 0 50%;
    transition: top 0.25s cubic-bezier(0.61, 1, 0.88, 1), color 0.25s cubic-bezier(0.61, 1, 0.88, 1), transform 0.25s cubic-bezier(0.61, 1, 0.88, 1), opacity 0.25s cubic-bezier(0.61, 1, 0.88, 1);
    z-index: 0;
  }
}
@media screen and (min-width: 1025px) {
  .home-page-form input .message-input {
    margin-top: 4.375vw;
  }
  .home-page-form input .message-input .form-label {
    top: 3px;
  }
}
@media screen and (min-width: 1921px) {
  .home-page-form input .message-input {
    margin-top: 84px;
  }
}
.home-page-form input .message-input::placeholder {
  position: absolute;
  top: 20px;
}
.home-page-form input[type=submit] {
  background-color: #17fcc4;
  color: #002ea6;
  border-radius: 50%;
  font-size: 24px;
}
@media screen and (max-width: 550px) {
  .home-page-form input[type=submit] {
    width: 45.6vw;
    height: 45.6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .home-page-form input[type=submit] {
    width: 228px;
    height: 228px;
  }
}
@media screen and (min-width: 1025px) {
  .home-page-form input[type=submit] {
    width: 11.875vw;
    height: 11.875vw;
  }
}
@media screen and (min-width: 1921px) {
  .home-page-form input[type=submit] {
    width: 228px;
    height: 228px;
  }
}
.home-page-form input[type=submit]:hover, .home-page-form input[type=submit]:focus {
  cursor: pointer;
}
@media screen and (min-width: 1025px) {
  .home-page-form label {
    transition: opacity 0.1 cubic-bezier(0.61, 1, 0.88, 1) !important;
  }
  .home-page-form label.filled {
    opacity: 0 !important;
  }
}

.equipment-form .confirmation-body h2,
.equipment-form .confirmation-body .h2,
.equipment-form .confirmation-body h3,
.equipment-form .confirmation-body .h3 {
  color: #002ea6 !important;
}
.equipment-form .confirmation-body .survey-results p {
  color: #002ea6 !important;
}
.equipment-form .confirmation-body .confirmation-footer {
  margin-top: 0px;
}
@media screen and (min-width: 1025px) {
  .equipment-form .confirmation-body .confirmation-footer {
    margin-top: 2.604vw;
  }
}
.equipment-form .confirmation-body .confirmation-footer .btn-txt {
  color: #002ea6 !important;
}
.equipment-form legend {
  color: #002ea6;
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 50px;
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .equipment-form legend {
    font-size: 48px;
  }
}
@media screen and (min-width: 1025px) {
  .equipment-form legend {
    margin-bottom: 6.25vw;
  }
}
@media screen and (min-width: 1921px) {
  .equipment-form legend {
    font-size: 48px;
    margin-bottom: 120px;
  }
}

.first-footer form {
  display: flex;
  margin-top: 26px;
}
.first-footer input {
  background-color: transparent;
  border: 1px solid #6682ca;
  font-weight: 400;
  color: #fff;
  transition: border 0.5s cubic-bezier(0.61, 1, 0.88, 1), color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  color: #fff;
  padding: 13px;
  width: 100%;
}
@media screen and (max-width: 380px) {
  .first-footer input {
    padding: 12px;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .first-footer input {
    font-size: 21px;
  }
}
@media screen and (min-width: 1025px) {
  .first-footer input {
    padding: 0.521vw;
  }
}
@media screen and (min-width: 1921px) {
  .first-footer input {
    font-size: 21px;
    padding: 10px;
    letter-spacing: 0.4px;
  }
}
.first-footer input:focus, .first-footer input:active {
  border: 1px solid #17fcc4;
}
.first-footer input[type=submit] {
  background-color: #17fcc4;
  color: #002ea6;
  font-weight: 700;
  width: auto;
  height: auto;
  margin: 0;
  overflow: hidden;
  border-radius: 40px;
  z-index: 0;
  transition: transform 0.6s cubic-bezier(0.61, 1, 0.88, 1), background-color 0.6s cubic-bezier(0.61, 1, 0.88, 1);
  backface-visibility: hidden;
}
@media screen and (max-width: 550px) {
  .first-footer input[type=submit] {
    padding: 4.2vw 9vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .first-footer input[type=submit] {
    padding: 21px 45px;
    font-size: 18px;
  }
}
@media screen and (min-width: 1025px) {
  .first-footer input[type=submit] {
    padding: 0.938vw 2.344vw;
    margin-top: 26px;
  }
}
@media screen and (min-width: 1921px) {
  .first-footer input[type=submit] {
    padding: 18px 45px;
    font-size: 18px;
    margin-top: 26px;
  }
}
.first-footer input[type=submit]::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 130%;
  height: calc(100% + 10px);
  z-index: 0;
  transform-origin: 0 0;
  transform: translateX(100%) skewX(-45deg);
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1), background-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  backface-visibility: hidden;
}
.first-footer input[type=submit]:hover, .first-footer input[type=submit]:focus {
  background-color: #fff;
  transform: scale(1.05);
}
.first-footer input[type=submit]:hover::before, .first-footer input[type=submit]:focus::before {
  transform: translateX(0) skewX(-45deg) scale(1.05);
}

.blog-subscribe-form form {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
@media screen and (max-width: 1024px) {
  .blog-subscribe-form form {
    flex-direction: column;
    align-items: left;
  }
}
@media screen and (max-width: 767px) {
  .blog-subscribe-form form {
    flex-direction: column;
    align-items: center;
  }
}
.blog-subscribe-form form input[type=email] {
  color: #fff;
  line-height: 30px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  width: 100%;
  font-size: 18px;
  padding: 18px 15px;
  width: 420px;
}
@media screen and (max-width: 767px) {
  .blog-subscribe-form form input[type=email] {
    width: 100%;
    margin-bottom: 10px;
    padding: 15px;
  }
}
@media screen and (min-width: 1025px) {
  .blog-subscribe-form form input[type=email] {
    padding: 0.938vw 0.781vw;
    margin-right: 15px;
  }
}
@media screen and (min-width: 1921px) {
  .blog-subscribe-form form input[type=email] {
    font-size: 18px;
    padding: 18px 15px;
  }
}
.blog-subscribe-form form input[type=email]::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-style: normal;
  opacity: 1;
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .blog-subscribe-form form input[type=email]::placeholder {
    font-size: 18px;
  }
}
@media screen and (min-width: 1921px) {
  .blog-subscribe-form form input[type=email]::placeholder {
    font-size: 18px;
  }
}
.blog-subscribe-form form input[type=submit] {
  background-color: #17fcc4;
  color: #002ea6;
  border-radius: 50px;
  font-weight: 700;
  border: none;
  padding: 20px 20px;
  transition: background-color 0.2s cubic-bezier(0.61, 1, 0.88, 1), color 0.2s cubic-bezier(0.61, 1, 0.88, 1);
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .blog-subscribe-form form input[type=submit] {
    font-size: 18px;
  }
}
@media screen and (min-width: 1025px) {
  .blog-subscribe-form form input[type=submit] {
    padding: 1.042vw 1.302vw;
  }
}
@media screen and (max-width: 1024px) {
  .blog-subscribe-form form input[type=submit] {
    display: block;
    margin-top: 15px;
  }
}
@media screen and (min-width: 1921px) {
  .blog-subscribe-form form input[type=submit] {
    font-size: 18px;
    padding: 20px 25px;
  }
}
.blog-subscribe-form form input[type=submit]:hover, .blog-subscribe-form form input[type=submit]:focus {
  cursor: pointer;
  background-color: #fff;
  color: #002ea6;
}

.full-width-form-row {
  width: 100% !important;
  display: block;
}

.article-content .hbspt-form .hs-form-field,
.sk-lander .hbspt-form .hs-form-field {
  position: relative;
  margin-bottom: 30px !important;
}
.article-content .hbspt-form .hs-form-field label:not(.hs-form-checkbox-display):not(.hs-error-msg),
.sk-lander .hbspt-form .hs-form-field label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
  position: absolute;
  z-index: 3;
  top: -12px;
  left: 22px;
  color: #fff;
  background-color: #002ea6;
  padding: 0 10px 0 10px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.6px;
  font-family: "Libre Franklin", sans-serif;
}
@media screen and (max-width: 1024px) {
  .article-content .hbspt-form .hs-form-field label:not(.hs-form-checkbox-display):not(.hs-error-msg),
  .sk-lander .hbspt-form .hs-form-field label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
    position: relative;
    top: 0;
    left: 0;
    background-color: transparent;
    display: block;
    margin-bottom: 8px;
  }
}
@media screen and (min-width: 1025px) {
  .article-content .hbspt-form .hs-form-field label:not(.hs-form-checkbox-display):not(.hs-error-msg),
  .sk-lander .hbspt-form .hs-form-field label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
    top: -0.625vw;
    left: 1.146vw;
    padding: 0vw 0.521vw 0vw 0.521vw;
    letter-spacing: 0.6px;
  }
}
@media screen and (min-width: 1921px) {
  .article-content .hbspt-form .hs-form-field label:not(.hs-form-checkbox-display):not(.hs-error-msg),
  .sk-lander .hbspt-form .hs-form-field label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
    top: -12px;
    left: 22px;
    padding: 0 10px 0 10px;
    letter-spacing: 0.6px;
  }
}
.article-content .hbspt-form .hs-form-field label.hs-form-booleancheckbox-display,
.sk-lander .hbspt-form .hs-form-field label.hs-form-booleancheckbox-display {
  position: relative !important;
  top: auto !important;
  left: auto !important;
  padding: 0 !important;
  margin-bottom: 5px;
}

.landing-page-form legend {
  width: fit-content;
}
.landing-page-form input,
.landing-page-form select {
  background-color: transparent;
  border: 1px solid #6682ca;
  font-weight: 400;
  color: #fff;
  font-size: 18px !important;
  line-height: 30px !important;
  transition: border 0.5s cubic-bezier(0.61, 1, 0.88, 1), color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  width: 310px;
  font-family: "Libre Franklin", sans-serif;
}
.landing-page-form input option,
.landing-page-form select option {
  font-family: "Libre Franklin", sans-serif;
  font-size: 18px !important;
  line-height: 30px !important;
}
@media screen and (max-width: 1024px) {
  .landing-page-form input,
  .landing-page-form select {
    padding: 5px;
    width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .landing-page-form input,
  .landing-page-form select {
    padding: 0.521vw;
  }
}
@media screen and (min-width: 1921px) {
  .landing-page-form input,
  .landing-page-form select {
    padding: 13px;
    letter-spacing: 0.4px;
  }
}
.landing-page-form input[type=submit],
.landing-page-form select[type=submit] {
  background-color: #17fcc4;
  color: #002ea6;
  width: auto;
  height: auto;
  margin: 0;
  overflow: hidden;
  border-radius: 40px;
  z-index: 0;
  transition: transform 0.6s cubic-bezier(0.61, 1, 0.88, 1), background-color 0.6s cubic-bezier(0.61, 1, 0.88, 1);
  backface-visibility: hidden;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 20px !important;
  font-family: "Red Hat Display", sans-serif !important;
}
@media screen and (max-width: 550px) {
  .landing-page-form input[type=submit],
  .landing-page-form select[type=submit] {
    padding: 4.2vw 9vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .landing-page-form input[type=submit],
  .landing-page-form select[type=submit] {
    padding: 21px 45px;
    font-size: 18px;
  }
}
@media screen and (min-width: 1025px) {
  .landing-page-form input[type=submit],
  .landing-page-form select[type=submit] {
    padding: 1.094vw 2.344vw;
  }
}
.landing-page-form input[type=submit]::before,
.landing-page-form select[type=submit]::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 130%;
  height: calc(100% + 10px);
  z-index: 0;
  transform-origin: 0 0;
  transform: translateX(100%) skewX(-45deg);
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1), background-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  backface-visibility: hidden;
}
.landing-page-form input[type=submit]:hover,
.landing-page-form select[type=submit]:hover {
  background-color: #fff;
  transform: scale(1.05);
}
.landing-page-form input[type=submit]:hover::before,
.landing-page-form select[type=submit]:hover::before {
  transform: translateX(0) skewX(-45deg) scale(1.05);
}
.landing-page-form input option,
.landing-page-form select option {
  color: #002ea6;
}
.landing-page-form select {
  width: 100%;
}
.landing-page-form .datepicker::placeholder {
  color: #fff;
  opacity: 1;
}
.landing-page-form input[type=number]::placeholder {
  color: #fff;
  opacity: 1;
}
.landing-page-form .hour_minute_colon {
  font-size: 16px;
}

fieldset {
  border: none;
}

.confirmation-body .survey-results {
  display: flex;
  font-size: 20px;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .confirmation-body .survey-results {
    flex-direction: column;
  }
}
.confirmation-body .survey-results .survey-res-heading {
  font-weight: 700;
  margin-bottom: 15px;
}
.confirmation-body .survey-results .survey-txt {
  line-height: 1.5;
}
.confirmation-body .survey-results .res-column {
  width: 100%;
  display: grid;
}
@media screen and (max-width: 1024px) {
  .confirmation-body .survey-results .res-column {
    grid-template-rows: auto 100px 1fr;
  }
}
@media screen and (min-width: 1025px) {
  .confirmation-body .survey-results .res-column {
    width: calc(32.33% - 2.084vw);
  }
}
@media screen and (min-width: 1921px) {
  .confirmation-body .survey-results .res-column {
    width: calc(32.33% - 40px);
  }
}
@media screen and (max-width: 767px) {
  .confirmation-body .survey-results .res-column {
    margin-bottom: 30px;
  }
}
.confirmation-body .survey-results .res-column.equipment-res {
  width: 100%;
}
.confirmation-body .survey-results .res-column.equipment-res .res-images img {
  max-width: 200px;
}
@media screen and (max-width: 1024px) {
  .confirmation-body .survey-results .res-column.equipment-res .res-images img {
    display: block;
    margin-right: 0;
  }
}
@media screen and (min-width: 1025px) {
  .confirmation-body .survey-results .res-column.equipment-res .res-images img {
    display: inline-block;
    margin-right: 30px;
  }
}
.confirmation-body .survey-results .res-images {
  margin-top: 20px;
}
@media screen and (min-width: 1025px) {
  .confirmation-body .survey-results .res-images {
    margin-top: 40px;
  }
}
.confirmation-body .survey-results .res-images.single-images img {
  display: block;
  margin-bottom: 20px;
}
@media screen and (min-width: 1025px) {
  .confirmation-body .survey-results .res-images.single-images img {
    margin-bottom: 30px;
  }
}
.confirmation-body .survey-results .res-img-text {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media screen and (min-width: 1025px) {
  .confirmation-body .survey-results .res-img-text {
    margin-bottom: 30px;
  }
}
.confirmation-body .survey-results .res-img-text img {
  margin-right: 20px;
}
.confirmation-body .survey-results .res-img-text p {
  text-transform: uppercase;
  font-weight: 700;
}
.confirmation-body .query-button-next .scroll-btn-img {
  transform: rotate(90deg) !important;
  top: 28%;
  left: 36%;
}
.confirmation-body .query-button-next .btn-txt {
  right: -88%;
}

.confirmation-footer {
  margin-top: 9.375vw;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .confirmation-footer {
    flex-direction: column;
  }
}
.confirmation-footer .cta-back {
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .confirmation-footer .cta-back {
    width: 35%;
  }
}
@media screen and (max-width: 767px) {
  .confirmation-footer .cta-back {
    margin-bottom: 20px;
  }
}

.header-open-form-btn,
.header-cta-btn {
  opacity: 0;
  transition: opacity 0.7s ease;
}
.header-open-form-btn.revealed,
.header-cta-btn.revealed {
  opacity: 1;
  transition: opacity 0.7s ease;
}
.header-open-form-btn:hover,
.header-cta-btn:hover {
  cursor: pointer;
}
.header-open-form-btn.side-menu-quiz-btn,
.header-cta-btn.side-menu-quiz-btn {
  opacity: 1;
}

.landing-header-open-form-btn,
.landing-header-cta-btn {
  opacity: 0;
  transition: opacity 0.7s ease;
}
.landing-header-open-form-btn.revealed,
.landing-header-cta-btn.revealed {
  opacity: 1;
  transition: opacity 0.7s ease;
}
.landing-header-open-form-btn:hover,
.landing-header-cta-btn:hover {
  cursor: pointer;
}
.landing-header-open-form-btn.side-menu-quiz-btn,
.landing-header-cta-btn.side-menu-quiz-btn {
  opacity: 1;
}

.header-landing {
  z-index: 1025;
  background-color: #fff;
}
@media screen and (max-width: 1024px) {
  .header-landing {
    position: fixed;
  }
}
.header-landing .content-wrap {
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 550px) {
  .header-landing .landing-template-btn.btn-offset-10 {
    padding: 0;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .header-landing .landing-template-btn.btn-offset-10 {
    padding: 0;
  }
}
.header-landing .landing-template-btn.btn-in-menu .btn-txt {
  background-color: #17fcc4;
}
@media screen and (max-width: 550px) {
  .header-landing .landing-template-btn.btn-in-menu .btn-txt {
    padding: 4.2vw 4vw;
    border-radius: 40px;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .header-landing .landing-template-btn.btn-in-menu .btn-txt {
    padding: 21px 20px;
    border-radius: 40px;
  }
}
@media screen and (min-width: 1025px) {
  .header-landing .landing-template-btn {
    margin-right: -5.573vw;
  }
}
@media screen and (min-width: 1921px) {
  .header-landing .landing-template-btn {
    margin-right: -107px;
  }
}
.header-landing .header-links-wrap {
  padding-top: 0;
}
.header-landing .mobile-menu-btn {
  border: 1px solid #002ea6;
}
@media screen and (max-width: 1024px) {
  .header-landing .header-links-wrap {
    margin-top: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .header-landing .header-links-wrap .btn-default {
    width: 100%;
    max-width: 491px;
    padding: 21px 51px;
  }
}
.header-landing .line-el {
  background-color: #002ea6;
}
.header-landing .logo-anim-white {
  opacity: 0 !important;
}
.header-landing .logo-anim-blue {
  opacity: 1 !important;
}
.header-landing .header-logo-white {
  opacity: 0 !important;
}
.header-landing .header-logo-blue {
  opacity: 1 !important;
}
.header-landing .non-linked-logo-header {
  pointer-events: none;
}
.header-landing .cta-text {
  position: relative;
  align-self: center;
}
.header-landing .cta-text a,
.header-landing .cta-text p {
  font-family: "Red Hat Display", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: #002ea6;
}

.admin-bar .header {
  padding-top: 32px;
}

.header .logo-on-scroll {
  opacity: 0 !important;
}

html.header-toggled-state .header .logo-anim-white.logo-on-scroll {
  opacity: 1 !important;
}

html.header-toggled-state .header .logo-no-scroll {
  opacity: 0 !important;
}

html.header-toggled-state.header-opened-dropdown .header .logo-no-scroll,
html.header-toggled-state.mobile-menu-opened .header .logo-no-scroll {
  opacity: 0 !important;
}

html.header-toggled-state.header-opened-dropdown .header .logo-anim-blue.logo-on-scroll,
html.header-toggled-state.mobile-menu-opened .header .logo-anim-blue.logo-on-scroll {
  opacity: 1 !important;
}

html {
  margin-top: 0 !important;
}
@media screen and (min-width: 1025px) {
  html:not(.header-background-color) .header:not(.header-landing) {
    background-color: #002ea6;
  }
}
@media screen and (min-width: 1025px) {
  html.header-offset-homepage .hero-section {
    padding-top: 13.438vw;
  }
}
@media screen and (min-width: 1921px) {
  html.header-offset-homepage .hero-section {
    padding-top: 258px;
  }
}
@media screen and (min-width: 1025px) {
  html.header-offset-homepage #main-content {
    padding-top: 0;
  }
}
html .landing-template-btn.revealed .btn-bg-el {
  background-color: #17fcc4;
}
html .landing-template-btn.revealed .btn-bg-el::before {
  background-color: #fff;
}
html .landing-template-btn.revealed:hover, html .landing-template-btn.revealed:focus {
  color: #002ea6;
}
html.header-toggled-state.skp-sc header {
  position: fixed;
}
@media screen and (max-width: 1024px) {
  html.header-toggled-state .header-landing .content-wrap::before {
    background-color: #002ea6;
  }
}
@media screen and (max-width: 550px) {
  html.header-toggled-state header .content-wrap {
    padding-bottom: 1vw;
  }
}
@media screen and (max-width: 1024px) {
  html.header-toggled-state header .content-wrap::before {
    background-color: #002ea6;
  }
  html.header-toggled-state header .mobile-menu-btn {
    border: 1px solid #fff;
  }
  html.header-toggled-state header .line-el {
    background-color: #fff;
  }
}
@media screen and (min-width: 1025px) {
  html.header-toggled-state header {
    background-color: #002ea6;
    top: 0;
  }
  html.header-toggled-state header .dropdown-item-links {
    border-radius: 0px 0px 30px 30px;
  }
}
html.header-toggled-state header .btn-default.btn-green-1.landing-template-btn .btn-bg-el::before {
  background-color: #fff;
}
html.header-toggled-state header .btn-default.btn-green-1.landing-template-btn:hover, html.header-toggled-state header .btn-default.btn-green-1.landing-template-btn:focus {
  color: #002ea6;
}
html.header-toggled-state header .logo-anim-white {
  opacity: 1;
}
html.header-toggled-state header .logo-anim-blue {
  opacity: 0;
}
html.header-toggled-state header .header-logo-white {
  opacity: 1;
}
html.header-toggled-state header .header-logo-blue {
  opacity: 0;
}
@media screen and (min-width: 1025px) {
  html.header-toggled-state header .lottie-anim-logo {
    width: 9.896vw;
  }
}
@media screen and (min-width: 1921px) {
  html.header-toggled-state header .lottie-anim-logo {
    width: 190px;
  }
}
@media screen and (min-width: 1025px) {
  html.header-toggled-state header .header-logo-link {
    margin-top: 15px;
    width: 7.917vw;
    height: 2.656vw;
  }
}
@media screen and (min-width: 1921px) {
  html.header-toggled-state header .header-logo-link {
    margin-top: 15px;
    width: 152px;
    height: 51px;
  }
}
@media screen and (min-width: 1921px) {
  html.header-toggled-state header .content-wrap {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 1025px) {
  html.header-toggled-state header .statement-analysis-btn {
    margin-top: 0;
  }
  html.header-toggled-state header .header-links-wrap {
    margin-top: 0;
  }
}
html.mobile-menu-opened {
  overflow: hidden;
}
html.mobile-menu-opened .header-landing .content-wrap::before {
  background-color: #fff;
}
html.mobile-menu-opened header .content-wrap::before {
  background-color: #fff;
}
html.mobile-menu-opened header .header-links-wrap {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
}
html.mobile-menu-opened header .header-links-content-wrap {
  opacity: 1;
  transition-delay: 0.15s;
}
html.mobile-menu-opened header .logo-anim-white {
  opacity: 0;
}
html.mobile-menu-opened header .logo-anim-blue {
  opacity: 1;
}
html.mobile-menu-opened header .header-logo-white {
  opacity: 0;
}
html.mobile-menu-opened header .header-logo-blue {
  opacity: 1;
}
html.mobile-menu-opened header .mobile-menu-btn {
  border: 1px solid #002ea6;
}
html.mobile-menu-opened header .line-el {
  background-color: #002ea6;
}
html.header-opened-dropdown .sk-sticky {
  z-index: 0;
}
html.header-opened-dropdown header .statement-analysis-btn .btn-bg-el {
  border: 1px solid #002ea6;
}
html.header-opened-dropdown header .statement-analysis-btn.btn-default.btn-white.section-color-blue .btn-bg-el::before {
  background-color: #002ea6;
}
html.header-opened-dropdown header .statement-analysis-btn:hover, html.header-opened-dropdown header .statement-analysis-btn:focus {
  color: #fff !important;
}
html.header-opened-dropdown header .header-links-content-wrap:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #dddddd;
  backface-visibility: hidden;
}
html.header-opened-dropdown header .header-link-item.opened-dropdown .dropdown-arrow-btn,
html.header-opened-dropdown header .dropdown-item-wrap.opened-dropdown .dropdown-arrow-btn {
  transform: translateY(-40%) rotate(-180deg);
}
html.header-opened-dropdown header .header-link-item:hover, html.header-opened-dropdown header .header-link-item:focus,
html.header-opened-dropdown header .dropdown-item-wrap:hover,
html.header-opened-dropdown header .dropdown-item-wrap:focus {
  color: #ff034a;
}
html.header-opened-dropdown header .header-link-item:hover .dropdown-arrow-btn::before, html.header-opened-dropdown header .header-link-item:focus .dropdown-arrow-btn::before,
html.header-opened-dropdown header .dropdown-item-wrap:hover .dropdown-arrow-btn::before,
html.header-opened-dropdown header .dropdown-item-wrap:focus .dropdown-arrow-btn::before {
  border-color: #ff034a;
}
html.header-opened-dropdown header .header-link-item:hover .dropdown-item-links, html.header-opened-dropdown header .header-link-item:focus .dropdown-item-links,
html.header-opened-dropdown header .dropdown-item-wrap:hover .dropdown-item-links,
html.header-opened-dropdown header .dropdown-item-wrap:focus .dropdown-item-links {
  opacity: 1;
  visibility: visible;
}
html.header-opened-dropdown header .statement-analysis-btn::before {
  border: 1px solid #002ea6;
}
html.header-opened-dropdown header .logo-anim-white {
  opacity: 0;
}
html.header-opened-dropdown header .logo-anim-blue {
  opacity: 1;
}
html.header-opened-dropdown header .header-logo-white {
  opacity: 0;
}
html.header-opened-dropdown header .header-logo-blue {
  opacity: 1;
}

.header-toggled-state .header {
  position: fixed;
}
.header-toggled-state .header .cta-text p {
  color: #fff;
}

.mobile-menu-opened .header,
.home .header,
.page-home .header {
  position: fixed;
}

.header {
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
}
@media screen and (max-width: 1024px) {
  .header.header-landing .content-wrap::before {
    background-color: #fff;
  }
}
.header .content-wrap {
  display: flex;
  transition: background-color 0.3s cubic-bezier(0.61, 1, 0.88, 1), padding-top 0.3s cubic-bezier(0.61, 1, 0.88, 1), padding-bottom 0.3s cubic-bezier(0.61, 1, 0.88, 1);
}
@media screen and (min-width: 1250px) {
  .header .content-wrap {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1249px) {
  .header .content-wrap {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .header .content-wrap {
    padding-right: 15px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 1024px) {
  .header .content-wrap {
    justify-content: space-between;
    align-items: center;
  }
  .header .content-wrap::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #002ea6;
    z-index: 1;
    transition: background-color 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  }
}
@media screen and (max-width: 550px) {
  .header .content-wrap {
    padding-top: 1vw;
    padding-bottom: 3vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .header .content-wrap {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 1025px) {
  .header .content-wrap {
    align-items: flex-start;
    border-radius: 30px;
    padding-top: 1.667vw;
    padding-bottom: 1.458vw;
  }
}
@media screen and (min-width: 1921px) {
  .header .content-wrap {
    padding-top: 32px;
    padding-bottom: 28px;
  }
}
.header .lottie-anim-logo {
  display: inline-block;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
}
@media screen and (max-width: 550px) {
  .header .lottie-anim-logo {
    left: -2vw;
    width: 38vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .header .lottie-anim-logo {
    left: -23px;
    width: 190px;
  }
}
@media screen and (min-width: 1025px) {
  .header .lottie-anim-logo {
    left: -1.667vw;
    width: 14.115vw;
  }
}
@media screen and (min-width: 1921px) {
  .header .lottie-anim-logo {
    left: -32px;
    width: 271px;
  }
}
.header .header-logo-link {
  flex: 0 0 auto;
  margin-top: 15px;
  position: relative;
  z-index: 2;
  transition: height 0.3s cubic-bezier(0.61, 1, 0.88, 1), opacity 0.1s cubic-bezier(0.61, 1, 0.88, 1), width 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 101%, rgba(0, 0, 0, 0.01) 102%);
}
.header .header-logo-link:hover .lottie-anim-logo, .header .header-logo-link:focus .lottie-anim-logo {
  transform: scale(1.05) translateY(-50%);
}
@media screen and (max-width: 550px) {
  .header .header-logo-link {
    width: 39.4vw;
    height: 13.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .header .header-logo-link {
    width: 197px;
    height: 66px;
  }
}
@media screen and (min-width: 1025px) {
  .header .header-logo-link {
    margin-top: 0.313vw;
    width: 11.302vw;
    height: 3.802vw;
  }
}
@media screen and (min-width: 1921px) {
  .header .header-logo-link {
    margin-top: 6px;
    width: 217px;
    height: 73px;
  }
}
.header .header-logo-link.lottie-anim-init .header-logo-img {
  opacity: 0;
  visibility: hidden;
}
.header .lottie-anim-logo {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.61, 1, 0.88, 1), width 0.3s cubic-bezier(0.61, 1, 0.88, 1), margin-top 0.3s cubic-bezier(0.61, 1, 0.88, 1), transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.header .logo-anim-white {
  opacity: 1;
}
.header .logo-anim-blue {
  opacity: 0;
}
.header .header-logo-white {
  opacity: 1;
}
.header .header-logo-blue {
  opacity: 0;
}
.header .header-logo-img {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.61, 1, 0.88, 1);
}
.header .header-logo-white {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: max-width 0.3s cubic-bezier(0.61, 1, 0.88, 1), width 0.3s cubic-bezier(0.61, 1, 0.88, 1), margin-top 0.3s cubic-bezier(0.61, 1, 0.88, 1), transform 0.3s cubic-bezier(0.61, 1, 0.88, 1);
}
.header .statement-analysis-btn {
  flex: 0 0 auto;
  transition: margin-top 0.3s cubic-bezier(0.61, 1, 0.88, 1), color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
@media screen and (max-width: 1024px) {
  .header .statement-analysis-btn {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .header .statement-analysis-btn {
    margin-top: 0.625vw;
    margin-right: -0.833vw;
  }
  .header .statement-analysis-btn:hover, .header .statement-analysis-btn:focus {
    color: #002ea6;
  }
  .header .statement-analysis-btn:hover::before, .header .statement-analysis-btn:focus::before {
    background-color: #17fcc4;
  }
}
@media screen and (min-width: 1921px) {
  .header .statement-analysis-btn {
    margin-top: 12px;
    margin-right: -16px;
  }
}
.header .header-links-wrap {
  flex: 0 1 auto;
  transition: margin-top 0.3s cubic-bezier(0.61, 1, 0.88, 1);
}
@media screen and (max-width: 500px) {
  .header .header-links-wrap.offset-x-mobile-47 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header .header-links-wrap.offset-x-mobile-47 .dropdown-items-links-hold {
    padding-top: 20px;
  }
  .header .header-links-wrap.offset-x-mobile-47 .dropdown-items-links-hold .dropdown-items-link.icon-link {
    padding-left: 37px;
    padding-top: 15px;
  }
  .header .header-links-wrap.offset-x-mobile-47 .dropdown-items-links-hold .dropdown-links-wrap {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .header .header-links-wrap {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
    position: absolute;
    width: 100vw;
    height: 100%;
    max-height: 100vh;
    max-height: calc(100vh - 175px);
    top: 0;
    z-index: 0;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 140px;
    padding-bottom: 0;
    transition: opacity 0.3s cubic-bezier(0.61, 1, 0.88, 1), visibility 0.3s cubic-bezier(0.61, 1, 0.88, 1), transform 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  }
  .header .header-links-wrap .statement-analysis-btn {
    display: inline-block;
    margin-top: 25px;
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .header .header-links-wrap {
    box-shadow: 4px 5px 3px rgba(172, 172, 172, 0.431372549);
  }
}
@media screen and (min-width: 1025px) {
  .header .header-links-wrap {
    margin-left: auto;
    margin-top: 0.729vw;
    margin-right: 1.302vw;
    padding-left: 2.083vw;
    padding-right: 0.729vw;
  }
  .header .header-links-wrap .statement-analysis-btn {
    display: none;
  }
}
@media screen and (min-width: 1921px) {
  .header .header-links-wrap {
    margin-top: 14px;
    margin-right: 25px;
    padding-left: 40px;
    padding-right: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .header .filler-item-link.dropdown-items-link {
    display: none;
  }
}
.header .header-links-content-wrap {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .header .header-links-content-wrap {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  }
}
@media screen and (min-width: 1025px) {
  .header .header-links-content-wrap {
    flex-flow: row nowrap;
    align-items: center;
    margin: 0vw -1.25vw;
  }
}
@media screen and (min-width: 1921px) {
  .header .header-links-content-wrap {
    margin: 0 -24px;
  }
}
.header .dropdown-item-wrap.opened .dropdown-arrow-btn {
  transform: translateY(-40%) rotate(-180deg);
}
@media screen and (max-width: 1024px) {
  .header .dropdown-item-wrap {
    overflow: unset;
  }
  .header .dropdown-item-wrap.opened {
    overflow-y: auto;
  }
}
.header .header-bg-el {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: background-color 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  background-color: #fff;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 5px 24px 0px rgba(0, 46, 166, 0.1);
  transition: border-radius 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  opacity: 0;
}
.header .dropdown-item-links {
  height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}
@media screen and (min-width: 1025px) {
  .header .dropdown-item-links {
    left: 0;
    position: absolute;
    width: max-content;
    top: 20px;
  }
}
.header .dropdown-items-link {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 25px;
  transition: color 0.3s cubic-bezier(0.61, 1, 0.88, 1);
}
@media screen and (max-width: 1024px) {
  .header .dropdown-items-link {
    font-size: 16px;
  }
}
@media screen and (min-width: 1025px) {
  .header .dropdown-items-link {
    width: calc(25% - 1.042vw);
    margin: 0.573vw 0.521vw;
    padding: 0.26vw 0vw;
  }
}
@media screen and (min-width: 1921px) {
  .header .dropdown-items-link {
    width: calc(25% - 20px);
    margin: 11px 10px;
    padding: 5px 0;
  }
}
.header .dropdown-items-link.icon-link {
  padding-left: 37px;
}
@media screen and (min-width: 1025px) {
  .header .dropdown-items-link.icon-link {
    padding-left: 1.927vw;
  }
}
@media screen and (min-width: 1921px) {
  .header .dropdown-items-link.icon-link {
    padding-left: 37px;
  }
}
.header .dropdown-items-link.icon-link .rates-svg .st0 {
  fill: #e2e8f9;
}
.header .dropdown-items-link.icon-link .equipment-svg-header .st0 {
  fill: #e2e8f9;
}
.header .dropdown-items-link.icon-link .new-business-svg .st1 {
  fill: #e2e8f9;
}
.header .dropdown-items-link:hover, .header .dropdown-items-link:focus {
  color: #008fc4;
}
.header .dropdown-items-link:hover .retail-svg .st0, .header .dropdown-items-link:focus .retail-svg .st0 {
  fill: #002ea6;
}
.header .dropdown-items-link:hover .retail-svg .st1, .header .dropdown-items-link:focus .retail-svg .st1 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .health-wellness-svg .st0, .header .dropdown-items-link:focus .health-wellness-svg .st0 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .health-wellness-svg .st1, .header .dropdown-items-link:focus .health-wellness-svg .st1 {
  fill: #002ea6;
}
.header .dropdown-items-link:hover .grocery-svg .st0, .header .dropdown-items-link:focus .grocery-svg .st0 {
  fill: #002ea6;
}
.header .dropdown-items-link:hover .grocery-svg .st1, .header .dropdown-items-link:focus .grocery-svg .st1 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .ecommerce-svg .st0, .header .dropdown-items-link:focus .ecommerce-svg .st0 {
  fill: #002ea6;
}
.header .dropdown-items-link:hover .ecommerce-svg .st1, .header .dropdown-items-link:focus .ecommerce-svg .st1 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .restaurant-svg .st0, .header .dropdown-items-link:focus .restaurant-svg .st0 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .restaurant-svg .st1, .header .dropdown-items-link:focus .restaurant-svg .st1 {
  fill: #002ea6;
}
.header .dropdown-items-link:hover .hospitality-svg .st0, .header .dropdown-items-link:focus .hospitality-svg .st0 {
  fill: #002ea6;
}
.header .dropdown-items-link:hover .hospitality-svg .st1, .header .dropdown-items-link:focus .hospitality-svg .st1 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .fashion-boutiques-svg .st0, .header .dropdown-items-link:focus .fashion-boutiques-svg .st0 {
  fill: #002ea6;
}
.header .dropdown-items-link:hover .fashion-boutiques-svg .st1, .header .dropdown-items-link:focus .fashion-boutiques-svg .st1 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .homeware-store-svg .st0, .header .dropdown-items-link:focus .homeware-store-svg .st0 {
  fill: #002ea6;
}
.header .dropdown-items-link:hover .homeware-store-svg .st1, .header .dropdown-items-link:focus .homeware-store-svg .st1 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .professional-services-svg .st0, .header .dropdown-items-link:focus .professional-services-svg .st0 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .professional-services-svg .st1, .header .dropdown-items-link:focus .professional-services-svg .st1 {
  fill: #002ea6;
}
.header .dropdown-items-link:hover .wholesale-icon-svg .st0, .header .dropdown-items-link:focus .wholesale-icon-svg .st0 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .wholesale-icon-svg .st1, .header .dropdown-items-link:focus .wholesale-icon-svg .st1 {
  fill: #002ea6;
}
.header .dropdown-items-link:hover .non-profit-svg .cls-1, .header .dropdown-items-link:focus .non-profit-svg .cls-1 {
  fill: #233e96;
}
.header .dropdown-items-link:hover .non-profit-svg .cls-2, .header .dropdown-items-link:focus .non-profit-svg .cls-2 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .beauty-salons-svg .cls-1, .header .dropdown-items-link:focus .beauty-salons-svg .cls-1 {
  fill: #233e96;
}
.header .dropdown-items-link:hover .beauty-salons-svg .cls-2, .header .dropdown-items-link:focus .beauty-salons-svg .cls-2 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .hardware-svg .cls-1, .header .dropdown-items-link:focus .hardware-svg .cls-1 {
  fill: #233e96;
}
.header .dropdown-items-link:hover .hardware-svg .cls-2, .header .dropdown-items-link:focus .hardware-svg .cls-2 {
  fill: #17fcc4;
}
.header .dropdown-items-link:hover .rates-svg .st0, .header .dropdown-items-link:focus .rates-svg .st0 {
  fill: #002ea6;
}
.header .dropdown-items-link:hover .equipment-svg-header .st0, .header .dropdown-items-link:focus .equipment-svg-header .st0 {
  fill: #002ea6;
}
.header .dropdown-items-link:hover .new-business-svg .st1, .header .dropdown-items-link:focus .new-business-svg .st1 {
  fill: #002ea6;
}
@media screen and (min-width: 1025px) {
  .header .dropdown-items-link .rates-svg .st0 {
    fill: #e2e8f9;
  }
  .header .dropdown-items-link .equipment-svg-header .st0 {
    fill: #e2e8f9;
  }
  .header .dropdown-items-link .new-business-svg .st1 {
    fill: #e2e8f9;
  }
  .header .dropdown-items-link:hover .rates-svg .st0, .header .dropdown-items-link:focus .rates-svg .st0 {
    fill: #002ea6;
  }
  .header .dropdown-items-link:hover .equipment-svg-header .st0, .header .dropdown-items-link:focus .equipment-svg-header .st0 {
    fill: #002ea6;
  }
  .header .dropdown-items-link:hover .new-business-svg circle, .header .dropdown-items-link:focus .new-business-svg circle {
    stroke: #002ea6;
  }
  .header .dropdown-items-link:hover .new-business-svg .st1, .header .dropdown-items-link:focus .new-business-svg .st1 {
    fill: #002ea6;
  }
}
.header .solution-svg {
  flex: 0 0 auto;
  position: absolute;
  backface-visibility: hidden;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 25px;
}
@media screen and (min-width: 1025px) {
  .header .solution-svg {
    width: 1.302vw;
  }
  .header .solution-svg .st0,
  .header .solution-svg .st1,
  .header .solution-svg .cls-1,
  .header .solution-svg .cls-2 {
    fill: #e2e8f9;
  }
}
@media screen and (min-width: 1921px) {
  .header .solution-svg {
    width: 25px;
  }
}
.header .solution-svg .st0,
.header .solution-svg .st1,
.header .solution-svg .cls-1,
.header .solution-svg .cls-2 {
  transition: fill 0.3s cubic-bezier(0.61, 1, 0.88, 1);
}
.header .dropdown-items-links-hold {
  transition: margin 0.3s cubic-bezier(0.61, 1, 0.88, 1), padding-top 0.3s cubic-bezier(0.61, 1, 0.88, 1), padding-top 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  padding-top: 60px;
  max-width: 800px;
}
.header .dropdown-links-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 40px;
}
@media screen and (min-width: 1025px) {
  .header .dropdown-links-wrap {
    opacity: 0;
    visibility: hidden;
    margin: -0.573vw -0.521vw;
    padding-bottom: 3.125vw;
  }
}
@media screen and (min-width: 1921px) {
  .header .dropdown-links-wrap {
    margin: -11px -10px;
    padding-bottom: 40px;
  }
}
.header .dropdown-links-wrap.single-column-links {
  flex-flow: column nowrap;
  width: fit-content;
}
@media screen and (min-width: 1025px) {
  .header .dropdown-links-wrap.single-column-links {
    margin: -0.573vw 0vw;
  }
}
@media screen and (min-width: 1921px) {
  .header .dropdown-links-wrap.single-column-links {
    margin: -11px 0;
  }
}
@media screen and (min-width: 1025px) {
  .header .dropdown-links-wrap.single-column-links .dropdown-items-link {
    width: auto;
    margin: 0.573vw 0vw;
  }
}
@media screen and (min-width: 1921px) {
  .header .dropdown-links-wrap.single-column-links .dropdown-items-link {
    margin: 11px 0;
  }
}
.header .dropdown-links-wrap.menu-plain .links-column {
  border-right: none;
}
.header .dropdown-links-wrap.menu-plain .dropdown-link-wrap:hover, .header .dropdown-links-wrap.menu-plain .dropdown-link-wrap:focus {
  background: transparent;
}
.header .header-link-item {
  transition: color 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  padding-top: 20px;
  padding-bottom: 20px;
}
@media screen and (max-width: 1024px) {
  .header .header-link-item {
    font-size: 18px;
    color: #002ea6 !important;
  }
}
@media screen and (min-width: 1025px) {
  .header .header-link-item {
    padding-top: 0.885vw;
    padding-bottom: 0.833vw;
    margin-right: 1.6667vw;
    font-size: 15px;
    line-height: 30px;
  }
  .header .header-link-item:hover, .header .header-link-item:focus {
    color: #17fcc4 !important;
  }
  .header .header-link-item::before {
    content: "";
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: -6px;
    width: calc(100% + 12px);
    height: 1px;
    background-color: #17fcc4;
    transform-origin: 0% 50%;
    transform: scaleX(0);
    backface-visibility: hidden;
    transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  }
  .header .header-link-item.opened-dropdown {
    color: #ff034a !important;
  }
  .header .header-link-item.opened-dropdown a {
    color: #ff034a !important;
  }
  .header .header-link-item.opened-dropdown::before {
    transform: scaleX(1);
  }
  .header .header-link-item.opened-dropdown .dropdown-arrow-btn::before {
    border-color: #ff034a !important;
  }
  .header .header-link-item:hover::before, .header .header-link-item:focus::before {
    transform: scaleX(1);
  }
  .header .header-link-item.active-item {
    color: #17fcc4 !important;
  }
}
@media screen and (min-width: 1441px) {
  .header .header-link-item {
    padding-bottom: 0.99vw;
  }
}
@media screen and (min-width: 1921px) {
  .header .header-link-item {
    padding-top: 17px;
    padding-bottom: 19px;
  }
}
.header .header-link-item.has-dropdown {
  position: relative;
  z-index: 1;
  transition: color 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  padding-right: 20px;
}
@media screen and (min-width: 1025px) {
  .header .header-link-item.has-dropdown {
    padding-right: 1.042vw;
  }
  .header .header-link-item.has-dropdown:hover, .header .header-link-item.has-dropdown:focus {
    color: #ff034a;
  }
  .header .header-link-item.has-dropdown:hover .dropdown-arrow-btn::before, .header .header-link-item.has-dropdown:focus .dropdown-arrow-btn::before {
    border-color: #ff034a;
  }
}
@media screen and (min-width: 1921px) {
  .header .header-link-item.has-dropdown {
    padding-right: 20px;
  }
}
.header .dropdown-arrow-btn {
  display: inline-block;
  position: absolute;
  backface-visibility: hidden;
  transition: transform 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  top: 45%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  right: -10px;
}
@media screen and (min-width: 1025px) {
  .header .dropdown-arrow-btn {
    width: 1.563vw;
    height: 1.563vw;
    right: -0.521vw;
  }
}
@media screen and (min-width: 1921px) {
  .header .dropdown-arrow-btn {
    width: 30px;
    height: 30px;
    right: -10px;
  }
}
.header .dropdown-arrow-btn::before {
  content: "";
  display: inline-block;
  position: absolute;
  backface-visibility: hidden;
  left: 50%;
  top: 50%;
  transition: transform 0.3s cubic-bezier(0.61, 1, 0.88, 1), border-color 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  transform: translate(-50%, -50%) rotate(-45deg);
  border-left-style: solid;
  border-bottom-style: solid;
  border-color: #17fcc4;
  width: 6px;
  height: 6px;
  border-left-width: 2.5px;
  border-bottom-width: 2.5px;
}
@media screen and (min-width: 1025px) {
  .header .dropdown-arrow-btn::before {
    width: 0.313vw;
    height: 0.313vw;
    border-left-width: 0.13vw;
    border-bottom-width: 0.13vw;
  }
}
@media screen and (min-width: 1921px) {
  .header .dropdown-arrow-btn::before {
    width: 6px;
    height: 6px;
    border-left-width: 2.5px;
    border-bottom-width: 2.5px;
  }
}
.header .mobile-menu-btn {
  position: relative;
  z-index: 2;
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.65);
  width: 55px;
  height: 55px;
  margin-right: -6px;
}
@media screen and (min-width: 1025px) {
  .header .mobile-menu-btn {
    display: none;
  }
}
.header .line-el {
  transition: background-color 0.3s cubic-bezier(0.61, 1, 0.88, 1);
  background-color: #fff;
  display: inline-block;
  width: 21px;
  height: 2px;
  margin: 2px 0;
}
@media screen and (min-width: 1025px) {
  .header.header-fr .header-links-wrap {
    margin-right: 1.25vw;
  }
}

.inner-pages-contact-section {
  background-color: #002ea6;
  padding-top: 100px;
  padding-bottom: 0px;
  overflow: hidden;
}
.inner-pages-contact-section p {
  color: #fff;
}
.inner-pages-contact-section .c-blue-1,
.inner-pages-contact-section .text-blue p {
  color: #002ea6;
}
.inner-pages-contact-section .talk-right-heading,
.inner-pages-contact-section .talk-left-heading {
  line-height: 32px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section .talk-right-heading,
  .inner-pages-contact-section .talk-left-heading {
    line-height: 61px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .inner-pages-contact-section .talk-right-heading,
  .inner-pages-contact-section .talk-left-heading {
    line-height: 61px;
  }
}
@media screen and (max-width: 767px) {
  .inner-pages-contact-section .talk-right-heading,
  .inner-pages-contact-section .talk-left-heading {
    line-height: 40px;
  }
}
.inner-pages-contact-section .talk-left-subheading,
.inner-pages-contact-section .talk-right-subheading {
  margin-bottom: 32px;
  font-size: 14px;
  letter-spacing: 7px;
}
.inner-pages-contact-section .talk-left-content,
.inner-pages-contact-section .talk-right-content {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.inner-pages-contact-section .talk-left-content strong,
.inner-pages-contact-section .talk-left-content b,
.inner-pages-contact-section .talk-right-content strong,
.inner-pages-contact-section .talk-right-content b {
  font-weight: 600;
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section {
    margin-top: -2px !important;
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .inner-pages-contact-section::after {
    content: "";
    display: inline-block;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(0, 143, 196, 0.7);
  }
}
.inner-pages-contact-section.blog-social-media::after {
  background-color: #17fcc5;
  opacity: 0.8;
}
@media screen and (max-width: 1024px) {
  .inner-pages-contact-section.blog-social-media .contact-block {
    background-color: #17fcc5;
    opacity: 0.8;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section.blog-social-media .contact-block {
    padding-left: 11.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section.blog-social-media .contact-block {
    padding-left: 212px;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section {
    padding-top: 171px;
    padding-bottom: 101px;
  }
}
.inner-pages-contact-section .max-w-container {
  z-index: 4;
}
.inner-pages-contact-section .bg-image {
  z-index: 1;
  background-size: cover;
  opacity: 0;
}
@media screen and (max-width: 1024px) {
  .inner-pages-contact-section .bg-image {
    opacity: 1 !important;
  }
}
.inner-pages-contact-section .txt-content {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}
.inner-pages-contact-section .background-flower-path-patterns {
  pointer-events: none;
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .inner-pages-contact-section .background-flower-path-patterns {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section .background-flower-path-patterns {
    right: 1.302vw;
    width: 47.083vw;
    height: 47.083vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section .background-flower-path-patterns {
    right: 25px;
    width: 904px;
    height: 904px;
  }
}
.inner-pages-contact-section .background-flower-path-svg {
  flex: 0 0 auto;
  height: 50%;
  width: 50%;
  opacity: 0.2;
}
.inner-pages-contact-section .background-flower-path-svg path {
  fill: transparent;
  stroke: #fff;
}
.inner-pages-contact-section .single-block {
  flex: 0 1 auto;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section .single-block {
    width: 50%;
  }
}
@media screen and (max-width: 1024px) {
  .inner-pages-contact-section .txt-content-block {
    padding-bottom: 100px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section .txt-content-block {
    padding-right: 11.458vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section .txt-content-block {
    padding-right: 282px;
  }
}
.inner-pages-contact-section .txt-content-block .txt-post-rtf {
  margin-top: 25px;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section .txt-content-block .txt-post-rtf {
    margin-top: 4.323vw;
    margin-left: -0.156vw;
    max-width: 17.188vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section .txt-content-block .txt-post-rtf {
    margin-top: 83px;
    margin-left: -3px;
    max-width: 330px;
  }
}
@media screen and (max-width: 1024px) {
  .inner-pages-contact-section .contact-block {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(23, 252, 197, 0.8);
    background-color: rgba(0, 143, 196, 0.7);
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section .contact-block {
    padding-left: 11.458vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section .contact-block {
    padding-left: 282px;
  }
}
.inner-pages-contact-section .contact-block .txt-post-rtf {
  margin-top: 25px;
  max-width: 430px;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .inner-pages-contact-section .contact-block .txt-post-rtf {
    max-width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section .contact-block .txt-post-rtf {
    margin-top: 1.563vw;
    margin-left: 0.104vw;
    max-width: 22.396vw;
    margin-right: -3.125vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section .contact-block .txt-post-rtf {
    margin-top: 30px;
    margin-left: 2px;
    max-width: 430px;
    margin-right: -60px;
  }
}
.inner-pages-contact-section .contact-block .btn-default {
  width: 100%;
  margin-top: 20px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section .contact-block .btn-default {
    margin-top: 1.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section .contact-block .btn-default {
    margin-top: 20px;
  }
}
.inner-pages-contact-section .txt-size-12 {
  margin-bottom: 25px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section .txt-size-12 {
    margin-left: 0.156vw;
    margin-bottom: 1.302vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section .txt-size-12 {
    margin-left: 3px;
    margin-bottom: 25px;
  }
}
.inner-pages-contact-section .txt-post-rtf ul:last-child,
.inner-pages-contact-section .txt-post-rtf p:last-child,
.inner-pages-contact-section .txt-post-rtf h2:last-child,
.inner-pages-contact-section .txt-post-rtf .h2:last-child,
.inner-pages-contact-section .txt-post-rtf h3:last-child,
.inner-pages-contact-section .txt-post-rtf .h3:last-child,
.inner-pages-contact-section .txt-post-rtf h4:last-child,
.inner-pages-contact-section .txt-post-rtf .h4:last-child,
.inner-pages-contact-section .txt-post-rtf h5:last-child,
.inner-pages-contact-section .txt-post-rtf .h5:last-child,
.inner-pages-contact-section .txt-post-rtf h6:last-child,
.inner-pages-contact-section .txt-post-rtf .h6:last-child {
  margin-bottom: 0;
}
.inner-pages-contact-section .buttons-wrap {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-top: 25px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section .buttons-wrap {
    margin-top: 2.813vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section .buttons-wrap {
    margin-top: 54px;
  }
}
.inner-pages-contact-section .contact-section-btn:hover, .inner-pages-contact-section .contact-section-btn:focus {
  border: 1px solid #fff;
}
.inner-pages-contact-section .contact-section-btn:hover .contact-btn-icon, .inner-pages-contact-section .contact-section-btn:focus .contact-btn-icon {
  transform: scale(1.05) translateY(-50%);
}
.inner-pages-contact-section .contact-section-btn:hover .btn-txt, .inner-pages-contact-section .contact-section-btn:focus .btn-txt {
  transform: translateX(5px);
}
.inner-pages-contact-section .contact-section-btn:not(:last-of-type) {
  margin-bottom: 17px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section .contact-section-btn:not(:last-of-type) {
    margin-bottom: 0.885vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section .contact-section-btn:not(:last-of-type) {
    margin-bottom: 17px;
  }
}
.inner-pages-contact-section .contact-section-btn.header-open-form-btn, .inner-pages-contact-section .contact-section-btn.header-cta-btn {
  margin-top: 17px;
}
.inner-pages-contact-section .contact-section-btn.landing-header-open-form-btn, .inner-pages-contact-section .contact-section-btn.landing-header-cta-btn {
  margin-top: 17px;
}
.inner-pages-contact-section .contact-section-btn .btn-txt {
  color: #fff;
}
.inner-pages-contact-section .contact-section-btn .btn-txt:hover {
  color: #fff;
}
.inner-pages-contact-section .contact-section-btn.callback-popup-btn {
  margin-top: 17px;
  text-align: left;
}
.inner-pages-contact-section .contact-section-btn.callback-popup-btn .btn-txt {
  color: #fff;
}
.inner-pages-contact-section .contact-section-btn.callback-popup-btn .btn-txt:hover {
  color: #fff;
}
.inner-pages-contact-section .contact-section-btn.block-callback-popup-btn {
  margin-top: 17px;
  text-align: left;
}
.inner-pages-contact-section .contact-section-btn.block-callback-popup-btn .btn-txt {
  color: #fff;
}
.inner-pages-contact-section .contact-section-btn.block-callback-popup-btn .btn-txt:hover {
  color: #fff;
}
.inner-pages-contact-section ul {
  list-style-type: none;
  margin-top: 29px;
  margin-bottom: 29px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section ul {
    margin-top: 1.51vw;
    margin-bottom: 1.51vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section ul {
    margin-top: 29px;
    margin-bottom: 29px;
  }
}
.inner-pages-contact-section ul li {
  position: relative;
  padding-left: 39px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section ul li {
    padding-left: 2.031vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section ul li {
    padding-left: 39px;
  }
}
.inner-pages-contact-section ul li::before {
  content: "" !important;
  display: inline-block;
  position: absolute;
  top: 0.5em;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/wp-content/themes/sekure/assets/media/images/pictograms/checkmark-green.webp");
  width: 21px;
  height: 15px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section ul li::before {
    width: 1.094vw;
    height: 0.781vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section ul li::before {
    width: 21px;
    height: 15px;
  }
}
.inner-pages-contact-section .guide-form {
  margin-top: 39px;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .inner-pages-contact-section .guide-form {
    margin-top: 2.031vw;
    margin-left: -1.406vw;
    width: calc(100% + 4.375vw);
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-contact-section .guide-form {
    margin-top: 39px;
    margin-left: -27px;
    width: calc(100% + 84px);
  }
}

.inner-equipment-list-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 1025px) {
  .inner-equipment-list-section {
    padding-top: 9.219vw;
    padding-bottom: 7.708vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-equipment-list-section {
    padding-top: 177px;
    padding-bottom: 148px;
  }
}
.inner-equipment-list-section .txt-content {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin-bottom: 25px;
}
@media screen and (min-width: 1025px) {
  .inner-equipment-list-section .txt-content {
    margin-bottom: 7.813vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-equipment-list-section .txt-content {
    margin-bottom: 150px;
  }
}
.inner-equipment-list-section .txt-size-80 {
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .inner-equipment-list-section .txt-size-80 {
    width: 36.458vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-equipment-list-section .txt-size-80 {
    width: 700px;
  }
}
.inner-equipment-list-section .txt-post-rtf {
  width: 100%;
  padding-top: 25px;
}
@media screen and (min-width: 1025px) {
  .inner-equipment-list-section .txt-post-rtf {
    width: calc(100% - 36.458vw);
    padding-left: 3.854vw;
    padding-top: 0.365vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-equipment-list-section .txt-post-rtf {
    width: calc(100% - 700px);
    padding-left: 74px;
    padding-top: 7px;
  }
}
.inner-equipment-list-section .content-blocks-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}
@media screen and (max-width: 550px) {
  .inner-equipment-list-section .content-blocks-wrap {
    margin: -15px;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .inner-equipment-list-section .content-blocks-wrap {
    margin: -30px;
  }
}
@media screen and (max-width: 1024px) {
  .inner-equipment-list-section .content-blocks-wrap {
    justify-content: center;
  }
}
@media screen and (min-width: 1025px) {
  .inner-equipment-list-section .content-blocks-wrap {
    margin: -1.563vw -2.604vw -1.563vw -3.646vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-equipment-list-section .content-blocks-wrap {
    margin: -30px -50px -30px -70px;
  }
}
.inner-equipment-list-section .img-hold {
  max-width: 243px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}
@media screen and (min-width: 1025px) {
  .inner-equipment-list-section .img-hold {
    margin-bottom: 2.031vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-equipment-list-section .img-hold {
    margin-bottom: 39px;
  }
}
.inner-equipment-list-section .img-wrapper {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
}
.inner-equipment-list-section .equipment-img {
  backface-visibility: hidden;
  transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.inner-equipment-list-section .equipment-block-btn-txt {
  display: unset;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.5s cubic-bezier(0.61, 1, 0.88, 1), color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.inner-equipment-list-section .single-block {
  position: relative;
  text-align: center;
}
.inner-equipment-list-section .single-block:hover .equipment-img, .inner-equipment-list-section .single-block:focus .equipment-img {
  transform: scale(1.05);
}
.inner-equipment-list-section .single-block:hover .green-arrow-icon, .inner-equipment-list-section .single-block:focus .green-arrow-icon {
  animation: equipment-list-section-hover 1.5s ease-in-out infinite;
}
.inner-equipment-list-section .single-block:hover .equipment-block-btn-txt, .inner-equipment-list-section .single-block:focus .equipment-block-btn-txt {
  background-size: 100% 1px;
}
@media screen and (max-width: 550px) {
  .inner-equipment-list-section .single-block {
    width: calc(50% - 30px);
    margin: 15px;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .inner-equipment-list-section .single-block {
    width: calc(50% - 60px);
    margin: 30px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-equipment-list-section .single-block {
    width: calc(25% - 5.208vw);
    margin: 1.563vw 2.604vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-equipment-list-section .single-block {
    width: calc(25% - 100px);
    margin: 30px 50px;
  }
}
.inner-equipment-list-section .equipment-block-btn {
  display: inline-block;
  text-decoration: none;
}
@media screen and (min-width: 1025px) {
  .inner-equipment-list-section .equipment-block-btn {
    margin: 0vw -0.521vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-equipment-list-section .equipment-block-btn {
    margin: 0 -10px;
  }
}
.inner-equipment-list-section .btn-txt {
  display: inline-block;
  position: relative;
  width: 100%;
}
.inner-equipment-list-section .green-arrow-img-wrapper {
  display: inline-block;
  width: 21px;
  height: 16px;
}
.inner-equipment-list-section .green-arrow-icon {
  font-size: 0;
  position: relative;
  left: 5px;
}
@media screen and (min-width: 1025px) {
  .inner-equipment-list-section .green-arrow-icon {
    left: 0.521vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-equipment-list-section .green-arrow-icon {
    left: 10px;
  }
}

.inner-info-img-video-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 1025px) {
  .inner-info-img-video-section {
    padding-top: 5.208vw;
    padding-bottom: 4.271vw;
  }
}
@media (min-width: 1921px) {
  .inner-info-img-video-section {
    padding-top: 100px;
    padding-bottom: 82px;
  }
}
.inner-info-img-video-section .main-img-wrapper {
  border-radius: 50%;
  z-index: 1;
}
@media (max-width: 1024px) {
  .inner-info-img-video-section .main-img-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 550px) {
  .inner-info-img-video-section .main-img-wrapper {
    width: 84.8vw;
    height: 84.8vw;
  }
}
@media (min-width: 551px) and (max-width: 1024px) {
  .inner-info-img-video-section .main-img-wrapper {
    width: 424px;
    height: 424px;
  }
}
@media (min-width: 1025px) {
  .inner-info-img-video-section .main-img-wrapper {
    position: absolute;
    top: 1.094vw;
    left: -25.313vw;
    width: 34.583vw;
    height: 34.583vw;
  }
}
@media (min-width: 1921px) {
  .inner-info-img-video-section .main-img-wrapper {
    top: 21px;
    left: -486px;
    width: 664px;
    height: 664px;
  }
}
.inner-info-img-video-section .txt-content {
  z-index: 2;
  padding-top: 25px;
  width: 100%;
}
@media (min-width: 1025px) {
  .inner-info-img-video-section .txt-content {
    padding-top: 7.083vw;
    margin-left: 13.281vw;
    width: 25.781vw;
  }
}
@media (min-width: 1921px) {
  .inner-info-img-video-section .txt-content {
    padding-top: 136px;
    margin-left: 255px;
    width: 495px;
  }
}
.inner-info-img-video-section .txt-size-100 {
  margin-bottom: 25px;
}
@media (min-width: 1025px) {
  .inner-info-img-video-section .txt-size-100 {
    margin-bottom: 3.177vw;
  }
}
@media (min-width: 1921px) {
  .inner-info-img-video-section .txt-size-100 {
    margin-bottom: 61px;
  }
}
@media (min-width: 1025px) {
  .inner-info-img-video-section .txt-post-rtf {
    padding-right: 5.208vw;
  }
}
@media (min-width: 1921px) {
  .inner-info-img-video-section .txt-post-rtf {
    padding-right: 100px;
  }
}
.inner-info-img-video-section .txt-post-rtf ul {
  list-style-type: none;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media (min-width: 1025px) {
  .inner-info-img-video-section .txt-post-rtf ul {
    margin-top: 1.563vw;
    margin-bottom: 1.563vw;
  }
}
@media (min-width: 1921px) {
  .inner-info-img-video-section .txt-post-rtf ul {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.inner-info-img-video-section .txt-post-rtf ul li {
  position: relative;
  padding-left: 15px;
}
.inner-info-img-video-section .txt-post-rtf ul li::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: #002ea6;
  top: calc(0.5em + 4px);
  left: 2px;
  width: 3px;
  height: 3px;
}
.inner-info-img-video-section .txt-post-rtf p:not(:last-of-type) {
  margin-bottom: 15px;
}
@media (min-width: 1025px) {
  .inner-info-img-video-section .txt-post-rtf p:not(:last-of-type) {
    margin-bottom: 1.563vw;
  }
}
@media (min-width: 1921px) {
  .inner-info-img-video-section .txt-post-rtf p:not(:last-of-type) {
    margin-bottom: 30px;
  }
}
.inner-info-img-video-section .img-circles-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
}
@media (max-width: 1024px) {
  .inner-info-img-video-section .img-circles-wrap {
    margin-top: 25px;
    justify-content: space-between;
  }
}
@media (max-width: 550px) {
  .inner-info-img-video-section .img-circles-wrap {
    justify-content: center;
  }
}
@media (min-width: 1025px) {
  .inner-info-img-video-section .img-circles-wrap {
    justify-content: flex-end;
    position: absolute;
    top: 0.99vw;
    right: -21.615vw;
    width: 51.875vw;
    height: 34.583vw;
  }
}
@media (min-width: 1921px) {
  .inner-info-img-video-section .img-circles-wrap {
    top: 19px;
    right: -415px;
    width: 996px;
    height: 664px;
  }
}
.inner-info-img-video-section .img-circles-wrap .btn-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 25px;
}
@media (min-width: 1025px) {
  .inner-info-img-video-section .img-circles-wrap .btn-wrap {
    margin-top: 3.906vw;
    margin-left: 2.76vw;
  }
}
@media (min-width: 1921px) {
  .inner-info-img-video-section .img-circles-wrap .btn-wrap {
    margin-top: 75px;
    margin-left: 53px;
  }
}
.inner-info-img-video-section .circles-img-wrap .img-wrapper {
  width: 100%;
}
@media (max-width: 550px) {
  .inner-info-img-video-section .circles-img-wrap {
    max-width: 225px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
}
@media (min-width: 500px) {
  .inner-info-img-video-section .circles-img-wrap {
    width: 31.33%;
  }
}
@media (min-width: 1025px) {
  .inner-info-img-video-section .circles-img-wrap {
    height: 50%;
  }
}
.inner-info-img-video-section .background-target-circle-transparent {
  border-radius: 50%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: #17fcc4;
}
.inner-info-img-video-section .background-target-circle-transparent.low-opacity {
  opacity: 0.2;
}
@media (max-width: 1024px) {
  .inner-info-img-video-section .background-target-circle-transparent {
    display: none;
  }
}
@media (min-width: 1025px) {
  .inner-info-img-video-section .background-target-circle-transparent {
    border-width: 2.917vw;
    width: 17.292vw;
    height: 17.292vw;
  }
}
@media (min-width: 1921px) {
  .inner-info-img-video-section .background-target-circle-transparent {
    border-width: 56px;
    width: 332px;
    height: 332px;
  }
}
.inner-info-img-video-section .background-target-circle-inner {
  position: absolute;
  border-radius: 50%;
  background-color: #17fcc4;
  width: 84px;
  height: 84px;
}
@media (min-width: 1025px) {
  .inner-info-img-video-section .background-target-circle-inner {
    width: 4.375vw;
    height: 4.375vw;
  }
}
@media (min-width: 1921px) {
  .inner-info-img-video-section .background-target-circle-inner {
    width: 84px;
    height: 84px;
  }
}

.inner-pages-landing-section {
  z-index: 2;
  margin-bottom: -1px;
}
@media screen and (min-width: 500px) and (max-width: 1024px) {
  .inner-pages-landing-section {
    margin-top: 0;
  }
}
.inner-pages-landing-section.savings-calculator-page-section .breadcrumb-wrap {
  padding-bottom: 31px;
}
.inner-pages-landing-section.text-page-section {
  background-color: #002ea6;
}
.inner-pages-landing-section.text-page-section .breadcrumb-wrap {
  padding-bottom: 31px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section.restaurants-landing-section .content-wrap {
    padding-bottom: 7.708vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section.restaurants-landing-section .content-wrap {
    padding-bottom: 148px;
  }
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section.restaurants-landing-section .txt-size-100 {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section.restaurants-landing-section .txt-size-100 {
    margin-bottom: 32px;
    font-size: 64px !important;
    line-height: 75px !important;
  }
}
@media screen and (max-width: 550px) {
  .inner-pages-landing-section.restaurants-landing-section .txt-size-100 {
    max-width: 90vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .inner-pages-landing-section.restaurants-landing-section .txt-size-100 {
    max-width: 450px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section.equipment-landing-section .content-wrap {
    padding-bottom: 14.531vw;
    padding-top: 5.417vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section.equipment-landing-section .content-wrap {
    padding-bottom: 279px;
    padding-top: 104px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section.meet-payment-expert-section .content-wrap {
    padding-top: 7.917vw;
    padding-bottom: 13.542vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section.meet-payment-expert-section .content-wrap {
    padding-top: 152px;
    padding-bottom: 260px;
  }
}
@media screen and (max-width: 550px) {
  .inner-pages-landing-section.meet-payment-expert-section .txt-size-100 {
    max-width: 85vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .inner-pages-landing-section.meet-payment-expert-section .txt-size-100 {
    max-width: 425px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section.meet-payment-expert-section .txt-size-100 {
    max-width: 36.458vw;
    margin-bottom: 32px;
    font-size: 64px !important;
    line-height: 75px !important;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section.meet-payment-expert-section .txt-size-100 {
    max-width: 700px;
  }
}
.inner-pages-landing-section.rates-detail-page-section .content-wrap {
  padding-top: 100px;
  padding-bottom: 100px;
}
.inner-pages-landing-section.rates-page-section .content-wrap {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section.rates-page-section .section-scroll-btn {
    margin-top: 85px !important;
    margin-bottom: -25px;
  }
}
.inner-pages-landing-section.rates-page-section .txt-post-rtf {
  margin-top: 50px;
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section.rates-page-section .txt-post-rtf {
    max-width: 500px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section.rates-page-section .txt-post-rtf {
    width: 36.458vw;
    margin-top: 2.552vw;
    margin-left: 0.156vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section.rates-page-section .txt-post-rtf {
    width: 700px;
    margin-top: 49px;
    margin-left: 3px;
  }
}
.inner-pages-landing-section.rates-page-section .txt-post-rtf.careers-txt {
  margin-top: 0;
}
@media screen and (max-width: 767px) {
  .inner-pages-landing-section.rates-page-section .txt-post-rtf.careers-txt {
    min-width: 240px;
  }
}
.inner-pages-landing-section .landing-video-btn {
  z-index: 1;
  position: absolute;
  flex: 0 0 auto;
  display: flex;
}
.inner-pages-landing-section .landing-video-btn:hover .circle-core, .inner-pages-landing-section .landing-video-btn:focus .circle-core {
  background-color: #002ea6;
}
.inner-pages-landing-section .landing-video-btn:hover .play-video-icon-landing, .inner-pages-landing-section .landing-video-btn:focus .play-video-icon-landing {
  border-color: transparent transparent transparent #17fcc4;
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section .landing-video-btn {
    right: 0;
  }
}
@media screen and (max-width: 550px) {
  .inner-pages-landing-section .landing-video-btn {
    width: 28.2vw;
    height: 28.2vw;
    bottom: 10vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .inner-pages-landing-section .landing-video-btn {
    bottom: 50px;
    width: 141px;
    height: 141px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .landing-video-btn {
    top: 10.938vw;
    right: 3.906vw;
    width: 7.344vw;
    height: 7.344vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .landing-video-btn {
    top: 183px;
    right: 35px;
    width: 141px;
    height: 141px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .landing-video-btn.careers-video-btn {
    right: 31%;
    bottom: 55%;
    top: unset;
  }
}
.inner-pages-landing-section .landing-video-btn .circle-core {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #e7eaeb;
  border-radius: 50%;
  transition: background-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.inner-pages-landing-section .landing-video-btn .circle-outer {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 215%;
  height: 215%;
  border-radius: 50%;
  pointer-events: none;
}
.inner-pages-landing-section .landing-video-btn .circle-inner {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 130%;
  height: 130%;
  border-radius: 50%;
  pointer-events: none;
}
.inner-pages-landing-section .landing-video-btn .play-video-icon-landing {
  position: absolute;
  display: inline-block;
  backface-visibility: hidden;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent #72868a;
  transition: border-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
@media screen and (max-width: 550px) {
  .inner-pages-landing-section .landing-video-btn .play-video-icon-landing {
    top: calc(50% - 2vw);
    left: calc(50% - 1.2vw);
    border-width: 1.8vw 0vw 1.8vw 3.2vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .inner-pages-landing-section .landing-video-btn .play-video-icon-landing {
    top: calc(50% - 10px);
    left: calc(50% - 6px);
    border-width: 9px 0 9px 16px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .landing-video-btn .play-video-icon-landing {
    top: calc(50% - 0.521vw);
    left: calc(50% - 0.313vw);
    border-width: 0.469vw 0vw 0.469vw 0.833vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .landing-video-btn .play-video-icon-landing {
    top: calc(50% - 10px);
    left: calc(50% - 6px);
    border-width: 9px 0 9px 16px;
  }
}
.inner-pages-landing-section .background-target-circle-transparent {
  position: absolute;
  border-radius: 50%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.1;
  border-style: solid;
  border-color: #17fcc4;
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section .background-target-circle-transparent {
    bottom: -10%;
    right: -10%;
    border-width: 9.961vw;
    width: 56.641vw;
    height: 56.641vw;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .background-target-circle-transparent {
    border-width: 7.552vw;
    top: 2.031vw;
    right: -21.979vw;
    width: 43.177vw;
    height: 43.177vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .background-target-circle-transparent {
    border-width: 145px;
    top: 39px;
    right: -422px;
    width: 829px;
    height: 829px;
  }
}
.inner-pages-landing-section .background-target-circle-inner {
  position: absolute;
  border-radius: 50%;
  background-color: #17fcc4;
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section .background-target-circle-inner {
    width: 14.355vw;
    height: 14.355vw;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .background-target-circle-inner {
    width: 10.938vw;
    height: 10.938vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .background-target-circle-inner {
    width: 210px;
    height: 210px;
  }
}
.inner-pages-landing-section .bg-image {
  background-size: cover;
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .bg-image {
    background-position: 67% 65%;
  }
}
.inner-pages-landing-section .bg-image.careers-banner {
  background-color: rgba(0, 0, 0, 0.39);
  background-blend-mode: multiply;
}
.inner-pages-landing-section .img-wrapper-small-img .img-wrapper {
  width: 100%;
}
@media screen and (max-width: 550px) {
  .inner-pages-landing-section .img-wrapper-small-img {
    width: 24vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .inner-pages-landing-section .img-wrapper-small-img {
    width: 120px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .img-wrapper-small-img {
    left: 0;
    z-index: 1;
    position: absolute;
    width: 8.958vw;
    top: 4.427vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .img-wrapper-small-img {
    width: 172px;
    top: 85px;
  }
}
.inner-pages-landing-section .industry-template-background-img {
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section .industry-template-background-img {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .industry-template-background-img {
    position: absolute;
    width: 34.844vw;
    top: 9.948vw;
    right: -10.26vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .industry-template-background-img {
    width: 669px;
    top: 191px;
    right: -197px;
  }
}
.inner-pages-landing-section .background-circles-wrap {
  position: absolute;
  width: 54.785vw;
  height: 56.641vw;
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section .background-circles-wrap {
    bottom: 0;
  }
}
@media screen and (max-width: 550px) {
  .inner-pages-landing-section .background-circles-wrap {
    right: -7.6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .inner-pages-landing-section .background-circles-wrap {
    right: -38px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .background-circles-wrap {
    top: 1.875vw;
    left: -22.813vw;
    width: 41.771vw;
    height: 43.125vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .background-circles-wrap {
    top: 36px;
    left: -438px;
    width: 802px;
    height: 828px;
  }
}
.inner-pages-landing-section .background-inner-circle {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 3, 74, 0.3);
}
.inner-pages-landing-section .background-inner-circle.green-inner-circle {
  background-color: rgba(23, 252, 195, 0.24);
}
.inner-pages-landing-section .background-inner-circle.green-inner-circle.lighter-green-circle {
  opacity: 0.3;
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section .background-inner-circle {
    width: 32.617vw;
    height: 32.617vw;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .background-inner-circle {
    width: 24.844vw;
    height: 24.844vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .background-inner-circle {
    width: 477px;
    height: 477px;
  }
}
.inner-pages-landing-section .background-inner-circle.lighter-circle {
  background-color: rgba(255, 3, 74, 0.1);
}
.inner-pages-landing-section .background-inner-circle.top-left {
  top: 0;
  left: 0;
}
.inner-pages-landing-section .background-inner-circle.top-right {
  top: 0;
  right: 0;
}
.inner-pages-landing-section .background-inner-circle.bottom-left {
  bottom: 0;
  left: 0;
}
.inner-pages-landing-section .background-inner-circle.bottom-right {
  bottom: 0;
  right: 0;
}
.inner-pages-landing-section .txt-size-100 {
  margin-bottom: 0;
}
.inner-pages-landing-section .txt-size-100 strong,
.inner-pages-landing-section .txt-size-100 b {
  color: #17fcc4;
}
.inner-pages-landing-section .txt-content .txt-post-rtf {
  margin-top: 24px;
}
.inner-pages-landing-section .content-wrap {
  z-index: 2;
  padding-top: 100px;
  padding-bottom: 130px;
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section .content-wrap {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section .content-wrap.careers {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.inner-pages-landing-section .icon-text-content .single-txt-block {
  z-index: 2;
  max-width: 500px;
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section .icon-text-content .single-txt-block {
    margin-top: 25px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .icon-text-content .single-txt-block {
    padding-top: 3.177vw;
    margin-left: 15.104vw;
    max-width: 33.854vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .icon-text-content .single-txt-block {
    padding-top: 61px;
    margin-left: 290px;
    max-width: 650px;
  }
}
.inner-pages-landing-section .icon-text-content .txt-block-title {
  margin-bottom: 10px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .icon-text-content .txt-block-title {
    margin-bottom: 1.979vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .icon-text-content .txt-block-title {
    margin-bottom: 38px;
  }
}
.inner-pages-landing-section .btn-default {
  margin-top: 50px;
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section .btn-default {
    margin-top: 0px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .btn-default {
    margin-left: -0.208vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .btn-default {
    margin-left: -4px;
  }
}
.inner-pages-landing-section .btn-default.hero-button {
  margin-top: 2.604vw;
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .btn-default.hero-button {
    margin-left: -0.208vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .btn-default.hero-button {
    margin-left: -4px;
  }
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section.sk-page-hero .btn-default {
    margin-top: 50px;
  }
}
.inner-pages-landing-section .txt-content {
  position: relative;
}
.inner-pages-landing-section .txt-blocks-content {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding-top: 50px;
  max-width: 500px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .txt-blocks-content {
    padding-left: 0.156vw;
    padding-top: 3.75vw;
    margin: 0vw -4.167vw;
    max-width: 40.156vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .txt-blocks-content {
    padding-left: 3px;
    padding-top: 72px;
    margin: 0 -80px;
    max-width: 771px;
  }
}
.inner-pages-landing-section .txt-blocks-content .txt-block {
  width: 100%;
}
.inner-pages-landing-section .txt-blocks-content .txt-block:not(:last-of-type) {
  margin-bottom: 32px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .txt-blocks-content .txt-block {
    margin: 0vw 4.167vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .txt-blocks-content .txt-block {
    margin: 0 80px;
  }
}
.inner-pages-landing-section .txt-blocks-content .txt-block-title {
  margin-bottom: 10px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .txt-blocks-content .txt-block-title {
    margin-bottom: 1.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .txt-blocks-content .txt-block-title {
    margin-bottom: 20px;
  }
}
.inner-pages-landing-section .txt-blocks-content-2 {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding-top: 50px;
  max-width: 500px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .txt-blocks-content-2 {
    padding-top: 2.604vw;
    margin: 0vw -1.563vw;
    max-width: 54.844vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .txt-blocks-content-2 {
    padding-top: 50px;
    margin: 0 -30px;
    max-width: 1053px;
  }
}
.inner-pages-landing-section .txt-blocks-content-2 .txt-block {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section .txt-blocks-content-2 .txt-block:not(:last-of-type) {
    margin: 0 0 25px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .txt-blocks-content-2 .txt-block {
    margin: 0vw 1.563vw;
    width: calc(50% - 3.125vw);
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-landing-section .txt-blocks-content-2 .txt-block {
    margin: 0 30px;
    width: calc(50% - 60px);
  }
}
@media screen and (max-width: 1024px) {
  .inner-pages-landing-section .section-scroll-btn {
    position: relative;
    margin-top: 62px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-landing-section .section-scroll-btn {
    margin-top: 85px !important;
    margin-bottom: -25px;
  }
}

.inner-pages-quiz-section-type-2 {
  background-color: #e7fff9;
  padding-top: 50px;
  padding-bottom: 50px;
}
.inner-pages-quiz-section-type-2 .side-menu-btns {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  backface-visibility: hidden;
}
@media screen and (max-width: 1024px) {
  .inner-pages-quiz-section-type-2 .side-menu-btns {
    padding-top: 25px;
    align-items: flex-start;
    flex-flow: row wrap;
    justify-content: center;
    margin: 10px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .side-menu-btns {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
  }
}
@media screen and (max-width: 1024px) {
  .inner-pages-quiz-section-type-2 .side-menu-quiz-btn {
    margin: 10px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .side-menu-quiz-btn:not(:last-of-type) {
    margin-bottom: 17px;
  }
}
.inner-pages-quiz-section-type-2 .side-menu-quiz-btn svg path {
  fill: #002ea6;
}
.inner-pages-quiz-section-type-2 .title-wrap {
  margin-bottom: 50px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .title-wrap {
    max-width: 57.292vw;
    margin-bottom: 5.573vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section-type-2 .title-wrap {
    max-width: 1100px;
    margin-bottom: 107px;
  }
}
.inner-pages-quiz-section-type-2 .title-wrap-txt {
  margin-top: 25px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .title-wrap-txt {
    margin-top: 2.917vw;
    margin-left: 0.156vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section-type-2 .title-wrap-txt {
    margin-top: 56px;
    margin-left: 3px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 {
    padding-top: 7.083vw;
    padding-bottom: 7.083vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section-type-2 {
    padding-top: 136px;
    padding-bottom: 136px;
  }
}
.inner-pages-quiz-section-type-2 .txt-size-12 {
  margin-bottom: 25px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .txt-size-12 {
    margin-bottom: 1.979vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section-type-2 .txt-size-12 {
    margin-bottom: 38px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .txt-size-48 {
    padding-right: 6.25vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section-type-2 .txt-size-48 {
    padding-right: 120px;
  }
}
.inner-pages-quiz-section-type-2 .btn-wrap {
  text-align: center;
  margin-top: 50px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .btn-wrap {
    margin-top: 3.385vw;
    padding-left: 3.281vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section-type-2 .btn-wrap {
    margin-top: 65px;
    padding-left: 63px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .quiz-content-wrap {
    margin-right: -1.51vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section-type-2 .quiz-content-wrap {
    margin-right: -29px;
  }
}
.inner-pages-quiz-section-type-2 .choices-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin: 0 0 -15px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .choices-wrap {
    margin: -0.781vw -1.094vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section-type-2 .choices-wrap {
    margin: -15px -21px;
  }
}
.inner-pages-quiz-section-type-2 .choice-item {
  position: relative;
  width: 100%;
  margin: 0 0 15px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .choice-item {
    width: calc(50% - 2.188vw);
    margin: 0.781vw 1.094vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section-type-2 .choice-item {
    width: calc(50% - 42px);
    margin: 15px 21px;
  }
}
.inner-pages-quiz-section-type-2 .choice-item [type=checkbox] {
  display: none;
}
.inner-pages-quiz-section-type-2 .choice-item [type=checkbox]:checked ~ .choice-label {
  background-color: #17fcc4;
  color: #002ea6;
}
.inner-pages-quiz-section-type-2 .choice-item [type=checkbox]:checked ~ .choice-label::before {
  background-color: #ededed;
}
.inner-pages-quiz-section-type-2 .choice-item [type=checkbox]:checked ~ .choice-label::after {
  opacity: 1;
}
.inner-pages-quiz-section-type-2 .choice-item .choice-label {
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 3px 20px 0.6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s cubic-bezier(0.61, 1, 0.88, 1), color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  font-size: 21px;
  padding: 31px 60px 31px 88px;
  border-radius: 45px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .choice-item .choice-label {
    font-size: 1.094vw;
    padding: 1.615vw 3.125vw 1.615vw 4.583vw;
    border-radius: 2.344vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section-type-2 .choice-item .choice-label {
    font-size: 21px;
    padding: 31px 60px 31px 88px;
    border-radius: 45px;
  }
}
.inner-pages-quiz-section-type-2 .choice-item .choice-label:hover::before, .inner-pages-quiz-section-type-2 .choice-item .choice-label:focus::before {
  background-color: #a2fee7;
}
.inner-pages-quiz-section-type-2 .choice-item .choice-label::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  backface-visibility: hidden;
  border-radius: 50%;
  transition: background-color 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  background-color: #ededed;
  left: 27px;
  width: 40px;
  height: 40px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .choice-item .choice-label::before {
    left: 1.406vw;
    width: 2.083vw;
    height: 2.083vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section-type-2 .choice-item .choice-label::before {
    left: 27px;
    width: 40px;
    height: 40px;
  }
}
.inner-pages-quiz-section-type-2 .choice-item .choice-label::after {
  content: "";
  display: inline-block;
  position: absolute;
  transform: rotate(-45deg) translateY(-50%);
  top: calc(50% - 3px);
  border-bottom: 3px solid #17fcc4;
  border-left: 3px solid #17fcc4;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  left: 44px;
  width: 12px;
  height: 6px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section-type-2 .choice-item .choice-label::after {
    left: 2.292vw;
    width: 0.625vw;
    height: 0.313vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section-type-2 .choice-item .choice-label::after {
    left: 44px;
    width: 12px;
    height: 6px;
  }
}

.inner-pages-quiz-section {
  background-color: #0633a8;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section {
    min-height: 110vh;
    display: flex;
    align-items: center;
    padding-top: 6.875vw;
    padding-bottom: 7.813vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section {
    padding-top: 132px;
    padding-bottom: 150px;
  }
}
.inner-pages-quiz-section .side-menu-btns {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  backface-visibility: hidden;
  margin-top: 30px;
}
@media screen and (max-width: 1024px) {
  .inner-pages-quiz-section .side-menu-btns {
    padding-top: 25px;
    align-items: flex-start;
    flex-flow: row wrap;
    justify-content: center;
    margin: 10px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section .side-menu-btns {
    flex-flow: row nowrap;
    justify-content: center;
    gap: 30px;
  }
}
@media screen and (max-width: 1024px) {
  .inner-pages-quiz-section .side-menu-quiz-btn {
    margin: 10px;
  }
}
.inner-pages-quiz-section .background-flower-path-patterns {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  .inner-pages-quiz-section .background-flower-path-patterns {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section .background-flower-path-patterns {
    left: -4.167vw;
    height: 75.417vw;
    width: 113.125vw;
  }
}
.inner-pages-quiz-section .background-flower-path-svg {
  flex: 0 0 auto;
  height: 50%;
  width: 33.33%;
  opacity: 0.1;
}
.inner-pages-quiz-section .background-flower-path-svg path {
  fill: transparent;
  stroke: #fff;
}
.inner-pages-quiz-section .max-w-container {
  z-index: 2;
}
.inner-pages-quiz-section .title-wrap-txt {
  margin-top: 25px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section .checkboxes-wrap {
    margin-left: 0.156vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section .checkboxes-wrap {
    margin-left: 3px;
  }
}
.inner-pages-quiz-section .checkboxes-hold {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}
.inner-pages-quiz-section .checkbox-row {
  margin-bottom: 7px;
}
@media screen and (max-width: 1024px) {
  .inner-pages-quiz-section .checkbox-row {
    width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section .checkbox-row:nth-of-type(odd) {
    width: 45%;
  }
  .inner-pages-quiz-section .checkbox-row:nth-of-type(even) {
    width: 55%;
  }
}
.inner-pages-quiz-section .checkboxes-wrap-title {
  margin-bottom: 25px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section .checkboxes-wrap-title {
    margin-bottom: 2.76vw;
    margin-left: 0.104vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section .checkboxes-wrap-title {
    margin-bottom: 53px;
    margin-left: 2px;
  }
}
.inner-pages-quiz-section .quiz-pagination-wrap {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section .quiz-pagination-wrap {
    margin-top: 2.396vw;
    margin-left: 0.104vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section .quiz-pagination-wrap {
    margin-top: 46px;
    margin-left: 2px;
  }
}
.inner-pages-quiz-section .quiz-pagination-wrap .buttons-wrap {
  margin-left: auto;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section .quiz-pagination-wrap .buttons-wrap {
    margin-left: 16.875vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section .quiz-pagination-wrap .buttons-wrap {
    margin-left: 324px;
  }
}
.inner-pages-quiz-section .seperator {
  position: relative;
  margin: 0 38px 0 44px;
  width: 15px;
  height: 29px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-quiz-section .seperator {
    margin: 0vw 1.979vw 0vw 2.292vw;
    width: 0.781vw;
    height: 1.51vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-quiz-section .seperator {
    margin: 0 38px 0 44px;
    width: 15px;
    height: 29px;
  }
}
.inner-pages-quiz-section .seperator::before {
  content: "";
  display: inline-block;
  backface-visibility: hidden;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(25deg);
  width: 2px;
  height: 100%;
  background-color: #17fcc4;
}

.inner-pages-slider-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-slider-section {
    padding-top: 5.104vw;
    padding-bottom: 6.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-slider-section {
    padding-top: 98px;
    padding-bottom: 116px;
  }
}
.inner-pages-slider-section .txt-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .inner-pages-slider-section .txt-content {
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-slider-section .txt-content {
    margin-bottom: 3.281vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-slider-section .txt-content {
    margin-bottom: 63px;
  }
}
.inner-pages-slider-section .swiper-container {
  overflow: visible;
  margin: 0 -15px;
}
@media screen and (max-width: 478px) {
  .inner-pages-slider-section .swiper-container {
    overflow: hidden;
  }
}
@media screen and (min-width: 400px) {
  .inner-pages-slider-section .swiper-container {
    margin: 0 -25px;
  }
}
@media screen and (min-width: 500px) {
  .inner-pages-slider-section .swiper-container {
    margin: 0 -42px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-slider-section .swiper-container {
    margin: 0vw -2.188vw;
    padding-left: 0.365vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-slider-section .swiper-container {
    margin: 0 -42px;
    padding-left: 7px;
  }
}
.inner-pages-slider-section .swiper-slide {
  box-sizing: content-box;
  padding: 0 15px;
}
@media screen and (min-width: 400px) {
  .inner-pages-slider-section .swiper-slide {
    padding: 0 25px;
  }
}
@media screen and (min-width: 500px) {
  .inner-pages-slider-section .swiper-slide {
    padding: 0 42px;
  }
}
@media screen and (max-width: 1024px) {
  .inner-pages-slider-section .swiper-slide {
    max-width: 518px;
    width: 75%;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-slider-section .swiper-slide {
    width: 26.979vw;
    padding: 0vw 2.188vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-slider-section .swiper-slide {
    width: 518px;
    padding: 0 42px;
  }
}
.inner-pages-slider-section .img-wrapper {
  margin-bottom: 15px;
  width: 100%;
}
@media screen and (min-width: 500px) {
  .inner-pages-slider-section .img-wrapper {
    margin-bottom: 28px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-slider-section .img-wrapper {
    margin-bottom: 1.458vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-slider-section .img-wrapper {
    margin-bottom: 28px;
  }
}
.inner-pages-slider-section .img-wrapper img {
  position: relative;
}
.inner-pages-slider-section .slide-title {
  margin-bottom: 8px;
}
@media screen and (min-width: 1025px) {
  .inner-pages-slider-section .slide-title {
    margin-bottom: 0.417vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-slider-section .slide-title {
    margin-bottom: 8px;
  }
}
.inner-pages-slider-section .txt-post-rtf {
  margin-bottom: 15px;
}
@media screen and (min-width: 500px) {
  .inner-pages-slider-section .txt-post-rtf {
    margin-bottom: 27px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-slider-section .txt-post-rtf {
    margin-bottom: 1.406vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-pages-slider-section .txt-post-rtf {
    margin-bottom: 27px;
  }
}
@media screen and (max-width: 767px) {
  .inner-pages-slider-section .btn-txt-arrow {
    margin-top: 25px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-pages-slider-section .btn-txt-arrow {
    margin-bottom: 16px;
    margin-right: 5px;
  }
}

.inner-txt-section-type-1 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.inner-txt-section-type-1 + .inner-txt-section-type-1 {
  padding-top: 0;
}
@media screen and (max-width: 550px) {
  .inner-txt-section-type-1 .txt-size-80 {
    max-width: 160vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .inner-txt-section-type-1 .txt-size-80 {
    max-width: 800px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-1 .txt-size-80 {
    max-width: 63.542vw;
    padding-left: 0.104vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-1 .txt-size-80 {
    max-width: 1220px;
    padding-left: 2px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-1 .txt-size-80.wider-inner-txt {
    max-width: 44.271vw;
    padding-left: 0.104vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-1 .txt-size-80.wider-inner-txt {
    max-width: 850px;
    padding-left: 2px;
  }
}
.inner-txt-section-type-1 .txt-size-30 {
  margin-top: 25px;
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-1 .txt-size-30 {
    margin-top: 2.917vw;
    margin-left: 0.313vw;
    max-width: 63.542vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-1 .txt-size-30 {
    margin-top: 56px;
    margin-left: 6px;
    max-width: 1220px;
  }
}
.inner-txt-section-type-1 .txt-blocks-content {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding-top: 25px;
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-1 .txt-blocks-content {
    padding-left: 0.313vw;
    padding-top: 4.167vw;
    margin: 0vw -3.906vw;
    max-width: 70.26vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-1 .txt-blocks-content {
    padding-left: 6px;
    padding-top: 80px;
    margin: 0 -75px;
    max-width: 1349px;
  }
}
.inner-txt-section-type-1 .txt-block {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .inner-txt-section-type-1 .txt-block:not(:last-of-type) {
    margin: 0 0 25px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-1 .txt-block {
    margin: 0vw 3.906vw;
    width: calc(50% - 7.812vw);
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-1 .txt-block {
    margin: 0 75px;
    width: calc(50% - 150px);
  }
}

.inner-txt-section-type-2 {
  background-color: #17fcc4;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-2 {
    padding-top: 11.615vw;
    padding-bottom: 13.594vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-2 {
    padding-top: 223px;
    padding-bottom: 261px;
  }
}
.inner-txt-section-type-2 .background-flower-path-patterns {
  pointer-events: none;
  position: absolute;
  top: 0;
  display: flex;
  flex-flow: row wrap;
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  .inner-txt-section-type-2 .background-flower-path-patterns {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-2 .background-flower-path-patterns {
    left: -4.167vw;
    height: 75.417vw;
    width: 113.125vw;
  }
}
.inner-txt-section-type-2 .background-flower-path-svg {
  flex: 0 0 auto;
  height: 50%;
  width: 33.33%;
  opacity: 0.5;
}
.inner-txt-section-type-2 .background-flower-path-svg path {
  fill: transparent;
  stroke: #fff;
}
.inner-txt-section-type-2 .max-w-container {
  z-index: 2;
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 1024px) {
  .inner-txt-section-type-2 .max-w-container {
    flex-flow: column nowrap;
  }
}
.inner-txt-section-type-2 .txt-size-80 {
  flex: 0 0 auto;
}
@media screen and (max-width: 550px) {
  .inner-txt-section-type-2 .txt-size-80 {
    max-width: 92vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .inner-txt-section-type-2 .txt-size-80 {
    max-width: 460px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-2 .txt-size-80 {
    max-width: 26.042vw;
    padding-left: 0.104vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-2 .txt-size-80 {
    max-width: 500px;
    padding-left: 2px;
  }
}
.inner-txt-section-type-2 .txt-post-rtf {
  margin-top: 25px;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-2 .txt-post-rtf {
    margin-left: 7.5vw;
    margin-top: 0.885vw;
    max-width: 26.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-2 .txt-post-rtf {
    margin-left: 144px;
    margin-top: 17px;
    max-width: 500px;
  }
}

.inner-txt-section-type-3 {
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-3 {
    padding-top: 8.75vw;
    padding-bottom: 9.896vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-3 {
    padding-top: 168px;
    padding-bottom: 190px;
  }
}
.inner-txt-section-type-3 .bg-image {
  z-index: 1;
  background-size: cover;
}
.inner-txt-section-type-3 .color-overlay {
  z-index: 2;
  background-color: rgba(0, 23, 83, 0.7);
}
.inner-txt-section-type-3 .max-w-container {
  z-index: 3;
}
.inner-txt-section-type-3 .txt-content {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
}
.inner-txt-section-type-3 .single-block {
  position: relative;
  flex: 0 1 auto;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-3 .single-block {
    width: 50%;
    padding-top: 1.406vw;
    padding-bottom: 1.615vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-3 .single-block {
    padding-top: 27px;
    padding-bottom: 31px;
  }
}
@media screen and (max-width: 1024px) {
  .inner-txt-section-type-3 .single-block:first-of-type {
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-3 .single-block:first-of-type {
    padding-right: 8.333vw;
  }
  .inner-txt-section-type-3 .single-block:first-of-type::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: calc(100% + 2px);
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-3 .single-block:first-of-type {
    padding-right: 210px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-3 .single-block:last-of-type {
    padding-left: 10.417vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-3 .single-block:last-of-type {
    padding-left: 266px;
  }
}
.inner-txt-section-type-3 .txt-size-48 {
  margin-bottom: 15px;
}
@media screen and (min-width: 500px) {
  .inner-txt-section-type-3 .txt-size-48 {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-3 .txt-size-48 {
    margin-bottom: 1.302vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-3 .txt-size-48 {
    margin-bottom: 25px;
  }
}
.inner-txt-section-type-3 .txt-post-rtf {
  margin-bottom: 15px;
}
@media screen and (min-width: 500px) {
  .inner-txt-section-type-3 .txt-post-rtf {
    margin-bottom: 28px;
  }
}
@media screen and (min-width: 1025px) {
  .inner-txt-section-type-3 .txt-post-rtf {
    margin-bottom: 1.458vw;
  }
}
@media screen and (min-width: 1921px) {
  .inner-txt-section-type-3 .txt-post-rtf {
    margin-bottom: 28px;
  }
}

.circle-info-white-section {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section {
    padding-top: 10.573vw;
    padding-bottom: 11.146vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section {
    padding-top: 203px;
    padding-bottom: 214px;
  }
}
.circle-info-white-section .arc-border-pink {
  border-radius: 50%;
  border: 1px solid rgba(252, 74, 107, 0.86);
  z-index: 3;
  transform: translateY(73.5%);
  opacity: 0;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section .arc-border-pink {
    display: none;
  }
}
.circle-info-white-section .arc-light-pink {
  border-radius: 50%;
  z-index: 2;
  background-color: #fec8d2;
  transform: translate(68%, 73.5%);
  opacity: 0;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section .arc-light-pink {
    display: none;
  }
}
.circle-info-white-section .info-white-section-img {
  z-index: 1;
  position: relative;
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section.offset-y-rates {
    padding-top: 9.688vw;
    padding-bottom: 8.646vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section.offset-y-rates {
    padding-top: 186px;
    padding-bottom: 166px;
  }
}
.circle-info-white-section .img-wrap {
  position: relative;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section .img-wrap {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section .img-wrap {
    width: 34.219vw;
    height: 34.01vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section .img-wrap {
    width: 657px;
    height: 653px;
  }
}
.circle-info-white-section .max-w-container {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section .max-w-container {
    flex-flow: row wrap;
  }
}
.circle-info-white-section .txt-content {
  flex: 0 0 auto;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section .txt-content {
    width: 20.833vw;
    padding-bottom: 0.417vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section .txt-content {
    width: 400px;
    padding-bottom: 8px;
  }
}
.circle-info-white-section .img-txt-col {
  position: relative;
  flex: 0 0 auto;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section .img-txt-col {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section .img-txt-col {
    padding-left: 2.344vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section .img-txt-col {
    padding-left: 45px;
  }
}
.circle-info-white-section .txt-size-60 {
  margin-bottom: 25px;
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section .txt-size-60 {
    margin-bottom: 1.615vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section .txt-size-60 {
    margin-bottom: 31px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section .txt-post-rtf {
    margin-left: 0.104vw;
    padding-right: 1.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section .txt-post-rtf {
    margin-left: 2px;
    padding-right: 20px;
  }
}
.circle-info-white-section .img-txt-content {
  position: relative;
  width: 100%;
  z-index: 4;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section .img-txt-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 30px;
    height: auto;
    width: 390px;
    border-radius: 15px;
    background: rgba(252, 74, 107, 0.86);
  }
}
@media screen and (max-width: 550px) {
  .circle-info-white-section .img-txt-content {
    width: 320px;
    padding: 30px 25px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section .img-txt-content {
    top: 0;
    right: 0;
    position: absolute;
    border-radius: 50%;
    background-color: rgba(252, 74, 107, 0.86);
    transform: translateX(68%);
    width: 34.219vw;
    height: 34.01vw;
    padding: 9.01vw 4.688vw 4.688vw 6.615vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section .img-txt-content {
    width: 657px;
    height: 653px;
    padding: 173px 90px 90px 127px;
  }
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section .img-txt-content {
    border-radius: 25px;
    margin-top: -15%;
  }
}
.circle-info-white-section .img-txt-col-person-name {
  position: relative;
  margin-left: 25px;
  padding-left: 27px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  font-family: "Libre Franklin", sans-serif;
  line-height: 30px;
  letter-spacing: 0.6px;
  color: #fff;
  margin-bottom: 0;
  font-weight: 600;
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section .img-txt-col-person-name {
    margin-left: 3.333vw;
    padding-left: 1.406vw;
    margin-top: 1.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section .img-txt-col-person-name {
    margin-left: 64px;
    padding-left: 27px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section .img-txt-col-person-name {
    padding: 30px 30px;
  }
}
@media screen and (max-width: 550px) {
  .circle-info-white-section .img-txt-col-person-name {
    padding: 0 25px;
    margin-top: 0;
  }
}
.circle-info-white-section .img-txt-col-person-name::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  background-color: #fff;
  height: 2px;
  top: calc(0.5em + 2px);
  width: 18px;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section .img-txt-col-person-name::before {
    top: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section .img-txt-col-person-name::before {
    width: 0.938vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section .img-txt-col-person-name::before {
    width: 18px;
  }
}
.circle-info-white-section .img-txt-col-person-position {
  margin-left: 52px;
  margin-top: 5px;
  font-weight: 400;
  font-size: 16px;
  font-family: "Libre Franklin", sans-serif;
  line-height: 30px;
  letter-spacing: 0.6px;
  color: #fff;
  margin-bottom: 0;
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section .img-txt-col-person-position {
    margin-left: 4.74vw;
    margin-top: 0.521vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section .img-txt-col-person-position {
    margin-left: 91px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section .circle-info-white-section .img-txt-content {
    border-radius: 25px;
    margin-top: -15%;
    padding-top: 0;
  }
}
.circle-info-white-section .img-txt-quote {
  color: #fff;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  position: relative;
  margin-bottom: 0;
}

.circle-info-white-section-2 {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: clip;
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-2 {
    padding-top: 8.333vw;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-2 {
    padding-top: 160px;
    padding-bottom: 139px;
  }
}
.circle-info-white-section-2 .info-white-section-img {
  position: relative;
  z-index: 2;
}
.circle-info-white-section-2 .circle-purple {
  left: inherit;
  border-radius: 50%;
  background-color: #fc4a6a;
  top: 0;
  right: 68%;
  z-index: 3;
  opacity: 0.7;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section-2 .circle-purple {
    display: none;
  }
}
.circle-info-white-section-2 .circle-border {
  left: inherit;
  border-radius: 50%;
  border: 1px solid rgba(27, 155, 203, 0.3);
  top: 73.5%;
  right: 68%;
  z-index: 2;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section-2 .circle-border {
    display: none;
  }
}
.circle-info-white-section-2 .circle-gray {
  left: inherit;
  border-radius: 50%;
  background-color: #f2f6fb;
  top: 73.3%;
  right: 0;
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section-2 .circle-gray {
    display: none;
  }
}
.circle-info-white-section-2 .img-width-wrap {
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section-2 .img-width-wrap {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
}
.circle-info-white-section-2 .img-wrap {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section-2 .img-wrap {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-2 .img-wrap {
    width: 28.646vw;
    height: 28.646vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-2 .img-wrap {
    width: 550px;
    height: 550px;
  }
}
.circle-info-white-section-2 .max-w-container {
  display: flex;
  align-items: flex-start;
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-2 .max-w-container {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section-2 .max-w-container {
    flex-flow: row wrap;
  }
}
.circle-info-white-section-2 .txt-content {
  flex: 0 1 auto;
  width: 100%;
  padding-top: 25px;
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-2 .txt-content {
    padding-top: 2.135vw;
    padding-left: 3.594vw;
    padding-right: 4.688vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-2 .txt-content {
    padding-top: 41px;
    padding-left: 69px;
    padding-right: 90px;
  }
}
.circle-info-white-section-2 .txt-size-60 {
  margin-bottom: 25px;
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-2 .txt-size-60 {
    margin-bottom: 1.615vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-2 .txt-size-60 {
    margin-bottom: 31px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-2 .txt-post-rtf {
    padding-right: 2.083vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-2 .txt-post-rtf {
    padding-right: 40px;
  }
}
.circle-info-white-section-2 .txt-post-rtf ul {
  list-style-type: none;
}
@media screen and (max-width: 550px) {
  .circle-info-white-section-2 .txt-post-rtf ul {
    margin: 6vw 0vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .circle-info-white-section-2 .txt-post-rtf ul {
    margin: 30px 0;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-2 .txt-post-rtf ul {
    margin: 1.563vw 0vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-2 .txt-post-rtf ul {
    margin: 30px 0;
  }
}
.circle-info-white-section-2 .txt-post-rtf ul li {
  padding-left: 17px;
  position: relative;
}
.circle-info-white-section-2 .txt-post-rtf ul li::before {
  content: "";
  position: absolute;
  display: inline-block;
  background-color: #002ea6;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  top: 0.65em;
  left: 4px;
}
.circle-info-white-section-2 .txt-post-rtf h2,
.circle-info-white-section-2 .txt-post-rtf .h2 {
  line-height: 1.2;
  margin-bottom: 15px;
}
@media screen and (max-width: 550px) {
  .circle-info-white-section-2 .txt-post-rtf h2,
  .circle-info-white-section-2 .txt-post-rtf .h2 {
    font-size: 6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .circle-info-white-section-2 .txt-post-rtf h2,
  .circle-info-white-section-2 .txt-post-rtf .h2 {
    font-size: 30px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-2 .txt-post-rtf h2,
  .circle-info-white-section-2 .txt-post-rtf .h2 {
    font-size: 1.563vw;
    margin-bottom: 2.083vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-2 .txt-post-rtf h2,
  .circle-info-white-section-2 .txt-post-rtf .h2 {
    font-size: 30px;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-2 .txt-post-rtf p {
    margin-left: 0.156vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-2 .txt-post-rtf p {
    margin-left: 3px;
  }
}
@media screen and (max-width: 550px) {
  .circle-info-white-section-2 .txt-post-rtf p:not(:last-of-type) {
    margin-bottom: 6.4vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .circle-info-white-section-2 .txt-post-rtf p:not(:last-of-type) {
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-2 .txt-post-rtf p:not(:last-of-type) {
    margin-bottom: 1.667vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-2 .txt-post-rtf p:not(:last-of-type) {
    margin-bottom: 32px;
  }
}

.circle-info-white-section-3 {
  overflow: clip;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-3 {
    padding-top: 6.146vw;
    padding-bottom: 13.333vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-3 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.circle-info-white-section-3 .info-white-section-img {
  position: relative;
  z-index: 2;
}
.circle-info-white-section-3 .circle-blue {
  left: inherit;
  border-radius: 50%;
  background-color: #002ea6;
  top: 0;
  left: 68%;
  z-index: 3;
  opacity: 0.2;
}
.circle-info-white-section-3 .circle-border {
  left: inherit;
  border-radius: 50%;
  border: 1px solid rgba(27, 155, 203, 0.3);
  top: 72.5%;
  left: 69.1%;
  z-index: 2;
}
.circle-info-white-section-3 .circle-gray {
  left: inherit;
  border-radius: 50%;
  background-color: #f2f6fb;
  top: 68%;
  left: -4.6%;
  z-index: 1;
}
.circle-info-white-section-3 .img-width-wrap {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section-3 .img-width-wrap {
    max-width: 400px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-3 .img-width-wrap {
    width: 28.646vw;
    height: 28.646vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-3 .img-width-wrap {
    width: 550px;
    height: 550px;
  }
}
.circle-info-white-section-3 .img-wrap {
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section-3 .img-wrap {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
}
.circle-info-white-section-3 .max-w-container {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section-3 .max-w-container {
    flex-flow: column-reverse;
  }
}
.circle-info-white-section-3 .txt-content {
  flex: 1 0 auto;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .circle-info-white-section-3 .txt-content {
    position: relative;
    z-index: 9;
    padding: 25px 0;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-3 .txt-content {
    width: 36.979vw;
    padding-top: 2.135vw;
    padding-left: 3.594vw;
    padding-right: 4.167vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-3 .txt-content {
    width: 710px;
    padding-top: 41px;
    padding-left: 69px;
    padding-right: 80px;
  }
}
.circle-info-white-section-3 .txt-size-60 {
  margin-bottom: 25px;
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-3 .txt-size-60 {
    margin-bottom: 2.5vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-3 .txt-size-60 {
    margin-bottom: 48px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-3 .txt-post-rtf {
    padding-right: 3.125vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-3 .txt-post-rtf {
    padding-right: 60px;
  }
}
.circle-info-white-section-3 .txt-post-rtf ul {
  list-style-type: none;
}
@media screen and (max-width: 550px) {
  .circle-info-white-section-3 .txt-post-rtf ul {
    margin: 6vw 0vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .circle-info-white-section-3 .txt-post-rtf ul {
    margin: 30px 0;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-3 .txt-post-rtf ul {
    margin: 1.563vw 0vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-3 .txt-post-rtf ul {
    margin: 30px 0;
  }
}
.circle-info-white-section-3 .txt-post-rtf ul li {
  padding-left: 17px;
  position: relative;
}
.circle-info-white-section-3 .txt-post-rtf ul li::before {
  content: "";
  position: absolute;
  display: inline-block;
  background-color: #002ea6;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  top: 0.65em;
  left: 4px;
}
.circle-info-white-section-3 .txt-post-rtf h2,
.circle-info-white-section-3 .txt-post-rtf .h2 {
  line-height: 1.2;
  margin-bottom: 15px;
  letter-spacing: 0.5px;
}
@media screen and (max-width: 550px) {
  .circle-info-white-section-3 .txt-post-rtf h2,
  .circle-info-white-section-3 .txt-post-rtf .h2 {
    font-size: 6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .circle-info-white-section-3 .txt-post-rtf h2,
  .circle-info-white-section-3 .txt-post-rtf .h2 {
    font-size: 30px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-3 .txt-post-rtf h2,
  .circle-info-white-section-3 .txt-post-rtf .h2 {
    letter-spacing: 0.026vw;
    font-size: 1.563vw;
    margin-bottom: 2.083vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-3 .txt-post-rtf h2,
  .circle-info-white-section-3 .txt-post-rtf .h2 {
    letter-spacing: 0.5px;
    font-size: 30px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 550px) {
  .circle-info-white-section-3 .txt-post-rtf p:not(:last-of-type) {
    margin-bottom: 6.4vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .circle-info-white-section-3 .txt-post-rtf p:not(:last-of-type) {
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-white-section-3 .txt-post-rtf p:not(:last-of-type) {
    margin-bottom: 1.667vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-white-section-3 .txt-post-rtf p:not(:last-of-type) {
    margin-bottom: 32px;
  }
}

.circle-info-blue-section {
  background-color: #002ea6;
  padding-top: 100px;
  padding-bottom: 100px;
}
.circle-info-blue-section h1,
.circle-info-blue-section h2,
.circle-info-blue-section h3,
.circle-info-blue-section h4,
.circle-info-blue-section h5,
.circle-info-blue-section h6,
.circle-info-blue-section .h1,
.circle-info-blue-section .h2,
.circle-info-blue-section .h3,
.circle-info-blue-section .h4,
.circle-info-blue-section .h5,
.circle-info-blue-section .h6,
.circle-info-blue-section span:not(.btn-txt),
.circle-info-blue-section a:not(button, .btn, .btn-primary, .btn-default, .btn-txt),
.circle-info-blue-section .title-highlight,
.circle-info-blue-section li,
.circle-info-blue-section p {
  color: #fff;
}
.circle-info-blue-section .content-wrap {
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .content-wrap {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .content-wrap {
    padding-top: 245px;
    padding-bottom: 248px;
  }
}
@media screen and (max-width: 550px) {
  .circle-info-blue-section .txt-size-12 {
    margin-bottom: 11.4vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .circle-info-blue-section .txt-size-12 {
    margin-bottom: 57px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .txt-size-12 {
    margin-bottom: 2.604vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .txt-size-12 {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 550px) {
  .circle-info-blue-section .txt-size-60 {
    margin-bottom: 30vw;
    max-width: 64vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .circle-info-blue-section .txt-size-60 {
    margin-bottom: 150px;
    max-width: 320px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .txt-size-60 {
    width: 20.313vw;
    margin-bottom: 1.875vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .txt-size-60 {
    width: 390px;
    margin-bottom: 36px;
  }
}
@media screen and (max-width: 1024px) {
  .circle-info-blue-section .txt-post-rtf br {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .circle-info-blue-section .txt-post-rtf {
    margin-bottom: 8vw;
    max-width: 50vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .circle-info-blue-section .txt-post-rtf {
    margin-bottom: 40px;
    max-width: 250px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .txt-post-rtf {
    width: 18.75vw;
    margin-bottom: 2.813vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .txt-post-rtf {
    width: 360px;
    margin-bottom: 54px;
  }
}
.circle-info-blue-section .txt-post-rtf strong,
.circle-info-blue-section .txt-post-rtf b {
  color: #17fcc4;
}
.circle-info-blue-section .txt-post-rtf h2,
.circle-info-blue-section .txt-post-rtf .h2 {
  line-height: 1.2;
  margin-bottom: 15px;
}
@media screen and (max-width: 550px) {
  .circle-info-blue-section .txt-post-rtf h2,
  .circle-info-blue-section .txt-post-rtf .h2 {
    font-size: 6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .circle-info-blue-section .txt-post-rtf h2,
  .circle-info-blue-section .txt-post-rtf .h2 {
    font-size: 30px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .txt-post-rtf h2,
  .circle-info-blue-section .txt-post-rtf .h2 {
    font-size: 1.563vw;
    margin-bottom: 2.083vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .txt-post-rtf h2,
  .circle-info-blue-section .txt-post-rtf .h2 {
    font-size: 30px;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .btn-wrap {
    margin-left: -0.521vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .btn-wrap {
    margin-left: -10px;
  }
}
@media screen and (max-width: 550px) {
  .circle-info-blue-section .btn-default {
    letter-spacing: 0.06vw;
    padding: 4.8vw 9.6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .circle-info-blue-section .btn-default {
    letter-spacing: 0.3px;
    padding: 24px 48px;
  }
}
.circle-info-blue-section .inner-img-wrap {
  border-radius: 50%;
  overflow: hidden;
}
.circle-info-blue-section .mobile-image {
  display: none;
}
@media screen and (max-width: 1024px) {
  .circle-info-blue-section .mobile-image {
    display: block;
  }
}
.circle-info-blue-section .desktop-image {
  display: none;
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .desktop-image {
    display: block;
  }
}
.circle-info-blue-section .inner-circles-wrap {
  position: absolute;
  border-radius: 50%;
  top: 0;
  width: 280px;
  height: 280px;
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .inner-circles-wrap {
    width: 14.583vw;
    height: 14.583vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .inner-circles-wrap {
    width: 280px;
    height: 280px;
  }
}
.circle-info-blue-section .inner-circles-wrap.inner-left-circle {
  right: 100%;
}
.circle-info-blue-section .inner-circles-wrap.inner-right-circle {
  left: 100%;
}
.circle-info-blue-section .border-circle {
  stroke: #4d6dc1;
  stroke-width: 0.5px;
}
.circle-info-blue-section .circle-full .inner-circle-base {
  background-color: #12d3be;
}
.circle-info-blue-section .circle-full .inner-circle-outer {
  background-color: #002ea6;
}
.circle-info-blue-section .circle-full .inner-circle-core {
  background-color: #12d3be;
}
.circle-info-blue-section .circle-opacity .inner-circle-base {
  background-color: #0557ac;
}
.circle-info-blue-section .circle-opacity .inner-circle-outer {
  background-color: #002ea6;
}
.circle-info-blue-section .circle-opacity .inner-circle-core {
  background-color: #0557ac;
}
.circle-info-blue-section.bg-white .circle-opacity .inner-circle-outer, .circle-info-blue-section.bg-white .circle-full .inner-circle-outer {
  background-color: #fff;
}
.circle-info-blue-section .inner-circle-base {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: 1;
}
.circle-info-blue-section .inner-circle-outer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
  border-radius: 50%;
  width: 182px;
  height: 182px;
  z-index: 2;
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .inner-circle-outer {
    width: 9.479vw;
    height: 9.479vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .inner-circle-outer {
    width: 182px;
    height: 182px;
  }
}
.circle-info-blue-section .inner-circle-core {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  z-index: 3;
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .inner-circle-core {
    width: 3.646vw;
    height: 3.646vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .inner-circle-core {
    width: 70px;
    height: 70px;
  }
}
.circle-info-blue-section .images-wrap {
  position: absolute;
  width: 280px;
  height: 280px;
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .images-wrap {
    width: 14.583vw;
    height: 14.583vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .images-wrap {
    width: 280px;
    height: 280px;
  }
}
@media screen and (max-width: 1024px) {
  .circle-info-blue-section .images-top {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .images-top {
    right: -2.813vw;
    top: 0px;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .images-top {
    right: -54px;
  }
}
@media screen and (max-width: 550px) {
  .circle-info-blue-section .images-mid {
    top: 10vw;
    left: 42vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .circle-info-blue-section .images-mid {
    top: 50px;
    left: 210px;
  }
  .circle-info-blue-section .images-mid .inner-img-wrap {
    width: 450px;
    height: 450px;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .images-mid {
    right: 11.719vw;
    top: 14.583vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .images-mid {
    right: 225px;
    top: 280px;
  }
}
@media screen and (max-width: 1024px) {
  .circle-info-blue-section .images-bot {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .circle-info-blue-section .images-bot {
    right: -17.24vw;
    bottom: 0.156vw;
  }
}
@media screen and (min-width: 1921px) {
  .circle-info-blue-section .images-bot {
    right: -331px;
    bottom: 3px;
  }
}

.hero .txt-content {
  max-width: 65%;
}
@media screen and (max-width: 1024px) {
  .hero .txt-content {
    max-width: 70%;
  }
}
@media screen and (max-width: 767px) {
  .hero .txt-content {
    max-width: 95%;
  }
}
.hero.inner-pages-landing-section .single-txt-block {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 30px;
  padding-top: 0;
}
.hero .hero-transparent-icon {
  position: absolute;
  width: 275px;
  height: auto;
  top: auto;
  bottom: 0;
  right: 0;
}

.sekure-block.circle-info-blue-section .image-group {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: auto;
  z-index: 0;
}
@media screen and (max-width: 1024px) {
  .sekure-block.circle-info-blue-section .image-group {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
  }
}
.sekure-block.circle-info-blue-section .images-wrap {
  position: relative;
  top: auto;
  bottom: auto;
  right: auto;
  left: auto;
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
}
.sekure-block.circle-info-blue-section .images-wrap .circle-img {
  width: 14.583vw;
  height: 14.583vw;
}
.sekure-block.circle-info-blue-section .inner-circles-wrap {
  position: relative;
}
.sekure-block.circle-info-blue-section .inner-circles-wrap.inner-left-circle {
  right: auto;
}
.sekure-block.circle-info-blue-section.circle-images-right.image-left .max-w-container {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 1024px) {
  .sekure-block.circle-info-blue-section.circle-images-right.image-left .max-w-container {
    display: block;
  }
}
.sekure-block.circle-info-blue-section.circle-images-right.image-left .images-wrap.images-mid {
  left: 14.583vw;
}
.sekure-block.circle-info-blue-section.circle-images-right.image-left .inner-circles-wrap.inner-right-circle {
  left: auto;
}
.sekure-block.circle-info-blue-section.circle-images-right.image-left .txt-content {
  width: 55%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
@media screen and (max-width: 1024px) {
  .sekure-block.circle-info-blue-section.circle-images-right.image-left .txt-content {
    width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .sekure-block.circle-info-blue-section.circle-images-right.image-left .inner-circles-wrap.mobile {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .sekure-block.circle-info-blue-section.circle-images-right.image-left .images-wrap .circle-img,
  .sekure-block.circle-info-blue-section.circle-images-right.image-left .inner-circles-wrap,
  .sekure-block.circle-info-blue-section.circle-images-right.image-left .images-mid .inner-img-wrap {
    width: 25vw;
    height: 25vw;
  }
  .sekure-block.circle-info-blue-section.circle-images-right.image-left .inner-circle-outer {
    height: 17vw;
    width: 17vw;
  }
  .sekure-block.circle-info-blue-section.circle-images-right.image-left .inner-circles-wrap.desktop {
    display: none;
  }
  .sekure-block.circle-info-blue-section.circle-images-right.image-left .images-wrap.images-mid {
    left: auto;
  }
}

.block-mc.inner-pages-slider-section .txt-content {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}
.block-mc.inner-pages-slider-section .btn-txt-arrow {
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Libre Franklin";
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .block-mc.inner-pages-slider-section .btn-txt-arrow {
    position: relative;
    top: auto;
    right: auto;
  }
}

@media (min-width: 1025px) {
  .two-columns-flex .row.image-left {
    flex-direction: row-reverse;
  }
}
@media (max-width: 1024px) {
  .two-columns-flex .row.image-left {
    flex-direction: column-reverse;
  }
  .two-columns-flex .row {
    flex-direction: column-reverse;
  }
}
.sekure-block .block-list .item-num, .sk-block .block-list .item-num {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85px;
  width: 85px;
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background: #002ea6;
  border-radius: 50%;
}

.whole-block-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  color: transparent !important;
  background-color: transparent !important;
  text-decoration: none;
  font-size: 16px;
}
.whole-block-link br {
  display: none;
}

.careers-page-search-jobs-section .max-w-container {
  z-index: 2222;
}

.benefits-section-careers-page .max-w-container {
  z-index: 2222;
}

.blocks-grid-section {
  background-color: #002ea6;
}
.blocks-grid-section .rates-title,
.blocks-grid-section .rates-title a {
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
}
.blocks-grid-section .blocks-wrap {
  display: flex;
  flex-flow: row wrap;
}
.blocks-grid-section .single-block {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .blocks-grid-section .single-block {
    position: relative;
  }
  .blocks-grid-section .single-block:not(:last-of-type) {
    border-bottom: 1px solid #fff;
  }
}
.blocks-grid-section .single-block:hover h2 a {
  position: relative;
}
.blocks-grid-section .single-block:hover h2 a::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-bottom: 1px solid #fff;
}
@media screen and (min-width: 1025px) {
  .blocks-grid-section .single-block {
    width: 50%;
    padding-top: 5.781vw;
    padding-bottom: 7.448vw;
  }
}
@media screen and (min-width: 1921px) {
  .blocks-grid-section .single-block {
    padding-top: 111px;
    padding-bottom: 143px;
  }
}
@media screen and (min-width: 1025px) {
  .blocks-grid-section .single-block:nth-of-type(1) {
    padding-top: 5.781vw;
    padding-right: 6.771vw;
  }
  .blocks-grid-section .single-block:nth-of-type(1) .bg-image {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    top: 0;
    left: 0;
  }
}
@media screen and (min-width: 1921px) {
  .blocks-grid-section .single-block:nth-of-type(1) {
    padding-top: 111px;
    padding-right: 130px;
  }
}
@media screen and (min-width: 1025px) {
  .blocks-grid-section .single-block:nth-of-type(2) {
    padding-left: 8.333vw;
  }
  .blocks-grid-section .single-block:nth-of-type(2) .bg-image {
    border-bottom: 1px solid #fff;
    top: 0;
    right: 0;
  }
}
@media screen and (min-width: 1921px) {
  .blocks-grid-section .single-block:nth-of-type(2) {
    padding-left: 160px;
  }
}
@media screen and (min-width: 1025px) {
  .blocks-grid-section .single-block:nth-of-type(2) .txt-post-rtf {
    margin-right: -1.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .blocks-grid-section .single-block:nth-of-type(2) .txt-post-rtf {
    margin-right: -20px;
  }
}
@media screen and (min-width: 1025px) {
  .blocks-grid-section .single-block:nth-of-type(3) {
    padding-top: 4.844vw;
    padding-right: 6.771vw;
    padding-bottom: 8.281vw;
  }
  .blocks-grid-section .single-block:nth-of-type(3) .bg-image {
    border-right: 1px solid #fff;
    bottom: 0;
    left: 0;
  }
}
@media screen and (min-width: 1921px) {
  .blocks-grid-section .single-block:nth-of-type(3) {
    padding-top: 93px;
    padding-right: 130px;
    padding-bottom: 159px;
  }
}
@media screen and (min-width: 1025px) {
  .blocks-grid-section .single-block:nth-of-type(4) {
    padding-left: 8.333vw;
    padding-top: 4.844vw;
    padding-bottom: 8.281vw;
  }
  .blocks-grid-section .single-block:nth-of-type(4) .bg-image {
    bottom: 0;
    right: 0;
  }
}
@media screen and (min-width: 1921px) {
  .blocks-grid-section .single-block:nth-of-type(4) {
    padding-left: 160px;
    padding-top: 93px;
    padding-bottom: 159px;
  }
}
@media screen and (min-width: 1025px) {
  .blocks-grid-section .single-block:nth-of-type(4) .txt-post-rtf {
    margin-right: -1.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .blocks-grid-section .single-block:nth-of-type(4) .txt-post-rtf {
    margin-right: -20px;
  }
}
.blocks-grid-section .bg-image {
  background-size: cover;
  z-index: 1;
}
@media screen and (min-width: 1025px) {
  .blocks-grid-section .bg-image {
    left: inherit;
    top: inherit;
    width: 50%;
    height: 50%;
  }
}
.blocks-grid-section .txt-size-48 {
  position: relative;
  z-index: 2;
}
.blocks-grid-section .txt-size-48 a {
  color: inherit;
}
.blocks-grid-section .txt-post-rtf {
  position: relative;
  z-index: 2;
  margin-top: 25px;
}
@media screen and (min-width: 1025px) {
  .blocks-grid-section .txt-post-rtf {
    margin-top: 1.719vw;
    margin-left: 0.156vw;
  }
}
@media screen and (min-width: 1921px) {
  .blocks-grid-section .txt-post-rtf {
    margin-top: 33px;
    margin-left: 3px;
  }
}

.text-page-content-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 1025px) {
  .text-page-content-section {
    padding-top: 4.583vw;
    padding-bottom: 3.229vw;
  }
}
@media screen and (min-width: 1921px) {
  .text-page-content-section {
    padding-top: 88px;
    padding-bottom: 62px;
  }
}
.text-page-content-section .max-w-container {
  z-index: 2;
}
.text-page-content-section .txt-size-72 {
  margin-bottom: 28px;
}
@media screen and (min-width: 1025px) {
  .text-page-content-section .txt-size-72 {
    margin-bottom: 1.458vw;
  }
}
@media screen and (min-width: 1921px) {
  .text-page-content-section .txt-size-72 {
    margin-bottom: 28px;
  }
}
@media screen and (min-width: 1025px) {
  .text-page-content-section .txt-post-rtf {
    margin-left: 0.208vw;
  }
}
@media screen and (min-width: 1921px) {
  .text-page-content-section .txt-post-rtf {
    margin-left: 4px;
  }
}
.text-page-content-section .circles-wrapper {
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .text-page-content-section .circles-wrapper {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .text-page-content-section .circles-wrapper {
    width: 34.219vw;
    height: 34.219vw;
  }
}
@media screen and (min-width: 1921px) {
  .text-page-content-section .circles-wrapper {
    width: 657px;
    height: 657px;
  }
}
.text-page-content-section .circles-wrapper div {
  border-radius: 50%;
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
@media screen and (min-width: 1025px) {
  .text-page-content-section .circles-1 {
    top: -9.688vw;
    left: -17.708vw;
  }
}
@media screen and (min-width: 1921px) {
  .text-page-content-section .circles-1 {
    top: -186px;
    left: -340px;
  }
}
.text-page-content-section .circles-1 .circle-full {
  opacity: 0.2;
  background-color: #002ea6;
}
.text-page-content-section .circles-1 .circle-border {
  opacity: 0.2;
  border: 1px solid #002ea6;
  transform: translate(1%, 73%);
}
@media screen and (min-width: 1025px) {
  .text-page-content-section .circles-2 {
    top: 12.8%;
    right: -19.948vw;
  }
}
@media screen and (min-width: 1921px) {
  .text-page-content-section .circles-2 {
    right: -383px;
  }
}
.text-page-content-section .circles-2 .circle-full {
  opacity: 0.1;
  background-color: #ffb3c8;
  transform: translate(0%, 73%);
}
.text-page-content-section .circles-2 .circle-border {
  border: 1px solid #ffb3c8;
}
@media screen and (min-width: 1025px) {
  .text-page-content-section .circles-3 {
    top: 26%;
    left: -17.708vw;
  }
}
@media screen and (min-width: 1921px) {
  .text-page-content-section .circles-3 {
    left: -340px;
  }
}
.text-page-content-section .circles-3 .circle-border {
  opacity: 0.7;
  border: 1px solid #17fcc4;
  transform: translate(1%, 73%);
}
@media screen and (min-width: 1025px) {
  .text-page-content-section .circles-4 {
    top: 40.5%;
    right: -15.833vw;
  }
}
@media screen and (min-width: 1921px) {
  .text-page-content-section .circles-4 {
    right: -304px;
  }
}
.text-page-content-section .circles-4 .circle-border {
  opacity: 0.7;
  border: 1px solid #17fcc4;
  transform: translate(1%, 73%);
}
@media screen and (min-width: 1025px) {
  .text-page-content-section .circles-5 {
    top: 57.5%;
    left: -17.135vw;
  }
}
@media screen and (min-width: 1921px) {
  .text-page-content-section .circles-5 {
    left: -329px;
  }
}
.text-page-content-section .circles-5 .circle-border {
  opacity: 0.7;
  border: 1px solid #17fcc4;
  transform: translate(1%, 73%);
}
@media screen and (min-width: 1025px) {
  .text-page-content-section .circles-6 {
    top: 77.6%;
    right: -16.823vw;
  }
}
@media screen and (min-width: 1921px) {
  .text-page-content-section .circles-6 {
    right: -323px;
  }
}
.text-page-content-section .circles-6 .circle-border {
  opacity: 0.7;
  border: 1px solid #17fcc4;
  transform: translate(1%, 73%);
}

.txt-btn-info-blue-section {
  background-color: #002ea6;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 1025px) {
  .txt-btn-info-blue-section {
    padding-top: 6.875vw;
    padding-bottom: 5.885vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-btn-info-blue-section {
    padding-top: 132px;
    padding-bottom: 113px;
  }
}
@media screen and (max-width: 550px) {
  .txt-btn-info-blue-section .txt-size-80 {
    max-width: 136vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-btn-info-blue-section .txt-size-80 {
    max-width: 680px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-btn-info-blue-section .txt-size-80 {
    max-width: 57.292vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-btn-info-blue-section .txt-size-80 {
    max-width: 1100px;
  }
}
.txt-btn-info-blue-section .txt-blocks-content {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding-top: 25px;
}
@media screen and (min-width: 1025px) {
  .txt-btn-info-blue-section .txt-blocks-content {
    padding-left: 0.104vw;
    padding-top: 4.375vw;
    margin: 0vw -3.542vw;
    max-width: 70.833vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-btn-info-blue-section .txt-blocks-content {
    padding-left: 2px;
    padding-top: 84px;
    margin: 0 -68px;
    max-width: 1360px;
  }
}
.txt-btn-info-blue-section .btn-wrap {
  margin-top: 25px;
}
@media screen and (min-width: 1025px) {
  .txt-btn-info-blue-section .btn-wrap {
    margin-top: 2.5vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-btn-info-blue-section .btn-wrap {
    margin-top: 48px;
  }
}
.txt-btn-info-blue-section .txt-block {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .txt-btn-info-blue-section .txt-block:not(:last-of-type) {
    margin: 0 0 25px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-btn-info-blue-section .txt-block {
    margin: 0vw 3.542vw;
    width: calc(50% - 7.083vw);
  }
  .txt-btn-info-blue-section .txt-block:last-of-type .txt-post-rtf {
    padding-right: 2.604vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-btn-info-blue-section .txt-block {
    margin: 0 68px;
    width: calc(50% - 136px);
  }
  .txt-btn-info-blue-section .txt-block:last-of-type .txt-post-rtf {
    padding-right: 50px;
  }
}

.reviews-heading-row {
  vertical-align: bottom !important;
}

.four-column-table tbody tr td:first-of-type {
  text-align: left;
  color: #5b5b5b;
}
.four-column-table tbody tr td:not(:first-of-type) {
  color: #666666;
}
.four-column-table tbody tr:nth-of-type(odd) {
  background-color: #f3fffc;
}

.page.page-id-1526 .inner-pages-landing-section.rates-page-section .content-wrap,
.page.page-id-28057 .inner-pages-landing-section.rates-page-section .content-wrap,
.page.page-id-32709 .inner-pages-landing-section.rates-page-section .content-wrap {
  padding-bottom: 250px;
}
@media screen and (max-width: 1024px) {
  .page.page-id-1526 .inner-pages-landing-section.rates-page-section .content-wrap,
  .page.page-id-28057 .inner-pages-landing-section.rates-page-section .content-wrap,
  .page.page-id-32709 .inner-pages-landing-section.rates-page-section .content-wrap {
    padding-bottom: 100px;
  }
}
.page.page-id-1526 .contact-tabs .testimonial-person,
.page.page-id-28057 .contact-tabs .testimonial-person,
.page.page-id-32709 .contact-tabs .testimonial-person {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: contain !important;
  object-position: center;
}
.page.page-id-1526 .contact-tabs .container,
.page.page-id-28057 .contact-tabs .container,
.page.page-id-32709 .contact-tabs .container {
  max-width: unset !important;
  padding-right: 0;
  padding-left: 0;
}

.txt-blocks-layout-section {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section {
    padding-top: 5.052vw;
    padding-bottom: 5.781vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section {
    padding-top: 97px;
    padding-bottom: 111px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section.contacts-page-tabs-section {
    padding-top: 5.052vw;
    padding-bottom: 0;
  }
  .txt-blocks-layout-section.contacts-page-tabs-section .contact-tabs {
    padding-bottom: 5.781vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section.contacts-page-tabs-section {
    padding-top: 97px;
    padding-bottom: 0;
  }
  .txt-blocks-layout-section.contacts-page-tabs-section .contact-tabs {
    padding-bottom: 111px;
  }
}
.txt-blocks-layout-section .background-svg-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .background-svg-wrap {
    width: 65.625vw;
    height: 65.625vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .background-svg-wrap {
    width: 1260px;
    height: 1260px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .background-svg-wrap .background-flower-path-svg {
    width: 32.813vw;
    height: 32.813vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .background-svg-wrap .background-flower-path-svg {
    width: 630px;
    height: 630px;
  }
}
.txt-blocks-layout-section .background-svg-wrap .st0 {
  fill: none;
  stroke-miterlimit: 10;
}
.txt-blocks-layout-section .background-svg-wrap .svg-full {
  opacity: 0.1;
}
.txt-blocks-layout-section .background-svg-wrap .svg-full .st0 {
  fill: #7f96d2;
}
.txt-blocks-layout-section .background-svg-wrap .svg-stroke {
  opacity: 0.2;
}
.txt-blocks-layout-section .background-svg-wrap .svg-stroke .st0 {
  stroke: #7f96d2;
}
@media screen and (max-width: 1024px) {
  .txt-blocks-layout-section .background-svg-wrap {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .svg-wrap-top-left {
    top: -25.469vw;
    left: -16.51vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .svg-wrap-top-left {
    top: -489px;
    left: -317px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .svg-wrap-top-left.svg-contacts {
    top: 0vw;
    left: -16.51vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .svg-wrap-top-left.svg-contacts {
    top: 0;
    left: -317px;
  }
}
.txt-blocks-layout-section .svg-wrap-middle-right {
  justify-content: flex-end;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .svg-wrap-middle-right {
    top: 11.771vw;
    right: -16.406vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .svg-wrap-middle-right {
    top: 226px;
    right: -315px;
  }
}
.txt-blocks-layout-section .content-block {
  padding-top: 47px;
  padding-bottom: 47px;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .content-block {
    padding-top: 2.448vw;
    padding-bottom: 2.448vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .content-block {
    padding-top: 47px;
    padding-bottom: 47px;
  }
}
.txt-blocks-layout-section .content-block:first-of-type {
  padding-top: 0;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .content-block:first-of-type {
    padding-top: 0;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .content-block:first-of-type {
    padding-top: 0;
  }
}
.txt-blocks-layout-section .content-block:last-of-type {
  padding-bottom: 0;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .content-block:last-of-type {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .content-block:last-of-type {
    padding-bottom: 0;
  }
}
.txt-blocks-layout-section .content-block:not(:last-of-type):not(.faq-block):not(.table-content-block) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.txt-blocks-layout-section .content-block.table-content-block {
  padding-bottom: 0;
}
.txt-blocks-layout-section .person-quote-block {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .txt-blocks-layout-section .person-quote-block {
    flex-flow: column nowrap;
  }
}
.txt-blocks-layout-section .person-quote-block .person-img-wrap {
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 auto;
  width: 150px;
  height: 150px;
}
@media screen and (max-width: 767px) {
  .txt-blocks-layout-section .person-quote-block .person-img-wrap {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .txt-blocks-layout-section .person-quote-block .person-img-wrap {
    margin-right: 24px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .person-quote-block .person-img-wrap {
    width: 7.813vw;
    height: 7.813vw;
    margin-right: 1.25vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .person-quote-block .person-img-wrap {
    width: 150px;
    height: 150px;
    margin-right: 24px;
  }
}
.txt-blocks-layout-section .person-quote-block .txt-content {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .txt-blocks-layout-section .person-quote-block .txt-content {
    flex-flow: column nowrap;
  }
}
.txt-blocks-layout-section .person-quote-block .txt-content-inner {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;
  margin-top: -4px;
  max-width: 730px;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .person-quote-block .txt-content-inner {
    margin-top: -0.208vw;
    max-width: 38.021vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .person-quote-block .txt-content-inner {
    margin-top: -4px;
    max-width: 730px;
  }
}
.txt-blocks-layout-section .person-quote-block .quote-img-wrap {
  flex: 0 0 auto;
  width: 26px;
  height: 24px;
}
@media screen and (max-width: 767px) {
  .txt-blocks-layout-section .person-quote-block .quote-img-wrap {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .txt-blocks-layout-section .person-quote-block .quote-img-wrap {
    margin-right: 24px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .person-quote-block .quote-img-wrap {
    width: 1.354vw;
    height: 1.25vw;
    margin-right: 1.25vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .person-quote-block .quote-img-wrap {
    width: 26px;
    height: 24px;
    margin-right: 24px;
  }
}
.txt-blocks-layout-section .person-quote-block .quote-by-txt {
  margin-bottom: 27px;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .person-quote-block .quote-by-txt {
    margin-bottom: 0.417vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .person-quote-block .quote-by-txt {
    margin-bottom: 8px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .txt-block {
    padding-top: 4.323vw;
    padding-bottom: 4.323vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .txt-block {
    padding-top: 83px;
    padding-bottom: 83px;
  }
}
.txt-blocks-layout-section .txt-block .txt-blocks {
  display: flex;
  flex-flow: row wrap;
  max-width: 1280px;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .txt-block .txt-blocks {
    margin: 0vw -1.042vw;
    max-width: 66.667vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .txt-block .txt-blocks {
    margin: 0 -20px;
    max-width: 1280px;
  }
}
@media screen and (max-width: 1024px) {
  .txt-blocks-layout-section .txt-block .txt-content {
    width: 100%;
  }
  .txt-blocks-layout-section .txt-block .txt-content:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .txt-block .txt-content {
    margin: 0vw 1.042vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .txt-block .txt-content {
    margin: 0 20px;
  }
}
.txt-blocks-layout-section .txt-post-rtf a[href^="tel:"]:not(.btn-default) {
  color: #fe295b;
  text-decoration: none;
}
.txt-blocks-layout-section .txt-post-rtf a[href^="tel:"]:not(.btn-default):hover, .txt-blocks-layout-section .txt-post-rtf a[href^="tel:"]:not(.btn-default):focus {
  text-decoration: underline;
}
.txt-blocks-layout-section .txt-post-rtf a.btn-default[href^="tel:"] {
  color: #001a5d !important;
}
.txt-blocks-layout-section .txt-post-rtf a.btn-default[href^="tel:"]:hover,
.txt-blocks-layout-section .txt-post-rtf a.btn-default[href^="tel:"]:focus {
  color: #17fcc4 !important;
}
.txt-blocks-layout-section .txt-block-title {
  margin-bottom: 25px;
  max-width: 850px;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .txt-block-title {
    margin-bottom: 3.125vw;
    max-width: 44.271vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .txt-block-title {
    margin-bottom: 60px;
    max-width: 850px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .txt-cols-2 .txt-content {
    width: calc(50% - 2.084vw);
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .txt-cols-2 .txt-content {
    width: calc(50% - 40px);
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .txt-cols-3 .txt-content {
    width: calc(33.33% - 2.084vw);
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .txt-cols-3 .txt-content {
    width: calc(33.33% - 40px);
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .txt-cols-4 .txt-content {
    width: calc(25% - 2.084vw);
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .txt-cols-4 .txt-content {
    width: calc(25% - 40px);
  }
}
.txt-blocks-layout-section .full-width-block {
  display: flex;
  flex-flow: row wrap;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .full-width-block {
    margin: 0;
  }
}
.txt-blocks-layout-section .full-width-block .txt-content {
  margin: 0;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .full-width-block .txt-content {
    width: 50%;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .full-width-block .txt-content:not(.table-block) {
    padding-right: 7.292vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .full-width-block .txt-content:not(.table-block) {
    padding-right: 140px;
  }
}
.txt-blocks-layout-section .images-block.reviews-images-block {
  margin: 20px 0;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .images-block.reviews-images-block {
    margin: 30px 0;
  }
}
.txt-blocks-layout-section .images-block .section-title {
  margin-bottom: 30px;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .images-block .section-title {
    margin-bottom: 2.292vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .images-block .section-title {
    margin-bottom: 44px;
  }
}
.txt-blocks-layout-section .images-block .images-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  margin: -15px;
}
@media screen and (max-width: 1024px) {
  .txt-blocks-layout-section .images-block .images-wrap {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .images-block .images-wrap {
    margin: -0.521vw -0.885vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .images-block .images-wrap {
    margin: -10px -17px;
  }
}
@media screen and (max-width: 1024px) {
  .txt-blocks-layout-section .images-block .images-wrap.landing-images-block {
    align-items: flex-end;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .txt-blocks-layout-section .images-block .images-wrap.landing-images-block {
    justify-content: flex-start;
  }
}
.txt-blocks-layout-section .images-block .images-wrap.landing-images-block .image-item img {
  width: 100%;
  max-width: 120px;
  max-height: 100%;
}
.txt-blocks-layout-section .images-block .image-item {
  flex: 0 1 auto;
  margin: 0 15px;
}
@media screen and (max-width: 767px) {
  .txt-blocks-layout-section .images-block .image-item {
    width: calc(50% - 30px);
  }
}
@media screen and (max-width: 1024px) {
  .txt-blocks-layout-section .images-block .image-item {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .images-block .image-item {
    align-self: last baseline;
  }
}
.txt-blocks-layout-section .icons-content-block-horizontal {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin: 0 0 -25px;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .icons-content-block-horizontal {
    margin: -1.042vw 0vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .icons-content-block-horizontal {
    margin: -20px 0px;
  }
}
.txt-blocks-layout-section .icons-content-block-horizontal .txt-post-rtf {
  width: 100%;
}
.txt-blocks-layout-section .icons-content-block-horizontal .icons-block-item {
  display: flex;
  margin: 0 0 25px;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .txt-blocks-layout-section .icons-content-block-horizontal .icons-block-item {
    align-items: flex-start;
    flex-flow: column nowrap;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .icons-content-block-horizontal .icons-block-item {
    align-items: flex-start;
    width: 50%;
    margin: 1.042vw 0vw;
    padding-right: 4.167vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .icons-content-block-horizontal .icons-block-item {
    margin: 20px 0px;
    padding-right: 80px;
  }
}
.txt-blocks-layout-section .icons-content-block-horizontal .img-wrap {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  margin-bottom: 25px;
}
@media screen and (max-width: 550px) {
  .txt-blocks-layout-section .icons-content-block-horizontal .img-wrap {
    height: 18vw;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-blocks-layout-section .icons-content-block-horizontal .img-wrap {
    height: 90px;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .icons-content-block-horizontal .img-wrap {
    height: 7.813vw;
    margin-bottom: 1.302vw;
    padding-right: 2.083vw;
    width: 13.021vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .icons-content-block-horizontal .img-wrap {
    height: 150px;
    margin-bottom: 25px;
    padding-right: 40px;
    width: 250px;
  }
}
.txt-blocks-layout-section .icons-content-block-horizontal .img-wrap img {
  max-height: unset;
}
@media screen and (max-width: 1024px) {
  .txt-blocks-layout-section .table-block {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .txt-blocks-layout-section .table-block .table-cols-4 {
    min-width: 600px;
  }
  .txt-blocks-layout-section .table-block .table-cols-8 {
    min-width: 800px;
  }
}
.txt-blocks-layout-section .table-block .table-cols-4 th {
  width: 25%;
}
.txt-blocks-layout-section .table-block .table-cols-4 td {
  width: 25%;
}
.txt-blocks-layout-section .table-block .table-cols-8 th {
  border-right: 1px solid #e0ece9;
  width: 10%;
}
.txt-blocks-layout-section .table-block .table-cols-8 th:first-of-type {
  width: 30%;
}
.txt-blocks-layout-section .table-block .table-cols-8 td {
  border-right: 1px solid #e0ece9;
  width: 10%;
}
.txt-blocks-layout-section .table-block .table-cols-8 td:first-of-type {
  width: 30%;
}
.txt-blocks-layout-section .table-block .heading-table {
  margin-bottom: 0;
}
.txt-blocks-layout-section .table-block .heading-table thead {
  color: #000;
}
@media screen and (max-width: 550px) {
  .txt-blocks-layout-section .table-block .heading-table thead {
    font-size: 6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-blocks-layout-section .table-block .heading-table thead {
    font-size: 30px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .table-block .heading-table thead {
    font-size: 1.563vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .table-block .heading-table thead {
    font-size: 30px;
  }
}
.txt-blocks-layout-section .table-block .heading-table thead th {
  vertical-align: middle;
}
.txt-blocks-layout-section .table-block .heading-table thead th:first-of-type {
  text-align: left;
  letter-spacing: 0.35px;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .table-block .heading-table thead th:first-of-type {
    letter-spacing: 0.018vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .table-block .heading-table thead th:first-of-type {
    letter-spacing: 0.35px;
  }
}
.txt-blocks-layout-section .table-block table {
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
  text-align: center;
  width: 100%;
  margin-bottom: 25px;
  font-weight: 700;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .table-block table {
    margin-bottom: 1.302vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .table-block table {
    margin-bottom: 25px;
  }
}
.txt-blocks-layout-section .table-block table thead {
  color: #002ea6;
}
@media screen and (max-width: 550px) {
  .txt-blocks-layout-section .table-block table thead {
    font-size: 4.8vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .txt-blocks-layout-section .table-block table thead {
    font-size: 24px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .table-block table thead {
    font-size: 1.25vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .table-block table thead {
    font-size: 24px;
  }
}
.txt-blocks-layout-section .table-block table thead th:first-of-type {
  text-align: left;
  letter-spacing: 0.35px;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .table-block table thead th:first-of-type {
    letter-spacing: 0.018vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section .table-block table thead th:first-of-type {
    letter-spacing: 0.35px;
  }
}
.txt-blocks-layout-section .table-block table tbody tr td:first-of-type {
  text-align: left;
  color: #5b5b5b;
}
.txt-blocks-layout-section .table-block table tbody tr td:not(:first-of-type) {
  color: #666666;
}
.txt-blocks-layout-section .table-block table tbody tr:nth-of-type(odd) {
  background-color: #f3fffc;
}
.txt-blocks-layout-section .table-block table strong,
.txt-blocks-layout-section .table-block table b {
  color: #002ea6;
}
.txt-blocks-layout-section .table-block table tr {
  vertical-align: initial;
}
.txt-blocks-layout-section .table-block table tr.four-columns-heading-row {
  vertical-align: middle;
}
.txt-blocks-layout-section .table-block table tr.four-columns-heading-row th {
  text-align: center;
  max-width: 150px;
}
.txt-blocks-layout-section .table-block table tr.four-columns-heading-row th:first-child {
  text-align: left;
}
.txt-blocks-layout-section .table-block table tr.four-columns-heading-row th img {
  display: block;
  margin: 0 auto;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section .table-block table tr.four-columns-heading-row th img {
    width: 35%;
  }
}
.txt-blocks-layout-section .table-block table img {
  vertical-align: middle;
}
.txt-blocks-layout-section .table-block table th {
  padding: 3px;
}
.txt-blocks-layout-section .table-block table th:first-of-type {
  padding-left: 0;
  padding-right: 0;
}
.txt-blocks-layout-section .table-block table td {
  padding: 4px;
}
@media screen and (max-width: 1024px) {
  .txt-blocks-layout-section .table-block table td {
    padding: 4px 10px;
  }
}
.txt-blocks-layout-section .table-block table td:first-of-type {
  padding-left: 0;
  padding-right: 0;
}
.txt-blocks-layout-section .table-block table tbody {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}

.quote-by-info {
  position: relative;
  padding-left: 48px;
}
@media screen and (min-width: 1025px) {
  .quote-by-info {
    padding-left: 2.5vw;
  }
}
@media screen and (min-width: 1921px) {
  .quote-by-info {
    padding-left: 48px;
  }
}
.quote-by-info::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  background-color: #002ea6;
  backface-visibility: hidden;
  top: 0.775em;
  width: 14px;
  height: 2px;
}

.txt-blocks-layout-section.landing-page-template-blocks {
  border-top: 1px solid #e3e8f5;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 1;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section.landing-page-template-blocks {
    padding-top: 0vw;
    padding-bottom: 0vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section.landing-page-template-blocks {
    padding-top: 0px;
    padding-bottom: 0px !important;
  }
}
.txt-blocks-layout-section.landing-page-template-blocks .img-content {
  border-radius: unset;
}
.txt-blocks-layout-section.landing-page-template-blocks .content-block {
  border-bottom: inherit !important;
}
.txt-blocks-layout-section.landing-page-template-blocks .txt-blocks {
  max-width: 1400px;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section.landing-page-template-blocks .txt-blocks {
    margin: 0vw -1.042vw;
    max-width: 72.917vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section.landing-page-template-blocks .txt-blocks {
    margin: 0 -20px;
    max-width: 1400px;
  }
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section.landing-page-template-blocks .svg-wrap-top-left {
    top: 15.521vw;
    left: -16.615vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section.landing-page-template-blocks .svg-wrap-top-left {
    top: 298px;
    left: -319px;
  }
}
.txt-blocks-layout-section.landing-page-template-blocks .svg-wrap-middle-right {
  justify-content: flex-end;
}
@media screen and (min-width: 1025px) {
  .txt-blocks-layout-section.landing-page-template-blocks .svg-wrap-middle-right {
    top: 11.771vw;
    right: -16.406vw;
  }
}
@media screen and (min-width: 1921px) {
  .txt-blocks-layout-section.landing-page-template-blocks .svg-wrap-middle-right {
    top: 226px;
    right: -315px;
  }
}

html.show-embed-popup-wrap {
  overflow: hidden;
}
html.show-embed-popup-wrap .embed-popup-wrap {
  opacity: 1;
  visibility: visible;
}

.embed-popup-wrap {
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  font-size: 0;
  transition: opacity 0.5s cubic-bezier(0.61, 1, 0.88, 1), visibility 0.5s cubic-bezier(0.61, 1, 0.88, 1);
}
.embed-popup-wrap .embed-content-wrap {
  position: absolute;
  backface-visibility: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  width: 100%;
}
.embed-popup-wrap .embed-content-wrap.show-content {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 550px) {
  .embed-popup-wrap .embed-content-wrap {
    max-width: calc(100% - 15.2vw);
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .embed-popup-wrap .embed-content-wrap {
    max-width: calc(100% - 76px);
  }
}
@media screen and (min-width: 1025px) {
  .embed-popup-wrap .embed-content-wrap {
    width: 64.063vw;
  }
}
@media screen and (min-width: 1921px) {
  .embed-popup-wrap .embed-content-wrap {
    width: 1230px;
  }
}
.embed-popup-wrap .iframe-container {
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.embed-popup-wrap iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@media screen and (max-width: 550px) {
  .block-builded-page .w-1523 {
    padding-left: 7.6vw;
    padding-right: 7.6vw;
  }
}
@media screen and (min-width: 551px) and (max-width: 1024px) {
  .block-builded-page .w-1523 {
    padding-left: 38px;
    padding-right: 38px;
  }
}
@media screen and (min-width: 1025px) {
  .block-builded-page .w-1523 {
    padding-left: 10.208vw;
    padding-right: 10.208vw;
  }
}
@media screen and (min-width: 1441px) {
  .block-builded-page .w-1523 {
    padding-left: 0;
    padding-right: 0;
  }
}
.block-builded-page .w-1523.icons-content-block-horizontal {
  margin: 0 auto;
}
.block-builded-page .w-1523.comparison-table {
  margin: 0 auto;
}

.comparison-table-fine-print {
  font-size: 14px;
}
@media screen and (min-width: 1025px) {
  .comparison-table-fine-print {
    padding-left: 45px;
  }
}
@media screen and (max-width: 1024px) {
  .comparison-table-fine-print {
    padding-left: 20px;
  }
}